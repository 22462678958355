import { put, call } from "redux-saga/effects";
import { userLogin, admins } from "../services/userServices";

export function* login(action) {
  try {
    yield put({ type: "LOGIN_IN_PROGRESS" });
    const userData = yield call(userLogin, action);
    if (userData) {
      let uId = userData.user.uid;
      yield call(admins, uId);
    }
  } catch (error) {
    console.log("error", error);
  }
}
