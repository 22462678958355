import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { debounce } from "lodash";

const AGAutocomplete = (props) => {
  // console.log("options", props.options, props.value, props.defaultValue);
  return (
    <Autocomplete
      // id='locations'
      noOptionsText={"No Options"}
      value={props.value || ""}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "0",
          padding: 0.5,
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          border: "0",
        },
        // width: 650,
        height: props.height,
        left: 137,
        top: 67,
        background: "#FFFFFF",
        border: "none",
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
        borderRadius: 2,
				cursor: "pointer",
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          fullWidth
          InputLabelProps={{}}
          placeholder={props.placeholder}
          error={props.error}
        />
      )}
      getOptionLabel={(option) => option.Name || option.name || ""}
      {...props}
      onInputChange={props.onInputChange && debounce(props.onInputChange, 300)}
    />
  );
};

export default AGAutocomplete;
