import DashboardIcon from "../assets/icons/DashboardIcon.png";
import LocationsIcon from "../assets/icons/LocationsIcon.png";
import MenuIcon from "../assets/icons/MenuIcon.png";
import AdminToolsIcon from "../assets/icons/AdminToolsIcon.png";
import OrdersIcon from "../assets/icons/OrdersIcon.png";
import AdvertisementIcon from "../assets/icons/AdvertisementIcon.png";
import CoOwnersIcon from "../assets/icons/CoOwnersIcon.png";
import VendorIcon from "../assets/icons/VendorIcon.png";
import SettingsIcon from "../assets/icons/SettingsIcon.png";
import LogoutIcon from "../assets/icons/LogoutIcon.png";
import BotManagement from "../assets/icons/BotManagement.png";

export const TopNavigationData = [
  {
    id: 0,
    path: "/",
    header: "Dashboard",
    Icon: DashboardIcon,
    disabled: false,
    role: "",
  },
  {
    id: 1,
    path: "/dailyplan",
    header: "Daily Plan",
    Icon: MenuIcon,
    disabled: false,
    role: "",
  },
  {
    id: 2,
    path: "/botManagement",
    header: "Bot Management",
    Icon: BotManagement,
    disabled: false,
    role: "SuperAdmin",
  },
  {
    id: 3,
    path: "/report",
    header: "Bot Report",
    Icon: AdminToolsIcon,
    disabled: false,
    role: "SuperAdmin",
  },
  {
    id: 4,
    path: "/orders",
    header: "Orders",
    Icon: OrdersIcon,
    disabled: true,
    role: "",
  },
  {
    id: 5,
    path: "/advertisement",
    header: "Advertisement",
    Icon: AdvertisementIcon,
    disabled: true,
    role: "",
  },
  {
    id: 6,
    path: "/coOwners",
    header: "Co-Owners",
    Icon: CoOwnersIcon,
    disabled: true,
    role: "",
  },
  {
    id: 7,
    path: "/vendor",
    header: "Vendor",
    Icon: VendorIcon,
    disabled: true,
    role: "",

    // component: "mainVendor",
  },
];

export const BottomNavigationData = [
  {
    id: 8,
    path: "/settings",
    header: "Settings",
    divider: true,
    Icon: SettingsIcon,
    disabled: false,
    role: "SuperAdmin",
  },
];

export const LogOutData = [
  {
    id: 9,
    // path: "/logout",
    header: "Logout",
    Icon: LogoutIcon,
    disabled: false,
    role: "",
  },
];
