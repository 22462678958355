import React from "react";
import {
  IconButton,  
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import colors from "../themes/colors";
import {  isEmpty } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";


export const FHSearchBar = (props) => {
	const {placeholder, value, onChange, handleSearch} = props;
  return (
    <Box
      mt={1}
      mb={1}
      display={"flex"}
      width="100%"
      border="1px solid black"
      borderRadius={1}
    >
      <TextField
        fullWidth
        placeholder={placeholder}
        value={value || ""}
        size="small"
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "0",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: "0",
          },
          width: "88%",
        }}
        onChange={onChange}
      />

      <Box mt={0.5} display="flex-end">
        <IconButton
          type="submit"
          aria-label="search"
          onClick={handleSearch}
        >
          {isEmpty(value) ? (
            <SearchIcon style={{ fill: colors.success }} />
          ) : (
            <CloseIcon />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};
