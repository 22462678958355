import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
	Box,
	Typography,
	FormControlLabel,
	Radio,
	RadioGroup,
	Chip,
} from "@mui/material";
import colors from "../../../themes/colors";
import { useDispatch, useSelector } from "react-redux";
import { map, filter, isEmpty } from "lodash";
import FHButton from "../../../components/FHButton";
import { FHLabel } from "../../../components/FormInputs/FHLabel";
import { FHTextfield } from "../../../components/FormInputs/FHTextfield";
import { FHAutocomplete } from "../../../components/FormInputs/FHAutocomplete";
import { FHDateTimePicker } from "../../../components/FormInputs/FHDateTimePicker";
import { WashTypes, plateNos, status } from "../../../data/dailyPlans";
import moment from "moment";
import { AddPlan } from "../../../services/dailyPlanServices";
import { generateRandomString } from "../../../services/utils";
import * as yup from "yup";

const validationSchema = yup.object({
	noOfBoxes: yup
		.number()
		.min(1, "no of boxes cannot be less than 1")
		.required("no of boxes is required"),
	when: yup.date()
		.test('is-greater-than-current', 'Date and time must be in the future', (value) => {
			const currentTimeMinus10 = new Date();
			currentTimeMinus10.setMinutes(currentTimeMinus10.getMinutes() - 10);
			return new Date(value) > currentTimeMinus10;
		})
		.required('Date and time is required')

});

const validationSchema2 = yup.object({
	status: yup.object().required("status is required")
});

const StoreAndWash = (props) => {
	const activityInfo = props.activityInfo
	const dispatch = useDispatch();

	const selectedLocation = useSelector(
		(state) => state.locationReducer.selectedLocation
	);
	const selectedMachine = useSelector(
		(state) => state.dailyPlanReducer.selectedMachine
	);
	const selectedEditPlan = useSelector(
		(state) => state.dailyPlanReducer.selectedEditPlan
	);
	const editPlan = useSelector((state) => state.dailyPlanReducer.editPlan);
	const dailyPlansByFilter = useSelector(
		(state) => state.dailyPlanReducer.dailyPlansByFilter
	);
	const duplicatePlan = useSelector(
		(state) => state.dailyPlanReducer.duplicatePlan
	);
	const addStatus = useSelector(
		(state) => state.dailyPlanReducer.addStatus
	);

	const [showQuantity, setShowQuantity] = useState(false);
	const [readOnly, setReadOnly] = useState(false);
	const [planExists, setPlanExists] = useState(false);
	const [showAddStatus, setShowAddStatus] = useState(false);
	const [showStatusButton, setShowStatusButton] = useState(false)
	const [showErrMsg, setShowErrMsg] = useState(false)



	const formik = useFormik({
		// enableReinitialize: editPromo,
		initialValues: {
			noOfBoxes: 5,
			washType: WashTypes[0],
			plates: "All Plates",
			plateNumbers: [],
			when: new Date(),
			status: status[0],
		},
		validationSchema: addStatus ? validationSchema2 : validationSchema,
		onSubmit: (e) => {
			console.log("formik values on submit", formik.values);
			AddStoreWash();
		},
	});

	let selectedPlateNos = formik.values.plateNumbers;
	let plateNoDropdown = filter(
		plateNos,
		(item) => !selectedPlateNos.includes(item)
	);

	const deleteSpecificPlates = (data, formik) => {
		console.log("v, formik", data, formik);
		if (!readOnly) {
			let fData = filter(
				formik.values.plateNumbers,
				(plateNo) => plateNo !== data
			);
			formik.setFieldValue("plateNumbers", fData);
		}
	};

	const AddStoreWash = async () => {
		let locId = selectedLocation?.d?.locId;
		let botId = selectedMachine?.id;
		let washType = formik.values.washType;
		let totalTime = washType?.timePeriod + 20;
		let endTime = moment(formik.values.when).add(totalTime, "minutes");
		let sTime = new Date(formik.values.when);
		let doesAnyPlanExistAtSelectedTime = filter(
			dailyPlansByFilter,
			(item) =>
				item?.when === sTime ||
				(item?.start < sTime && item?.end > endTime) ||
				(item?.start < sTime && item?.end > sTime) ||
				(item?.start > sTime && item?.start < endTime && item?.end > endTime) ||
				(item?.start > sTime && item?.end < endTime)
		);
		// console.log(
		// 	"doesAnyPlanExistAtSelectedTime",
		// 	dailyPlansByFilter,
		// 	sTime,
		// 	doesAnyPlanExistAtSelectedTime
		// );
		if (isEmpty(doesAnyPlanExistAtSelectedTime) || (!isEmpty(doesAnyPlanExistAtSelectedTime) && doesAnyPlanExistAtSelectedTime[0]?.status === "FAILED")  || (!isEmpty(doesAnyPlanExistAtSelectedTime) && doesAnyPlanExistAtSelectedTime[0]?.status === "COMPLETED")) {
			setPlanExists(false);
			let planData = {
				locId: locId,
				botId: botId,
				start: new Date(formik.values.when),
				end: new Date(endTime),
				noOfBoxes: formik.values.noOfBoxes,
				washType: formik.values.washType,
				plates: formik.values.plates,
				plateNumbers: [],
				operation: "Store+Wash",
				when: formik.values.when,
			};
			let isCreatingDoc = true;
			let docId;
			if (editPlan && !addStatus) {
				docId = selectedEditPlan?.docId;
			} else if (editPlan && addStatus) {
				docId = selectedEditPlan?.docId;
				let planStatus = formik.values.status
				planData = {
					...planData,
					status: planStatus?.name
				}
			}
			else {
				docId = generateRandomString(isCreatingDoc, 20);
			}
			// console.log("addStoreWash", planData);
			// AddPlan(locId, botId, docId, planData);
			// dispatch({ type: "DAILY_PLAN", payload: false });
			// dispatch({ type: "EDIT_PLAN", payload: false });
			// dispatch({ type: "SELECTED_ADD_PLAN", payload: {} });
			try {
				const addPlanResult = await AddPlan(locId, botId, docId, planData);
				if (addPlanResult === 'success') {
					dispatch({
						type: "LOG_ACTIVITY",
						payload: activityInfo,
					});
					setShowErrMsg(false)
					dispatch({ type: "DAILY_PLAN", payload: false });
					dispatch({ type: "EDIT_PLAN", payload: false });
					dispatch({ type: "SELECTED_ADD_PLAN", payload: {} });
				} else {
					setShowErrMsg(true)
					console.log("Add Plan Error", addPlanResult);
				}

			} catch (error) {
				console.error("Error while adding plan:", error);
			}
		} else {
			setPlanExists(true);
		}
	};

	useEffect(() => {
		if (!isEmpty(selectedEditPlan)) {
			formik.setFieldValue("noOfBoxes", selectedEditPlan?.noOfBoxes);
			formik.setFieldValue("washType", selectedEditPlan?.washType);
			formik.setFieldValue("plates", selectedEditPlan?.plates);
			formik.setFieldValue("plateNumbers", selectedEditPlan?.plateNumbers);
			formik.setFieldValue("when", selectedEditPlan?.when);
			if (selectedEditPlan?.when < new Date()) {
				setReadOnly(true);
				dispatch({ type: "ADD_STATUS", payload: true });
				setShowStatusButton(true)
			}
		}
	}, []);

	useEffect(() => {
		if (duplicatePlan) {
			setReadOnly(false);
			dispatch({ type: "EDIT_PLAN", payload: false });
			formik.setFieldValue("when", new Date());
			dispatch({ type: "DUPLICATE_PLAN", payload: false });
		}
	}, [duplicatePlan]);

	const handleAddPlanStatus = () => {
		setShowAddStatus(true);
		setShowStatusButton(false)
	}

	return (
		<Box>
			<form onSubmit={formik.handleSubmit}>
				<FHLabel label={"No of Boxes"} />
				<FHTextfield
					fullWidth
					id="noOfBoxes"
					name="noOfBoxes"
					type="number"
					disabled={readOnly}
					value={formik.values.noOfBoxes}
					onChange={formik.handleChange}
					error={formik.touched.noOfBoxes && Boolean(formik.errors.noOfBoxes)}
					helperText={formik.touched.noOfBoxes && formik.errors.noOfBoxes}
				/>

				<FHLabel label={"Wash Type"} />
				<FHAutocomplete
					options={WashTypes}
					defaultValue={formik.values.washType}
					value={formik.values.washType}
					disabled={readOnly}
					singleselect={"true"}
					id="washType"
					name="washType"
					placeholder="Select Wash Type"
					multiple={false}
					onChange={(e, newValue) => formik.setFieldValue("washType", newValue)}
					error={formik.touched.washType && Boolean(formik.errors.washType)}
					helperText={formik.touched.washType && formik.errors.washType}
				/>

				<FHLabel label={"Plates"} />
				<RadioGroup
					row
					aria-labelledby="demo-radio-buttons-group-label"
					disabled={readOnly}
					value={formik.values.plates}
					onChange={(e) => formik.setFieldValue("plates", e.target.value)}
					error={formik.touched.plates && Boolean(formik.errors.plates)}
					helperText={formik.touched.plates && formik.errors.plates}
				>
					<FormControlLabel
						value="All Plates"
						control={
							<Radio
								sx={{
									color: colors.lightGrey,
									"&.Mui-checked": {
										color: colors.success,
									},
								}}
								disabled={readOnly}
							/>
						}
						label="All Plates"
						sx={{
							"& .MuiFormControlLabel-label": {
								fontWeight: 600,
								fontSize: 14,
							},
						}}
					/>
					<FormControlLabel
						value="Previously Cooked"
						control={
							<Radio
								sx={{
									color: colors.lightGrey,
									"&.Mui-checked": {
										color: colors.success,
									},
								}}
								disabled={readOnly}
							/>
						}
						label="Previously Cooked"
						sx={{
							"& .MuiFormControlLabel-label": {
								fontWeight: 600,
								fontSize: 14,
							},
						}}
					/>
					<FormControlLabel
						value="Specific Plates"
						control={
							<Radio
								sx={{
									color: colors.lightGrey,
									"&.Mui-checked": {
										color: colors.success,
									},
								}}
								disabled={readOnly}
							/>
						}
						label="Specific Plates"
						sx={{
							"& .MuiFormControlLabel-label": {
								fontWeight: 600,
								fontSize: 14,
							},
						}}
					/>
				</RadioGroup>

				{formik.values.plates === "Specific Plates" && (
					<FHAutocomplete
						multiple
						options={plateNoDropdown}
						defaultValue={[]}
						value={formik.values.plateNumbers}
						disabled={readOnly}
						id="plateNumbers"
						name="plateNumbers"
						placeholder="Select plate numbers"
						onChange={(e, newValue) =>
							formik.setFieldValue("plateNumbers", newValue)
						}
						chip={map(formik.values.plateNumbers, (data, i) => (
							<Box mb={1} key={i}>
								<Chip
									label={
										<Typography style={{ whiteSpace: "normal", fontSize: 14 }}>
											{data}
										</Typography>
									}
									variant="filled"
									onDelete={() => {
										deleteSpecificPlates(data, formik);
									}}
									style={{ background: "#F0F4FF", color: "black" }}
								/>
							</Box>
						))}
						error={
							formik.touched.plateNumbers && Boolean(formik.errors.plateNumbers)
						}
						helperText={
							formik.touched.plateNumbers && formik.errors.plateNumbers
						}
					/>
				)}



				<FHLabel label={"When?"} />
				<FHDateTimePicker
					value={formik.values.when}
					editForm={editPlan}
					disabled={readOnly}
					onChange={(newValue) => formik.setFieldValue("when", newValue?.$d)}
					error={formik.touched.when && Boolean(formik.errors.when)}
				// helperText={formik.touched.when && formik.errors.when}
				/>
				{!readOnly && formik.errors.when && <Typography color={'red'} fontSize={12}>{formik.errors.when}</Typography>}
				{planExists && (
					<Typography color={"red"} fontSize={13}>
						A plan has been already added at the selected time period.
					</Typography>
				)}

				{showStatusButton && addStatus && !selectedEditPlan?.hasOwnProperty('status') || showStatusButton && addStatus && (selectedEditPlan?.hasOwnProperty('status') && selectedEditPlan?.status !== "COMPLETED") ? (
					<Box mt={2} mb={2} display={"flex"} justifyContent={"flex-start"}>
						<FHButton
							name={"Update Plan Status"}
							variant="outlined"
							color={colors.success}
							small
							w_40
							commonButtonStyle
							onClick={handleAddPlanStatus}
						/>
					</Box>
				) : <></>}

				{showAddStatus ? (<>
					<FHLabel label={"Add Status"} />
					<FHAutocomplete
						options={status}
						defaultValue={formik.values.status}
						value={formik.values.status}
						singleselect={"true"}
						id="status"
						name="status"
						placeholder="Select Status"
						multiple={false}
						onChange={(e, newValue) => formik.setFieldValue("status", newValue)}
						error={formik.touched.status && Boolean(formik.errors.status)}
						helperText={formik.touched.status && formik.errors.status}
					/>
				</>) : <></>}

				{showErrMsg && <Box mt={2}> <Typography className="error-msg" color={"red"} fontSize={12}>Something went wrong while adding the plan.</Typography></Box>}

				{!readOnly || showAddStatus ? (
					<Box mt={14} mb={2} display={"flex"} justifyContent={"flex-end"}>
						<FHButton
							name={showAddStatus ? "Add Status" : "Save Plan"}
							variant="outlined"
							disabled={showQuantity}
							color={colors.success}
							small
							w_40
							commonButtonStyle
							onClick={formik.handleSubmit}
						// errors={validated ? validate.errors : undefined}
						/>
					</Box>
				) : <></>}
			</form>
		</Box>
	);
};

export default StoreAndWash;
