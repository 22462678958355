import React, { useState, useEffect } from "react";
import FHSelect from "../../components/FHSelect";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, map } from "lodash";

export const DashboardLocSelect = () => {
  const dispatch = useDispatch();

  const locations = useSelector((state) => state.locationReducer.locations);
  const locationDropdown = useSelector(
    (state) => state.locationReducer.locationDropdown
  );
  const updatingOutlet = useSelector(
    (state) => state.locationReducer.updatingOutlet
  );

  useEffect(() => {
    if (!isEmpty(locations)) {
      let allLocId = map(locations, (item) => item.d?.locId);
      allLocId.push("common");
      let allLocations = {
        Name: "All Locations",
        locIds: allLocId,
      };
      let locationDropdownList = [allLocations, ...(locations || [])];
      dispatch({ type: "LOCATION_DROPDOWN", payload: locationDropdownList });
    }
  }, [locations]);

  let defaultLocation = !isEmpty(locationDropdown) && locationDropdown[0];
  const [location, setSelectedLocation] = useState("");

  useEffect(() => {
    if (!isEmpty(defaultLocation) && !updatingOutlet) {
      setSelectedLocation(defaultLocation);
      dispatch({
        type: "STORE_SELECTED_LOCATION",
        payload: defaultLocation,
      });
    }
  }, [defaultLocation]);

  useEffect(() => {
    dispatch({ type: "UPDATING_OUTLET_STATUS", payload: false });
  }, []);

  const handleSelectLocations = (e, v) => {
    console.log("vv", v);
    if (v) {
      setSelectedLocation(v);
      dispatch({ type: "STORE_SELECTED_LOCATION", payload: v });
    } else {
      setSelectedLocation("");
      dispatch({ type: "STORE_SELECTED_LOCATION", payload: "" });
    }
  };

  return (
    <FHSelect
      height={45}
      disableClearable={true}
      name="locations"
      placeholder="Select location"
      options={locationDropdown || []}
      value={(!isEmpty(location) && location) || {}}
      onChange={(e, v) => {
        handleSelectLocations(e, v);
      }}
    />
  );
};
