import React, { useEffect, useState } from "react";
import { Grid, List, ListItem, Typography, Popover } from "@mui/material";
import { Box } from "@mui/system";
import colors from "../../themes/colors";
import { filter } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";
import { AddOrEditUser } from "./AddOrEditUser";
import { FHDrawer } from "../../components/FHDrawer";
import EditOrDuplicate from "./EditOrDuplicate";
import FHButton from "../../components/FHButton";
import FHDialogBox from "../../components/FHDialogBox";
import { updateAdmin } from "../../services/userServices";
import { notify } from "../../services/utils";
import { roles } from "../../Constants";
import { FHAutocomplete } from "../../components/FormInputs/FHAutocomplete";
import { FHLabel } from "../../components/FormInputs/FHLabel";

const customStyles = makeStyles(commonClasses);

export const AddedAdminUsers = (props) => {
  const common = customStyles(commonClasses);
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);
  const [adminIds, setAdminIds] = useState();
  const [addUserPop, setAddUserPop] = useState(false);
  const [role, setRole] = useState({ id: "SuperAdmin", name: "Super Admin" });
  const [AddComItem, setAddComItem] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const menuLocation = useSelector((state) => state.menuReducer.menuLocation);
  const allAdminUsers = useSelector((state) => state.userReducer.allAdminUsers);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    dispatch({ type: "STORE_SELECTED_ADMIN_DETAILS", payload: item });
  };

  const handleAddItem = (item) => {
    setAddUserPop(!addUserPop);
    setAnchorEl(null);
    notify("User Added successfully");
    let updateLocIds = item?.locIds || [];
    updateLocIds.push(menuLocation.d.locId);
    updateAdmin({ ...item, locIds: updateLocIds });
    // dispatch({ type: "GET_ALL_ADMIN_USERS" });
  };

  const handleClose = (name) => {
    if (name === "Edit") {
      setDrawer(true);
    }

    setAddComItem(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  const currentLocMenu = useSelector(
    (state) => state.menuReducer.currentLocationMenu
  );

  let addAdmins = [];
  filter(allAdminUsers, (itm) =>
    itm?.locIds?.includes(menuLocation?.d.locId)
  ).map((item, i) => {
    addAdmins.push(item.uid);
  });

  useEffect(() => {
    setAdminIds(addAdmins);
  }, [menuLocation, currentLocMenu, allAdminUsers]);

  return (
    <Box>
      <Grid container mb={1}>
        <Grid item xs={8} sm={6}>
          <Typography pt={1} fontSize={18} fontWeight={700}>
            Users
          </Typography>
        </Grid>
        <Grid mt={1} item xs={4} sm={6} textAlign={"right"}>
          <FHButton
            name={"Add +"}
            variant="outlined"
            smallAddButton
            commonButtonStyle
            onClick={() => setAddUserPop(!addUserPop)}
          />
        </Grid>
      </Grid>
      <Box justifyContent={"center"}>
        <List className={common.height18Vh}>
          {filter(allAdminUsers, (itm) =>
            itm?.locIds?.includes(menuLocation?.d.locId)
          ).map((item, i) => {
            return (
              <ListItem
                key={i}
                mt={1}
                className={[common.menulistItemalign, common.bgMain].join(" ")}
                onClick={(e) => handleClick(e, item)}
              >
                <Grid container>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <Typography
                      fontSize={15}
                      color={colors.darkGrey}
                      style={{
                        width: "170px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.name || item.firstName || "No Name"} ({item.role})
                    </Typography>
                    <Typography
                      textAlign={"right"}
                      fontSize="12px"
                      style={{
                        // width: "230px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      color={colors.darkGrey}
                    >
                      {item.uid}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    md={12}
                    xs={12}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <Typography fontSize={12} color={colors.LighterGrey}>
                      {item?.email}
                    </Typography>

                    <Typography
                      textAlign={"right"}
                      fontSize={12}
                      color={colors.LighterGrey}
                    >
                      {item?.phoneNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <FHDialogBox
        open={addUserPop}
        handleClose={() => setAddUserPop(!addUserPop)}
        body={
          <Box style={{ width: "420px" }}>
            <FHLabel label={"User Role"} />
            <FHAutocomplete
              options={roles}
              value={role}
              singleselect={"true"}
              id="item"
              name="item"
              placeholder="Select User role"
              multiple={false}
              onChange={(e, newValue) => setRole(newValue)}
            />
            <Grid container spacing={1} mt={1}>
              <Grid item md={12} sm={12} xs={12} mb={0.5}>
                <Typography>Results</Typography>
              </Grid>
              {filter(
                allAdminUsers,
                (itm) =>
                  adminIds &&
                  !adminIds.includes(itm?.uid) &&
                  itm?.role === role?.id
              ).map((u, i) => {
                return (
                  <Grid
                    item
                    key={i}
                    md={6}
                    sm={6}
                    xs={6}
                    onClick={(e) => handleAddItem(u)}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      gap="12px"
                      key={i}
                      p={1}
                      style={{
                        border: "1px solid #ACACAC",
                        borderRadius: "5px",
                      }}
                      alignItems="center"
                      className={common.mousecursor}
                      onClick={(e) => handleAddItem(u)}
                    >
                      <Typography>
                        {u?.firstName || u?.lastName || u?.name || "no name"}(
                        {u.role})
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        }
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <EditOrDuplicate
          editAndDuplicate={false}
          anchorEl={anchorEl}
          onPopClose={handleClose}
        />
      </Popover>
      <FHDrawer
        open={drawer}
        onClose={() => {}}
        onOpen={() => {}}
        drawerBorder
        data={<AddOrEditUser onClose={() => setDrawer(false)} />}
      />
    </Box>
  );
};
