import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import colors from "../../themes/colors";
import { filter, map, isEmpty, uniqBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  sortOrders,
  getIconsusers,
  filterData,
  getOrdersByDateFilter,
} from "../../services/utils";
import { getTime, covertdateformate } from "../../services/dateTimeConversion";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonOffRoundedIcon from "@mui/icons-material/PersonOffRounded";
import FHButton from "../../components/FHButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { FHSearchBar } from "../../components/FHSearchBar";

const customStyles = makeStyles(commonClasses);

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export const UserCard = (props) => {
  const common = customStyles(commonClasses);
  const dispatch = useDispatch();
  const mobileView = useMediaQuery("(max-width:920px)");

  const [searchQuery, setSearchQuery] = useState("");
  const [alldata, setalldata] = useState(true);
  const [newdata, setnewdata] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [loadMoreUsers, setLoadMoreUsers] = useState(false);

  const selectedLocation = useSelector(
    (state) => state.locationReducer.selectedLocation
  );
  const historyData = useSelector((state) => state.orderReducer.historyData);
  const Incartdata = useSelector((state) => state.orderReducer.incartData);
  const cartData = useSelector((state) => state.orderReducer.cartData);
  const registeredUsers = useSelector(
    (state) => state.userReducer.registeredusers
  );
  const userSearchData = useSelector(
    (state) => state.userReducer.userSearchData
  );

  const selectedUser = useSelector(
    (state) => state.userReducer.selectedUser || []
  );
  const dateRange = useSelector((state) => state.orderReducer.dateRange);
  // const ordersByDate = useSelector((state) => state.orderReducer.ordersByDate);

  let ordersList = [];
  ordersList = [
    ...(map(historyData, (item) => item) || []),
    ...(map(Incartdata, (item) => item) || []),
    ...(map(cartData, (item) => item) || []),
  ];
  let finalOrders = uniqBy(ordersList, (item) => item.cartId);

  let ordersByDate = [];
  if (!isEmpty(finalOrders) && !isEmpty(dateRange)) {
    ordersByDate = getOrdersByDateFilter(finalOrders, dateRange);
  }
  let ordersByLocation =
    selectedLocation?.Name === "All Locations"
      ? map(ordersByDate, (item) => item)
      : filter(
          ordersByDate,
          (item) => item.locationId === selectedLocation?.d?.locId
        );

  // INSERT EXTRA DATA IN USERS

  function AddRegisteredData(data) {
    const regData = map(data, (item) => {
      const name = item.name === "" ? "" : item.name;
      return {
        ...item,
        userId: item.uid,
        name: "",
        currentStatus: "No Orders",
        userDetails: { name },
        joined: item.createdAt,
      };
    });
    return regData;
  }

  const registerdata = filter(
    AddRegisteredData(registeredUsers),
    (u) => u?.userDetails?.name !== ""
  );

  // GET ONLY 24HRS DATA
  function convertDateStringToTimestamp(dateString) {
    return Math.floor(new Date(dateString).getTime() / 1000);
  }

  const dataFiltered = filter(
    ordersByLocation,
    (item, index, arr) =>
      arr.findIndex((t) => t.userId === item.userId) === index
  );
  const anonymous = filter(
    dataFiltered,
    (u) => u.userDetails.name === "Anonymous"
  );

  useEffect(() => {
    dispatch({ type: "SHOW_REGISTERED_USER_DATA", payload: dataFiltered });
  }, []);

  let userLists = [];
  if (alldata) {
    userLists = dataFiltered;
  } else if (newdata) {
    userLists = getOrdersByDateFilter(registerdata, dateRange);
  } else {
    userLists = anonymous;
  }

  const finalUserData = filterData(
    searchQuery,
    sortOrders(getIconsusers(userLists))
  );

  // SHOW ANONYMOUS LENGTH

  const handleUserDetails = (data) => {
    if (mobileView) {
      dispatch({ type: "USERSCARD", payload: false });
    } else {
      dispatch({ type: "MENUCARD", payload: false });
      dispatch({ type: "PROMOCARD", payload: false });
      dispatch({ type: "FEEDBACKCARD", payload: false });
    }

    dispatch({ type: "GET_SELECTED_USER", payload: data?.userId || data.uid });
    dispatch({
      type: "GET_USER_OLD_ORDERS",
      payload: data?.userId || data.uid,
    });
    dispatch({ type: "USERDETAILS", payload: true });
  };

  const handleSearchQuery = (txt) => {
    setSearchQuery(txt);
    dispatch({ type: "CLEAR_USER_SEARCH_DATA" });
  };

  const handleSeachClick = () => {
    if (!isEmpty(searchQuery)) {
      setSearchQuery("");
    }
    return;
  };

  useEffect(() => {
    if (isEmpty(searchQuery)) {
      dispatch({ type: "CLEAR_USER_SEARCH_DATA" });
    }
  }, [searchQuery]);

  const handleLoadMoreOrders = () => {
    if (!isEmpty(searchQuery)) {
      dispatch({
        type: "GET_USER_SEARCH_DATA",
        payload: { searchQuery: searchQuery },
      });
      setLoadMoreUsers(true);
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <Box>
          <Typography fontSize={23} fontWeight={700}>
            Users
          </Typography>
        </Box>
        <Box display="flex" justifyContent="end" gap={3}>
          <Grid
            borderRadius={14}
            className={alldata ? common.userCartActive : ""}
          >
            <BootstrapTooltip title="All users">
              <IconButton
                onClick={() => {
                  setalldata(true);
                  setnewdata(false);
                }}
              >
                <Grid display="flex" alignItems="center" gap={1}>
                  <GroupsIcon
                    style={{ color: colors.success }}
                    fontSize="medium"
                  />
                  <Typography fontSize={12} fontWeight={600}>
                    {dataFiltered.length}
                  </Typography>
                </Grid>
              </IconButton>
            </BootstrapTooltip>
          </Grid>
          <Grid
            borderRadius={14}
            className={!alldata && !newdata ? common.userCartActive : ""}
          >
            <BootstrapTooltip title="Anonymous users">
              <IconButton
                onClick={() => {
                  setalldata(false);
                  setnewdata(false);
                }}
              >
                <Grid display="flex" alignItems="center" gap={1}>
                  <PersonOffRoundedIcon
                    style={{ color: colors.danger }}
                    fontSize="medium"
                  />
                  <Typography fontSize={12} fontWeight={600}>
                    {anonymous.length}
                  </Typography>
                </Grid>
              </IconButton>
            </BootstrapTooltip>
          </Grid>
          <Grid
            borderRadius={14}
            className={newdata ? common.userCartActive : ""}
          >
            <BootstrapTooltip title="Newly Registered users">
              <IconButton
                onClick={() => {
                  setalldata(false);
                  setnewdata(true);
                }}
              >
                <Grid display="flex" alignItems="center" gap={1}>
                  <GroupAddIcon
                    style={{ color: colors.incartblue }}
                    fontSize="medium"
                  />
                  <Typography fontSize={12} fontWeight={600}>
                    {getOrdersByDateFilter(registerdata, dateRange)?.length}
                  </Typography>
                </Grid>
              </IconButton>
            </BootstrapTooltip>
          </Grid>
        </Box>
      </Box>
      <Box>
        <FHSearchBar
          placeholder={"Search by name, userId, mobile, email..."}
          value={searchQuery || ""}
          onChange={(event) => handleSearchQuery(event.target.value)}
          handleSearch={handleSeachClick}
        />
        <List
          className={
            isEmpty(selectedUser) && !mobileView
              ? common.listalignUser
              : !isEmpty(selectedUser) && !mobileView
              ? common.selectedListalignUser
              : mobileView
              ? common.selectedListalignUser
              : ""
          }
        >
          {!isEmpty(finalUserData) ? (
            <>
              {map(finalUserData, (item, i) => (
                <ListItem
                  key={i}
                  className={
                    !isEmpty(selectedUser) && selectedUser?.uid === item.userId
                      ? common.selectedUser
                      : common.userlistItemalign
                  }
                  // onClick={() => handleUserDetails(u)}
                  onClick={() => handleUserDetails(item)}
                >
                  <Box width="100%">
                    <Grid container display="flex">
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontWeight={600}
                          color={
                            !isEmpty(selectedUser) &&
                            selectedUser?.uid === item.userId
                              ? colors.bright
                              : "black"
                          }
                        >
                          {item?.userDetails?.name
                            ? item?.userDetails?.name.length < 16
                              ? item?.userDetails?.name
                              : item?.userDetails?.name
                            : // : item?.userDetails?.name.slice(0, 16) + "..."
                              "Anonymous"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className={common.flex_end}
                        color={
                          !isEmpty(selectedUser) &&
                          selectedUser?.uid === item.userId
                            ? colors.bright
                            : "black"
                        }
                      >
                        <Typography fontSize={14} fontWeight={600}>
                          {item?.userDetails?.mobileNo
                            ? item?.userDetails?.mobileNo
                            : item?.userDetails?.email < 16
                            ? item?.userDetails?.email
                            : item?.userDetails?.email}
                          {/* : item?.userDetails?.email.slice(0, 12) + "..."} */}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid>
                          {item?.currentStatus === "No Orders" ? (
                            <>
                              <Typography
                                display="inline"
                                fontSize={11}
                                color={
                                  !isEmpty(selectedUser) &&
                                  selectedUser?.uid === item.userId
                                    ? colors.bright
                                    : colors.darkGrey
                                }
                              >
                                User ID :
                              </Typography>
                              <Typography
                                display="inline"
                                fontSize={11}
                                color={
                                  !isEmpty(selectedUser) &&
                                  selectedUser?.uid === item.userId
                                    ? colors.bright
                                    : colors.darkGrey
                                }
                              >
                                {item.userId}
                              </Typography>
                            </>
                          ) : (
                            <Grid
                              display="flex"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Grid
                                className={common.flex_start}
                                gap="8px"
                                alignItems="center"
                              >
                                <item.icon.src
                                  style={{
                                    color:
                                      !isEmpty(selectedUser) &&
                                      selectedUser?.uid === item.userId
                                        ? colors.bright
                                        : item.icon.color,

                                    height: "15px",
                                    width: "15px",
                                  }}
                                />
                                <Typography
                                  fontSize={10.5}
                                  color={
                                    !isEmpty(selectedUser) &&
                                    selectedUser?.uid === item.userId
                                      ? colors.bright
                                      : colors.darkGrey
                                  }
                                >
                                  {item?.cartId}
                                </Typography>
                              </Grid>

                              <Grid>
                                <Typography
                                  fontSize={12}
                                  color={
                                    !isEmpty(selectedUser) &&
                                    selectedUser?.uid === item.userId
                                      ? colors.bright
                                      : "black"
                                  }
                                >
                                  {getTime(item?.createdAt?.seconds)}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>

                        {item.joined ? (
                          <>
                            <Typography
                              fontSize={11}
                              display="inline"
                              color={
                                !isEmpty(selectedUser) &&
                                selectedUser?.uid === item.userId
                                  ? colors.bright
                                  : colors.darkGrey
                              }
                            >
                              joined :
                            </Typography>
                            <Typography
                              display="inline"
                              fontSize={11}
                              color={
                                !isEmpty(selectedUser) &&
                                selectedUser?.uid === item.userId
                                  ? colors.bright
                                  : colors.darkGrey
                              }
                            >
                              {
                                covertdateformate(item?.createdAt?.seconds)
                                  ?.dateTime
                              }
                            </Typography>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className={common.flex_end}>
                      <Typography
                        fontSize={13}
                        color={
                          !isEmpty(selectedUser) &&
                          selectedUser?.uid === item.userId
                            ? colors.bright
                            : "black"
                        }
                      >
                        {item?.orderTotal ? `Rs ${item?.orderTotal} ` : <></>}
                      </Typography>
                    </Grid>
                  </Box>
                </ListItem>
              ))}
            </>
          ) : (
            <Box justifyContent={"centeter"}>
              <Typography>No users.</Typography>
            </Box>
          )}

          {!isEmpty(searchQuery) && isEmpty(userSearchData) && (
            <Box mt={0.5} mb={2}>
              <FHButton
                name={"Load more"}
                variant="outlined"
                w_50
                bgSuccess
                extraSmallButton
                onClick={() => handleLoadMoreOrders()}
              ></FHButton>
            </Box>
          )}
          {!isEmpty(searchQuery) && loadMoreUsers && isEmpty(userSearchData) ? (
            <Box justifyContent={"centeter"}>
              <Typography>No users found.</Typography>
            </Box>
          ) : !isEmpty(searchQuery) &&
            loadMoreUsers &&
            !isEmpty(userSearchData) ? (
            <>
              <Box mt={0.5} mb={0.5}>
                <Typography variant="body2" color={colors.success}>
                  More Users
                </Typography>
              </Box>
              {map(userSearchData, (item, i) => (
                <ListItem
                  key={i}
                  className={common.userlistItemalign}
                  // onClick={() => handleUserDetails(u)}
                  onClick={() => handleUserDetails(item)}
                >
                  <Box width="100%">
                    <Grid container spacing={1} display="flex">
                      <Grid item xs={6}>
                        <Typography fontSize={14} fontWeight={600}>
                          {item.name
                            ? item.name.length < 16
                              ? item.name
                              : item.name
                            : // : item?.userDetails?.name.slice(0, 16) + "..."
                              "Anonymous"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} className={common.flex_end}>
                        <Typography fontSize={14} fontWeight={600}>
                          {item.mobileNo
                            ? item.mobileNol
                            : item.email < 16
                            ? item.email
                            : item.email}
                          {/* : item?.userDetails?.email.slice(0, 12) + "..."} */}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid>
                          <Typography display="inline" fontSize={11}>
                            User ID :
                          </Typography>
                          <Typography
                            display="inline"
                            fontSize={11}
                            color={colors.linergrey}
                          >
                            {item.uid}
                          </Typography>
                        </Grid>

                        <>
                          <Typography fontSize={11} display="inline">
                            joined :
                          </Typography>
                          <Typography
                            display="inline"
                            fontSize={11}
                            color={colors.linergrey}
                          >
                            {
                              covertdateformate(item?.createdAt?.seconds)
                                ?.dateTime
                            }
                          </Typography>
                        </>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className={common.flex_end}>
                      <Typography fontSize={13}>
                        {item?.orderTotal ? `Rs ${item?.orderTotal} ` : <></>}
                      </Typography>
                    </Grid>
                  </Box>
                </ListItem>
              ))}
            </>
          ) : (
            <></>
          )}
        </List>
      </Box>
    </>
  );
};
