import React, { useEffect, useState } from "react";
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	IconButton,
	Rating,
	Link,
	Button,
	useMediaQuery,
	Popover,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import { Box } from "@mui/system";
import FHCard from "../../components/FHCard";
import colors from "../../themes/colors";
import Coupon from "../../assets/icons/ordersMenu/Coupon.png";
import WhatsApp from "../../assets/icons/ordersMenu/WhatsApp.svg";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, map, filter } from "lodash";
import commonClasses from "../../themes/commonClasses";
import { makeStyles } from "@mui/styles";
import UserList from "./UserDetails";
import { statusColos, TextCapalize } from "../../services/utils";
import { convertTimestamp } from "../../services/dateTimeConversion";
import closeIcon from "../../assets/icons/Close.png";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import StarIcon from "@mui/icons-material/Star";
import FHButton from "../../components/FHButton";
import { DispenseOrder } from '../../services/botServices'
import { getOrderProcessKey } from '../../services/utils'


const customStyles = makeStyles(commonClasses);

const commonStyles = {
	m: 1,
	color: colors.linegrey,
	width: "10rem",
	height: "1rem",
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		color: colors.success,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const OrderDetails = () => {
	UserList();

	const common = customStyles(commonClasses);
	const dispatch = useDispatch();
	const mobileView = useMediaQuery("(max-width:920px)");

	const selectedLocation = useSelector(
		(state) => state.locationReducer.selectedLocation
	);
	const selectedOrderDetails = useSelector(
		(state) => state.orderReducer.detailsCardData || []
	);
	const userOrderDetails = useSelector(
		(state) => state.orderReducer.userOrderDetails
	);
	const userOrderDetailsCard = useSelector(
		(state) => state.dashboard.userOrderDetailsCard
	);
	const userDetailsCard = useSelector(
		(state) => state.dashboard.userDetailsCard
	);
	const ordersDetailCard = useSelector(
		(state) => state.dashboard.orderDetailsCard
	);
	const feedbackView = useSelector((state) => state.dashboard.feedbackView);
	const userFeedback = useSelector((state) => state.userReducer.userFeedback);
	const currentTab = useSelector((state) => state.dashboard.currentTab);
	const userRole = useSelector((state) => state.userReducer.userRole);
	const machines = useSelector((state) => state.botReducer.machinesInfo)

	let detailsCardData = [];
	if (userDetailsCard && userOrderDetailsCard) {
		detailsCardData = userOrderDetails;
	} else {
		detailsCardData = selectedOrderDetails;
	}

	let cartInfo = [];

	!isEmpty(detailsCardData) && (cartInfo = detailsCardData.cartInfo);

	// console.log("getOrderProcessKey(detailsCardData)", getOrderProcessKey(detailsCardData))

	const handleShowUserDetails = () => {
		if (mobileView) {
			dispatch({ type: "ORDERDETAILS", payload: false });
		} else {
			dispatch({ type: "ORDERSCARD", payload: false });
			dispatch({ type: "FEEDBACKCARD", payload: false });
		}
		dispatch({ type: "GET_SELECTED_USER", payload: detailsCardData?.userId });
		dispatch({
			type: "GET_USER_OLD_ORDERS",
			payload: detailsCardData?.userId,
		});
		dispatch({ type: "USERDETAILS", payload: true });
	};

	useEffect(() => {
		dispatch({
			type: "GET_USER_FEEDBACK",
			payload: {
				docId: detailsCardData?.userId,
				orderId: detailsCardData?.cartId,
			},
		});
	}, [detailsCardData]);

	const closeDetailsView = () => {
		if (mobileView) {
			if (currentTab?.tabLabel === "Orders" && userOrderDetailsCard) {
				dispatch({ type: "USERDETAILS", payload: true });
				dispatch({ type: "USERLASTORDERDETAILS", payload: false });
				dispatch({ type: "CLEAR_USER_ORDER_DETAILS", payload: true });
			} else if (currentTab?.tabLabel === "Orders" && selectedOrderDetails) {
				dispatch({ type: "ORDERSCARD", payload: true });
				dispatch({ type: "ORDERDETAILS", payload: false });
				dispatch({ type: "CLEAR_DETAILS_CARD" });
			} else if (currentTab?.tabLabel === "Users" && userOrderDetails) {
				dispatch({ type: "USERDETAILS", payload: true });
				dispatch({ type: "USERLASTORDERDETAILS", payload: false });
				dispatch({ type: "CLEAR_USER_ORDER_DETAILS", payload: true });
			} else if (currentTab?.tabLabel === "Feedbacks" && selectedOrderDetails) {
				dispatch({ type: "FEEDBACKCARD", payload: true });
				dispatch({ type: "ORDERDETAILS", payload: false });
				dispatch({ type: "CLEAR_DETAILS_CARD" });
			}
		} else {
			if (
				userOrderDetailsCard &&
				!isEmpty(selectedOrderDetails) &&
				!feedbackView
			) {
				dispatch({ type: "USERLASTORDERDETAILS", payload: false });
				dispatch({ type: "ORDERDETAILS", payload: true });
				dispatch({ type: "CLEAR_USER_ORDER_DETAILS", payload: true });
			} else if (
				userOrderDetailsCard &&
				isEmpty(selectedOrderDetails) &&
				!feedbackView
			) {
				dispatch({ type: "USERLASTORDERDETAILS", payload: false });
				dispatch({ type: "USERSCARD", payload: true });
				dispatch({ type: "ORDERSCARD", payload: true });
				dispatch({ type: "CLEAR_USER_ORDER_DETAILS", payload: true });
			} else if (ordersDetailCard && userDetailsCard && feedbackView) {
				dispatch({ type: "USERDETAILS", payload: false });
				dispatch({ type: "FEEDBACKCARD", payload: true });
			} else if (userOrderDetailsCard && userDetailsCard && feedbackView) {
				dispatch({ type: "USERLASTORDERDETAILS", payload: false });
				dispatch({ type: "ORDERDETAILS", payload: true });
				dispatch({ type: "CLEAR_USER_ORDER_DETAILS", payload: true });
			} else {
				dispatch({ type: "MENUCARD", payload: true });
				dispatch({ type: "PROMOCARD", payload: true });
				dispatch({ type: "ORDERSCARD", payload: true });
				dispatch({ type: "USERSCARD", payload: true });
				dispatch({ type: "FEEDBACKCARD", payload: true });
				dispatch({ type: "USERDETAILS", payload: false });
				dispatch({ type: "CLEAR_DETAILS_CARD" });
				dispatch({ type: "CLEAR_SELECTED_USERDATA" });
				dispatch({ type: "CLEAR_USER_OLD_ORDERS" });
				dispatch({ type: "ORDERDETAILS", payload: false });
				dispatch({ type: "CLEAR_FEEDBACK_DETAILS" });
				dispatch({ type: "FEEDBACKVIEW", payload: false });
			}
			setBotDispensed({})
		}
	};

	const handleWhatsApp = (data) => {
		let payload = {
			msg: `${data.userDetails?.name ||
				data.userDetails?.mobileNo ||
				data.userDetails?.email ||
				"Anonymous"
				} is contacted via WhatsApp`,
			activityType: "updates",
			bot: "",
			created: {
				at: new Date(),
				name:
					data.userDetails?.name ||
					data.userDetails?.mobileNo ||
					data.userDetails?.email ||
					"Anonymous",
				uid: data?.userId,
			},
			initiator: "superAdmin",
			logLevel: "info",
		};
		dispatch({
			type: "LOG_ACTIVITY",
			payload: payload,
		});
	};

	const handleCalls = (data) => {
		let payload = {
			msg: `${data.userDetails?.name ||
				data.userDetails?.mobileNo ||
				data.userDetails?.email ||
				"Anonymous"
				} is contacted via phone call`,
			activityType: "updates",
			bot: "",
			created: {
				at: new Date(),
				name:
					data.userDetails?.name ||
					data.userDetails?.mobileNo ||
					data.userDetails?.email ||
					"Anonymous",
				uid: data?.userId,
			},
			initiator: "superAdmin",
			logLevel: "info",
		};
		dispatch({
			type: "LOG_ACTIVITY",
			payload: payload,
		});
	};

	const handleMail = (data) => {
		let payload = {
			msg: `${data.userDetails?.name ||
				data.userDetails?.mobileNo ||
				data.userDetails?.email ||
				"Anonymous"
				} is contacted via email`,
			activityType: "updates",
			bot: "",
			created: {
				at: new Date(),
				name:
					data.userDetails?.name ||
					data.userDetails?.mobileNo ||
					data.userDetails?.email ||
					"Anonymous",
				uid: data?.userId,
			},
			initiator: "superAdmin",
			logLevel: "info",
		};
		dispatch({
			type: "LOG_ACTIVITY",
			payload: payload,
		});
	};

	const [showMachines, setShowMachines] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null);
	const [botDispensed, setBotDispensed] = useState({})
	const [dispensing, setDispensing] = useState(null)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

		// console.log("Json string", JSON.stringify(detailsCardData))
	

	const handleDispenseBot = (data) => {
		setBotDispensed(data)
		let locId = data?.locId
		let docId = data?.id
		let orderId = detailsCardData?.cartId
		const keysWithUnderscore = Object.keys(detailsCardData).filter(key => key.includes('underProcess_'));
		const filteredData = keysWithUnderscore.reduce((result, key) => {
			result[key] = detailsCardData[key];
			return result;
		}, {});
		let botsUnderProcess = map(filteredData, item => item)
		let currentBot = filter(botsUnderProcess, item => item?.botId === data?.id)
		if(getOrderProcessKey(detailsCardData) === undefined  || (!isEmpty(currentBot) && currentBot[0]?.status === false)) {
			setDispensing(false)
			DispenseOrder(locId, docId, orderId)
			setAnchorEl(null);
			setDialogOpen(false);
		}else {
			setDispensing(true)
		}
		

	}

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	const handleDispense = () => {
		setShowMachines(!showMachines);
	}
	let botsList = filter(machines, item => item.locId === detailsCardData?.locationId)

	const [dialogOpen, setDialogOpen] = useState(false);
	const [botToDispense, setBotToDispense] = useState({});

	const handleDialog = (actn, u) => {
		if (actn === "close") {
			setDialogOpen(false);
			setDispensing(false);
			setBotToDispense({})
		} else {
			setDialogOpen(true);
			setBotToDispense(u)
		}
		setAnchorEl(null);
	};

	return (
		<>
			<FHCard
				variant={mobileView ? "default" : "primary"}
				detailsCard
				bgMain={mobileView ? false : true}
				body={
					<>
						<Box display={"flex"} justifyContent={"space-between"}>
							<Typography fontSize={23} fontWeight={700} ml={mobileView ? 1.5 : 0} mt={mobileView ? 2 : 0}>
								Orders Details
							</Typography>
							<IconButton onClick={() => closeDetailsView()}>
								<img src={closeIcon} />
							</IconButton>
						</Box>
						{!isEmpty(detailsCardData) ? (
							<Box p={3}>
								<Box>
									<Box mt={1} width="100%">
										<Grid
											display="flex"
											flexDirection="row"
											justifyContent="space-between"
										>
											<Grid
												display="flex"
												flexDirection="column"
												justifyContent="space-between"
											>
												<Typography
													fontSize={18}
													fontWeight={700}
													style={{
														color: statusColos(detailsCardData.currentStatus),
													}}
												>
													{detailsCardData.currentStatus ? (
														detailsCardData.currentStatus
													) : (
														<Typography
															fontSize={18}
															fontWeight={700}
															style={{
																color: statusColos(detailsCardData.currentStatus),
															}}
														>
															Incart
														</Typography>
													)}
												</Typography>
												<Typography
													fontSize={18}
													sx={{
														display: {
															xs: "block",
															md: "block",
															lg: "none",
															xl: "none",
															sm: "block",
														},
													}}
												>
													{
														convertTimestamp(
															detailsCardData.deliveredAt?.seconds
																? detailsCardData.deliveredAt?.seconds
																: detailsCardData.createdAt?.seconds
														)?.pattern1 // ask
													}
												</Typography>
												<Grid display="flex" gap="25px" alignItems="center">
													<Typography fontSize={15}>
														{detailsCardData.cartId}
													</Typography>
													<Typography
														fontSize={18}
														sx={{
															display: {
																xs: "inline",
																md: "block",
																lg: "block",
																xl: "block",
																sm: "block",
															},
														}}
													>
														<Typography
															display="inline"
															color={colors.linergrey}
															fontSize={15}
														>
															Bot:
														</Typography>{" "}
														<Typography display="inline" fontSize={15}>
															{detailsCardData?.underProcess_TCVB?.botName}
														</Typography>
													</Typography>
												</Grid>
											</Grid>

											<Grid>
												<Typography
													fontSize={18}
													sx={{
														display: {
															xs: "none",
															md: "none",
															lg: "block",
															xl: "block",
															sm: "none",
														},
													}}
												>
													{
														convertTimestamp(detailsCardData.createdAt?.seconds)
															?.pattern1
													}
												</Typography>
											</Grid>
										</Grid>

										<Box mt={2}>
											<Grid
												container
												display="flex"
												justifyContent="space-between"
												spacing={2}
											>
												<Grid item display="flex" justifyContent="start" gap={1}>
													<Button
														style={{ textTransform: "none" }}
														variant="contained"
														viewnamebutton={true}
														onClick={handleShowUserDetails}
													>
														{
															// !isEmpty(detailsCardData?.userDetails?.name) && !isEmpty(detailsCardData?.userDetails?.mobileNo)
															//   ?  (detailsCardData?.userDetails?.name)
															//   : isEmpty(detailsCardData?.userDetails?.name) &&
															//     !isEmpty(detailsCardData?.userDetails?.mobileNo)
															//   ? detailsCardData?.userDetails?.mobileNo
															//   : detailsCardData.userId
															!isEmpty(detailsCardData?.userDetails?.name) &&
																!isEmpty(detailsCardData?.userDetails?.mobileNo)
																? `${TextCapalize(
																	detailsCardData?.userDetails?.name
																)}${" "}|${" "}${detailsCardData?.userDetails?.mobileNo
																}`
																: !isEmpty(detailsCardData?.userDetails?.name) &&
																	!isEmpty(detailsCardData?.userDetails?.email)
																	? `${TextCapalize(
																		detailsCardData?.userDetails?.name
																	)}${" "}|${" "}${TextCapalize(
																		detailsCardData?.userDetails?.email
																	)}`
																	: detailsCardData.userId
														}
													</Button>

													{userRole === "SuperAdmin" ||
														userRole === "CustomerSupport" ? (
														<>
															{detailsCardData.userDetails?.mobileNo ? (
																<>
																	<Grid
																		width="40px"
																		borderRadius="5px"
																		bgcolor={colors.main}
																		textAlign="center"
																		border={`1px solid ${colors.success}`}
																	>
																		<Link
																			href={`https://wa.me/${detailsCardData?.userDetails?.mobileNo}`}
																			target="_blank"
																			style={{ color: colors.success }}
																			onClick={() =>
																				handleWhatsApp(detailsCardData)
																			}
																		>
																			<Box mt>
																				<img src={WhatsApp}></img>
																			</Box>
																		</Link>
																	</Grid>
																	<Grid
																		width="40px"
																		borderRadius="5px"
																		bgcolor={colors.main}
																		color={colors.success}
																		textAlign="center"
																		border={`1px solid ${colors.success}`}
																	>
																		<Link
																			href={`tel:${detailsCardData?.userDetails?.mobileNo}`}
																			target="_blank"
																			style={{ color: colors.success }}
																			onClick={() => handleCalls(detailsCardData)}
																		>
																			<Box mt>
																				<CallIcon color={colors.success} />
																			</Box>
																		</Link>
																	</Grid>
																</>
															) : (
																<></>
															)}
														</>
													) : (
														<></>
													)}

													{userRole === "SuperAdmin" ||
														userRole === "CustomerSupport" ? (
														<>
															{detailsCardData.userDetails?.email ? (
																<>
																	<Grid
																		width="40px"
																		borderRadius="5px"
																		bgcolor={colors.main}
																		textAlign="center"
																		border={`1px solid ${colors.success}`}
																	>
																		<Link
																			href={`mailto:${detailsCardData?.userDetails?.email}`}
																			target="_blank"
																			style={{ color: colors.success }}
																			onClick={() => handleMail(detailsCardData)}
																		>
																			<Box mt>
																				<EmailIcon />
																			</Box>
																		</Link>
																	</Grid>
																</>
															) : (
																<></>
															)}
														</>
													) : (
														<></>
													)}
												</Grid>

												{/* <Grid display="flex" justifyContent="end">
                        {detailsCardData.currentStatus === "Refund request" ||
                        detailsCardData.currentStatus === "Delivered" ||
                        detailsCardData.currentStatus === "Expired" ||
                        detailsCardData.currentStatus == "Payment Success" ||
                        detailsCardData.currentStatus == "COD" ? (
                          <FHButton
                            name={"Initiate Refund"}
                            variant="outlined"
                            smallButton
                            bgSuccess
                            textWhite
                          ></FHButton>
                        ) : (
                          <></>
                        )}
                      </Grid> */}
												<Grid justifyContent={"end"} mt={mobileView ? 2 : 0} ml={mobileView ? 2 : 0}>
													{userRole === "SuperAdmin" ||
														userRole === "CustomerSupport" ? (
														<Box>
															{detailsCardData?.currentStatus == "COD"  || detailsCardData?.currentStatus == "Payment Success" ? (
																<FHButton
																	name={"Dispense Order"}
																	variant="outlined"
																	smallButton
																	bgSuccess
																	textWhite
																	onClick={handleClick}
																></FHButton>
															 ) : <></>}
															<Popover
																id={id}
																open={open}
																anchorEl={anchorEl}
																onClose={handleClose}
																anchorOrigin={{
																	vertical: "bottom",
																	horizontal: "right",
																}}
																transformOrigin={{
																	vertical: "top",
																	horizontal: "right",
																}}
																PaperProps={{ sx: { border: `2px solid ${colors.success}` } }}
															>
																{map(botsList, (u, i) => (
																	<Box key={i}>
																		<Box
																			display="flex"
																			justifyContent="center"
																			gap="12px"
																			key={i}
																			p={1}
																			sx={{
																				...commonStyles,
																				borderBottom: 0.5,
																				width: 200,
																				cursor: "pointer",
																				// backgroundColor:
																				// 	u.id === botDispensed?.id ? colors.success : "",
																			}}
																			alignItems="center"
																			className={common.mousecursor}
																			onClick={(e) => handleDialog("open", u)}
																		>
																			<Typography
																				textAlign={"center"}
																				sx={{
																					color: "black",
																				}}
																			>
																				{u?.name}
																			</Typography>

																		</Box>
																	</Box>
																))}
															</Popover>
														</Box>
													) : (<></>)}

												</Grid>

											</Grid>
										</Box>
									</Box>
								</Box>

								<Box mt={1}>
									<TableContainer component={Paper}>
										<Table>
											<TableHead>
												<TableRow>
													<StyledTableCell align="left" width="49%">
														Item
													</StyledTableCell>
													<StyledTableCell align="left" width="17%">
														Quatity
													</StyledTableCell>
													<StyledTableCell align="left" width="17%">
														Price
													</StyledTableCell>
													<StyledTableCell align="left" width="17%">
														Total
													</StyledTableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{map(detailsCardData.cartInfo, (row) => (
													<TableRow key={row.name}>
														<TableCell component="th" scope="row">
															{row.name}
															<Typography display="block" color={colors.grey}>
																{map(row.extraAdd, (u) => u.name)}
															</Typography>
														</TableCell>
														<TableCell align="left">{row.quantity}</TableCell>
														<TableCell align="left">{row.price}</TableCell>
														<TableCell align="left">
															{row.quantity * row.price}
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Box>

								{!isEmpty(detailsCardData.code) && (
									<Box
										p={1}
										bgcolor={colors.couponbg}
										mt={1}
										className={common.flex_row_se}
										gap="20px"
										alignItems="center"
									>
										<Box>
											<img src={Coupon} alt="coupon" />
										</Box>

										<Box className={common.flex_sb} gap="20px" width="100%">
											<Box>
												<Typography color={colors.darkGreen}>
													{detailsCardData.couponName}
												</Typography>
												<Typography>{`RS. ${detailsCardData.maxDiscountAmount}.00 Coupon Savings`}</Typography>
											</Box>
											<Box>
												<Typography color={colors.darkGreen}>
													{detailsCardData.code}
												</Typography>
											</Box>
										</Box>
									</Box>
								)}

								<Box>
									<Grid display="flex" justifyContent="space-between" mt={0}>
										<Typography p={1}>
											Sub Total ({cartInfo?.length} Items)
										</Typography>
										<Typography p={1}>{detailsCardData.subTotal}</Typography>
									</Grid>
									{!isEmpty(detailsCardData.code) && (
										<Grid display="flex" justifyContent="space-between">
											<Typography color={colors.success} p={1}>
												Coupon discount
											</Typography>
											<Typography p={1}>
												{detailsCardData.couponDiscount}
											</Typography>
										</Grid>
									)}

									<Grid display="flex" justifyContent="space-between" mt={0}>
										<Typography p={1}>Packaging Charges</Typography>
										<Typography p={1}>{detailsCardData.packingTotal}</Typography>
									</Grid>
									<Grid display="flex" justifyContent="space-between" mt={0}>
										<Typography p={1}>Tax (GSTIN-5%)</Typography>
										<Typography p={1}>{Number(detailsCardData.taxTotal).toFixed(2)}</Typography>
									</Grid>
									<Grid display="flex" justifyContent="space-between" mt={0}>
										<Typography p={1}>Total</Typography>
										<Typography p={1}>{detailsCardData.orderTotal}</Typography>
									</Grid>
								</Box>

								{!isEmpty(userFeedback) && (
									<Grid
										display
										flexDirection="column"
										gap="12px"
										bgcolor={colors.bright}
										p
										borderRadius={1}
									>
										<Typography fontSize={18} fontWeight={600} mb>
											Feedback
										</Typography>

										<Box display={"flex"} justifyContent={"row"} mb={1}>
											<Typography fontSize={15} fontWeight={600} mr={1}>
												Order Rating:
											</Typography>
											<Rating
												name="text-feedback"
												value={userFeedback?.orderRating}
												readOnly
												precision={0.5}
												emptyIcon={
													<StarIcon
														style={{ opacity: 0.55 }}
														fontSize="inherit"
													/>
												}
											/>
										</Box>
										<Box display={"flex"} justifyContent={"row"} mb={1}>
											<Typography fontSize={15} fontWeight={600} mr={1}>
												Experience Rating:
											</Typography>
											<Rating
												name="text-feedback"
												value={userFeedback?.experienceRating}
												readOnly
												precision={0.5}
												emptyIcon={
													<StarIcon
														style={{ opacity: 0.55 }}
														fontSize="inherit"
													/>
												}
											/>
										</Box>

										{userFeedback?.experienceRating && (
											<>
												<Typography>{userFeedback?.comment}</Typography>

												<Box>
													<Grid container spacing={0.5}>
														{map(userFeedback?.reasons, (u, i) => (
															<Grid item xs="auto" key={i}>
																<Typography
																	bgcolor={colors.main}
																	borderRadius={5}
																	mt={0.5}
																	fontSize={13.5}
																	p={0.7}
																	mr={1}
																	color="black"
																	mb={1}
																>
																	{u}
																</Typography>
															</Grid>
														))}
													</Grid>
												</Box>
											</>
										)}
									</Grid>
								)}
							</Box>
						) : (
							<Box mt={4}>
								<Typography>Select an order to view details</Typography>
							</Box>
						)}
					</>
				}
			/>

			<Dialog
				open={dialogOpen}
				onClose={() => handleDialog("close")}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{`Do you want to dispese order for ${botToDispense?.name}?`}
				</DialogTitle>

				{dispensing && (<DialogContent style={{color:'red'}}>The bot is currently dispensing.</DialogContent>)}

				<DialogActions>
					<FHButton
						name={"Cancel"}
						variant="outlined"
						color={colors.success}
						smallButton
						commonButtonStyle
						onClick={() => handleDialog("close")}
					/>
					<FHButton
						name={"Dispense"}
						variant="outlined"
						color={colors.success}
						smallButton
						commonButtonStyle
						onClick={() => {
							handleDispenseBot(botToDispense);
						}}
					/>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default OrderDetails;
