import {
  collection,
  getDocs,
  query,
  setDoc,
  doc,
  updateDoc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { db } from "../App";
import store from "../stores";
import { map } from "lodash";
import { generateRandomString } from "../services/utils";

export const currentLocationMenu = (data) => {
  let locIds = data.payload;
  let locMenu = [];

  for (let locId of locIds) {
    const q = query(collection(db, `/menu/${locId}/items`), orderBy("name"));
    onSnapshot(q, (querySnapshot) => {
      let snapData = querySnapshot.docs;
      snapData.forEach((docx) => {
        locMenu = {
          ...locMenu,
          [`${locId}_${docx.id}`]: { ...docx.data(), locationId: locId },
        };
      });
      store.dispatch({ type: "STORE_LOCATIONS_MENU", payload: locMenu });
    });
  }
};

// export const getCommonMenu = () => {
//   const q = query(collection(db, `/menu/common/items`));
//   const commonMenu = onSnapshot(q, (querySnapshot) => {
//     const cMenu = map(querySnapshot.docs, async(doc) => {
// 			let q = await getDocs(
//         query(collection(db, `/menu/common/items/${document?.id}/promoCodes`))
//       );
//       if (q.docs.length > 0) {
//         promoByItems(document);
//       }
//       return doc.data();
//     });
//      console.log("cMenu", cMenu);
//     store.dispatch({
//       type: "STORE_COMMON_MENU",
//       payload: cMenu,
//     });
//   });
//   return () => commonMenu();
// };
export const categories = async (data) => {
  const q = query(collection(db, `/menu/common/categories`));
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};

export const getCommonMenu = async () => {
  const q = query(collection(db, `/menu/common/items`), orderBy("name"));
  let commonMenu = [];
  onSnapshot(q, (querySnapshot) => {
    let snapData = querySnapshot.docs;

    // snapData.forEach(async (docx) => {
    //   let document = docx.data();
    //   let q = await getDocs(
    //     query(collection(db, `/menu/common/items/${document?.id}/promoCodes`))
    //   );
    //   if (q.docs?.length !== 0) {
    //     promoByItems(document);
    //   }
    // });

    snapData.forEach((docx) => {
      commonMenu = { ...commonMenu, [docx.id]: { ...docx.data() } };
    });

    store.dispatch({
      type: "STORE_COMMON_MENU",
      payload: commonMenu,
    });
  });
};

export const currentLocationAvailableMenu = (data) => {
  let locIds = data.payload;
  let availableLocMenu = [];

  for (let locId of locIds) {
    const q = query(collection(db, `/menu/${locId}/availableItmes`));
    onSnapshot(q, (querySnapshot) => {
      let snapData = querySnapshot.docs;
      snapData.forEach((docx) => {
        // let itemRes = await getDoc(
        //   query(doc(db, `/menu/${locId}/items/${docx.id}`))
        // );
        // let itemMenu = itemRes.data();
        availableLocMenu = {
          ...availableLocMenu,
          [`${locId}_${docx.id}`]: {
            ...docx.data(),
            // name: itemMenu?.name || '',
            menuItem: docx.id,
            locationId: locId,
          },
        };
      });
      // console.log("itemMenu", availableLocMenu)

      store.dispatch({
        type: "STORE_CURRENT_AVAILABLE_MENU",
        payload: availableLocMenu,
      });
    });
  }
};

export const updateMenuItemDetails = async (
  locId,
  docId,
  price,
  url,
  status,
  hide
) => {
  console.log("menuUpdate api", locId, docId, price, url, status, hide);
  await updateDoc(
    doc(db, `/menu/${locId}/items/${docId}`),
    {
      hide: hide,
      price: price,
      imgs: [{ uri: url }],
      status: status,
    },
    { merge: true }
  );
};

export const addItemToLocation = async (item) => {
  console.log("Adding menu item", item);
  let locId = item?.id.split("@")[1];
  let docId = item?.id;
  let name = item?.name;
  await setDoc(
    doc(db, `/menu/${locId}/items/${docId}`),
    { ...item, id: docId, name: name },
    {
      merge: true,
    }
  );
  return;
};

export const addDuplicateCommonItem = async (item) => {
  console.log("Adding duplicate menu item", item);
  let locId = "common";
  let docId = generateRandomString(true, 20);
  let name = item?.name + "(Duplicate Copy)";
  let menuItem;
  if (item?.addOn?.length >= 1) {
    item.addOn.map((i) => {
      i?.options?.map((j) => {
        console.log(j[item.itemId], item?.itemId);
        if (j[item?.itemId]) {
          j[docId] = j[item?.itemId] || 0;
          delete j[item?.itemId];
        }
      });
    });
  }
  if (
    ["Beverages", "Snacks", "Vada", "Chutney + Sambar"].includes(item?.category)
  ) {
    menuItem = {
      ...item,
      id: docId,
      itemId: docId,
      name: name,
      items: [docId],
      groupedItems: { [docId]: 1 },
    };
  } else if (["Idli"].includes(item?.category)) {
    menuItem = {
      ...item,
      id: docId,
      itemId: docId,
      name: name,
      items: [docId, docId],
      groupedItems: { [`${docId}~${docId}`]: 1 },
    };
  } else {
    menuItem = {
      ...item,
      id: docId,
      itemId: docId,
      name: name,
    };
  }
  await setDoc(
    doc(db, `/menu/${locId}/items/${docId}`),
    {
      ...menuItem,
    },
    {
      merge: true,
    }
  );
  return;
};

export const updateMenuItem = async (data) => {
  console.log("menuUpdate api", data);
  let locId = data?.id?.split("@")[1] || "common";
  let docId = data?.id;
  await updateDoc(doc(db, `/menu/${locId}/items/${docId}`), data, {
    merge: true,
  });
  return;
};
