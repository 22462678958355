import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Graphics from "../assets/images/bgGraphics.png";
import Colors from "../themes/colors";
import FHLogo from "../assets/images/logoBg.png";
import CommanClasses from "../themes/commonClasses";
import InputBox from "../components/InputBox";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FHButton from "../components/FHButton";
import FHBox from "../components/FHBox";
import FHTypography from "../components/FHTypography";
import FormHelperText from "@mui/material/FormHelperText";
import { get, isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";

const customStyles = makeStyles(CommanClasses);

const useStyles = makeStyles(() => ({
  centerAlign: {
    justifyContent: "center",
  },
  body: {
    height: "100%",
    margin: 0,
  },
  rootLogin: {
    backgroundImage: `url(${Graphics})`,
    minHeight: "100vh",
    height: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "sticky",
    backgroundColor: Colors.success,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loginCard: {
    margin: "auto",
    width: "60%",
    minWidth: 300,
    maxWidth: 400,
    // marginTop: 32,
    backgroundColor: Colors.bright,
    maxHeight: "90vh",
    borderRadius: 20,

    // padding: 10px;
  },
  errorText: {
    color: "red",
  },
}));

export const Login = () => {
  const common = customStyles(CommanClasses);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validate, setValidate] = useState(false);

  const inProgress = useSelector((state) => state.userReducer.inProgress);
  const errMsg = useSelector((state) => state.userReducer.errMsg);

  const handleLogin = () => {
    if (email === "" || password === "") {
      setValidate(true);
    } else {
      dispatch({ type: "LOGIN", loginInfo: { email, password } });
    }
  };


	// useEffect(() => {
	// 	dispatch({type:'LOGOUT'})
	// },[])

  return (
    <>
      {inProgress && <LinearProgress color='success'/>}
      <Box className={classes.body}>
        <FHBox
          rootLogin
          boxBody={
            <Grid container>
              <Grid item xs={12}>
                <Box className={classes.loginCard}>
                  <Box p={1.5}>
                    <Box p={3} display="flex" justifyContent="center">
                      <img src={FHLogo} alt={"img"} style={{ height: 55, width: 120 , alignContent:'center'}}/>
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Typography className="lightText">
                        {"Enter your credentials to continue to Freshot"}
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      <FHTypography text={"User Name"} textSm textTLight />

                      <Box mt={1}>
                        <InputBox
                          style={{ width: "100%" }}
                          value={email}
                          endadornment={
                            <InputAdornment className="pointer" position="end">
                              <CheckIcon />
                            </InputAdornment>
                          }
                          onChange={(event) => setEmail(event.target.value)}
                        />
                        {validate && isEmpty(email) && (
                          <FormHelperText style={{ color: "red" }}>
                            Enter email
                          </FormHelperText>
                        )}
                      </Box>
                    </Box>
                    <Box mt={2} mb={2}>
                      <Typography
                        className={[
                          "lightText",
                          common.textSm,
                          common.textTLight,
                        ].join(" ")}
                        gutterBottom
                        variant="standard"
                      >
                        Password
                      </Typography>
                      <Box mt={1}>
                        <InputBox
                          style={{ width: "100%" }}
                          type={showPassword ? "text" : "password"}
                          value={password}
                          endadornment={
                            <InputAdornment
                              position="end"
                              className="pointer"
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                            >
                              {showPassword ? (
                                <VisibilityIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <VisibilityOffIcon
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </InputAdornment>
                          }
                          onChange={(event) => setPassword(event.target.value)}
                        />
                        {validate && isEmpty(password) ? (
                          <FormHelperText style={{ color: "red" }}>
                            Enter password
                          </FormHelperText>
                        ) : !isEmpty(errMsg) ? (
                          <FormHelperText style={{ color: "red" }}>
                            {errMsg}
                          </FormHelperText>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Box>
                    <Box mt={4} mb={6}>
                      <FHButton
                        name={"Login"}
                        variant="outlined"
                        color={Colors.success}
                        smallButton
                        w_100
                        commonButtonStyle
                        onClick={() => handleLogin()}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          }
        ></FHBox>
      </Box>
    </>
  );
};
