import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { getTime } from "../services/dateTimeConversion";
import PersonIcon from "@mui/icons-material/Person";
import useStyles from "./NavigationStyle";
import { map } from "lodash";
import { Grid, Divider, Hidden, useMediaQuery } from "@mui/material";
import colors from "../themes/colors";
import { useDispatch, useSelector } from "react-redux";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: "none",
  },
  "&:before": {
    display: "none",
  },
}));

// const AccordionSummary = styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === "dark"
//       ? "rgba(255, 255, 255, .05)"
//       : "rgba(0, 0, 0, .03)",
//   flexDirection: "row-reverse",
//   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//     transform: "rotate(90deg)",
//   },
//   "& .MuiAccordionSummary-content": {
//     marginLeft: theme.spacing(1),
//   },
// }));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions({ activits }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [expandedId, setExpandedId] = useState(null);

  const handleAccordionChange = (accordionId) => {
    setExpandedId(accordionId === expandedId ? null : accordionId);
  };

  const profileIcon = (data) => {
    let profileColor = "";
    if (data?.initiator === "superAdmin") {
      profileColor = "#EBA900";
    } else if (data?.initiator === "user") {
      profileColor = "#4DA731";
    } else if (data?.initiator === "admin") {
      profileColor = "#CE0000";
    } else {
      profileColor = "";
    }
    return {
      color: profileColor,
    };
  };
  const mobileView = useMediaQuery("(max-width:920px)");
  const ordersCard = useSelector((state) => state.dashboard.ordersCard);
  const locations = useSelector((state) => state.locationReducer.locations);
  const userDetailsCard = useSelector(
    (state) => state.dashboard.userDetailsCard
  );
  const usersCard = useSelector((state) => state.dashboard.usersCard);
  const oldOrders = useSelector((state) => state.orderReducer.loadOldOrders);

  // const handleShowOrderDetails = (orderId) => {
  //   let locIds = map(locations, (item) => item?.d?.locId);
  //   dispatch({
  //     type: "LOAD_OLD_ORDERS",
  //     payload: { locIds: locIds, searchQuery: orderId },
  //   });
  //   dispatch({ type: "STORE_DETAILS_DATA", payload: oldOrders[0] });
  //   dispatch({ type: "ORDERDETAILS", payload: true });

  //   if (mobileView) {
  //     dispatch({ type: "ORDERSCARD", payload: false });
  //   } else {
  //     if (ordersCard && usersCard && userDetailsCard) {
  //       dispatch({ type: "USERSCARD", payload: false });
  //       dispatch({ type: "USERDETAILS", payload: false });
  //     } else {
  //       dispatch({ type: "MENUCARD", payload: false });
  //       dispatch({ type: "PROMOCARD", payload: false });
  //       dispatch({ type: "USERSCARD", payload: false });
  //       dispatch({ type: "FEEDBACKCARD", payload: false });
  //       dispatch({ type: "CLEAR_SELECTED_USERDATA" });
  //     }
  //   }
  // };
  return (
    <div>
      {map(activits, (data, i) => (
        <Accordion
          key={i}
          expanded={data.id === expandedId}
          onChange={() => handleAccordionChange(data.id)}
        >
          <MuiAccordionSummary
            aria-controls={`panel-content-${data.id}`}
            id={`panel-header-${data.id}`}
          >
            <Grid container>
              <Grid
                item
                xs={1}
                sm={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                textAlign="center"
              >
                <Typography fontSize="16px" color={"black"} pr={1}>
                  <PersonIcon style={profileIcon(data)} />
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8}>
                <Typography fontSize="14px" color={"black"}>
                  {data.msg}
                </Typography>
                <Hidden smUp>
                  <Typography fontSize="14px">
                    By {data?.created?.name}
                  </Typography>
                </Hidden>
                <Typography fontSize={10} color={"#AEAEAE"}>
                  {getTime(data.created?.at?.seconds)}
                </Typography>
              </Grid>
              <Hidden only={"xs"}>
                <Grid item xs={3} sm={3}>
                  <Typography
                    fontSize="14px"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    By {data?.created?.name}
                  </Typography>
                </Grid>
              </Hidden>
              {data.id !== expandedId && (
                <Grid item xs={12} md={12}>
                  <Divider classes={{ root: classes.divider }} />
                </Grid>
              )}
            </Grid>
          </MuiAccordionSummary>
          <AccordionDetails>
            <Grid container>
              {data?.metaData &&
                Object.entries(data?.metaData).map(([key, value], idx) => {
                  return ["string", "number"].includes(typeof value) ? (
                    <Grid pl={5} item xs={6} key={idx} display={"flex"}>
                      <Typography fontSize={12} fontWeight={600} mr={1}>
                        {key}:
                      </Typography>
                      <Typography
                        color={colors.linergrey}
                        // display="inline"
                        fontWeight={600}
                        fontSize={12}
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        // onClick={() =>
                        //   handleShowOrderDetails(key === "orderId" ? value : "")
                        // }
                      >
                        {value}
                      </Typography>
                    </Grid>
                  ) : (
                    <>
                      <Grid pl={5} item xs={12}>
                        <Typography fontSize={12} fontWeight={600} mr={1}>
                          {key}
                        </Typography>
                      </Grid>
                      {Object.entries(value).map(([key1, value1], idx1) => (
                        <Grid pl={7} item xs={6} key={idx1} display={"flex"}>
                          <Typography fontSize={12} fontWeight={600} mr={1}>
                            {key1}:
                          </Typography>
                          <Typography
                            color={colors.linergrey}
                            // display="inline"
                            fontWeight={600}
                            fontSize={12}
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {["string", "number"].includes(typeof value1) &&
                              value1}
                          </Typography>
                        </Grid>
                      ))}
                    </>
                  );
                })}
            </Grid>
            <Divider classes={{ root: classes.divider }} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
