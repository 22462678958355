const INITIAL_STATE = {
  menuLocation: {},
  currentLocationMenu: {},
  cLocAvailableMenu: {},
  commonMenu: {},
  selectedMenuDetails: {},
  categories: [],
};

//Types
export const GET_LOCATIONS_MENU = "GET_LOCATIONS_MENU";
export const GET_CURRENT_AVAILABLE_MENU = "GET_CURRENT_AVAILABLE_MENU";
export const GET_COMMON_MENU = "GET_COMMON_MENU";
export const GET_CATEGORIES = "GET_CATEGORIES";

//actions
export const STORE_MENU_LOCATION = "STORE_MENU_LOCATION";
export const STORE_CATEGORIES = "STORE_CATEGORIES";
export const STORE_LOCATIONS_MENU = "STORE_LOCATIONS_MENU";
export const STORE_COMMON_MENU = "STORE_COMMON_MENU";
export const STORE_CURRENT_AVAILABLE_MENU = "STORE_CURRENT_AVAILABLE_MENU";
export const STORE_SELECTED_MENU_DETAILS = "STORE_SELECTED_MENU_DETAILS";
export const CLEAR_SELECTED_MENU_DETAILS = "CLEAR_SELECTED_MENU_DETAILS";
export const LOGOUT = "LOGOUT";

export const menuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STORE_MENU_LOCATION:
      return {
        ...state,
        menuLocation: action.payload,
      };
    case STORE_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case STORE_COMMON_MENU:
      return {
        ...state,
        commonMenu: action.payload,
      };
    case STORE_LOCATIONS_MENU:
      return {
        ...state,
        currentLocationMenu: action.payload,
      };
    case STORE_CURRENT_AVAILABLE_MENU:
      return {
        ...state,
        cLocAvailableMenu: action.payload,
      };
    case STORE_SELECTED_MENU_DETAILS:
      return {
        ...state,
        selectedMenuDetails: action.payload,
      };
    case CLEAR_SELECTED_MENU_DETAILS:
      return {
        ...state,
        selectedMenuDetails: {},
      };
    case LOGOUT:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
