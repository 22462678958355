const INITIAL_STATE = {
	salesLocation: {},
	salesDateRange : {},
	showSales : false
};

//actions
export const STORE_SALES_LOCATION = "STORE_SALES_LOCATION";
export const SALES_DATE_RANGE = "SALES_DATE_RANGE";
export const SHOW_SALES = 'SHOW_SALES';
export const LOGOUT = "LOGOUT";

export const salesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case STORE_SALES_LOCATION:
			return {
				...state,
				salesLocation: action.payload,
			};
		case SALES_DATE_RANGE:
			return {
				...state,
				salesDateRange: action.payload,
			}
		case SHOW_SALES:
			return {
				...state,
				showSales : action.payload
			}
		case LOGOUT:
			return {
				...INITIAL_STATE,
			};
		default:
			return state;
	}
};
