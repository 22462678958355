import React, { useState } from "react";
import {
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormHelperText,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { reportTypesOptions, resolveMethodsOptions } from "../data/reportType";
import { useSelector } from "react-redux";

const FHReportForm = ({ addNewReport, loading }) => {
  const classes = useStyles();
  const [actionName, setActionName] = useState("");
  const [msg, setMsg] = useState("");
  const [resolveNote, setResolveNote] = useState("");
  const [status, setStatus] = useState("");
  const [reportType, setReportType] = useState("");
  const [resolveMethods, setResolveMethods] = useState([]);
  const [errors, setErrors] = useState({});

  const user = useSelector((state) => state.userReducer.user);

  const validateForm = () => {
    const newErrors = {};
    if (!actionName) {
      newErrors.actionName = "Action Name is required";
    }
    if (!msg) {
      newErrors.msg = "Message is required";
    }
    if (!resolveNote) {
      newErrors.resolveNote = "Resolve Note is required";
    }
    if (!status) {
      newErrors.status = "Status is required";
    }
    if (!reportType) {
      newErrors.reportType = "Report Type is required";
    }
    if (resolveMethods.length === 0) {
      newErrors.resolveMethods = "Resolve Method is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleResolveMethodsChange = (event) => {
    const value = event.target.value;
    setResolveMethods(typeof value === "string" ? value.split(",") : value);
  };

  const handleSave = () => {
    // Save resolve note logic here
    if (validateForm()) {
      const reportDetails = {
        metaData: {
          actionName,
        },
        created: {
          at: new Date(),
          name: user.email,
          uid: user.id,
        },
        msg,
        resolveNote,
        status,
        reportType,
        resolveMethods,
        resolveTime: new Date(),
        initiator: "admin",
      };
      console.log("resolve details:", reportDetails);
      addNewReport(reportDetails);
    }
  };

  return (
    <div className={classes.formContainer}>
      <Typography variant="h6" className={classes.field} mb={1}>
        Add new report
      </Typography>
      <TextField
        label="Name"
        rows={4}
        value={actionName}
        onChange={(e) => setActionName(e.target.value)}
        variant="outlined"
        fullWidth
        margin="normal"
      />
      {errors.actionName && (
        <FormHelperText error={true}>{errors.actionName}</FormHelperText>
      )}
      <TextField
        label="Message"
        rows={4}
        value={msg}
        onChange={(e) => setMsg(e.target.value)}
        variant="outlined"
        fullWidth
        margin="normal"
      />
      {errors.msg && <FormHelperText error={true}>{errors.msg}</FormHelperText>}
      <TextField
        label="Resolve Note"
        multiline
        rows={4}
        value={resolveNote}
        onChange={(e) => setResolveNote(e.target.value)}
        variant="outlined"
        fullWidth
        margin="normal"
      />
      {errors.resolveNote && (
        <FormHelperText error={true}>{errors.resolveNote}</FormHelperText>
      )}
      <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel>Status</InputLabel>
        <Select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          label="Status"
        >
          <MenuItem value="resolved">Resolved</MenuItem>
          <MenuItem value="ignored">Ignored</MenuItem>
        </Select>
        {errors.status && (
          <FormHelperText error={true}>{errors.status}</FormHelperText>
        )}
      </FormControl>
      <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel>Report Type</InputLabel>
        <Select
          value={reportType}
          onChange={(e) => setReportType(e.target.value)}
          label="Report Type"
        >
          {reportTypesOptions.map((method) => (
            <MenuItem value={method}>{method}</MenuItem>
          ))}
        </Select>
        {errors.reportType && (
          <FormHelperText error={true}>{errors.reportType}</FormHelperText>
        )}
      </FormControl>
      <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel>Resolve Method</InputLabel>
        <Select
          multiple
          value={resolveMethods}
          onChange={handleResolveMethodsChange}
          input={<OutlinedInput label="Resolve Method" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {resolveMethodsOptions.map((method) => (
            <MenuItem key={method} value={method}>
              <Checkbox
                style={{ color: "black" }}
                checked={resolveMethods.indexOf(method) > -1}
              />
              <ListItemText primary={method} />
            </MenuItem>
          ))}
        </Select>
        {errors.resolveMethods && (
          <FormHelperText error={true} color="red">
            {errors.resolveMethods}
          </FormHelperText>
        )}
      </FormControl>
      <LoadingButton
        size="small"
        onClick={handleSave}
        endIcon={<SendIcon />}
        loading={loading}
        loadingPosition="end"
        variant="outlined"
        color="success"
        style={{ float: "right" }}
      >
        <span>Report</span>
      </LoadingButton>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: theme.spacing(2),
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  chip: {
    marginBottom: theme.spacing(2),
  },
}));

export default FHReportForm;
