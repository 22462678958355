/*All the colors use in the applications are here....*/
const colors = {
	main: '#f0f4ff',
	primary: '#EC3024',
	secondary: '#AB1107',
	warning: '#FFB533CC',
	lightYellow:'#FCC25B',
	success: '#61B945',
	darkGreen:'#4DA731',
	alert: '#CE0202',
	disable:'#a7a7a7',
	lightGrey: '#787A80',
	darkGrey:'#474747',
	lighter: '#F1F6FA',
	LighterGrey:'#AEAEAE',
	grey: '#808080',
	lighterGrey: '#4D4D4D',
	danger: '#ED3024',
	linegrey:"#CACACA",
	linergrey:" #ACACAC",
	dark: '#292929',
	dara50: 'rgba(0, 0, 0, 0.5)',
	dark25: 'rgba(0, 0, 0, 0.25)',
	dark15: 'rgba(0, 0, 0, 0.15)',
	bright: '#FFFFFF',
	incartblue:" #1189CD",
	pendingorder:"#EBA900",
	waitingOrder:"#8A2DD3",
	cancelled:'#CE0000',
	disabled:'#F2F2F2',
	good:'#46BAAC',
	couponbg:"rgba(77, 167, 49, 0.12)",
}
export default colors
