import { map, isEmpty, uniqBy } from "lodash";

export const INITIAL_STATE = {
  promoCodes: {},
  showPromoDrawer: false,
  addPromo: false,
  locationPromos: {},
  menuItemPromos: {},
  promoUsersIncluded: {},
  promoUsersExcluded: {},
  userPromos: {},
  editPromo: false,
  editUserPromo: false,
  selectedPromoToEdit: {},
  addUserSpecificPromo: false,
};

// TYPES
export const GET_PROMO_CODES = "GET_PROMO_CODES";
export const GET_LOCATION_BASED_PROMOS = "GET_LOCATION_BASED_PROMOS";
export const GET_USER_PROMOS = "GET_USER_PROMOS";

// ACTIONS
export const STORE_PROMO_CODES = "STORE_PROMO_CODES";
export const SHOW_PROMO_DRAWER = "SHOW_PROMO_DRAWER";
export const ADD_PROMO_CODE = "ADD_PROMO_CODE";
export const EDIT_PROMO_CODE = "EDIT_PROMO_CODE";
export const EDIT_USER_PROMO_CODE = "EDIT_USER_PROMO_CODE";
export const ADD_USER_SPECIFIC_PROMO = "ADD_USER_SPECIFIC_PROMO";
export const PROMO_SELECTED_TO_EDIT = "PROMO_SELECTED_TO_EDIT";
export const STORE_LOCATION_BASED_PROMOS = "STORE_LOCATION_BASED_PROMOS";
export const STORE_MENUITEM_BASED_PROMOS = "STORE_MENUITEM_BASED_PROMOS";
export const STORE_USER_PROMOS = "STORE_USER_PROMOS";
export const STORE_USERS_INCLUDED = "STORE_USERS_INCLUDED";
export const STORE_USERS_EXCLUDED = "STORE_USERS_EXCLUDED";
export const RESET_SELETED_EDITPROMO = "RESET_SELETED_EDITPROMO";
export const LOGOUT = "LOGOUT";

export const promocodeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STORE_PROMO_CODES:
      return {
        ...state,
        promoCodes: action.payload,
      };
    case SHOW_PROMO_DRAWER:
      return {
        ...state,
        showPromoDrawer: action.payload,
      };
    case ADD_PROMO_CODE:
      return {
        ...state,
        addPromo: action.payload,
      };
    case EDIT_PROMO_CODE:
      return {
        ...state,
        editPromo: action.payload,
      };

    case EDIT_USER_PROMO_CODE:
      return {
        ...state,
        editUserPromo: action.payload,
      };
    case PROMO_SELECTED_TO_EDIT: {
      return {
        ...state,
        selectedPromoToEdit: action.payload,
      };
    }
    case ADD_USER_SPECIFIC_PROMO: {
      return {
        ...state,
        addUserSpecificPromo: action.payload,
      };
    }
    case STORE_LOCATION_BASED_PROMOS:
      return {
        ...state,
        locationPromos: action.payload,
      };
    case STORE_MENUITEM_BASED_PROMOS:
      let menuId = action.payload.menuId;
      let menuData = action.payload.menuPromos;
      return {
        ...state,
        menuItemPromos: {
          ...state.menuItemPromos,
          [menuId]: map(menuData, (item) => item),
        },
      };
    case STORE_USERS_INCLUDED:
      return {
        ...state,
        promoUsersIncluded: action.payload,
      };
    case STORE_USERS_EXCLUDED:
      return {
        ...state,
        promoUsersExcluded: action.payload,
      };
    case STORE_USER_PROMOS:
      return {
        ...state,
        userPromos: action.payload,
      };
    case RESET_SELETED_EDITPROMO:
      return {
        ...state,
        selectedPromoToEdit: {},
        editPromo: false,
        editUserPromo: false,
        addUserSpecificPromo: false,
        addPromo: false,
      };
    case LOGOUT:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};
