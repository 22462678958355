import { call, put } from "@redux-saga/core/effects";
import {
  currentLocationMenu,
  categories,
  currentLocationAvailableMenu,
  getCommonMenu,
} from "../services/menuServices";
import { STORE_CATEGORIES } from "../stores/menuStore";

export function* getCurrentLocationMenu(action) {
  try {
    yield call(currentLocationMenu, action);
  } catch (error) {
    console.log("Error", error);
  }
}
export function* getCategories(action) {
  try {
    let categoriesData = yield call(categories, action);
    yield put({ type: STORE_CATEGORIES, payload: categoriesData });
  } catch (error) {
    console.log("Error", error);
  }
}

export function* getCurrentLocAvailMenu(action) {
  try {
    yield call(currentLocationAvailableMenu, action);
  } catch (error) {
    console.log("Error", error);
  }
}

export function* getAllMenu(action) {
  try {
    yield call(getCommonMenu, action);
  } catch (error) {
    console.log("Error", error);
  }
}
