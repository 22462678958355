import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import colors from "../../themes/colors";
import { isEmpty, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";

const customStyles = makeStyles(commonClasses);

const commonStyles = {
  m: 1,
  color: colors.linegrey,
  width: "10rem",
  height: "1rem",
};

export default function AddPlanFilter(props) {
  const { onPopClose, editAndDuplicate } = props;
  const common = customStyles(commonClasses);
  const dispatch = useDispatch();
  const [dropdown, setDropdown] = useState([]);
  const selectedAddPlan = useSelector(
    (state) => state.dailyPlanReducer.selectedAddPlan
  );

  const handleClose = (name) => {
    onPopClose(name);
  };

  const dropDownBoth = [
    {
      id: 1,
      name: "Edit",
    },
    {
      id: 2,
      name: "Duplicate",
    },
  ];
  const dropDownEdit = [
    {
      id: 1,
      name: "Edit",
    },
  ];
  const menuLocation = useSelector((state) => state.menuReducer.menuLocation);

  useEffect(() => {
    if (editAndDuplicate && isEmpty(menuLocation)) {
      setDropdown(dropDownBoth);
    } else {
      setDropdown(dropDownEdit);
    }
  }, []);
  return (
    <Box>
      {map(dropdown, (u, i) => (
        <Box key={i}>
          <Box
            display="flex"
            justifyContent="center"
            gap="12px"
            key={i}
            p={1}
            sx={{
              ...commonStyles,
              borderBottom: 1,
              width: 100,
              backgroundColor:
                u.name === selectedAddPlan?.name ? colors.success : "",
            }}
            alignItems="center"
            className={common.mousecursor}
            onClick={(e) => handleClose(u.name)}
          >
            <Typography
              sx={{
                color: u.name === selectedAddPlan?.name ? "white" : "black",
              }}
            >
              {u.name}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
