import React from "react";
import {
	Grid,
	IconButton,
	Typography,
	useMediaQuery,
	Box,
} from "@mui/material";
import FHCard from "../../../components/FHCard";
import { isEmpty, map, filter } from "lodash";
// import commonClasses from "../../../themes/commonClasses";
// import { makeStyles } from "@mui/styles";
import closeIcon from "../../../assets/icons/Close.png";
import { useDispatch, useSelector } from "react-redux";
import MenuInfo from "./MenuInfo";
import MenuStatus from "./MenuStatus";
import IdliBotStatusTable from "./IdliBotStatus";

// const customStyles = makeStyles(commonClasses);
const MenuDetails = () => {
	// const common = customStyles(commonClasses);
	const dispatch = useDispatch();
	const mobileView = useMediaQuery("(max-width:920px)");

	const selectedMenuDetails = useSelector(
		(state) => state.menuReducer.selectedMenuDetails
	);
	const botStatus = useSelector((status) => status.botReducer.botStatus);
	const currentLocMenu = useSelector(
		(state) => state.menuReducer.currentLocationMenu
	);
	const availableItems = useSelector(
		(state) => state.menuReducer.cLocAvailableMenu
	);
	const machinesInfo = useSelector((state) => state.botReducer.machinesInfo);

	let itemsWithStatus = [];
	map(botStatus, (item) =>
		map(item.status, (sItem) =>
			itemsWithStatus.push({
				...sItem,
				machineId: item.machineId,
				machineName: item.machineName,
				subSystem: item.subSystem,
			})
		)
	);

	//Idli bot status
	let itemWithQuantity = [];
	map(currentLocMenu, (item) =>
		map(availableItems, (aItem) => {
			if (
				(item.id === aItem.menuItem && item.category === "Idli") ||
				(item.id === aItem.menuItem && item.category === "Vada")
			) {
				let abc = aItem.botInfo;
				Object.values(abc).map((menu) =>
					itemWithQuantity.push({
						...menu,
						locationId: item.locationId,
						id: item.id,
						category: item.category,
						menuItemId: item?.itemId,
						name: item.name,
					})
				);
			}
			return;
		})
	);
	const uniqueMenuItems = itemWithQuantity.reduce((acc, menu) => {
		const existingMenu = acc.find(
			(item) =>
				item?.botId === menu?.botId &&
				item?.locationId === menu?.locationId &&
				item?.menuItemId === menu?.menuItemId
		);
		if (existingMenu) {
			existingMenu.quantity += menu.quantity;
		} else {
			acc.push({ ...menu });
		}
		return acc;
	}, []);

	let idliBotStatus = map(uniqueMenuItems, (item) => {
		let machine = filter(machinesInfo, (mItem) => mItem.id === item.botId);
		if (!isEmpty(machine)) {
			return {
				...item,
				machineName: machine[0].name,
			};
		}
	});

	let statuByMenu = filter(
		idliBotStatus,
		(item) =>
			item?.menuItemId === selectedMenuDetails?.itemId &&
			item?.locationId === selectedMenuDetails?.locationId
	);

	const closeDetailsView = () => {
		if (mobileView) {
			dispatch({ type: "MENUCARD", payload: true });
		} else {
			dispatch({ type: "PROMOCARD", payload: true });
			dispatch({ type: "ORDERSCARD", payload: true });
			dispatch({ type: "USERSCARD", payload: true });
			dispatch({ type: "FEEDBACKCARD", payload: true });
		}

		dispatch({ type: "CLEAR_SELECTED_MENU_DETAILS" });
		dispatch({ type: "MENUDETAILS", payload: false });
	};

	return (
		<FHCard
			variant={mobileView && "default"}
			detailsCard
			bgMain={!mobileView}
			body={
				<>
					<Box display={"flex"} justifyContent={"space-between"}>
						<Typography fontSize={23} fontWeight={700}>
							Menu Details
						</Typography>
						<IconButton onClick={() => closeDetailsView()}>
							<img src={closeIcon} />
						</IconButton>
					</Box>
					{!isEmpty(selectedMenuDetails) ? (
						<Grid container spacing={2} p={mobileView ? 0 : 2} xs={12}>
							<MenuInfo menuStatus={itemsWithStatus} />
							{selectedMenuDetails?.category === "Idli" ||
								selectedMenuDetails?.category === "Vada" ? (
								<IdliBotStatusTable idliBotStatus={statuByMenu} />
							) : (
								// <></>
								<MenuStatus menuStatus={itemsWithStatus} />
							)}
						</Grid>
					) : (
						<Box mt={4}>
							<Typography>Select an Menu to view details</Typography>
						</Box>
					)}
				</>
			}
		/>
	);
};

export default MenuDetails;
