import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
	Grid,
	Box,
	Typography,
	FormControlLabel,
	Radio,
	RadioGroup,
	Checkbox,
	Chip,
} from "@mui/material";
import colors from "../../../themes/colors";
import { useDispatch, useSelector } from "react-redux";
import { map, filter, isEmpty, uniqBy } from "lodash";
import FHButton from "../../../components/FHButton";
import { FHLabel } from "../../../components/FormInputs/FHLabel";
import { FHTextfield } from "../../../components/FormInputs/FHTextfield";
import { FHAutocomplete } from "../../../components/FormInputs/FHAutocomplete";
import { FHDateTimePicker } from "../../../components/FormInputs/FHDateTimePicker";
import { AddPlan } from "../../../services/dailyPlanServices";
import { generateRandomString } from "../../../services/utils";
import { WashTypes, plateNos, status } from "../../../data/dailyPlans";
import moment from "moment";
import { validationSchema } from "./PlateFillValidation";
import CancelIcon from "@mui/icons-material/Cancel";
import * as yup from "yup";

const validationSchema1 = yup.object({
	status: yup.object().required("status is required")
});

const WashAndFill = (props) => {
	const activityInfo = props.activityInfo
	const dispatch = useDispatch();
	const selectedLocation = useSelector(
		(state) => state.locationReducer.selectedLocation
	);
	const selectedMachine = useSelector(
		(state) => state.dailyPlanReducer.selectedMachine
	);
	const MenuAtLocation = useSelector(
		(state) => state.menuReducer.currentLocationMenu
	);
	const selectedEditPlan = useSelector(
		(state) => state.dailyPlanReducer.selectedEditPlan
	);
	const editPlan = useSelector((state) => state.dailyPlanReducer.editPlan);
	const dailyPlansByFilter = useSelector(
		(state) => state.dailyPlanReducer.dailyPlansByFilter
	);
	const duplicatePlan = useSelector(
		(state) => state.dailyPlanReducer.duplicatePlan
	);
	const addStatus = useSelector(
		(state) => state.dailyPlanReducer.addStatus
	);

	const [showQuantity, setShowQuantity] = useState(false);
	const [showMaxItemMsg, setShowMaxItemMsg] = useState(false);
	const [selectitem, setSelectItem] = useState(false);
	const [addQuantity, setAddQuantity] = useState(false);
	const [readOnly, setReadOnly] = useState(false);
	const [planExists, setPlanExists] = useState(false);
	const [showAddStatus, setShowAddStatus] = useState(false);
	const [showStatusButton, setShowStatusButton] = useState(false)
	const [showErrMsg, setShowErrMsg] = useState(false)

	const formik = useFormik({
		// enableReinitialize: editPromo,
		initialValues: {
			washType: WashTypes[0],
			plates: "All Plates",
			plateNumbers: [],
			item: { name: "", quantity: 1 },
			itemList: [],
			quantity: 20,
			when: new Date(),
			startCookingAfterFill: true,
			cookingTime: 20,
			coolingPeriodAfterCooking: 20,
			status: status[0],
		},
		validationSchema: addStatus ? validationSchema1 : validationSchema,
		onSubmit: (e) => {
			console.log("formik values on submit", formik.values);
			AddWashAndFill();
		},
	});

	console.log("formik values", formik);

	let selectedPlateNos = formik.values.plateNumbers;
	let plateNoDropdown = filter(
		plateNos,
		(item) => !selectedPlateNos.includes(item)
	);

	const deleteSpecificPlates = (data, formik) => {
		console.log("v, formik", data, formik);
		if (!readOnly) {
			let fData = filter(
				formik.values.plateNumbers,
				(plateNo) => plateNo !== data
			);
			formik.setFieldValue("plateNumbers", fData);
		}
	};

	let seletedLocMenu = filter(
		MenuAtLocation,
		(item) => item?.locationId === selectedLocation?.d?.locId
	);

	let selectedIds = map(formik.values.itemList, (item) => item.id);
	let itemsDropdown = filter(
		seletedLocMenu,
		(item) =>
			!selectedIds.includes(item.id) &&
			item.category === "Idli" &&
			uniqBy(item.id)
	);

	const handleAddItem = () => {
		let items = formik.values.itemList;
		if (items.length < 4) {
			setShowMaxItemMsg(false);
			// setShowAddQuantityMsg(false);
			formik.setFieldValue("itemList", [
				...formik.values.itemList,
				{ name: "Select Item", quantity: 1 },
			]);
			setSelectItem(true);
			console.log("addItem", formik.values.itemList);
		}
		// else if (items.length < 4 &&  selectitem) {
		//   setShowAddQuantityMsg(true);
		// }
		else {
			setShowMaxItemMsg(true);
		}
	};

	const handleItemChange = (e, newValue) => {
		let menuItems = filter(
			formik.values.itemList,
			(item) => item.name !== "Select Item"
		);
		let groupItem =
			newValue?.hasOwnProperty("groupedItems") &&
				!isEmpty(newValue?.groupedItems)
				? Object.keys(newValue?.groupedItems)
				: "";
		let toppingId =
			newValue?.hasOwnProperty("toppingsId") && !isEmpty(newValue?.toppingsId)
				? Object.keys(newValue?.toppingsId)
				: "";
		let itemToAdd = {
			id: newValue?.id,
			name: newValue?.name,
			itemId: newValue?.itemId,
			items: newValue?.items,
			groupedItems: groupItem[0] || "",
			toppingsId: toppingId[0] || "",
		};
		if (newValue !== null && newValue !== undefined) {
			formik.setFieldValue("itemList", [
				...menuItems,
				{ ...itemToAdd, quantity: 0 },
			]);
			setSelectItem(false);
			setAddQuantity(true);
		}
	};

	const handleQuantityChange = (e, index) => {
		formik.setFieldValue("quantity", parseInt(e.target.value));
		let fItems = [];
		let sItems = [...formik.values.itemList];
		fItems = map(sItems, (item, i) => {
			if (i === index) {
				return {
					...item,
					quantity: parseInt(e.target.value),
				};
			}
			return item;
		});
		formik.setFieldValue("itemList", fItems);
		setAddQuantity(false);
	};

	const handleDeleteItem = (index) => {
		setShowMaxItemMsg(false);
		setSelectItem(false);
		setAddQuantity(false);

		const updatedTextFields = [...formik.values.itemList];
		updatedTextFields.splice(index, 1);
		formik.setFieldValue("itemList", updatedTextFields);
	};

	const AddWashAndFill = async () => {
		let locId = selectedLocation?.d?.locId;
		let botId = selectedMachine?.id;
		let washType = formik.values.washType;
		let totalTime = washType?.timePeriod + 20;
		let endTime = moment(formik.values.when).add(totalTime, "minutes");
		let sTime = new Date(formik.values.when);
		let doesAnyPlanExistAtSelectedTime = filter(
			dailyPlansByFilter,
			(item) =>
				item?.when === sTime ||
				(item?.start < sTime && item?.end > endTime) ||
				(item?.start < sTime && item?.end > sTime) ||
				(item?.start > sTime && item?.start < endTime && item?.end > endTime) ||
				(item?.start > sTime && item?.end < endTime)
		);
		// console.log(
		// 	"doesAnyPlanExistAtSelectedTime",
		// 	dailyPlansByFilter,
		// 	sTime,
		// 	doesAnyPlanExistAtSelectedTime
		// );
		if (isEmpty(doesAnyPlanExistAtSelectedTime) || (!isEmpty(doesAnyPlanExistAtSelectedTime) && doesAnyPlanExistAtSelectedTime[0]?.status === "FAILED") || (!isEmpty(doesAnyPlanExistAtSelectedTime) && doesAnyPlanExistAtSelectedTime[0]?.status === "COMPLETED")) {
			setPlanExists(false);
			let planData = {
				locId: locId,
				botId: botId,
				start: new Date(formik.values.when),
				end: new Date(endTime),
				washType: formik.values.washType,
				plates: formik.values.plates,
				plateNumbers: [],
				operation: "Wash+Fill",
				item: formik.values.itemList,
				startCookingAfterFill: formik.values.startCookingAfterFill,
				cookingTime: formik.values.startCookingAfterFill
					? formik.values.cookingTime
					: null,
				coolingPeriodAfterCooking: formik.values.startCookingAfterFill
					? formik.values.coolingPeriodAfterCooking
					: null,
				when: formik.values.when,
			};
			let isCreatingDoc = true;
			let docId;
			if (editPlan && !addStatus) {
				docId = selectedEditPlan?.docId;
			} else if (editPlan && addStatus) {
				docId = selectedEditPlan?.docId;
				let planStatus = formik.values.status
				planData = {
					...planData,
					status: planStatus?.name
				}
			}
			else {
				docId = generateRandomString(isCreatingDoc, 20);
			}
			// console.log("addPlateWash", planData);
			// AddPlan(locId, botId, docId, planData);
			// dispatch({ type: "DAILY_PLAN", payload: false });
			// dispatch({ type: "EDIT_PLAN", payload: false });
			// dispatch({ type: "SELECTED_ADD_PLAN", payload: {} });
			try {
				const addPlanResult = await AddPlan(locId, botId, docId, planData);
				if (addPlanResult === 'success') {
					dispatch({
						type: "LOG_ACTIVITY",
						payload: activityInfo,
					});
					setShowErrMsg(false)
					dispatch({ type: "DAILY_PLAN", payload: false });
					dispatch({ type: "EDIT_PLAN", payload: false });
					dispatch({ type: "SELECTED_ADD_PLAN", payload: {} });
				} else {
					setShowErrMsg(true)
					console.log("Add Plan Error", addPlanResult);
				}

			} catch (error) {
				console.error("Error while adding plan:", error);
			}
		} else {
			setPlanExists(true);
		}
	};

	useEffect(() => {
		if (!isEmpty(selectedEditPlan)) {
			formik.setFieldValue("washType", selectedEditPlan?.washType);
			formik.setFieldValue("plates", selectedEditPlan?.plates);
			formik.setFieldValue(
				"plateNumbers",
				selectedEditPlan?.plateNumbers || []
			);
			formik.setFieldValue("itemList", selectedEditPlan?.item);
			formik.setFieldValue(
				"startCookingAfterFill",
				selectedEditPlan?.startCookingAfterFill
			);
			formik.setFieldValue("cookingTime", selectedEditPlan?.cookingTime || 20);
			formik.setFieldValue(
				"coolingPeriodAfterCooking",
				selectedEditPlan?.coolingPeriodAfterCooking || 20
			);
			formik.setFieldValue("when", selectedEditPlan?.when);
			if (selectedEditPlan?.when < new Date()) {
				setReadOnly(true);
				dispatch({ type: "ADD_STATUS", payload: true });
				setShowStatusButton(true);
			}
		}
	}, []);

	useEffect(() => {
		if (duplicatePlan) {
			setReadOnly(false);
			dispatch({ type: "EDIT_PLAN", payload: false });
			formik.setFieldValue("when", new Date());
			let selectedItems = map(itemsDropdown, (item) => item.id);
			let menuData = filter(selectedEditPlan?.item, item => !selectedItems.includes(item.id))
			if (!isEmpty(menuData)) {
				formik.setFieldValue("itemList", menuData);

			}
			dispatch({ type: "DUPLICATE_PLAN", payload: false });
		}
	}, [duplicatePlan]);

	const handleAddPlanStatus = () => {
		setShowAddStatus(true);
		setShowStatusButton(false);
	}

	return (
		<Box>
			<form onSubmit={formik.handleSubmit}>
				<FHLabel label={"Wash Type"} />
				<FHAutocomplete
					options={WashTypes}
					defaultValue={formik.values.washType}
					value={formik.values.washType}
					singleselect={"true"}
					disabled={readOnly}
					id="washType"
					name="washType"
					placeholder="Select Wash Type"
					multiple={false}
					onChange={(e, newValue) => formik.setFieldValue("washType", newValue)}
					error={formik.touched.washType && Boolean(formik.errors.washType)}
					helperText={formik.touched.washType && formik.errors.washType}
				/>

				<FHLabel label={"Plates"} />
				<RadioGroup
					row
					aria-labelledby="demo-radio-buttons-group-label"
					disabled={readOnly}
					value={formik.values.plates}
					onChange={(e) => formik.setFieldValue("plates", e.target.value)}
					error={formik.touched.plates && Boolean(formik.errors.plates)}
					helperText={formik.touched.plates && formik.errors.plates}
				>
					<FormControlLabel
						value="All Plates"
						control={
							<Radio
								sx={{
									color: colors.lightGrey,
									"&.Mui-checked": {
										color: colors.success,
									},
								}}
								disabled={readOnly}
							/>
						}
						label="All Plates"
						sx={{
							"& .MuiFormControlLabel-label": {
								fontWeight: 600,
								fontSize: 14,
							},
						}}
					/>
					<FormControlLabel
						value="Previously Cooked"
						control={
							<Radio
								sx={{
									color: colors.lightGrey,
									"&.Mui-checked": {
										color: colors.success,
									},
								}}
								disabled={readOnly}
							/>
						}
						label="Previously Cooked"
						sx={{
							"& .MuiFormControlLabel-label": {
								fontWeight: 600,
								fontSize: 14,
							},
						}}
					/>
					<FormControlLabel
						value="Specific Plates"
						control={
							<Radio
								sx={{
									color: colors.lightGrey,
									"&.Mui-checked": {
										color: colors.success,
									},
								}}
								disabled={readOnly}
							/>
						}
						label="Specific Plates"
						sx={{
							"& .MuiFormControlLabel-label": {
								fontWeight: 600,
								fontSize: 14,
							},
						}}
					/>
				</RadioGroup>

				{formik.values.plates === "Specific Plates" && (
					<FHAutocomplete
						multiple
						options={plateNoDropdown}
						defaultValue={[]}
						disabled={readOnly}
						value={formik.values.plateNumbers}
						id="plateNumbers"
						name="plateNumbers"
						placeholder="Select plate numbers"
						onChange={(e, newValue) =>
							formik.setFieldValue("plateNumbers", newValue)
						}
						chip={map(formik.values.plateNumbers, (data, i) => (
							<Box mb={1} key={i}>
								<Chip
									label={
										<Typography style={{ whiteSpace: "normal", fontSize: 14 }}>
											{data}
										</Typography>
									}
									variant="filled"
									onDelete={() => {
										deleteSpecificPlates(data, formik);
									}}
									style={{ background: "#F0F4FF", color: "black" }}
								/>
							</Box>
						))}
						error={
							formik.touched.plateNumbers && Boolean(formik.errors.plateNumbers)
						}
						helperText={
							formik.touched.plateNumbers && formik.errors.plateNumbers
						}
					/>
				)}

				<FHLabel label={"Items"} />
				{map(formik.values.itemList, (menuItem, index) => (
					<Grid container spacing={2} key={index}>
						<Grid item sm={6} xs={12}>
							<FHLabel label={"Item"} />
							<FHAutocomplete
								options={itemsDropdown}
								// defaultValue={formik.values.item}
								value={menuItem}
								singleselect={"true"}
								disabled={readOnly}
								id="item"
								name="item"
								placeholder="Select Items"
								multiple={false}
								onChange={(e, newValue) => handleItemChange(e, newValue)}
								error={formik.touched.item && Boolean(formik.errors.item)}
								helperText={formik.touched.item && formik.errors.item}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<FHLabel label={"Quantity"} />
							<Grid container spacing={1}>
								<Grid item sm={10} xs={10}>
									<FHTextfield
										fullWidth
										id="quantity"
										name="quantity"
										disabled={selectitem || readOnly}
										type="number"
										width={"100%"}
										placeholder="Enter quantity"
										value={menuItem.quantity}
										onChange={(e) => {
											handleQuantityChange(e, index);
										}}
										error={
											formik.touched.quantity && Boolean(formik.errors.quantity)
										}
										helperText={
											formik.touched.quantity && formik.errors.quantity
										}
									/>
								</Grid>
								<Grid item sm={2} xs={2} mt={1}>
									<CancelIcon onClick={() => handleDeleteItem(index)} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				))}
				{formik.touched.itemList && formik.errors.itemList && (
					<Typography color={"red"} fontSize={12}>
						{formik.errors.itemList}
					</Typography>
				)}
				{/* {showAddQuantityMsg &&
          <Typography color={"red"}>
            Add quantity for the selected Item{" "}
          </Typography>
        } */}
				{!readOnly && (
					<Box mt={1} mb={2} display={"flex"} justifyContent={"flex-start"}>
						<FHButton
							name={"Add +"}
							variant="outlined"
							disabled={selectitem || addQuantity}
							color={colors.success}
							small
							w_40
							commonButtonStyle
							onClick={handleAddItem}
						/>
					</Box>
				)}
				{showMaxItemMsg && (
					<Typography color={"red"}>Cannot add more than 4 items</Typography>
				)}
				<FHLabel label={"When?"} />
				<FHDateTimePicker
					value={formik.values.when}
					editForm={editPlan}
					disabled={readOnly}
					onChange={(newValue) => formik.setFieldValue("when", newValue?.$d)}
					error={formik.touched.when && Boolean(formik.errors.when)}
				// helperText={formik.touched.when && formik.errors.when}
				/>
				{!readOnly && formik.errors.when && <Typography color={'red'} fontSize={12}>{formik.errors.when}</Typography>}
				{planExists && (
					<Typography color={"red"} fontSize={13}>
						A plan has been already added at the selected time period.
					</Typography>
				)}

				<Grid container spacing={1} mt={1}>
					<Grid item sm={1} ml={-1.2}>
						<Checkbox
							id="startCookingAfterFill"
							name="startCookingAfterFill"
							label="startCookingAfterFill"
							sx={{
								color: "#474747",
								"&.Mui-checked": {
									color: "green",
								},
							}}
							disabled={readOnly}
							checked={formik.values.startCookingAfterFill}
							onChange={formik.handleChange}
							inputProps={{ "aria-label": "controlled" }}
							error={
								formik.touched.startCookingAfterFill &&
								Boolean(formik.errors.startCookingAfterFill)
							}
							helperText={
								formik.touched.startCookingAfterFill &&
								formik.errors.startCookingAfterFill
							}
						/>
					</Grid>
					<Grid item sm={11}>
						<Typography mt={1.3} fontSize={15} fontWeight={600}>
							Start Cooking after fill
						</Typography>
					</Grid>
				</Grid>

				{formik.values.startCookingAfterFill && (
					<>
						<FHLabel label={"Cooking Time (in min)"} />
						<FHTextfield
							fullWidth
							id="cookingTime"
							name="cookingTime"
							type="number"
							disabled={readOnly}
							value={formik.values.cookingTime}
							onChange={formik.handleChange}
							error={
								formik.touched.cookingTime && Boolean(formik.errors.cookingTime)
							}
							helperText={
								formik.touched.cookingTime && formik.errors.cookingTime
							}
						/>

						<FHLabel label={"Cooling period after cooking (in min)"} />

						<FHTextfield
							fullWidth
							id="coolingPeriodAfterCooking"
							name="coolingPeriodAfterCooking"
							type="number"
							disabled={readOnly}
							value={formik.values.coolingPeriodAfterCooking}
							onChange={formik.handleChange}
							error={
								formik.touched.coolingPeriodAfterCooking &&
								Boolean(formik.errors.coolingPeriodAfterCooking)
							}
							helperText={
								formik.touched.coolingPeriodAfterCooking &&
								formik.errors.coolingPeriodAfterCooking
							}
						/>
					</>
				)}

				{showStatusButton && addStatus && !selectedEditPlan?.hasOwnProperty('status') || showStatusButton && addStatus && (selectedEditPlan?.hasOwnProperty('status') && selectedEditPlan?.status !== "COMPLETED") ? (
					<Box mt={2} mb={2} display={"flex"} justifyContent={"flex-start"}>
						<FHButton
							name={"Update Plan Status"}
							variant="outlined"
							color={colors.success}
							small
							w_40
							commonButtonStyle
							onClick={handleAddPlanStatus}
						/>
					</Box>
				) : <></>}

				{showAddStatus ? (<>
					<FHLabel label={"Add Status"} />
					<FHAutocomplete
						options={status}
						defaultValue={formik.values.status}
						value={formik.values.status}
						singleselect={"true"}
						id="status"
						name="status"
						placeholder="Select Status"
						multiple={false}
						onChange={(e, newValue) => formik.setFieldValue("status", newValue)}
						error={formik.touched.status && Boolean(formik.errors.status)}
						helperText={formik.touched.status && formik.errors.status}
					/>
				</>) : <></>}

				{showErrMsg && <Box mt={2}> <Typography className="error-msg" color={"red"} fontSize={12}>Something went wrong while adding the plan.</Typography></Box>}

				{!readOnly || showAddStatus ? (
					<Box mt={14} mb={2} display={"flex"} justifyContent={"flex-end"}>
						<FHButton
							name={showAddStatus ? "Add Status" : "Save Plan"}
							variant="outlined"
							disabled={showQuantity}
							color={colors.success}
							small
							w_40
							commonButtonStyle
							onClick={formik.handleSubmit}
						// errors={validated ? validate.errors : undefined}
						/>
					</Box>
				) : <></>}
			</form>
		</Box>
	);
};

export default WashAndFill;
