import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormHelperText,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { get, map } from "lodash";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { getStatusChip } from "./FHReportList";
import { resolveMethodsOptions, reportTypesOptions } from "../data/reportType";
import { getTime } from "../services/dateTimeConversion";
import { msToTime } from "../services/utils";

const FHReportDetail = ({
  report,
  addReportResolveNote,
  startCapturing,
  loading,
}) => {
  const classes = useStyles();
  const [resolveNote, setResolveNote] = useState("");
  const [status, setStatus] = useState("");
  const [reportType, setReportType] = useState("");
  const [resolveMethods, setResolveMethods] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (report) {
      setResolveNote(report.resolveNote || "");
      setReportType(report.reportType || "");
      setResolveMethods(report.resolveMethods || []);
    }
  }, [report]);

  const validateForm = () => {
    const newErrors = {};
    if (!resolveNote) {
      newErrors.resolveNote = "Resolve Note is required";
    }
    if (!status) {
      newErrors.status = "Status is required";
    }
    if (!reportType) {
      newErrors.reportType = "Report Type is required";
    }
    if (resolveMethods.length === 0) {
      newErrors.resolveMethods = "Resolve Method is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleResolveMethodsChange = (event) => {
    const value = event.target.value;
    setResolveMethods(typeof value === "string" ? value.split(",") : value);
  };

  const handleSave = () => {
    // Save resolve note logic here
    if (validateForm()) {
      const reportDetails = {
        resolveNote,
        status,
        reportType,
        resolveMethods,
        resolveTime: new Date(),
      };
      console.log("resolve details:", reportDetails);
      addReportResolveNote(reportDetails, status);
    }
  };

  if (!report) return null;

  return (
    <div className={classes.detailContainer}>
      <div className={classes.detailMain}>
        <Typography variant="h6" className={classes.field} mb={1}>
          Report Detail
        </Typography>
        <Typography variant="body2" className={classes.field}>
          ID: {report.id}
        </Typography>
        <Typography variant="body1" className={classes.field}>
          Created At: {getTime(report.created?.at?.seconds)}
        </Typography>
        <Typography variant="body1" className={classes.field}>
          Message: {report.msg}
        </Typography>
        {map(report.metaData, (value, key) => (
          <Typography key={key} variant="body2" className={classes.field}>
            {key}: {value}
          </Typography>
        ))}

        <Typography variant="body2" className={classes.field}>
          LS Checks:{" "}
          {JSON.stringify(
            get(report, "recoveryInfo.preConditions.lsChecks", {})
          )}
        </Typography>
        <Typography variant="body2" className={classes.field}>
          Actions to Run:{" "}
          {get(report, "recoveryInfo.recoveryGuide.actionToRun", []).join(", ")}
        </Typography>
        <Typography variant="body2" className={classes.field}>
          Recovery Guide:{" "}
          {get(report, "recoveryInfo.recoveryGuide.messages", []).join(", ")}
        </Typography>
        <Typography variant="body2" className={classes.field}>
          Status: {getStatusChip(report.status)}
        </Typography>
        <Typography variant="body2" className={classes.field}>
          Resolved At: {getTime(report.resolveTime?.seconds)}
        </Typography>
        <Typography variant="body1" className={classes.field}>
          Time taken:{" "}
          {msToTime(
            (get(report, "resolveTime.seconds", new Date().getTime() / 1000) -
              get(report, "created.at.seconds")) *
              1000
          )}
        </Typography>
        <Box className={classes.logContainer}>
          {map(get(report, "resolvedLogs", []), (value, idx) => (
            <Typography key={idx} variant="body2" className={classes.field}>
              {value}
            </Typography>
          ))}
        </Box>
        <LoadingButton
          size="small"
          onClick={startCapturing}
          endIcon={<PlayCircleFilledWhiteIcon />}
          disabled={report.status != "unresolved"}
          loading={loading}
          loadingPosition="end"
          variant="outlined"
          color="success"
          style={{ float: "left" }}
        >
          <span> Start capturing</span>
        </LoadingButton>
      </div>

      <div>
        <TextField
          label="Resolve Note"
          multiline
          rows={4}
          value={resolveNote}
          onChange={(e) => setResolveNote(e.target.value)}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        {errors.resolveNote && (
          <FormHelperText error={true}>{errors.resolveNote}</FormHelperText>
        )}
        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel>Status</InputLabel>
          <Select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            label="Status"
          >
            <MenuItem value="resolved">Resolved</MenuItem>
            <MenuItem value="ignored">Ignored</MenuItem>
          </Select>
          {errors.status && (
            <FormHelperText error={true}>{errors.status}</FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel>Report Type</InputLabel>
          <Select
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            label="Report Type"
          >
            {reportTypesOptions.map((method) => (
              <MenuItem value={method}>{method}</MenuItem>
            ))}
          </Select>
          {errors.reportType && (
            <FormHelperText error={true}>{errors.reportType}</FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel>Resolve Method</InputLabel>
          <Select
            multiple
            value={resolveMethods}
            onChange={handleResolveMethodsChange}
            input={<OutlinedInput label="Resolve Method" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {resolveMethodsOptions.map((method) => (
              <MenuItem key={method} value={method}>
                <Checkbox
                  style={{ color: "black" }}
                  checked={resolveMethods.indexOf(method) > -1}
                />
                <ListItemText primary={method} />
              </MenuItem>
            ))}
          </Select>
          {errors.resolveMethods && (
            <FormHelperText error={true} color="red">
              {errors.resolveMethods}
            </FormHelperText>
          )}
        </FormControl>
        <LoadingButton
          size="small"
          onClick={handleSave}
          endIcon={<SendIcon />}
          disabled={report.status != "unresolved"}
          loading={loading}
          loadingPosition="end"
          variant="outlined"
          color="success"
          style={{ float: "right" }}
        >
          <span> Save Resolve Note</span>
        </LoadingButton>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  detailContainer: {
    padding: theme.spacing(2),
  },
  detailMain: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  chip: {
    marginBottom: theme.spacing(2),
  },
  logContainer: {
    padding: theme.spacing(1),
    border: "1px solid #E0E0E0",
    backgroundColor: "#F7F7F7",
    borderRadius: 4,
    maxHeight: 300,
    overflowY: "auto",
  },
}));

export default FHReportDetail;
