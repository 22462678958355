import React, { useEffect, useState } from "react";
import { Box, Grid, useMediaQuery, Typography, IconButton } from "@mui/material";
import FHSelect from "../../../components/FHSelect";
import { useSelector, useDispatch } from "react-redux";
import { filter, isEmpty } from "lodash";
import { SalesDateFilter } from "./SalesDateFilter";
import { SalesSummaryTable } from "./SalesSummaryTable";
import closeIcon from "../../../assets/icons/Close.png";
import colors from "../../../themes/colors";

const SalesSummary = () => {
	const dispatch = useDispatch();
	const [selectedMachine, setSelectedMachine] = useState("");
	const [activities, setActivities] = useState(false);
	const mobileView = useMediaQuery("(max-width:920px)");

	const locations = useSelector((state) => state.locationReducer.locations);
	const selctedSalesLocation = useSelector(
		(state) => state.salesReducer.salesLocation
	);
	const machinesList = useSelector((state) => state.botReducer.machinesInfo);
	const dailyPlan = useSelector((state) => state.dailyPlanReducer.dailyPlan);
	const userRole = useSelector((state) => state.userReducer.userRole);

	const [salesLocation, setSalesLocation] = useState("");

	useEffect(() => {
		if (!isEmpty(locations)) {
			setSalesLocation(locations[0]);
			dispatch({ type: "STORE_SALES_LOCATION", payload: locations[0] });
		}
	}, [locations]);

	const handleSelectLocations = (e, v) => {
		if (v) {
			setSalesLocation(v);
			dispatch({ type: "STORE_SALES_LOCATION", payload: v });
		} else {
			setSalesLocation("");
			dispatch({ type: "STORE_SALES_LOCATION", payload: "" });
		}
	};

	const closeSummary = () => {
		dispatch({ type: "SHOW_SALES", payload: false });
		dispatch({ type: "STORE_SALES_LOCATION", payload: locations[0] });
		setSalesLocation(locations[0]);
	}

	return (
		<Box>
			<Grid container mb={1} p={2}>
				<Grid item xs={12} sm={12}>
					<Box
						display={"flex"}
						flexDirection={"row"}
						justifyContent={"space-between"}
					>
						<Box>
							<Typography fontSize={23} fontWeight={700}>
								Sales Summary
							</Typography>
						</Box>
						<Box>
							<IconButton onClick={() => closeSummary()}>
								<img src={closeIcon} />
							</IconButton>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} sm={12} mt={2}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<FHSelect
								height={40}
								disableClearable={true}
								name="locations"
								placeholder="Select location"
								options={locations || []}
								style={{ backgroundColor: `${colors.main}` }}
								value={(!isEmpty(salesLocation) && salesLocation) || {}}
								onChange={(e, v) => {
									handleSelectLocations(e, v);
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<SalesDateFilter />
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={12} xs={12}>
					<Box mt={2}>
						<SalesSummaryTable />
					</Box>
				</Grid>
			</Grid>

		</Box>
	);
};


export default SalesSummary