import React, { useEffect, useState } from "react";
import { Popover, Typography } from "@mui/material";
import { Box } from "@mui/system";
import colors from "../../themes/colors";
import { isEmpty, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";
import FHButton from "../../components/FHButton";
import {
  dailyPlans,
  dailyPlansForTbot,
  dailyPlansForChunteyBot,
} from "../../data/dailyPlans";

const customStyles = makeStyles(commonClasses);

const commonStyles = {
  m: 1,
  color: colors.linegrey,
  width: "10rem",
  height: "1rem",
};

export const AddPlanFilter = () => {
  const common = customStyles(commonClasses);
  const dispatch = useDispatch();

  const selectedAddPlan = useSelector(
    (state) => state.dailyPlanReducer.selectedAddPlan
  );
  const selectedMachine = useSelector(
    (state) => state.dailyPlanReducer.selectedMachine
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!isEmpty(dailyPlans)) {
      dispatch({ type: "SELECTED_ADD_PLAN", payload: {} });
    }
  }, []);

  const handleAddPlan = (data) => {
    dispatch({ type: "DAILY_PLAN", payload: true });
    dispatch({ type: "SELECTED_ADD_PLAN", payload: data });
    setAnchorEl(null);
  };

  const [addPlanFilterData, setAddPlanFilterData] = useState([]);
  useEffect(() => {
    if (
      selectedMachine?.machineType === "TCVB" ||
      selectedMachine?.botInfo?.botType === "TCVB"
    ) {
      setAddPlanFilterData(dailyPlansForTbot);
    } else if (
      selectedMachine?.machineType === "CSVB" ||
      selectedMachine?.botInfo?.botType === "CSVB"
    ) {
      setAddPlanFilterData(dailyPlansForChunteyBot);
    } else {
      setAddPlanFilterData(dailyPlans);
    }
  }, [selectedMachine]);

  return (
    <Box>
      <FHButton
        name={"Add Plan +"}
        variant="outlined"
        button
        commonButtonStyle
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ sx: { border: `2px solid ${colors.success}` } }}
      >
        {map(addPlanFilterData, (u, i) => (
          <Box key={i}>
            <Box
              display="flex"
              justifyContent="center"
              gap="12px"
              key={i}
              p={1}
              sx={{
                ...commonStyles,
                borderBottom: 1,
                width: 100,
                backgroundColor:
                  u.name === selectedAddPlan?.name ? colors.success : "",
              }}
              alignItems="center"
              className={common.mousecursor}
              onClick={(e) => handleAddPlan(u)}
            >
              <Typography
                sx={{
                  color: u.name === selectedAddPlan?.name ? "white" : "black",
                }}
              >
                {u.name}
              </Typography>
            </Box>
          </Box>
        ))}
      </Popover>
    </Box>
  );
};
