import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import FHButton from "../../../components/FHButton";
import colors from "../../../themes/colors";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, map, filter } from "lodash";
// import commonClasses from "../../../themes/commonClasses";
// import { makeStyles } from "@mui/styles";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { covertdateformate } from "../../../services/dateTimeConversion";
import { updateAcvmItemQuantity } from "../../../services/botServices";
import "react-datepicker/dist/react-datepicker.css";
import { FHDateTimePicker } from "../../../components/FormInputs/FHDateTimePicker";
import { FHTextfield } from "../../../components/FormInputs/FHTextfield";

// const customStyles = makeStyles(commonClasses);
const MenuStatus = (props) => {
  let itemsWithStatus = props.menuStatus;
  // let idliBotStatus = props.idliBotStatus;
  // console.log("itemsWithStatus: " , itemsWithStatus)
  // const common = customStyles(commonClasses);
  const dispatch = useDispatch();
  const mobileView = useMediaQuery("(max-width:920px)");

  const selectedMenuLocation = useSelector(
    (state) => state.menuReducer.menuLocation
  );
  const selectedMenuDetails = useSelector(
    (state) => state.menuReducer.selectedMenuDetails
  );
  const acvmUpdates = useSelector((status) => status.botReducer.acvmUpdates);
  const userRole = useSelector((state) => state.userReducer.userRole);
  let acvmIds = map(acvmUpdates, (item) => item.index);

  const [editAcvmQuantity, setEditAcvmQuantity] = useState(false);
  const [disableAcvmSave, setDisableAcvmSave] = useState(true);

  useEffect(() => {
    if (!isEmpty(selectedMenuDetails)) {
      setEditAcvmQuantity(false);
      setDisableAcvmSave(true);
      dispatch({ type: "CLEAR_UPADTES" });
    }
  }, [selectedMenuDetails]);

  let findSelectedAcvmItem = (idx) => {
    let nItem = [];
    nItem = acvmUpdates && filter(acvmUpdates, (aItem) => aItem.index === idx);
    let cItem;
    if (!isEmpty(nItem)) {
      cItem = { ...nItem[0] };
    }
    return null;
    // console.log("findSelectedAcvmItem", nItem, cItem);

    return cItem;
  };

  const handleAcvmQuantites = (value, sData, idx) => {
    console.log("value, sData, idx", value, sData, idx);
    let newQuantity = Number(value);
    console.log("new quantity", value, newQuantity);
    let newData;
    if (acvmIds.includes(idx)) {
      let getCurrent = filter(acvmUpdates, (item) => item.index === idx);
      newData = {
        ...getCurrent[0],
        availableQuantity: newQuantity,
        index: idx,
      };
    } else {
      newData = { ...sData, availableQuantity: newQuantity, index: idx };
    }

    dispatch({
      type: "STORE_ACVM_UPDATES",
      payload: { index: idx, data: newData },
    });
  };

  const handleEditAcvmQuantity = () => {
    if (editAcvmQuantity) {
      setEditAcvmQuantity(false);
      setDisableAcvmSave(true);
      dispatch({ type: "CLEAR_UPADTES" });
    } else {
      setEditAcvmQuantity(true);
      setDisableAcvmSave(false);
    }
  };

  const handleDateChange = (date, item, idx) => {
    console.log("date", date, item, idx);
    let newData;
    if (acvmIds.includes(idx)) {
      let getCurrent = filter(acvmUpdates, (item) => item.index === idx);
      newData = { ...getCurrent[0], expiryAt: date, index: idx };
    } else {
      newData = { ...item, expiryAt: date, index: idx };
    }
    dispatch({
      type: "STORE_ACVM_UPDATES",
      payload: { index: idx, data: newData },
    });
  };

  const handleAcvmQuanityUpdate = () => {
    let fieldNames = [
      {
        id: 1,
        name: "canister",
      },
      {
        id: 2,
        name: "slot",
      },
      {
        id: 3,
        name: "container",
      },
    ];
    let final = map(acvmUpdates, (item) => {
      if (item) {
        let objectKeys = [];
        map(Object.keys(item), (kItem) =>
          objectKeys.push(kItem.split("Id")?.[0])
        );
        let fieldName = filter(fieldNames, (item) =>
          objectKeys.includes(item.name)
        );
        return {
          ...item,
          itemKey: `${fieldName[0].name}${
            item.slotId || item.canisterId || item.containerId
          }`,
        };
      }
    });

    console.log("final data to be updated", final);
    map(final, (data) => {
      let locId = selectedMenuLocation?.d?.locId;
      updateAcvmItemQuantity(
        locId,
        data.machineId,
        data.subSystem,
        data.itemKey,
        data.availableQuantity,
        data.expiryAt
      );
    });
    setEditAcvmQuantity(false);
    setDisableAcvmSave(true);
    dispatch({ type: "CLEAR_UPADTES" });
  };

  let xyz = filter(
    itemsWithStatus,
    (sdata) => sdata.itemId === selectedMenuDetails?.itemId
  );
  let finalXYZ = [];
  if (xyz.length > 1) {
    finalXYZ = [xyz[xyz.length - 1]];
  } else {
    finalXYZ = xyz;
  }
  // console.log("xyz", finalXYZ);

  return (
    <>
      {!isEmpty(itemsWithStatus) && (
        <Grid item p={ mobileView ? 0 : 2} lg={12} md={12} sm={12} xs={12}>
          <Grid display={"flex"} justifyContent={"flex-end"}>
            {userRole === "SuperAdmin" && (
              <IconButton onClick={handleEditAcvmQuantity}>
                {editAcvmQuantity ? (
                  <HighlightOffIcon
                    sx={{ color: colors.success }}
                    fontSize="small"
                  />
                ) : (
                  <DriveFileRenameOutlineRoundedIcon
                    sx={{ color: colors.success }}
                    fontSize="small"
                  />
                )}
              </IconButton>
            )}
          </Grid>
          <Grid
            sx={{ maxWidth: window.innerWidth < 600 ? 320 : "100%", overflow: "scroll" }}
            bgcolor={window.innerWidth < 600 ? colors.main : colors.bright}
          >
            <TableContainer>
              <Table
                sx={{
                  minWidth: window.innerWidth < 600 ? 0 : "100%",
                  maxWidth: window.innerWidth < 600 ? 150 : "100%",
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <TableCell align="left">
                      <Typography
                        color={colors.linergrey}
                        fontSize={12}
                        fontWeight={600}
                      >
                        Bot
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        color={colors.linergrey}
                        fontSize={12}
                        fontWeight={600}
                      >
                        Can #
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        color={colors.linergrey}
                        fontSize={12}
                        fontWeight={600}
                      >
                        Qty (ml)
                      </Typography>
                    </TableCell>
                    {/* <TableCell align="left">
                              <Typography
                                color={colors.linergrey}
                                fontSize={12}
                                fontWeight={600}
                              >
                                Temp.(C)
                              </Typography>
                            </TableCell> */}
                    <TableCell align="left">
                      <Typography
                        color={colors.linergrey}
                        fontSize={12}
                        fontWeight={600}
                      >
                        Expiry
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        color={colors.linergrey}
                        fontSize={12}
                        fontWeight={600}
                      >
                        Last Refill (ml)
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        color={colors.linergrey}
                        fontSize={12}
                        fontWeight={600}
                      >
                        Expiry Qty(ml)
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        color={colors.linergrey}
                        fontSize={12}
                        fontWeight={600}
                      >
                        Name
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(
                    filter(
                      itemsWithStatus,
                      (sdata) => sdata.itemId === selectedMenuDetails?.itemId
                    ),
                    (item, idx) => (
                      <TableRow
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          width="12%"
                        >
                          <Typography
                            color={colors.success}
                            fontSize={13}
                            fontWeight={600}
                            display="inline"
                          >
                            {item.machineName}
                          </Typography>
                          {/* <Typography
                                    fontWeight={600}
                                    fontSize={13}
                                    color={colors.cancelled}
                                    display="inline"
                                  >
                                    bot
                                  </Typography>
                                  <Typography
                                    fontWeight={600}
                                    display="inline"
                                    fontSize={13}
                                  >
                                    1
                                  </Typography> */}
                        </TableCell>
                        <TableCell align="left">
                          <Typography fontSize={13} fontWeight={600}>
                            {item.canisterId || item.slotId || item.containerId}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" style={{ width: "15%" }}>
                          {editAcvmQuantity ? (
                            <FHTextfield
                              fullWidth
                              name="availableQuantity"
                              width={"100%"}
                              placeholder={item.availableQuantity || 0}
                              value={
                                !isEmpty(findSelectedAcvmItem(idx))
                                  ? findSelectedAcvmItem(idx)?.availableQuantity
                                  : item.availableQuantity || ""
                              }
                              onChange={(e) =>
                                handleAcvmQuantites(e.target.value, item, idx)
                              }
                            />
                          ) : (
                            <Typography fontSize={13} fontWeight={600}>
                              {item.availableQuantity}
                            </Typography>
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {editAcvmQuantity ? (
                            <>
                              <FHDateTimePicker
                                value={
                                  !isEmpty(findSelectedAcvmItem(idx))
                                    ? findSelectedAcvmItem(idx)?.expiryAt ||
                                      covertdateformate(
                                        findSelectedAcvmItem(idx)?.expiryAt
                                          ?.seconds
                                      )?.dateTime
                                    : covertdateformate(item.expiryAt?.seconds)
                                        ?.dateTime || ""
                                }
                                onChange={(newValue) =>
                                  handleDateChange(newValue?.$d, item, idx)
                                }
                              />
                            </>
                          ) : (
                            <>
                              {item?.hasOwnProperty("expiryAt") ? (
                                <>
                                  <Typography fontSize={13} fontWeight={600}>
                                    {
                                      covertdateformate(item.expiryAt?.seconds)
                                        ?.time
                                    }
                                  </Typography>
                                  <Typography
                                    fontSize={12}
                                    fontWeight={600}
                                    color={colors.linergrey}
                                  >
                                    {
                                      covertdateformate(item.expiryAt?.seconds)
                                        ?.date
                                    }
                                  </Typography>
                                </>
                              ) : (
                                <Typography fontSize={14} fontWeight={600}>
                                  -
                                </Typography>
                              )}
                            </>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {item?.hasOwnProperty("loadQuantity") ? (
                            <>
                              <Typography fontSize={13} fontWeight={600}>
                                {item.loadQuantity}
                              </Typography>
															{item.hasOwnProperty("updatedAt") && (
                              <Typography
                                fontSize={12}
                                fontWeight={600}
                                color={colors.linergrey}
                              >
                                {
                                  covertdateformate(item.updatedAt?.seconds)
                                    ?.dateMonth
                                }{" "}
                                |{" "}
                                {
                                  covertdateformate(item.updatedAt?.seconds)
                                    ?.time
                                }
                              </Typography>
															)}
                            </>
                          ) : (
                            <Typography fontSize={14} fontWeight={600}>
                              -
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <Typography fontSize={13} fontWeight={600}>
                            {item.qtyBeforeExpiry || "-"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography fontSize={14} fontWeight={600}>
                            -
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {!disableAcvmSave && (
            <Grid item xs={12} mt={2.5}>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <FHButton
                  name={"Save Quantity Changes"}
                  variant="outlined"
                  bgSuccess
                  smallButton
                  textWhite
                  onClick={handleAcvmQuanityUpdate}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default MenuStatus;
