import React, { useEffect, useState } from "react";
import { setDoc, doc, addDoc, collection } from "firebase/firestore";
import {
  Divider,
  Grid,
  useMediaQuery,
  Alert,
  Snackbar,
  CardContent,
  Card,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FHReportList from "../../components/FHReportList";
import FHReportDetail from "../../components/FHReportDetail";
import { LocationSelect } from "../LocationSelect";
import FHSelect from "../../components/FHSelect";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, filter, get } from "lodash";
import { db } from "../../App";
import { FHDrawer } from "../../components/FHDrawer";
import { DateFilter } from "../Dashboard/DateFilter";
import FHButton from "../../components/FHButton";
import colors from "../../themes/colors";
import FHReportForm from "../../components/FHReportForm";

const ReportScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mobileView = useMediaQuery("(max-width:920px)");
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedMachine, setSelectedMachine] = useState({});
  const [loading, setLoading] = useState(false);
  const [botReports, setBotReports] = useState([]);
  const [detailView, setDetailView] = useState(false);
  const [showReportForm, setShowReportForm] = useState(false);
  const [error, setError] = useState(null);
  const [resolvedCount, setResolvedCount] = useState(0);
  const [ignoredCount, setIgnoredCount] = useState(0);
  const [unresolvedCount, setUnresolvedCount] = useState(0);
  const [mechanicalFailureCount, setMechanicalFailureCount] = useState(0);
  const [humanErrorCount, setHumanErrorCount] = useState(0);
  const [hardwareFailureCount, setHardwareFailureCount] = useState(0);
  const [otherFailureCount, setOtherFailureCount] = useState(0);
  const [resolveMethods, setResolveMethods] = useState({});

  const selectedLocation = useSelector(
    (state) => state.locationReducer.selectedLocation
  );
  const machinesList = useSelector((state) => state.botReducer.machinesInfo);
  const reports = useSelector((state) =>
    get(state, "botReducer.botReports", [])
  );
  const dateRange = useSelector((state) => state.orderReducer.dateRange);

  let machinesDropdown = filter(
    machinesList,
    (item) => item?.locId === selectedLocation?.d?.locId
  );
  const locId = get(selectedLocation, "d.locId", "unknown");

  useEffect(() => {
    const filteredReports = reports.filter((report) => {
      const reportTime = get(report, "created.at.seconds", 0);
      const start = get(dateRange, "startDateInSeconds", 0);
      const end = get(dateRange, "endDateInSeconds", Date.now());
      return reportTime >= start && reportTime <= end;
    });

    const sortedReports = filteredReports.sort(
      (a, b) => b.created.at.seconds - a.created.at.seconds
    );

    console.log("sortedReports", sortedReports);

    setBotReports(sortedReports);
    const resolved = sortedReports.filter(
      (report) => report.status === "resolved"
    ).length;
    const ignored = sortedReports.filter(
      (report) => report.status === "ignored"
    ).length;
    const unresolved = sortedReports.filter(
      (report) => report.status === "unresolved"
    ).length;
    setResolvedCount(resolved);
    setIgnoredCount(ignored);
    setUnresolvedCount(unresolved);

    const mechanicalFailures = sortedReports.filter(
      (report) => report.reportType === "Mechanical Failure"
    ).length;
    const humanErrors = sortedReports.filter(
      (report) => report.reportType === "Human Error"
    ).length;
    const hardwareFailures = sortedReports.filter(
      (report) => report.reportType === "Hardware Failure"
    ).length;
    const otherFailures = sortedReports.filter(
      (report) => report.reportType === "Other"
    ).length;
    setMechanicalFailureCount(mechanicalFailures);
    setHumanErrorCount(humanErrors);
    setHardwareFailureCount(hardwareFailures);
    setOtherFailureCount(otherFailures);

    const resolveMethodCounts = {};
    sortedReports.forEach((report) => {
      if (report.resolveMethods) {
        report.resolveMethods.forEach((method) => {
          if (!resolveMethodCounts[method]) {
            resolveMethodCounts[method] = 0;
          }
          resolveMethodCounts[method]++;
        });
      }
    });
    setResolveMethods(resolveMethodCounts);
  }, [reports, dateRange]);

  const snackbarClose = () => {
    setError("");
  };

  const handleMachine = (e, v) => {
    dispatch({ type: "SELECTED_MACHINE", payload: v });
    dispatch({
      type: "GET_BOT_REPORTS",
      payload: { locId, machineId: v.id },
    });
    setSelectedMachine(v);
    setSelectedReport(null);
    setError(null);
    setLoading(false);
  };

  const handleReport = (v) => {
    setSelectedReport(v);
    setDetailView(true);
  };

  const addNewReport = (reportDetails) => {
    const botId = get(selectedMachine, "id", "");

    if (!botId || !locId) {
      setError("Please select report and machine");
      return;
    }
    setLoading(true);
    addDoc(collection(db, `/acvms/${locId}/machines/${botId}/reports`), {
      ...reportDetails,
    })
      .then((res) => {
        console.log("Successfully added new report");
        setLoading(false);
        setShowReportForm(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
        setShowReportForm(false);
      });
  };
  const addReportResolveNote = (reportDetails, status) => {
    const reportId = get(selectedReport, "id", "");
    const botId = get(selectedMachine, "id", "");

    if (!botId || !locId || !reportId) {
      setError("Please select report and machine");
      return;
    }
    setLoading(true);
    setDoc(
      doc(db, `/acvms/${locId}/machines/${botId}/reports/${reportId}`),
      {
        ...reportDetails,
      },
      {
        merge: true,
      }
    )
      .then((res) => {
        console.log("Successfully updated resolve note");
        setDoc(
          doc(db, `/acvms/${locId}/machines/${botId}`),
          {
            errorState: {
              resolved: true,
              reportIds: { [reportId]: { status } },
            },
          },
          {
            merge: true,
          }
        )
          .then((res) => {
            console.log("Successfully changed bot error state");
            setSelectedReport(null);
            setLoading(false);
            setDetailView(false);
          })
          .catch((err) => {
            setError(err.message);
            setLoading(false);
            setDetailView(false);
          });
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
        setDetailView(false);
      });
  };

  const startCapturing = () => {
    const reportId = get(selectedReport, "id", "");
    const botId = get(selectedMachine, "id", "");

    if (!botId || !locId || !reportId) {
      setError("Please select report and machine");
      return;
    }
    setLoading(true);
    setDoc(
      doc(db, `/acvms/${locId}/machines/${botId}`),
      {
        errorState: {
          isCapturing: true,
          reportIds: { [reportId]: { capture: true } },
        },
      },
      {
        merge: true,
      }
    )
      .then((res) => {
        console.log("Capturing started successfully");
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  return (
    <div className={classes.container}>
      <Grid item md={6} sm={12} xs={12} mt={mobileView ? 2 : 0}>
        <Grid container spacing={2}>
          <Grid item sm={3} xs={12}>
            <LocationSelect />
          </Grid>
          <Grid item sm={3} xs={12}>
            <FHSelect
              height={45}
              disableClearable={true}
              name="machines"
              placeholder="Select machine"
              options={machinesDropdown || []}
              value={selectedMachine}
              onChange={(e, v) => {
                handleMachine(e, v);
              }}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <DateFilter />
          </Grid>
          <Grid item sm={3} xs={12}>
            <FHButton
              name={"Add new report"}
              variant="outlined"
              color={colors.success}
              smallButton
              commonButtonStyle
              onClick={() => setShowReportForm(true)}
            />
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.outerMain}>
        {isEmpty(botReports) ? (
          <Alert severity="info">No report found.</Alert>
        ) : (
          <div className={classes.outer}>
            <div className={classes.reportLeft}>
              <div className={classes.cards}>
                <Card className={classes.statusCard}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Resolved Issues
                    </Typography>
                    <Typography variant="h3">{resolvedCount}</Typography>
                  </CardContent>
                </Card>
                <Card className={classes.statusCard}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Ignored Issues
                    </Typography>
                    <Typography variant="h3">{ignoredCount}</Typography>
                  </CardContent>
                </Card>
                <Card className={classes.statusCard}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Unresolved Issues
                    </Typography>
                    <Typography variant="h3">{unresolvedCount}</Typography>
                  </CardContent>
                </Card>
              </div>
              <div>
                <FHReportList
                  rows={botReports}
                  onSelectReport={handleReport}
                  resolveMethods={resolveMethods}
                />
              </div>
            </div>
            <div className={classes.reportRight}>
              <Card className={classes.overviewCard}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.cardHeader}
                >
                  Failure Overview
                </Typography>
                <div className={classes.cardBody}>
                  <div className={classes.cardTextContainer}>
                    <p className={classes.cardText}>Mechanical Failure</p>{" "}
                    <p className={classes.cardText1}>
                      {mechanicalFailureCount}
                    </p>
                  </div>
                  <Divider />
                  <div className={classes.cardTextContainer}>
                    <p className={classes.cardText}>Human Error</p>{" "}
                    <p className={classes.cardText1}>{humanErrorCount}</p>
                  </div>
                  <Divider />
                  <div className={classes.cardTextContainer}>
                    <p className={classes.cardText}>Hardware Failure</p>{" "}
                    <p className={classes.cardText1}>{hardwareFailureCount}</p>
                  </div>
                  <Divider />
                  <div className={classes.cardTextContainer}>
                    <p className={classes.cardText}>Other</p>{" "}
                    <p className={classes.cardText1}>{otherFailureCount}</p>
                  </div>
                </div>
              </Card>
              <Card className={classes.overviewCard}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.cardHeader}
                >
                  Resolve Method Overview
                </Typography>
                <div className={classes.cardBody}>
                  {Object.keys(resolveMethods).map((method) => (
                    <div key={method}>
                      <div className={classes.cardTextContainer}>
                        <p className={classes.cardText}>{method}</p>{" "}
                        <p className={classes.cardText1}>
                          {resolveMethods[method]}
                        </p>
                      </div>
                      <Divider />
                    </div>
                  ))}
                </div>
              </Card>
            </div>
          </div>
        )}
      </div>

      <FHDrawer
        open={detailView}
        onClose={() => setDetailView(false)}
        data={
          <FHReportDetail
            report={selectedReport}
            addReportResolveNote={addReportResolveNote}
            startCapturing={startCapturing}
            loading={loading}
          />
        }
      />

      <FHDrawer
        open={showReportForm}
        onClose={() => setShowReportForm(false)}
        data={<FHReportForm addNewReport={addNewReport} loading={loading} />}
      />

      <Snackbar
        duration={200}
        open={error}
        onClose={snackbarClose}
        sx={{ backroundColor: "#62BB46", color: "white" }}
        message={error ? error : `Somthing went wrong!`}
        key={"top" + "right"}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(4),
  },
  outerMain: {
    border: "1px solid #E0E0E0",
  },
  outer: {
    display: "flex",
    flexDirection: "row",
  },
  reportLeft: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
  },
  cards: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: theme.spacing(1),
  },
  statusCard: {
    width: "15vw",
    margin: theme.spacing(1),
  },
  reportRight: {
    width: "30%",
  },
  overviewCard: {
    margin: theme.spacing(1),
  },
  cardHeader: {
    padding: theme.spacing(1),
    backgroundColor: "#E0E0E0",
  },
  cardTextContainer: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(1),
    justifyContent: "space-between",
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  cardText: {
    fontSize: "1vw",
    fontWeight: 500,
  },
  cardText1: {
    fontSize: "1.1vw",
    fontWeight: 600,
  },
}));

export default ReportScreen;
