import React, { useState, useEffect, useRef } from "react";
import FHSelect from "../components/FHSelect";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

export const LocationSelect = () => {
  const dispatch = useDispatch();

  const locations = useSelector((state) => state.locationReducer.locations);
  const locationDropdown = useSelector(
    (state) => state.locationReducer.locationDropdown
  );

  useEffect(() => {
    if (!isEmpty(locations)) {
      let locationDropdownList = [...(locations || [])];
      dispatch({ type: "LOCATION_DROPDOWN", payload: locationDropdownList });
    }
  }, [locations]);

  useEffect(() => {
    if (!isEmpty(locationDropdown)) {
      dispatch({
        type: "STORE_SELECTED_LOCATION",
        payload: locations[0],
      });
      setSelectedLocation(locations[0]);
    }
  }, [locationDropdown]);

  const [location, setSelectedLocation] = useState("");

  const handleSelectLocations = (e, v) => {
    console.log("vv", v);
    if (v) {
      setSelectedLocation(v);
      dispatch({ type: "STORE_SELECTED_LOCATION", payload: v });
    } else {
      setSelectedLocation("");
      dispatch({ type: "STORE_SELECTED_LOCATION", payload: "" });
    }
  };

  return (
    <FHSelect
      height={45}
      disableClearable={true}
      name="locations"
      placeholder="Select location"
      options={locationDropdown || []}
      value={(!isEmpty(location) && location) || {}}
      onChange={(e, v) => {
        handleSelectLocations(e, v);
      }}
    />
  );
};
