import React, { useEffect, useState } from "react";
import { Button, Popover, Typography } from "@mui/material";
import { Box } from "@mui/system";
import colors from "../../../themes/colors";
import { isEmpty, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getDates, getLastSevenDays } from "../../../services/dateTimeConversion";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../../themes/commonClasses";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FHSelect from "../../../components/FHSelect";


const customStyles = makeStyles(commonClasses);

const commonStyles = {
	m: 1,
	color: colors.linegrey,
	width: "10rem",
	height: "1rem",
};

export const SalesDateFilter = () => {
	const common = customStyles(commonClasses);
	const dispatch = useDispatch();

	const showSales = useSelector(
		(state) => state.salesReducer.showSales
	);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	let today = new Date();

	let datesDropdown = [
		{ ...getDates(today), Name: "Today's Sales" },
		{ ...getLastSevenDays(), Name: "Last 7 days" },
	];

	// console.log("datesDropdown", datesDropdown);
	const [salesDate, setSalesDate] = useState("");



	useEffect(() => {
		if (!isEmpty(datesDropdown)) {
			setSalesDate(datesDropdown[0]);
			dispatch({ type: "SALES_DATE_RANGE", payload: datesDropdown[0] });
		}
	}, [showSales]);

	const handleDateChange = (e, v) => {
		console.log("dateChange", v)
		setSalesDate(v);
		dispatch({ type: "SALES_DATE_RANGE", payload: v });
		setAnchorEl(null);
	};

	return (
		<FHSelect
			height={40}
			// disableClearable={true}
			name="Date"
			placeholder="Select Date"
			options={datesDropdown || []}
			style={{ backgroundColor: `${colors.main}` }}
			value={(!isEmpty(salesDate) && salesDate) || {}}
			onChange={(e, v) => {
				handleDateChange(e, v);
			}}
		/>
	);
};
