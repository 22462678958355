import {
  collection,
  query,
  doc,
  setDoc,
  onSnapshot,
  getDoc,
  getDocs,
  where,
} from "firebase/firestore";
import { db } from "../App";
import store from "../stores";
import { isEmpty, map } from "lodash";
import { generateRandomString } from "../services/utils";

let botReportSubscription;

export const machinesInfo = (data) => {
  let locIds = data.payload;
  let machines = [];
  let docData = {};
  for (let locId of locIds) {
    const q = query(collection(db, `/acvms/${locId}/machines`));
    onSnapshot(q, (querySnapshot) => {
      let snapData = querySnapshot.docs;
      snapData.forEach((docx) => {
        docData = docx.data();
        machines = {
          ...machines,
          [`${locId}_${docx.id}`]: {
            ...docx.data(),
            locId: locId,
            name: docData?.name || docData?.botInfo?.name,
            id: docData?.id || docData?.botInfo?.id,
          },
        };
        let payload = {
          machineId: docData?.id || docData?.botInfo?.id,
          locId: locId,
          machineName: docData?.name || docData?.botInfo?.name,
        };
        botStatus(payload);
        // botManagementStatus(payload)
      });
      store.dispatch({ type: "STORE_MACHINES_INFO", payload: machines });
    });
  }
};

export const subscribeToBotReports = (action) => {
  if (botReportSubscription) {
    botReportSubscription();
  }
  const { locId, machineId } = action.payload;
  console.log("subscribing to bot reports", locId, machineId);

  // Calculate dates
  const currentDate = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setDate(currentDate.getDate() - 30);

  // Query Firestore
  const collectionRef = collection(
    db,
    `/acvms/${locId}/machines/${machineId}/reports`
  );
  const dateQuery = query(
    collectionRef,
    where("created.at", ">=", oneMonthAgo),
    where("created.at", "<=", currentDate)
  );

  // Set up onSnapshot listener
  botReportSubscription = onSnapshot(dateQuery, (querySnapshot) => {
    let reports = [];
    querySnapshot.forEach((doc) => {
      reports.push({ ...doc.data(), id: doc.id });
    });
    store.dispatch({
      type: "STORE_BOT_REPORTS",
      payload: reports,
    });
  });
};

export const botStatus = async (data) => {
  // console.log("botstatus api inputs", data);
  let locId = data?.locId;
  let machineId = data?.machineId;
  let machineName = data?.machineName;
  let botStatus = [];
  const q = query(
    collection(db, `/acvms/${locId}/machines/${machineId}/status`)
  );
  const machine = query(doc(db, `/acvms/${locId}/machines/${machineId}`));
  let res = await getDoc(machine);
  onSnapshot(q, (querySnapshot) => {
    let snapData = querySnapshot.docs;
    if (!isEmpty(snapData)) {
      botStatus = map(snapData, (item) => {
        return {
          status: item.data(),
          subSystem: item.id,
          machineName: machineName,
          machineId: machineId,
          locationId: locId,
        };
      });
    }
    if (!isEmpty(botStatus)) {
      store.dispatch({ type: "STORE_BOT_STATUS", payload: botStatus });
    }
  });
};

export const updateAcvmItemQuantity = async (
  locId,
  machineId,
  subSystem,
  slotInfo,
  itemQuantity,
  expiryAt
) => {
  // console.log("sdsdsd", locId, machineId, subSystem, slotInfo, itemQuantity);
  await setDoc(
    doc(db, `/acvms/${locId}/machines/${machineId}/status/${subSystem}`),
    {
      [slotInfo]: {
        availableQuantity: itemQuantity,
        expiryAt: expiryAt,
      },
    },
    { merge: true }
  );
};

export const videoCallUpdates = async (
  locId,
  machineId,
  userId,
  userName,
  appOnCall,
  link
) => {
  console.log("info", locId, machineId, userId, userName);
  await setDoc(
    doc(db, `/acvms/${locId}/machines/${machineId}`),
    {
      videoInfo: {
        appOnCall: appOnCall,
        connectCall: true,
        status: "",
        // link: link,
        triggeredBy: {
          id: userId,
          name: userName,
          when: new Date(),
        },
      },
    },
    { merge: true }
  );
};
export const updateMachine = async (locId, machineId, item) => {
  console.log("info", locId, machineId, item);
  await setDoc(
    doc(db, `/acvms/${locId}/machines/${machineId}`),
    {
      ...item,
    },
    { merge: true }
  );
};

export const masterMachines = () => {
  let machines = [];
  const q = query(collection(db, `/acvms/common/machines`));
  onSnapshot(q, (querySnapshot) => {
    let snapData = querySnapshot.docs;
    snapData.forEach(async (docx) => {
      machines = {
        ...machines,
        [`${docx.id}`]: { ...docx.data() },
      };
      store.dispatch({ type: "STORE_MASTER_MACHINES", payload: machines });
    });
  });
};

export const duplicateMachine = async (item) => {
  console.log("Adding duplicate machine", item);
  let docId = generateRandomString(true, 20);
  let name = item?.botInfo?.name + "(Duplicate Copy)";
  await setDoc(
    doc(db, `/acvms/common/machines/${docId}`),
    {
      ...item,
      botInfo: {
        ...item.botInfo,
        name: name,
        id: docId,
      },
    },
    {
      merge: true,
    }
  );
  const acvmsStatus = query(
    collection(db, `/acvms/common/machines/${item?.botInfo?.id}/status`)
  );

  const snapshot = await getDocs(acvmsStatus);
  snapshot?.docs?.map(async (doc2) => {
    await setDoc(
      doc(db, `/acvms/common/machines/${docId}/status/${doc2.id}`),
      { ...doc2.data() },
      {
        merge: true,
      }
    );
  });

  const acvmssStatus = query(
    collection(db, `/acvms/common/machines/${item?.botInfo?.id}/ssStatus`)
  );
  const snapshot1 = await getDocs(acvmssStatus);
  snapshot1?.docs?.map(async (doc1) => {
    await setDoc(
      doc(db, `/acvms/common/machines/${docId}/ssStatus/${doc1.id}`),
      { ...doc1.data() },
      {
        merge: true,
      }
    );
  });

  return;
};

export const addMachineToLoc = async (locId, item) => {
  let itemDocId = item?.botInfo?.id;
  const itemLocId = item?.locId || locId;
  const newDocId = doc(collection(db, `/acvms/${locId}/machines/`)).id;
  console.log("Adding machine to loc", item);
  item.botInfo.id = newDocId;
  item.id = newDocId;
  item.locId = locId;
  await setDoc(
    doc(db, `/acvms/${locId}/machines/${newDocId}`),
    {
      ...item,
    },
    {
      merge: true,
    }
  );
  const acvmsStatus = 
    collection(db, `/acvms/${itemLocId}/machines/${itemDocId}/status`)
  ;
  const snapshot = await getDocs(acvmsStatus); 

  snapshot?.docs?.map(async (doc2) => {
    await setDoc(
      doc(db, `/acvms/${locId}/machines/${newDocId}/status/${doc2.id}`),
      { ...doc2.data() },
      {
        merge: true,
      }
    );
  });
  return;
};

export const DispenseOrder = async (locId, botId, orderId) => {
  try {
    await setDoc(
      doc(db, `/acvms/${locId}/machines/${botId}`),
      {
        dispenseOrder: {
          orderInfo: {
            id: orderId,
            locId: locId,
          },
          processOrder: true,
        },
      },
      {
        merge: true,
      }
    );

    console.log("Order Dispense details updated");
    return "success";
  } catch (error) {
    console.log("something went wrong", error);
    return error;
  }
};

export const appInfoUpdate = async (locId, machineId) => {
  console.log("info", locId, machineId);
  await setDoc(
    doc(db, `/acvms/${locId}/machines/${machineId}`),
    {
      appInfo: {
        getSensorsInfo: true,
      },
    },
    { merge: true }
  );
};

let onSnapshotUnsubscribe;
export const botManagementStatus = async (data) => {
  let locId = data?.locId;
  let machineId = data?.machineId;
  let machineName = data?.machineName;
  let botStatus = [];
  const q = query(
    collection(db, `/acvms/${locId}/machines/${machineId}/ssStatus`)
  );
  try {
    onSnapshotUnsubscribe = onSnapshot(q, (querySnapshot) => {
      let snapData = querySnapshot.docs;
      if (!isEmpty(snapData)) {
        botStatus = map(snapData, (item) => {
          return {
            status: item.data(),
            subSystem: item.id,
            machineName: machineName,
            machineId: machineId,
            locationId: locId,
          };
        });
      }
      if (!isEmpty(botStatus)) {
        store.dispatch({
          type: "STORE_BOT_MANAGEMENT_STATUS",
          payload: botStatus,
        });
      }
    });
  } catch (error) {
    console.log("something went wrong", error);
    return error;
  }
};

export const unsubscribeBotManagementStatus = () => {
  if (onSnapshotUnsubscribe) {
    console.log("botManagement snapshotUnsubscribed");
    onSnapshotUnsubscribe();
  }
};
