import React, { useEffect } from "react";
import { Routes, Route, Switch, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import { NavigationBar } from "../components/NavigationBar";
import { Dashboard } from "../containers/Dashboard";
import { DailyPlan } from "../containers/DailyPlan";
import { BotManagement } from "../containers/BotManagement";
import SetSubMenu from "../containers/Settings/Settings";
import MenuMaster from "../containers/Settings/MenuMaster/MenuMaster";
import { MachineMaster } from "../containers/Settings/MachineMaster/MachineMaster";
import { PromoCodes } from "../containers/Settings/Promocodes/AddOrEditPromos";
import { SetLocation } from "../containers/Settings/Locations/SetLocation";
import SubSystem from "../containers/Settings/SubSystem/SubSystem";
import OrderList from "../containers/Dashboard/OrderDetails";
import UserList from "../containers/Dashboard/UserDetails";
import { useSelector } from "react-redux";
import { unsubscribeBotManagementStatus } from "../services/botServices";
import ReportScreen from "../containers/report/ReportScreen";

const history = createBrowserHistory();

export const NavigationContainer = () => {
  const user = useSelector((state) => state.userReducer.user);
  const location = useLocation();
  // console.log("location.pathname", location.pathname)

  useEffect(() => {
    if (location.pathname !== "/botManagement") {
      unsubscribeBotManagementStatus();
    }
  }, [location]);
  return (
    <>
      <NavigationBar
        menuDisplay={
          user.role === "SuperAdmin" ? (
            <Routes history={history}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/settings" element={<SetSubMenu />} />
              <Route path="/settings/menu" element={<MenuMaster />} />
              <Route path="/settings/machine" element={<MachineMaster />} />
              <Route path="/settings/promocodes" element={<PromoCodes />} />
              <Route path="/settings/locations" element={<SetLocation />} />
              <Route path="/dailyplan" element={<DailyPlan />} />
              <Route path="/botManagement" element={<BotManagement />} />
              <Route path="/report" element={<ReportScreen />} />
              <Route path="*" element={<Dashboard />} />
            </Routes>
          ) : (
            <Routes history={history}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dailyplan" element={<DailyPlan />} />
              <Route path="*" element={<Dashboard />} />
            </Routes>
          )
        }
      />
    </>
  );
};
export default NavigationContainer;
