import {
	Box,
	Grid,
	List,
	ListItem,
	ListItemButton,
	Typography,
	Link,
	IconButton,
	useMediaQuery,
	Hidden
} from "@mui/material";
import React, { useEffect } from "react";
import FHCard from "../../components/FHCard";
import colors from "../../themes/colors";
import Bot from "../../assets/icons/ordersMenu/Bot.svg";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";
import { map } from "lodash";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import ArticleIcon from "@mui/icons-material/Article";
import WhatsApp from "../../assets/icons/ordersMenu/WhatsApp.svg";
import { sortOrders, getIcons, getFeedbackIcons } from "../../services/utils";
import {
	getTime,
	covertdateformate,
	datetemplate,
} from "../../services/dateTimeConversion";
import { FaCoins } from "react-icons/fa";
import closeIcon from "../../assets/icons/Close.png";
import VerifiedIcon from "@mui/icons-material/Verified";

const customStyles = makeStyles(commonClasses);

const UserList = () => {
	const common = customStyles(commonClasses);
	const dispatch = useDispatch();
	const mobileView = useMediaQuery("(max-width:920px)");

	const user = useSelector((state) => state.userReducer.selectedUser);
	const userOlderOrders = useSelector(
		(state) => state.userReducer.userOldOrders
	);
	const cardDetails = useSelector(
		(state) => state.orderReducer.userOrderDetails
	);
	const userOrderDetailsCard = useSelector(
		(state) => state.dashboard.userOrderDetailsCard
	);
	const usersCard = useSelector((state) => state.dashboard.usersCard);
	const ordersDetailCard = useSelector(
		(state) => state.dashboard.orderDetailsCard
	);
	const ordersCard = useSelector((state) => state.dashboard.ordersCard);
	const userDetailsCard = useSelector(
		(state) => state.dashboard.userDetailsCard
	);
	const selectedOrderDetails = useSelector(
		(state) => state.orderReducer.detailsCardData
	);
	const userRole = useSelector((state) => state.userReducer.userRole);

	const feedbackView = useSelector((state) => state.dashboard.feedbackView);
	const currentTab = useSelector((state) => state.dashboard.currentTab);
	const userPromos = useSelector(
		(state) => state.promocodeReducer.promoCodes
	).filter(
		(obj) =>
			obj?.userIssuePromo === true && obj?.userPromos[0]?.uid === user?.uid
	);

	let lastOrders =
		sortOrders(getFeedbackIcons(getIcons(userOlderOrders))) || [];

	const handleOldOrderDetails = (data) => {
		if (mobileView) {
			dispatch({ type: "USERDETAILS", payload: false });
		} else {
			if (ordersDetailCard && userDetailsCard) {
				dispatch({ type: "ORDERDETAILS", payload: false });
			} else if (ordersCard && usersCard && userDetailsCard) {
				dispatch({ type: "ORDERSCARD", payload: false });
				dispatch({ type: "USERSCARD", payload: false });
			}
		}
		dispatch({ type: "STORE_USER_ORDER_DETAILS", payload: data });
		dispatch({ type: "USERLASTORDERDETAILS", payload: true });
	};

	const closeUserDetailsView = () => {
		if (mobileView) {
			if (currentTab?.tabLabel === "Orders") {
				dispatch({ type: "ORDERDETAILS", payload: true });
			} else if (currentTab?.tabLabel === "Users") {
				dispatch({ type: "USERSCARD", payload: true });
			}
			dispatch({ type: "USERDETAILS", payload: false });
			dispatch({ type: "CLEAR_SELECTED_USERDATA" });
			dispatch({ type: "CLEAR_USER_ORDER_DETAILS" });
		} else {
			if (
				userDetailsCard &&
				userOrderDetailsCard &&
				!isEmpty(selectedOrderDetails)
			) {
				dispatch({ type: "USERLASTORDERDETAILS", payload: false });
				dispatch({ type: "ORDERDETAILS", payload: true });
				dispatch({ type: "CLEAR_USER_ORDER_DETAILS" });
			} else if (
				userDetailsCard &&
				userOrderDetailsCard &&
				isEmpty(selectedOrderDetails)
			) {
				dispatch({ type: "USERLASTORDERDETAILS", payload: false });
				dispatch({ type: "ORDERSCARD", payload: true });
				dispatch({ type: "USERSCARD", payload: true });
				dispatch({ type: "CLEAR_USER_ORDER_DETAILS" });
			} else if (ordersCard && usersCard && userDetailsCard) {
				dispatch({ type: "MENUCARD", payload: true });
				dispatch({ type: "PROMOCARD", payload: true });
				dispatch({ type: "FEEDBACKCARD", payload: true });
				dispatch({ type: "USERDETAILS", payload: false });
				dispatch({ type: "CLEAR_SELECTED_USERDATA" });
			} else if (
				!isEmpty(selectedOrderDetails) &&
				userDetailsCard &&
				feedbackView
			) {
				dispatch({ type: "FEEDBACKCARD", payload: true });
				dispatch({ type: "USERDETAILS", payload: false });
			} else if (
				userDetailsCard &&
				userOrderDetailsCard &&
				!isEmpty(selectedOrderDetails) &&
				feedbackView
			) {
				dispatch({ type: "USERLASTORDERDETAILS", payload: false });
				dispatch({ type: "ORDERDETAILS", payload: true });
				dispatch({ type: "CLEAR_USER_ORDER_DETAILS" });
			} else {
				dispatch({ type: "CLEAR_SELECTED_USERDATA" });
				dispatch({ type: "CLEAR_USER_OLD_ORDERS" });
				dispatch({ type: "USERDETAILS", payload: false });
				dispatch({ type: "ORDERSCARD", payload: true });
			}
		}
	};

	useEffect(() => {
		if (user?.uid) {
			dispatch({ type: "GET_USER_PROMOS", payload: user?.uid });
		}
	}, [user]);

	const handleWhatsApp = (data) => {
		let payload = {
			msg: `${data.name || data.mobileNo || data.email || data.uid
				} is contacted via WhatsApp`,
			activityType: "updates",
			bot: "",
			created: {
				at: new Date(),
				name: data.name || data.mobileNo || data.email || "Anonymous",
				uid: data.uid,
			},
			initiator: "superAdmin",
			logLevel: "info",
		};
		dispatch({
			type: "LOG_ACTIVITY",
			payload: payload,
		});
	};

	const handleCalls = (data) => {
		let payload = {
			msg: `${data.name || data.mobileNo || data.email || data.uid
				} is contacted via phone call`,
			activityType: "updates",
			bot: "",
			created: {
				at: new Date(),
				name: data.name || data.mobileNo || data.email || "Anonymous",
				uid: data.uid,
			},
			initiator: "superAdmin",
			logLevel: "info",
		};
		dispatch({
			type: "LOG_ACTIVITY",
			payload: payload,
		});
	};

	const handleMail = (data) => {
		let payload = {
			msg: `${data.name || data.mobileNo || data.email || data.uid
				} is contacted via email`,
			activityType: "updates",
			botName: data?.underProcess_TCVB?.botName,
			created: {
				at: new Date(),
				name: data.name || data.mobileNo || data.email || "Anonymous",
				uid: data.uid,
			},
			initiator: "superAdmin",
			logLevel: "info",
		};
		dispatch({
			type: "LOG_ACTIVITY",
			payload: payload,
		});
	};

	const handleEditPromo = (selectedPromo) => {
		dispatch({
			type: "SHOW_PROMO_DRAWER",
			payload: true,
		});
		dispatch({
			type: "EDIT_USER_PROMO_CODE",
			payload: true,
		});
		dispatch({
			type: "PROMO_SELECTED_TO_EDIT",
			payload: selectedPromo,
		});
	};

	const handleAddPromo = () => {
		dispatch({
			type: "SHOW_PROMO_DRAWER",
			payload: true,
		});
		dispatch({
			type: "RESET_SELETED_EDITPROMO",
		});
		dispatch({
			type: "ADD_USER_SPECIFIC_PROMO",
			payload: true,
		});
	};

	return (
		<FHCard
			body={
				<>
					{!isEmpty(user) && (
						<Grid container spacing={1}>
							<Grid
								item
								md={12}
								xs={12}
								sm={12}
								className={common.flex_row_sb}
								alignItems="center"
							>
								<Box flexDirection={"column"}>
									<Typography fontSize={20} fontWeight={600}>
										{user.name || "Anonymous"}
									</Typography>
									<Typography fontSize={12} color={colors.linegrey}>
										{user.uid}
									</Typography>
								</Box>

								<Grid className={common.flex_end} gap="10px">
									<Hidden only={"xs"}>
										{user?.ratingInfo && (
											<Box
												display="flex"
												gap="10px"
												borderRadius="5px"
												border="1px red solid"
												alignItems="center"
												justifyContent="space-evenly"
												p
											>
												<img src={Bot}></img>
												<Typography
													fontSize={13}
													fontWeight={600}
													color={colors.primary}
												>
													{user?.ratingInfo?.ratingAvg} / 5
												</Typography>
											</Box>
										)}
									</Hidden>
									<IconButton onClick={() => closeUserDetailsView()}>
										<img src={closeIcon}></img>
									</IconButton>
								</Grid>
							</Grid>

							<Hidden smUp>
								<Grid xs={4} mt={1}>
									{user?.ratingInfo && (
										<Box
											display="flex"
											gap="10px"
											borderRadius="5px"
											border="1px red solid"
											alignItems="center"
											justifyContent="space-evenly"
											p
										>
											<img src={Bot}></img>
											<Typography
												fontSize={13}
												fontWeight={600}
												color={colors.primary}
											>
												{user?.ratingInfo?.ratingAvg} / 5
											</Typography>
										</Box>
									)}
								</Grid>
							</Hidden>

							<Grid
								item
								md={12}
								xs={12}
								sm={12}
								className={common.flex_row_sb}
								alignItems="center"
							>
								<Box
									display="flex"
									gap="10px"
									borderRadius="5px"
									alignItems="center"
								>
									<PeopleAltIcon />
									<Typography fontSize={12} display="inline">
										{user.ageGroup}
									</Typography>
								</Box>

								<Typography
									fontSize={12}
									sx={{
										display: {
											xs: "block",
											md: "none",
											lg: "block",
											sm: "block",
										},
									}}
								>
									Joined: {covertdateformate(user.createdAt?.seconds)?.dateTime}
								</Typography>
							</Grid>

							<Grid item md={12} xs={12} sm={12}>
								<Typography
									fontSize={12}
									sx={{
										display: {
											xs: "none",
											md: "block",
											lg: "none",
											sm: "none",
										},
									}}
								>
									Joined: {covertdateformate(user.createdAt?.seconds)?.dateTime}
								</Typography>
							</Grid>

							{!isEmpty(user.mobileNo) && (
								<Grid
									item
									className={common.flex_row_start}
									gap="10px"
									md={12}
									xs={12}
									sm={12}
								>
									<Grid
										item
										md={6}
										xs={6}
										sm={6}
										borderRadius="5px"
										bgcolor={colors.main}
										textAlign="center"
									>
										<Typography mt>{user.mobileNo}</Typography>
									</Grid>
									{userRole === "SuperAdmin" ||
										userRole === "CustomerSupport" ? (
										<>
											<Grid
												item
												md={2}
												xs={2}
												sm={2}
												borderRadius="5px"
												bgcolor={colors.main}
												color={colors.success}
												textAlign="center"
												border={`1px solid ${colors.success}`}
											>
												<Link
													href={`https://wa.me/${user.mobileNo}`}
													target="_blank"
													style={{ color: colors.success }}
													onClick={() => handleWhatsApp(user)}
												>
													<Box mt>
														<img src={WhatsApp}></img>
													</Box>
												</Link>
											</Grid>
											<Grid
												item
												md={2}
												xs={2}
												sm={2}
												borderRadius="5px"
												bgcolor={colors.main}
												color={colors.success}
												textAlign="center"
												border={`1px solid ${colors.success}`}
											>
												<Link
													href={`tel:${user.mobileNo}`}
													target="_blank"
													style={{ color: colors.success }}
													onClick={() => handleCalls(user)}
												>
													<Box mt>
														<CallIcon />
													</Box>
												</Link>
											</Grid>
										</>
									) : (
										<></>
									)}
								</Grid>
							)}

							{userRole === "SuperAdmin" || userRole === "CustomerSupport" ? (
								<>
									{!isEmpty(user.email) && (
										<Grid
											item
											className={common.flex_row_start}
											gap="10px"
											md={12}
											xs={12}
											sm={12}
											width="auto"
										>
											<Grid
												p
												borderRadius="5px"
												bgcolor={colors.main}
												textAlign="center"
											>
												<Typography mb={0.5}>{user.email}</Typography>
											</Grid>

											<Grid
												item
												md={2}
												xs={2}
												sm={2}
												borderRadius="5px"
												bgcolor={colors.main}
												color={colors.success}
												textAlign="center"
												border={`1px solid ${colors.success}`}
											>
												<Link
													href={`mailto:${user.email}`}
													target="_blank"
													style={{ color: colors.success }}
													onClick={() => handleMail(user)}
												>
													<Box mt>
														<EmailIcon />
													</Box>
												</Link>
											</Grid>
										</Grid>
									)}
								</>
							) : (
								<></>
							)}

							<Grid item md={12} xs={12} sm={12}>
								{userRole === "SuperAdmin" || userRole === "CustomerSupport" ? (
									<Grid
										item
										height="40px"
										md={6}
										xs={6}
										sm={6}
										lg={6}
										borderRadius="5px"
										bgcolor={colors.main}
										className={common.flex_row_se}
										textAlign="center"
										border={`1px solid ${colors.success}`}
										alignItems="center"
										p={0.4}
										onClick={handleAddPromo}
									>
										<VerifiedIcon
											fontSize="large"
											sx={{ color: colors.success }}
										/>
										<Typography fontSize={15}>Issue Coupons</Typography>
									</Grid>
								) : (
									<></>
								)}
							</Grid>

							{!isEmpty(userPromos) && (
								<>
									<Grid item sm={12} xs={12} md={12}>
										<Typography mt fontWeight={700} display="block">
											Issued Coupons
										</Typography>
									</Grid>

									<Grid item md={12} sm={12} xs={12}>
										<List
											className={
												mobileView
													? common.listalign
													: userPromos.length > 1
														? common.listalignPromo
														: ""
											}
										>
											{map(userPromos, (data, i) => (
												<ListItem
													className={
														data.status === "ACTIVE"
															? common.activePromo
															: common.inactivePromo
													}
													key={i}
												// onClick={() => handleShowOrderDetails(u)}
												>
													<Box width="100%">
														<Grid>
															<Grid
																display="flex"
																justifyContent="space-between"
																alignItems="center"
															>
																<Grid
																	display="flex"
																	justifyContent="start"
																	alignItems="center"
																	gap={3}
																>
																	<Typography fontSize={15} fontWeight={600}>
																		{data.code}
																	</Typography>
																	{!isEmpty(data?.startDate) &&
																		!isEmpty(data?.endDate) ? (
																		<Typography
																			fontSize={12}
																			sx={{
																				display: {
																					sm: "block",
																					md: "none",
																					lg: "block",
																					xs: "none",
																				},
																			}}
																		>
																			{datetemplate(data?.startDate?.seconds)} -
																			{datetemplate(data?.endDate?.seconds)}
																		</Typography>
																	) : (
																		<Typography>-</Typography>
																	)}
																</Grid>

																<Grid
																	display="flex"
																	justifyContent="space-between"
																	alignItems="center"
																	gap={2}
																>
																	<Grid>
																		{/* <Typography fontSize={12}>
                            {data.users?.length} Users
                          </Typography> */}
																	</Grid>

																	<Box>
																		<DriveFileRenameOutlineRoundedIcon
																			fontSize="small"
																			className={common.promolistIt}
																			style={{
																				color: colors.success,
																				padding: 2,
																			}}
																			onClick={() => handleEditPromo(data)}
																		/>
																	</Box>
																</Grid>
															</Grid>
														</Grid>
														<Grid
															sx={{
																display: {
																	sm: "none",
																	md: "block",
																	lg: "none",
																	xs: "block",
																},
															}}
														>
															<Typography fontSize={10.5}>
																{datetemplate(data?.startDate?.seconds)} -
																{datetemplate(data?.endDate?.seconds)}
															</Typography>
														</Grid>
														<Grid alignItems="start">
															<Typography
																fontSize={11.5}
																color={colors.linergrey}
															>
																{data.couponName}
															</Typography>
														</Grid>

														<Grid>
															<Typography
																fontSize={11.5}
																display="inline"
																color={colors.linergrey}
															>
																created by :{" "}
															</Typography>
															<Typography display="inline" fontSize={12}>
																{!isEmpty(data?.createdBy?.name)
																	? data?.createdBy?.name
																	: data?.createdBy?.email}
															</Typography>
														</Grid>
													</Box>
												</ListItem>
											))}
										</List>
									</Grid>
								</>
							)}
							<Grid item sm={12} xs={12} md={12}>
								<Typography mt fontWeight={700} display="block">
									Last orders
								</Typography>
							</Grid>

							<Grid item md={12} sm={12} xs={12}>
								<List className={common.userOldOrders}>
									{!isEmpty(lastOrders) &&
										map(lastOrders, (item) => (
											<ListItemButton
												style={{
													backgroundColor:
														!isEmpty(cardDetails) &&
															item.cartId === cardDetails?.cartId
															? colors.success
															: colors.main,
													borderRadius: "8px",
													marginBottom: "13px",
												}}
												onClick={() => handleOldOrderDetails(item)}
											>
												<ListItem sx={{ padding: "0px" }}>
													<Grid item sx={12} xs={12} md={12}>
														<Grid
															container
															className={common.flex_sb}
															sm={12}
															xs={12}
															md={12}
														>
															<Grid className={common.flex_start} gap={0.2}>
																<item.icon.src
																	style={{
																		height: "15px",
																		color:
																			!isEmpty(cardDetails) &&
																				item.cartId === cardDetails?.cartId
																				? colors.bright
																				: item.icon.color,
																	}}
																/>
																<Typography
																	fontSize={10}
																	color={
																		!isEmpty(cardDetails) &&
																			item.cartId === cardDetails?.cartId
																			? colors.bright
																			: colors.LighterGrey
																	}
																>
																	{item.cartId}
																</Typography>
															</Grid>
															<Grid
																item
																sx={{
																	display: {
																		xs: "block",
																		md: "none",
																		lg: "block",
																		sm: "block",
																	},
																}}
															>
																<Typography
																	fontSize={9}
																	color={
																		!isEmpty(cardDetails) &&
																			item.cartId === cardDetails?.cartId
																			? colors.bright
																			: colors.LighterGrey
																	}
																>
																	{getTime(item.createdAt?.seconds)}
																</Typography>
															</Grid>
														</Grid>

														<Grid
															item
															mt={0}
															ml={3.3}
															sx={{
																display: {
																	xs: "none",
																	md: "block",
																	lg: "none",
																	sm: "none",
																},
															}}
														>
															<Typography
																fontSize={9}
																color={
																	!isEmpty(cardDetails) &&
																		item.cartId === cardDetails?.cartId
																		? colors.bright
																		: colors.LighterGrey
																}
															>
																{getTime(item.createdAt?.seconds)}
															</Typography>
														</Grid>

														<Grid
															item
															display="flex"
															justifyContent="start"
															mt={0}
															sm={12}
															xs={12}
															md={12}
															ml={0.3}
															gap={0.5}
															alignItems="center"
														>
															<LocationOnIcon
																fontSize="small"
																sx={{
																	height: "15px",
																	color:
																		!isEmpty(cardDetails) &&
																			item.cartId === cardDetails?.cartId
																			? colors.bright
																			: colors.darkGrey,
																}}
															/>
															<Typography
																fontSize={13}
																color={
																	!isEmpty(cardDetails) &&
																		item.cartId === cardDetails?.cartId
																		? colors.bright
																		: colors.darkGrey
																}
															>
																{item.locationName || "-"}
															</Typography>
														</Grid>

														<Grid
															item
															className={common.flex_sb}
															sm={12}
															xs={12}
															md={12}
															alignItems="center"
														>
															<Grid
																item
																className={common.flex_start}
																gap={0.5}
																sm={6}
																xs={6}
																md={6}
																ml={0.35}
																alignItems="center"
															>
																<ArticleIcon
																	fontSize="small"
																	sx={{
																		height: "15px",
																		color:
																			!isEmpty(cardDetails) &&
																				item.cartId === cardDetails?.cartId
																				? colors.bright
																				: colors.darkGrey,
																	}}
																/>
																<Typography
																	fontSize={13}
																	color={
																		!isEmpty(cardDetails) &&
																			item.cartId === cardDetails?.cartId
																			? colors.bright
																			: colors.darkGrey
																	}
																>
																	{item.cartInfo?.length} Items
																</Typography>
															</Grid>

															<Grid
																item
																className={common.flex_end}
																gap="15px"
																alignItems="center"
																sm={5}
																xs={5}
																md={5}
															>
																<Box
																	sx={{
																		display: {
																			xs: "block",
																			md: "none",
																			lg: "block",
																			sm: "block",
																		},
																	}}
																>
																	<Grid className={common.flex_sb} gap="5px">
																		{!isEmpty(item.code) && (
																			<Grid
																				className={common.flex_evenly}
																				bgcolor="white"
																				border={`1px solid ${colors.success}`}
																				height="13px"
																				width="auto"
																				p={0.2}
																				alignItems="center"
																			>
																				<VerifiedIcon
																					fontSize="small"
																					sx={{
																						color: colors.success,
																						height: "11px",
																					}}
																				/>
																				<Typography fontSize={8}>
																					{item.code}
																				</Typography>
																			</Grid>
																		)}
																		{item.loyaltyPointsEarn > 0 && (
																			<Grid
																				display="flex"
																				bgcolor="white"
																				border={`1px solid ${colors.pendingorder}`}
																				height="13px"
																				width="62px"
																				p={0.2}
																				alignItems="center"
																			>
																				<FaCoins
																					style={{ height: "10px" }}
																					color={colors.lightYellow}
																				/>
																				<Typography fontSize={8}>
																					{item.loyaltyPointsEarn} earned
																				</Typography>
																			</Grid>
																		)}
																	</Grid>
																</Box>
																<Typography
																	fontSize={13}
																	color={
																		!isEmpty(cardDetails) &&
																			item.cartId === cardDetails?.cartId
																			? colors.bright
																			: colors.darkGrey
																	}
																>
																	Rs.{item.orderTotal}
																</Typography>
															</Grid>
														</Grid>

														<Box
															mt={0.4}
															ml={0.82}
															sx={{
																display: {
																	xs: "none",
																	md: "block",
																	lg: "none",
																	sm: "none",
																},
															}}
														>
															<Grid
																container
																className={common.flex_start}
																gap="5px"
															>
																{!isEmpty(item.code) && (
																	<Grid
																		className={common.flex_evenly}
																		bgcolor="white"
																		border={`1px solid ${colors.success}`}
																		height="13px"
																		width="auto"
																		p={0.2}
																		alignItems="center"
																	>
																		<VerifiedIcon
																			fontSize="small"
																			sx={{
																				color: colors.success,
																				height: "11px",
																			}}
																		/>
																		<Typography fontSize={8}>
																			{item.code}
																		</Typography>
																	</Grid>
																)}
																{item.loyaltyPointsEarn > 0 && (
																	<Grid
																		className={common.flex_evenly}
																		bgcolor="white"
																		border={`1px solid ${colors.pendingorder}`}
																		height="13px"
																		width="auto"
																		p={0.2}
																		alignItems="center"
																	>
																		<FaCoins
																			style={{ height: "10px" }}
																			color={colors.lightYellow}
																		/>
																		<Typography fontSize={8}>
																			{item.loyaltyPointsEarn} earned
																		</Typography>
																	</Grid>
																)}
															</Grid>
														</Box>

														{!isEmpty(item.feedback) && (
															<>
																<hr></hr>

																<Grid
																	item
																	display="flex"
																	justifyContent="start"
																	gap="12px"
																	sx={12}
																	xs={12}
																	md={12}
																	mt
																	alignItems="center"
																>
																	<img src={Bot} alt="" />
																	<Typography
																		fontSize={16}
																		fontWeight={600}
																		color={item.feedback?.color}
																	>
																		{item.feedback?.feedbackText}
																	</Typography>
																</Grid>
																{/* <Typography>
                            jsdadkw ddjeqnnqfi dqwniq jw wdqiw dwqdi wdqwqidd
                            idwqid{" "}
                          </Typography> */}
															</>
														)}
													</Grid>
												</ListItem>
											</ListItemButton>
										))}
									<></>
								</List>
							</Grid>
						</Grid>
					)}
				</>
			}
		/>
	);
};

export default UserList;
