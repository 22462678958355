export const uischema = {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/name",
        label: "Item Name", // YOU CAN GIVE CUSTOM LABEL OR AUTOMATICALLY IT TAKES NAME AS LABEL
      },
      {
        type: "Control",
        scope: "#/properties/itemId",
      },
      {
        type: "HorizontalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/price",
          },
          {
            type: "Control",
            scope: "#/properties/oldPrice",
          },
          {
            type: "Control",
            scope: "#/properties/packagingCharge",
          },
        ],
      },
      {
        type: "HorizontalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/gst",
          },
          {
            type: "Control",
            scope: "#/properties/gstIncluded",
          },
        ],
      },
  
      {
        type: "Control",
        scope: "#/properties/flags",
      },
      {
        type: "Control",
        scope: "#/properties/consumables",
      },
      {
        type: "HorizontalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/status",
            label: "Boolean as Toggle",
            options: {
              toggle: true,
            },
          },
          {
            type: "Control",
            scope: "#/properties/statusMsg",
          },
        ],
      },
      {
        type: "Control",
        scope: "#/properties/description",
      },
      {
        type: "Control",
        scope: "#/properties/category",
      },
      {
        type: "Control",
        scope: "#/properties/subCategory",
      },
      {
        type: "Control",
        scope: "#/properties/combos",
      },
      {
        type: "Control",
        scope: "#/properties/toppings",
      },
    ],
  };
  
  
  
  
  export const schema = {
    type: "object",
    properties: {
      name: {
        type: "string",
        minLength: 3,
        description: "Please enter your name",
      },
      itemId: {
        type: "string",
      },
      price: {
        type: "number",
      },
      oldPrice: {
        type: "number",
      },
      packagingCharge: {
        type: "number",
      },
      gstIncluded: {
        type: "boolean",
      },
      gst: {
        type: "number",
      },
      flags: {
        type: "string",
      },
      consumables: {
        type: "boolean",
      },
      description: {
        type: "string",
      },
      category: {
        type: "string",
        enum: ["idli", "vada"],
      },
      subCategory: {
        type: "string",
      },
      status: {
        type: "boolean",
      },
      statusMsg: {
        type: "string",
      },
      combos: {
        type: "string",
        enum: ["consumables"],
      },
      toppings: {
        type: "string",
        enum: ["consumables"],
      },
      Addons: {
        type: "string",
        enum: [""],
      },
      SubAddons: {
        type: "string",
        enum: [""],
      },
    },
    "required": [
        "name",
    ],
  };