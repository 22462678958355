import React, { useEffect } from "react";
import {
	useMediaQuery,
	Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import { DailySalesTable } from "./DailySales";
import { WeeklySalesTable } from "./WeeklySales";

export const SalesSummaryTable = () => {
	const mobileView = useMediaQuery("(max-width:920px)");

	const salesDateRange = useSelector((state) => state.salesReducer.salesDateRange)

	return (
		<Box>
			{salesDateRange?.Name === "Today's Sales" ? (
				<DailySalesTable />
			) : (
				<WeeklySalesTable />
			)}
		</Box >
	)
}
