import _ from "lodash";
import {
  collection,
  where,
  query,
  doc,
  updateDoc,
  onSnapshot,
  setDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../App";
import store from "../stores";
import { map } from "lodash";
import { generateRandomString } from "../services/utils";

export const locations = (data) => {
  let locIds = data.payload;
  console.log("location ids in api", data, locIds);

  const q = query(collection(db, "locations"), where("d.locId", "in", locIds));
  const getLoc = onSnapshot(q, (querySnapshot) => {
    const locationsData = map(querySnapshot.docs, (doc) => {
      return doc.data();
    });
    store.dispatch({
      type: "STORE_LOCATIONS",
      payload: locationsData,
    });
  });
  return () => getLoc();
};

export const addLocation = async (data) => {
  console.log("Adding location", data);
  let locId = data.d?.locId || generateRandomString(true, 20);
  console.log("locId", locId);
  await setDoc(
    doc(db, `/locations/${locId}`),
    { ...data, d: { locationDetails: data.d.locationDetails, locId: locId } },
    {
      merge: true,
    }
  );
  return;
};

export const updateOutletStatus = async (
  locId,
  outletStatus,
  locationStatus
) => {
  console.log("location data", locId, outletStatus, locationStatus);
  const locRef = doc(db, `/locations/${locId}`);
  await updateDoc(
    locRef,
    {
      outletStatus: outletStatus,
      locationStatus: {
        ...locationStatus,
      },
    },
    { merge: true }
  );
};

export const allLocations = async () => {
  const q = query(collection(db, `/locations`));
  const snapshot = await getDocs(q);
  snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  store.dispatch({
    type: "STORE_ALL_LOCATIONS",
    payload: snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })),
  });
};
