import React from "react";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { isEmpty } from "lodash";

export const FHAutocomplete = (props) => {
  const {
    options,
    defaultValue,
    value,
    onChange,
    placeholder,
    chip,
    error,
    helperText,
    singleselect,
    searchbar = false,
    onInputChange,
    handlesearch,
  } = props;

  const isOptionEqualToValue = (option, value) => {
    if (Object.keys(value).length === 0) {
      // Match the empty object with an empty option
      return Object.keys(option).length === 0;
    }

    // Customize the equality test for non-empty value
    return (
      option === value ||
      option?.Name === value?.Name ||
      option?.name === value?.name ||
      (option.id === value.id && option.name === value.name) ||
      (option.uid === value.uid && option.name === value.Name)
    );
  };
	const mobileView = useMediaQuery("(max-width:920px)");


  return (
    <Box>
      <Autocomplete
        multiple
        variant="standard"
        size="small"
        options={options}
        defaultValue={defaultValue || undefined}
        getOptionLabel={(option) =>
          option?.Name ||
          option?.name ||
          (option?.uid && `${option?.name}(${option?.uid})`) ||
          option ||
          ""
        }
        onInputChange={onInputChange}
        value={value || undefined}
        isOptionEqualToValue={(option, value) => option === value}
        onChange={onChange}
        renderTags={() => null}
        renderInput={(params) =>
          !searchbar ? (
            <TextField
              {...params}
              variant="outlined"
              placeholder={placeholder}
              style={{
                border:
                  error || helperText || props.disabled
                    ? "0.5px solid #ACACAC"
                    : "3px solid #ACACAC",
                borderRadius: error || helperText ? "" : 9,
								width: mobileView ? '80%' : '100%'
              }}
              error={error}
              helperText={helperText}
              // {...props}
            />
          ) : (
            <TextField
              {...params}
              variant="outlined"
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handlesearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{
                border:
                  error || helperText || props.disabled
                    ? "0.5px solid #ACACAC"
                    : "3px solid #ACACAC",
                borderRadius: error || helperText ? "" : 9,
              }}
              error={error}
              helperText={helperText}
            />
          )
        }
        style={{
          paper: { width: mobileView ? 200 : 400 },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "0",
            padding: 0.5,
            paddingRight: searchbar && "0px !important",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: "0",
          },
        }}
        {...props}
      />
      {!singleselect && (
        <Box
          mt={1}
          sx={{
            "& > :not(:last-child)": { mr: 1 },
            "& > *": { mr: 1 },
          }}
        >
          {!isEmpty(value) && chip}
        </Box>
      )}
    </Box>
  );
};
