import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import {
  Grid,
  Box,
  IconButton,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Checkbox,
  Hidden,
} from "@mui/material";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import colors from "../../themes/colors";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, get } from "lodash";
import FHButton from "../../components/FHButton";
import { FHLabel } from "../../components/FormInputs/FHLabel";
import { FHTextfield } from "../../components/FormInputs/FHTextfield";
import * as yup from "yup";
import SampleImg from "../../assets/images/sample.png";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import commonClasses from "../../themes/commonClasses";
import { makeStyles } from "@mui/styles";
import closeIcon from "../../assets/icons/Close.png";
import { updateMenuItem } from "../../services/menuServices";
import { notify } from "../../services/utils";

const customStyles = makeStyles(commonClasses);

export const AddOrEditMenu = (props) => {
  const { onClose } = props;
  const common = customStyles(commonClasses);
  const [statusValue, setStatusValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const storage = getStorage();
  const dispatch = useDispatch();

  const selectedMenuDetails = useSelector(
    (state) => state.menuReducer.selectedMenuDetails
  );
  const categories = useSelector((state) => state.menuReducer.categories);
  const menuLocation = useSelector((state) => state.menuReducer.menuLocation);
  const user = useSelector((state) => state.userReducer.user);
  const OpenFileInput = useRef(null);

  const validationSchema = yup.object({
    name: yup.string().required("name is required"),
    description: yup.string().required("description is required"),
    category: yup.string().required("category is required"),
    price: yup.number().required("price is required"),
    status: yup.string().required("status is required"),
    subCategory: yup.string().required("subCategory is required"),
    packagingCharge: yup.number().required("packagingCharge is required"),
  });

  const formFields = {
    name: "",
    description: "",
    category: "",
    subCategory: "",
    packagingCharge: "",
    price: 0,
    status: "",
    id: "",
    gstIncluded: false,
    gst: 0,
    imgs: [],
    flags: "",
  };

  const formik = useFormik({
    initialValues: formFields,
    validationSchema: validationSchema,
    onSubmit: async (e) => {
      setLoading(true);
      console.log("formik values on submit", formik.values);
      let formData = formik.values;
      if (!formData.gstIncluded) {
        formData = { ...formData, gst: 0 };
      }
      selectedMenuDetails.imgs[0].uri = formData?.imgs[0].uri;
      formData = {
        ...formData,
        imgs: selectedMenuDetails.imgs,
      };
      updateMenuItem(formData)
        .then((res) => {
          onClose();
          setImageFile(null);
          setImageUrl(null);
          setLoading(false);
          notify("Menu Item Updated Successfully");
          let payload = {
            msg: `Updated Menu Item`,
            botId: "",
            activityType: "updates",
            created: {
              at: new Date(),
              name: user?.name || user.firstName,
              uid: user?.uid,
            },
            initiator: "superAdmin",
            logLevel: "info",
            metaData: {
              itemName: formik.values.name,
              itemId: formik.values.id,
              locId: menuLocation?.d?.locId || "common",
              locName: menuLocation?.Name || "",
            },
          };
          dispatch({
            type: "LOG_ACTIVITY",
            payload: payload,
          });
        })
        .catch((err) => {
          setLoading(false);
          notify("Menu Item Updated Unsuccessfully");
          console.log(err);
        });
    },
  });

  useEffect(() => {
    formik.setFieldValue("name", selectedMenuDetails?.name || "");
    formik.setFieldValue("price", selectedMenuDetails?.price || 0);
    formik.setFieldValue("description", selectedMenuDetails?.description || "");
    formik.setFieldValue("category", selectedMenuDetails?.category || "");
    formik.setFieldValue("id", selectedMenuDetails?.id || "");
    formik.setFieldValue("subCategory", selectedMenuDetails?.subCategory || "");
    formik.setFieldValue(
      "gstIncluded",
      selectedMenuDetails?.gstIncluded || false
    );
    formik.setFieldValue("gst", selectedMenuDetails?.gst || "");
    formik.setFieldValue("imgs", selectedMenuDetails?.imgs || "");
    formik.setFieldValue("flags", selectedMenuDetails?.flags || "");
    formik.setFieldValue(
      "packagingCharge",
      selectedMenuDetails?.packagingCharge || 0
    );
    formik.setFieldValue("status", selectedMenuDetails?.status || "");
    setStatusValue(
      selectedMenuDetails?.hide === true
        ? "Hide"
        : selectedMenuDetails?.status || ""
    );
  }, [selectedMenuDetails]);

  const handleStatusChange = (e) => {
    setStatusValue(e.target.value);
    if (e.target.value === "Hide") {
      formik.setFieldValue("hide", true);
      formik.setFieldValue("status", selectedMenuDetails?.status);
    } else if (e.target.value === "Active") {
      formik.setFieldValue("hide", false);
      formik.setFieldValue("status", e.target.value);
    } else {
      formik.setFieldValue("hide", selectedMenuDetails?.hide);
      formik.setFieldValue("status", e.target.value);
    }
  };

  let imgurl = get(selectedMenuDetails, "imgs[0].uri");

  const handleRemoveImg = () => {
    setImageFile(null);
    setImageUrl(null);
    formik.setFieldValue("imgs", [{ uri: imgurl }]);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    setImageUrl(URL.createObjectURL(file));
    if (file !== null) {
      console.log("update with new image");
      const storageRef = ref(storage, `menuItemPictures/${file?.name}`);
      const fileRef = uploadBytesResumable(storageRef, file, {
        cacheControl: "public,max-age=31536000",
      });
      await fileRef
        .then(function (snapshot) {
          console.log("image uploaded to the storage", snapshot);
          if (snapshot.state === "success") {
            getDownloadURL(snapshot.ref).then(function (url) {
              // console.log("image url from storage", url);
              let newUrl = "";
              if (url) {
                let tempUrl = url.split("/o/");
                let baseUrl = tempUrl[0];
                let additionalUrl = tempUrl[1];
                let newbaseUrl = "https://images.freshot.in/";
                newUrl = newbaseUrl + additionalUrl;
                console.log("oldUrl", url);
                // console.log("newUrl", newUrl);
              }
              if (!isEmpty(newUrl)) {
                let finalData = {
                  ...selectedMenuDetails,
                  imgs: [{ uri: url }],
                };
                console.log("finalData with new image", finalData);
                formik.setFieldValue("imgs", [{ uri: url }]);
              }
            });
          }
        })
        .catch((e) => console.log("uploading image error => ", e));
    } else {
      formik.setFieldValue("imgs", [{ uri: imgurl }]);
      console.log("without image update");
    }
  };

  const handleImageEdit = () => {
    // setDisableSave(false);
    OpenFileInput.current.click();
  };
  const MenuImage = () => {
    return (
      <>
        <Box display={"flex"} justifyContent={"flex-end"} mr={1.5}>
          <IconButton
            className={common.mediaIcon}
            onClick={imageFile !== null ? handleRemoveImg : handleImageEdit}
          >
            {imageFile !== null ? (
              <HighlightOffIcon
                sx={{ color: colors.success }}
                fontSize="small"
              />
            ) : (
              <DriveFileRenameOutlineRoundedIcon
                sx={{ color: colors.success }}
                fontSize="small"
              />
            )}
          </IconButton>
          <input
            type="file"
            ref={OpenFileInput}
            onChange={handleImageUpload}
            style={{ display: "none" }}
          />
        </Box>
        <Box className={common.image}>
          <img
            src={
              imageUrl === null
                ? get(selectedMenuDetails, "imgs[0].uri") || SampleImg
                : imageUrl
            }
            style={{
              height:
                !isEmpty(get(selectedMenuDetails, "imgs[0].uri")) || imageUrl
                  ? "100%"
                  : 50,
              width:
                !isEmpty(get(selectedMenuDetails, "imgs[0].uri")) || imageUrl
                  ? "100%"
                  : 50,
              display: "flex",
              justifyContent: "center",
              background: "cover",
              margin: "auto",
              borderRadius: 5,
            }}
          />
        </Box>
      </>
    );
  };
  return (
    <Box p={2.5}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Box>
              <Typography fontSize={23} fontWeight={700}>
                Edit Menu
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={() => onClose()}>
                <img src={closeIcon} />
              </IconButton>
            </Box>
          </Box>

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Hidden smUp>
                <Grid
                  item
                  md={5}
                  mt={4}
                  sm={5}
                  xs={12}
                  justifyContent={"center"}
                >
                  <MenuImage />
                </Grid>
              </Hidden>
              <Grid item md={7} sm={7} xs={12}>
                <Grid container spacing={1}>
                  <Grid item md={12} sm={12} xs={12}>
                    <FHLabel label={"Item Name"} />
                    <FHTextfield
                      fullWidth
                      id={"name"}
                      type={"text"}
                      name={"Name"}
                      width={"100%"}
                      // disabled={formik.values === "locId"}
                      placeholder="Enter Item name"
                      value={formik.values.name}
                      // onChange={formik.handleChange}
                      onChange={(e) =>
                        formik.setFieldValue("name", e.target.value)
                      }
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <FHLabel label={"Item Description"} />
                    <FHTextfield
                      fullWidth
                      multiline
                      rows={4}
                      id={"description"}
                      type={"text"}
                      name={"Description"}
                      width={"100%"}
                      // disabled={formik.values === "locId"}
                      placeholder="Enter Item Description"
                      value={formik.values.description}
                      // onChange={formik.handleChange}
                      onChange={(e) =>
                        formik.setFieldValue("description", e.target.value)
                      }
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Hidden only={"xs"}>
                <Grid item md={5} mt={4} sm={5} xs={5}>
                  <MenuImage />
                </Grid>
              </Hidden>

              <Grid item md={6} sm={12} xs={12}>
                <FHLabel label={"Category"} />
                <FHTextfield
                  select
                  fullWidth
                  id={"category"}
                  type={"text"}
                  name={"category"}
                  width={"100%"}
                  // disabled={formik.values === "locId"}
                  placeholder="Enter category"
                  value={formik.values.category}
                  // onChange={formik.handleChange}
                  // onChange={(e) =>
                  //   formik.setFieldValue("category", e.target.value)
                  // }
                  error={
                    formik.touched.category && Boolean(formik.errors.category)
                  }
                  helperText={formik.touched.category && formik.errors.category}
                >
                  {categories?.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </FHTextfield>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FHLabel label={"Sub Category"} />
                <FHTextfield
                  fullWidth
                  id={"subCategory"}
                  type={"text"}
                  name={"subCategory"}
                  width={"100%"}
                  placeholder="Enter Sub Category"
                  value={formik.values.subCategory}
                  onChange={(e) =>
                    formik.setFieldValue("subCategory", e.target.value)
                  }
                  error={
                    formik.touched.subCategory &&
                    Boolean(formik.errors.subCategory)
                  }
                  helperText={
                    formik.touched.subCategory && formik.errors.subCategory
                  }
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FHLabel label={"Price"} />
                <FHTextfield
                  fullWidth
                  id={"price"}
                  type={"number"}
                  name={"Price"}
                  width={"100%"}
                  // disabled={formik.values === "locId"}
                  placeholder="Enter price"
                  value={formik.values.price}
                  // onChange={formik.handleChange}
                  onChange={(e) =>
                    formik.setFieldValue("price", parseInt(e.target.value))
                  }
                  error={formik.touched.price && Boolean(formik.errors.price)}
                  helperText={formik.touched.price && formik.errors.price}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FHLabel label={"Packaging Charge"} />
                <FHTextfield
                  fullWidth
                  id={"packagingCharge"}
                  type={"number"}
                  name={"packagingCharge"}
                  width={"100%"}
                  // disabled={formik.values === "locId"}
                  placeholder="Enter Packaging Charge"
                  value={formik.values.packagingCharge}
                  // onChange={formik.handleChange}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "packagingCharge",
                      parseInt(e.target.value)
                    )
                  }
                  error={
                    formik.touched.packagingCharge &&
                    Boolean(formik.errors.packagingCharge)
                  }
                  helperText={
                    formik.touched.packagingCharge &&
                    formik.errors.packagingCharge
                  }
                />
              </Grid>
              <Grid
                item
                md={6}
                sm={6}
                xs={6}
                display={"flex"}
                ml={-1}
                alignItems={"center"}
              >
                <Checkbox
                  id="gstIncluded"
                  name="gstIncluded"
                  label="gstIncluded"
                  color={"success"}
                  checked={formik.values.gstIncluded}
                  onChange={formik.handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <FHLabel label={"GST Included"} />
              </Grid>
              {formik.values.gstIncluded && (
                <Grid item md={6} sm={6} xs={6}>
                  <FHLabel label={"GST %"} />
                  <FHTextfield
                    fullWidth
                    id={"gst"}
                    type={"number"}
                    name={"Gst"}
                    width={"100%"}
                    // disabled={formik.values === "locId"}
                    placeholder="Enter Item GST"
                    value={formik.values.gst}
                    // onChange={formik.handleChange}
                    onChange={(e) =>
                      formik.setFieldValue("gst", parseInt(e.target.value))
                    }
                    error={formik.touched.gst && Boolean(formik.errors.gst)}
                    helperText={formik.touched.gst && formik.errors.gst}
                  />
                </Grid>
              )}

              <Grid item md={6} sm={12} xs={12}>
                <FHLabel label={"Status"} />
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={statusValue}
                  onChange={handleStatusChange}
                >
                  <FormControlLabel
                    value="Active"
                    control={
                      <Radio
                        sx={{
                          color: colors.lightGrey,
                          "&.Mui-checked": {
                            color: colors.success,
                          },
                        }}
                      />
                    }
                    label="Active"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontWeight: 600,
                        fontSize: 15,
                      },
                    }}
                  />
                  <FormControlLabel
                    value="inActive"
                    control={
                      <Radio
                        sx={{
                          color: colors.lightGrey,
                          "&.Mui-checked": {
                            color: colors.success,
                          },
                        }}
                      />
                    }
                    label="InActive"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontWeight: 600,
                        fontSize: 15,
                      },
                    }}
                  />
                  <FormControlLabel
                    value="Hide"
                    control={
                      <Radio
                        sx={{
                          color: colors.lightGrey,
                          "&.Mui-checked": {
                            color: colors.success,
                          },
                        }}
                      />
                    }
                    label="Hide"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontWeight: 600,
                        fontSize: 15,
                      },
                    }}
                  />
                </RadioGroup>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FHLabel label={"Today`s Flag"} />
                <FHTextfield
                  fullWidth
                  id={"flag"}
                  type={"text"}
                  name={"Flag"}
                  width={"100%"}
                  placeholder="Enter Today`s Flag"
                  value={formik.values.flags}
                  onChange={(e) =>
                    formik.setFieldValue("flags", e.target.value)
                  }
                  error={formik.touched.flags && Boolean(formik.errors.flags)}
                  helperText={formik.touched.flags && formik.errors.flags}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12} textAlign={"right"}>
                <FHButton
                  loading={loading}
                  name={"Save"}
                  variant="outlined"
                  color={colors.success}
                  small
                  w_40
                  // disabled={
                  //   showItemMaxUses || showLocMaxUses || showUserMaxUses
                  // }
                  commonButtonStyle
                  onClick={formik.handleSubmit}
                  // errors={validated ? validate.errors : undefined}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};
