import React, { useEffect, useState } from "react";
import { Grid, List, ListItem, Typography, Popover } from "@mui/material";
import { Box } from "@mui/system";
import colors from "../../themes/colors";
import { filter } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";
import { FHDrawer } from "../../components/FHDrawer";
import EditOrDuplicate from "./EditOrDuplicate";
import FHButton from "../../components/FHButton";
import { notify } from "../../services/utils";
import { EditMachine } from "./EditMachine";
import { addMachineToLoc } from "../../services/botServices";
import FHModal from "../../components/FHModal";

const customStyles = makeStyles(commonClasses);

export const AddedBots = (props) => {
  const common = customStyles(commonClasses);
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);
  const [machineIds, setMachineIds] = useState();
  const [AddBot, setAddBot] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dMachine, setDMachine] = useState();
  const [selectedLocation, setSelectedLocation] = useState({});
  const [existingBotName, setExistingBotName] = useState({});
  const [modalSatus, setModalSatus] = useState('');
  const [selectedBot, setSelectedBot] = useState(null);
  
  const menuLocation = useSelector((state) => state.menuReducer.menuLocation);
  const allAdminUsers = useSelector((state) => state.userReducer.allAdminUsers);
  const machinesInfo = useSelector((state) => state.botReducer.machinesInfo);
  const masterMachines = useSelector(
    (state) => state.botReducer.masterMachines
  );
  const allLocation = useSelector((state) => state.locationReducer.locations);

  const handleClick = (event, item) => {
    setDMachine(item);
    setAnchorEl(event.currentTarget);
  };
  const handleAddBot = (event) => {
    setModalSatus('SELECT LOCATION')
  };
  const handleAddItem = (item) => {
    setModalSatus('')
    setAnchorEl(null);
    notify("User Added Bot successfully");
    console.log({ item: item, locId: menuLocation?.d?.locId }, "test of");
    addMachineToLoc(menuLocation?.d?.locId, item);
  };

  const handleClose = (name) => {
    if (name === "Edit") {
      setDrawer(true);
    }
    setAddBot(false);
    setModalSatus('')
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  const currentLocMenu = useSelector(
    (state) => state.menuReducer.currentLocationMenu
  );
  const selectedBotHandler = (value) => {
    setSelectedBot(value);
    setModalSatus('CONFIRM')
  };
  let addedMachineIds = [];
  filter(machinesInfo, (itm) => itm?.locId === menuLocation?.d.locId).map(
    (item, i) => {
      addedMachineIds.push(item?.botInfo?.id);
    }
  );

  useEffect(() => {
    setMachineIds(addedMachineIds);
    const existBotName = Object.values(machinesInfo).reduce((result, item) => {
      if (item?.locId === menuLocation?.d.locId) {
         return [...result, item.name];
      }
      return result;
    }, []);
    setExistingBotName([...existBotName]);
  }, [menuLocation, currentLocMenu, allAdminUsers]);

  const selectedLocationHandler = (value) => {
    const locationData = allLocation.find((loc) => loc.Name === value.Name);
    console.log({Name: value.Name, locId: locationData?.d?.locId},'test of');
    setSelectedLocation({Name: value.Name, locId: locationData?.d?.locId});
    setModalSatus('SELECT BOT')
  };

  return (
    <Box>
      <Grid container mb={1}>
        <Grid item xs={8} sm={6}>
          <Typography fontSize={18} fontWeight={700}>
            Bots
          </Typography>
        </Grid>
        <Grid item xs={4} sm={6} textAlign={"right"}>
          {filter(
            masterMachines,
            (itm) => !machineIds?.includes(itm?.botInfo?.id)
          ).length > 0 && (
            <FHButton
              name={"Add +"}
              variant="outlined"
              smallAddButton
              commonButtonStyle
              onClick={(event) => handleAddBot(event)}
            />
          )}
        </Grid>
      </Grid>
      <Box justifyContent={"center"}>
        <List className={common.height18Vh}>
          {filter(
            machinesInfo,
            (itm) => itm?.locId === menuLocation?.d.locId
          ).map((item, i) => {
            return (
              <ListItem
                key={i}
                mt={1}
                className={[common.menulistItemalign, common.bgMain].join(" ")}
                onClick={(e) => handleClick(e, item)}
              >
                <Grid container>
                  <Grid
                    item
                    md={12}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <Typography
                      fontSize={15}
                      color={colors.darkGrey}
                      style={{
                        width: "170px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item?.botInfo?.name || item.name || "No Name"}
                    </Typography>

                    <Typography
                      fontSize="12px"
                      textAlign={"right"}
                      style={{
                        width: "230px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      color={colors.darkGrey}
                    >
                      Version.{item?.botInfo?.version || "v1"}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            );
          })}
        </List>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        
          <EditOrDuplicate
            editAndDuplicate={false}
            anchorEl={anchorEl}
            onPopClose={handleClose}
          />
      </Popover>
      <FHDrawer
        open={drawer}
        onClose={() => {}}
        onOpen={() => {}}
        drawerBorder
        data={
          <EditMachine onClose={() => setDrawer(false)} dMachine={dMachine} />
        }
      />
      <Box>
        {
        modalSatus==='SELECT LOCATION'?<FHModal 
          openDialog={Boolean(modalSatus)} 
          handleClose={handleClose} 
          dialogTitle={"Select Location"} 
          dialogContent={allLocation} 
          submitHandler={selectedLocationHandler}
        />:null
      }
        {
          modalSatus==='SELECT BOT'?<FHModal 
            openDialog={Boolean(modalSatus)} 
            handleClose={handleClose} 
            dialogTitle={"Select Location"} 
            dialogContent={filter(
              machinesInfo,
              (itm) => itm?.locId === selectedLocation?.locId && !existingBotName.includes(itm?.botInfo?.name)
              // machineIds?.includes(itm?.botInfo?.id)
            )} 
            submitHandler={selectedBotHandler}
          />:null
        }
        {
          modalSatus==='CONFIRM'?<FHModal 
            openDialog={Boolean(modalSatus)} 
            isConfirmationPage={true}
            handleClose={handleClose} 
            submitHandler={()=> handleAddItem(selectedBot)}
          />:null
        }
      </Box>
    </Box>
  );
};
