import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
	Box,
	Grid,
	Hidden,
	IconButton,
	Divider,
	Dialog,
	DialogActions,
	DialogTitle,
	useMediaQuery,
	fabClasses,
} from "@mui/material";
import useStyles from "./NavigationStyle";
import MuiDrawer from "@mui/material/Drawer";
// import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import logo from "../assets/icons/NavLogo.png";
import logo1 from "../assets/icons/freshot.png";
import logo2 from "../assets/images/logoBg.png";
import hamburger from "../assets/icons/hamburger.png";
import { map, filter, isEmpty } from "lodash";
import {
	TopNavigationData,
	BottomNavigationData,
	LogOutData,
} from "../data/navigationData";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FHButton from "./FHButton";
import Color from "../themes/colors";
import { BiStats } from "react-icons/bi";
import { VideoCallButton } from "../containers/VideoCallButton";
import salesLogo from "../assets/icons/sales.svg";
// import commonClasses from "../themes/commonClasses";
// import { makeStyles } from "@mui/styles";

const drawerWidth = 220;
// const customStyles = makeStyles(commonClasses);

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

export const NavigationBar = (props) => {
	const classes = useStyles();
	// const common = customStyles(commonClasses);
	// const theme = useTheme();
	const navigate = useNavigate();
	const notMobileView = useMediaQuery("(max-width:921px)");
	const mobileView = useMediaQuery("(max-width:920px)");

	const [open, setOpen] = React.useState(false);
	const [menu, setMenu] = useState("Dashboard");
	const dispatch = useDispatch();

	const selectedLocation = useSelector(
		(state) => state.locationReducer.selectedLocation
	);
	const mobileMenu = useSelector((state) => state.dashboard.mobileMenu);
	const userRole = useSelector((state) => state.userReducer.userRole);
	const showActivities = useSelector(
		(state) => state.activityReducer.showActivities
	);

	// const handleDrawerOpen = () => {
	//   setOpen(true);
	// };

	const handleDrawerClose = () => {
		setOpen(!open);
	};

	//dialog
	const [dialogOpen, setDialogOpen] = useState(false);

	const handleDialog = (actn) => {
		if (actn === "close") {
			setDialogOpen(false);
		} else {
			setDialogOpen(true);
		}
	};

	const handleLogout = () => {
		dispatch({ type: "LOGOUT" });
		navigate("/");
		handleDialog("close");
	};

	const handleMobileMenuClose = () => {
		setOpen(false);
		dispatch({ type: "MOBILEMENU", payload: false });
	};

	useEffect(() => {
		if (mobileMenu) {
			setOpen(true);
		}
	}, [mobileMenu]);
	// useEffect(() => {
	// 	dispatch({type:'LOGOUT'})

	// },[])

	const Users = (menus) => {
		if (userRole === "SuperAdmin") {
			return menus;
		} else if (
			userRole === "Admin" ||
			userRole === "CoOwner" ||
			userRole === "Vendor" ||
			userRole === "CustomerSupport"
		) {
			return filter(menus, (data) => data.role === "");
		}
	};

	const handleMobileMenu = () => {
		dispatch({ type: "MOBILEMENU", payload: !mobileMenu });
	};

	const handleActivities = () => {
		dispatch({ type: "SHOW_ACTIVITIES", payload: !showActivities });
	};

	const handleShowSales = () => {
		dispatch({ type: 'SHOW_SALES', payload: true })
	}

	useEffect(() => {
		dispatch({ type: "SHOW_ACTIVITIES", payload: false });
	}, []);

	const DrawerMenu = () => {
		return (
			<Drawer
				variant="permanent"
				open={open}
				classes={{ paper: clsx(classes.drawerPaper) }}
			>
				<DrawerHeader>
					<Box
						p={open ? 3 : 2}
						pl={open ? 3 : 0}
						pr={open ? 6 : 0.5}
						className={classes.toolbarLogo}
					>
						{mobileView && (
							<IconButton onClick={handleMobileMenuClose}>
								<ChevronLeftIcon
									style={{
										color: "#ffffff",
										marginLeft: 20,
										height: 50,
										width: 30,
									}}
								/>
							</IconButton>
						)}
						<img alt="logo" src={open || (open && mobileView) ? logo : logo1} />
					</Box>
				</DrawerHeader>
				<Divider classes={{ root: classes.divider }} />
				<List>
					{map(Users(TopNavigationData), (data, i) => (
						<ListItem key={i} disablePadding>
							<ListItemButton
								disabled={data.disabled}
								sx={{
									// minHeight: 48,
									justifyContent: open ? "initial" : "center",
									px: 2.5,
								}}
								onClick={() => handleMenuSelect(data)}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : "auto",
										justifyContent: "center",
									}}
								>
									{GetIcon(data.Icon)}
								</ListItemIcon>
								<ListItemText
									primary={data.header}
									classes={{
										primary:
											menu === data.header
												? classes.selectedMenu
												: classes.menuText,
									}}
									sx={{ opacity: open ? 1 : 0 }}
								/>
							</ListItemButton>
						</ListItem>
					))}
				</List>
				{isEmpty(Users(BottomNavigationData)) &&
					isEmpty(Users(TopNavigationData)) ? (
					<></>
				) : (
					<Divider classes={{ root: classes.divider }} />
				)}

				<List>
					{map(Users(BottomNavigationData), (data, i) => (
						<ListItem key={i} disablePadding sx={{ display: "block" }}>
							<ListItemButton
								disabled={data.disabled}
								sx={{
									minHeight: 48,
									justifyContent: open ? "initial" : "center",
									px: 2.5,
								}}
								onClick={() =>
									data.id === 9 ? handleDialog("open") : handleMenuSelect(data)
								}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : "auto",
										justifyContent: "center",
									}}
								>
									{GetIcon(data.Icon)}
								</ListItemIcon>
								<ListItemText
									primary={data.header}
									classes={{
										primary:
											menu === data.header
												? classes.selectedMenu
												: classes.menuText,
									}}
									sx={{ opacity: open ? 1 : 0 }}
								/>
							</ListItemButton>
						</ListItem>
					))}
					{map(LogOutData, (data, i) => (
						<ListItem key={i} disablePadding sx={{ display: "block" }}>
							<ListItemButton
								disabled={data.disabled}
								sx={{
									minHeight: 48,
									justifyContent: open ? "initial" : "center",
									px: 2.5,
								}}
								onClick={() =>
									data.id === 9 ? handleDialog("open") : handleMenuSelect(data)
								}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : "auto",
										justifyContent: "center",
									}}
								>
									{GetIcon(data.Icon)}
								</ListItemIcon>
								<ListItemText
									primary={data.header}
									classes={{
										primary:
											menu === data.header
												? classes.selectedMenu
												: classes.menuText,
									}}
									sx={{ opacity: open ? 1 : 0 }}
								/>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Drawer>
		);
	};

	const GetIcon = (Icon) => {
		return (
			<Box
				style={{
					background: "white",
					position: "relative",
					height: 40,
					width: 40,
					borderRadius: 20,
					display: "table",
					textAlign: "center",
				}}
			>
				<img
					style={{
						height: 15,
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
					}}
					alt={Icon}
					src={Icon}
				/>
			</Box>
		);
	};

	const handleMenuSelect = (data) => {
		navigate(data.path);
		setMenu(data.header);
		setOpen(false);
		if (mobileView) {
			dispatch({ type: "MOBILEMENU", payload: false });
		}
	};

	return (
		<>
			<Box sx={{ display: "flex" }}>
				{!mobileView && !mobileMenu ? (
					<DrawerMenu />
				) : mobileMenu ? (
					<DrawerMenu />
				) : (
					<></>
				)}
				{!mobileView && (
					<IconButton
						onClick={handleDrawerClose}
						className={classes.iconButton}
						style={{
							background: "#ffffff",
							padding: 15,
							marginLeft: -10,
							position: "sticky",
							top: 20,
							// marginRight: -22,
							zIndex: 1800,
						}}
					>
						{!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				)}
				<Box component="main" sx={{ flexGrow: 1, p: mobileView ? 1 : 3 }}>
					{/* <DrawerHeader /> */}
					{/* <Hidden mdDown> */}
					{mobileView && (
						<Grid container p={0}>
							<Grid item xs={6}>
								<Box display="flex" justifyContent={"flex-start"}>
									<Box>
										<IconButton onClick={handleMobileMenu}>
											<img src={hamburger} />
										</IconButton>
									</Box>
									<Box mt={1.5} ml={1.5}>
										<img src={logo2} style={{ height: 40, width: 80 }} />
									</Box>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box
									display="flex"
									flexDirection={"row"}
									justifyContent={"flex-end"}
								>
									<FHButton
										name={<img src={salesLogo} style={{ height: "30", width: "40" }} />}
										variant="outlined"
										// disabled={true}
										smallButton
										w_50
										viewActivityBtnsmall
										textWhite
										onClick={() => handleShowSales()}
									/>
									<FHButton
										name={<BiStats style={{ height: "30", width: "40" }} />}
										variant="outlined"
										// disabled={true}
										smallButton
										w_50
										viewActivityBtnsmall
										textWhite
										onClick={() => handleActivities()}
									/>
									{selectedLocation?.Name !== "All Locations" && menu === 'Dashboard' && (
										<VideoCallButton />
									)}
								</Box>
							</Grid>
						</Grid>
					)}
					{/* </Hidden> */}
					{props.menuDisplay}
				</Box>
			</Box>

			<Dialog
				open={dialogOpen}
				onClose={() => handleDialog("close")}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Do you really want to Logout?"}
				</DialogTitle>

				<DialogActions>
					<FHButton
						name={"Cancel"}
						variant="outlined"
						color={Color.success}
						smallButton
						commonButtonStyle
						onClick={() => handleDialog("close")}
					/>
					<FHButton
						name={"Logout"}
						variant="outlined"
						color={Color.success}
						smallButton
						commonButtonStyle
						onClick={() => {
							handleLogout();
						}}
					/>
				</DialogActions>
			</Dialog>
		</>
	);
};
