export const graphOptions = {
	responsive: true,
	plugins: {
		legend: {
			position: 'top',
		},
		tooltip: {
			callbacks: {
				label: (context) => {
					const dataset = context.dataset.label || '';
					const value = context.parsed.y || 0;
					return `${dataset}: ₹${value}`;
				},
			},
		},
	},
	scales: {
		x: {
			beginAtZero: true
		},
		y: {
			beginAtZero: true,
			ticks: {
				callback: (value) => `₹${value}`,
			},
		},
	},
}