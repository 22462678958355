import React, { useEffect, useState } from "react";
import { Popover, Typography } from "@mui/material";
import { Box } from "@mui/system";
import colors from "../../themes/colors";
import { isEmpty, map , filter} from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";
import FHButton from "../../components/FHButton"

const customStyles = makeStyles(commonClasses);

const commonStyles = {
	m: 1,
	color: colors.linegrey,
	width: "10rem",
	height: "1rem",
};

export const AddOperationFilter = () => {
	const common = customStyles(commonClasses);
	const dispatch = useDispatch();

	const selectedAddPlan = useSelector(
		(state) => state.dailyPlanReducer.selectedAddPlan
	);
	const selectedMachine = useSelector(
		(state) => state.dailyPlanReducer.selectedMachine
	);
	const operations = useSelector(
		(state) => state.dailyPlanReducer.operations
	);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleClose = () => {
		setAnchorEl(null);
	};

	const [addOperationData, setAddOperationData] = useState([]);
	useEffect(() => {
		let dropdown = []
		if (
			selectedMachine?.machineType === "TCVB" ||
			selectedMachine?.botInfo?.botType === "TCVB"
		) {
			dropdown = filter(operations, item => item.botType === selectedMachine?.machineType === "TCVB" ||
				selectedMachine?.botInfo?.botType === "TCVB")
			
		} else if (
			selectedMachine?.machineType === "CSVB" ||
			selectedMachine?.botInfo?.botType === "CSVB"
		) {
			dropdown = filter(operations, item => item.botType === selectedMachine?.machineType === "CSVB" ||
				selectedMachine?.botInfo?.botType === "CSVB")
		} else {
			dropdown = filter(operations, item => item.botType === selectedMachine?.machineType === "ICVB" ||
				selectedMachine?.botInfo?.botType === "ICVB" || selectedMachine?.botInfo?.botType === "TEST")
		}
		setAddOperationData(dropdown);
	}, [selectedMachine]);

	const handleAddOperation = (u) => {
		dispatch({ type: "ADD_OPERATION_DIALOG", payload: true });
		setAnchorEl(null);
		dispatch({ type: "SELECTED_OPERATION", payload: u });

	}

	return (
		<Box>
			<FHButton
				name={"Add Operation"}
				variant="outlined"
				button
				commonButtonStyle
				onClick={handleClick}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				PaperProps={{ sx: { border: `2px solid ${colors.success}` } }}
			>
				{map(addOperationData, (u, i) => (
					<Box key={i}>
						<Box
							display="flex"
							justifyContent="center"
							gap="12px"
							key={i}
							p={1}
							sx={{
								...commonStyles,
								borderBottom: 1,
								width: 'auto',
								backgroundColor:
									u.name === selectedAddPlan?.name ? colors.success : "",
							}}
							alignItems="center"
							className={common.mousecursor}
							onClick={(e) => handleAddOperation(u)}
						>
							<Typography
								sx={{
									color: u.name === selectedAddPlan?.name ? "white" : "black",
								}}
							>
								{u.name}
							</Typography>
						</Box>
					</Box>
				))}
			</Popover>
		</Box>
	);
};
