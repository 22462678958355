import React, { useEffect } from "react";
import "./App.css";
import Routes from "./routes";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/ErrorFallback";
import { ThemeProvider } from "@mui/material/styles";
import CustomisedTheme from "./themes/customizedTheme";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useDispatch } from "react-redux";

const firebaseConfig = {
  apiKey: "AIzaSyChPy2KjijxUSEWsDRS3HnJu3spvAAx3rY",
  authDomain: "freshot-prod.firebaseapp.com",
  projectId: "freshot-prod",
  storageBucket: "freshot-prod.appspot.com",
  messagingSenderId: "930270784467",
  appId: "1:930270784467:web:954c3558452d984df71d26",
  measurementId: "G-EB8MEF5BQ5",
};
// const firebaseConfig = {
//   apiKey: "AIzaSyDuDWQ-yZtd80Ueev4_6L_1aC9D6_FrKmc",
//   authDomain: "freshot-dev.firebaseapp.com",
//   projectId: "freshot-dev",
//   storageBucket: "freshot-dev.appspot.com",
//   messagingSenderId: "968004212913",
//   appId: "1:968004212913:web:4aeac97a3b36bbaaa16a90",
//   measurementId: "G-6E9BZC7C5L"
// };

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
const auth = getAuth();

function App() {
  // const dispatch = useDispatch();
  useEffect(() => {
    getAuth().onAuthStateChanged(function (user) {
      // console.log(user, "User Uid");
    });
  }, [auth]);
  return (
    <ThemeProvider theme={CustomisedTheme}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location = "/";
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <Routes />
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
