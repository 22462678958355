import * as React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import closeIcon from "../assets/icons/Close.png";

export default function AlertDialog({ open, handleClose, body }) {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box p={1}>
        <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
          <IconButton onClick={() => handleClose()}>
            <img src={closeIcon} />
          </IconButton>
        </Box>
        {body}
      </Box>
    </Dialog>
  );
}
