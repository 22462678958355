import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Grid,
  Box,
  IconButton,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputAdornment,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import colors from "../../themes/colors";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import FHButton from "../../components/FHButton";
import { FHLabel } from "../../components/FormInputs/FHLabel";
import { FHTextfield } from "../../components/FormInputs/FHTextfield";
import InputBox from "../../components/InputBox";
import * as yup from "yup";
import closeIcon from "../../assets/icons/Close.png";
import { notify } from "../../services/utils";
import { updateMachine } from "../../services/botServices";

export const EditMachine = (props) => {
  const { onClose, dMachine } = props;
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.userReducer.user);
  const menuLocation = useSelector((state) => state.menuReducer.menuLocation);

  const validationSchema = yup.object({
    name: yup.string().required("name is required"),
    version: yup.string().required("version is required"),
    wsPort: yup.string().required("wsPort is required"),
    wsIP: yup.string().required("wsIP is required"),
  });

  const formFields = {
    restart: false,
    update: false,
    name: "",
    version: "",
    wsPort: "",
    wsIP: "",
    espPassword: "",
  };

  const formik = useFormik({
    // enableReinitialize: editPromo,
    initialValues: formFields,
    validationSchema: validationSchema,
    onSubmit: async (e) => {
      setLoading(true);
      console.log("formik values on submit", formik.values);
      let formData = {
        ...dMachine,
        appInfo: {
          ...dMachine.appInfo,
          restart: formik.values.restart,
          update: formik.values.update,
          lastUpdated: new Date(),
        },
        botInfo: {
          ...dMachine.botInfo,
          name: formik.values.name,
          description: formik.values.description,
          version: formik.values.version,
        },
        configuration: {
          ...dMachine.configuration,
          wsIP: formik.values.wsIP,
          wsPort: formik.values.wsPort,
          espPassword: password,
        },
      };
      updateMachine(menuLocation?.d?.locId || "common", dMachine?.botInfo?.id, {
        ...formData,
      });
      notify("Updated Machine Successfully");
      let payload = {
        msg: `Updated Machine ${formik.values.name}`,
        botId: "",
        activityType: "updates",
        created: {
          at: new Date(),
          name: user?.name || user?.firstName,
          uid: user?.uid,
        },
        initiator: "superAdmin",
        logLevel: "info",
        metaData: {
          locId: menuLocation?.d?.locId || "common",
          locName: menuLocation?.Name || "",
        },
      };
      dispatch({
        type: "LOG_ACTIVITY",
        payload: payload,
      });
      handleOnClose();
    },
  });

  useEffect(() => {
    formik.setFieldValue("restart", dMachine?.appInfo?.restart || false);
    formik.setFieldValue("update", dMachine?.appInfo?.update || false);
    formik.setFieldValue("name", dMachine?.botInfo?.name || "");
    formik.setFieldValue("description", dMachine?.botInfo?.description || "");
    formik.setFieldValue("version", dMachine?.botInfo?.version || "");
    formik.setFieldValue("wsPort", dMachine?.configuration?.wsPort || "");
    formik.setFieldValue("wsIP", dMachine?.configuration?.wsIP || "");

    setPassword(dMachine?.configuration.espPassword || "");
  }, [dMachine, onClose]);

  const handleOnClose = () => {
    formik.resetForm();
    onClose();
    setLoading(false);
  };

  return (
    <Box p={2.5} sx={{ width: 490 }}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Box>
              <Typography fontSize={23} fontWeight={700}>
                Edit Machine{" "}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={() => handleOnClose()}>
                <img src={closeIcon} />
              </IconButton>
            </Box>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <FHLabel label={"App Info"} />
                <Box
                  p={1}
                  pl={2}
                  style={{ border: "3px solid lightgray", borderRadius: "5px" }}
                >
                  <Grid container spacing={1}>
                    <Grid item md={6} sm={6} xs={6}>
                      <FHLabel label={"Restart"} />
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={formik.values.restart}
                        name={"Restart"}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "restart",
                            e.target.value === "true" ? true : false
                          )
                        }
                      >
                        <FormControlLabel
                          value={true}
                          control={
                            <Radio
                              sx={{
                                color: colors.lightGrey,
                                "&.Mui-checked": {
                                  color: colors.success,
                                },
                              }}
                            />
                          }
                          label="Yes"
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontWeight: 600,
                              fontSize: 14,
                            },
                          }}
                        />
                        <FormControlLabel
                          value={false}
                          control={
                            <Radio
                              sx={{
                                color: colors.lightGrey,
                                "&.Mui-checked": {
                                  color: colors.success,
                                },
                              }}
                            />
                          }
                          label="NO"
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontWeight: 600,
                              fontSize: 14,
                            },
                          }}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item md={6} sm={6} xs={6}>
                      <FHLabel label={"Update"} />
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={formik.values.update}
                        name={"Update"}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "update",
                            e.target.value === "true" ? true : false
                          )
                        }
                      >
                        <FormControlLabel
                          value={true}
                          control={
                            <Radio
                              sx={{
                                color: colors.lightGrey,
                                "&.Mui-checked": {
                                  color: colors.success,
                                },
                              }}
                            />
                          }
                          label="Yes"
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontWeight: 600,
                              fontSize: 14,
                            },
                          }}
                        />
                        <FormControlLabel
                          value={false}
                          control={
                            <Radio
                              sx={{
                                color: colors.lightGrey,
                                "&.Mui-checked": {
                                  color: colors.success,
                                },
                              }}
                            />
                          }
                          label="NO"
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontWeight: 600,
                              fontSize: 14,
                            },
                          }}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <FHLabel label={"Bot Info"} />
                <Box
                  p={1}
                  pl={2}
                  style={{ border: "3px solid lightgray", borderRadius: "5px" }}
                >
                  <Grid container spacing={1}>
                    <Grid item md={6} sm={12} xs={12}>
                      <FHLabel label={"Name"} />
                      <FHTextfield
                        fullWidth
                        id={"name"}
                        type={"text"}
                        name={"Name"}
                        width={"100%"}
                        placeholder="Enter name"
                        value={formik.values.name}
                        onChange={(e) =>
                          formik.setFieldValue("name", e.target.value)
                        }
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <FHLabel label={"Description"} />
                      <FHTextfield
                        fullWidth
                        id={"description"}
                        type={"text"}
                        name={"Description"}
                        width={"100%"}
                        placeholder="Enter description"
                        value={formik.values.description||""}
                        onChange={(e) =>
                          formik.setFieldValue("description", e.target.value)
                        }
                        error={
                          formik.touched.description &&
                          Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description &&
                          formik.errors.description
                        }
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <FHLabel label={"Version"} />
                      <FHTextfield
                        fullWidth
                        id={"version"}
                        type={"text"}
                        name={"Version"}
                        width={"100%"}
                        placeholder="Enter version"
                        value={formik.values.version}
                        onChange={(e) =>
                          formik.setFieldValue("version", e.target.value)
                        }
                        error={
                          formik.touched.version &&
                          Boolean(formik.errors.version)
                        }
                        helperText={
                          formik.touched.version && formik.errors.version
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <FHLabel label={"Configuration"} />
                <Box
                  p={1}
                  pl={2}
                  style={{ border: "3px solid lightgray", borderRadius: "5px" }}
                >
                  <Grid container spacing={1}>
                    <Grid item md={6} sm={12} xs={12}>
                      <FHLabel label={"Web socket IP"} />
                      <FHTextfield
                        fullWidth
                        id={"wsIP"}
                        type={"text"}
                        name={"wsIP"}
                        width={"100%"}
                        placeholder="Enter Web socket IP"
                        value={formik.values.wsIP}
                        onChange={(e) =>
                          formik.setFieldValue("wsIP", e.target.value)
                        }
                        error={
                          formik.touched.wsIP && Boolean(formik.errors.wsIP)
                        }
                        helperText={formik.touched.wsIP && formik.errors.wsIP}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <FHLabel label={"Web socket Port"} />
                      <FHTextfield
                        fullWidth
                        // id={"wsPort"}
                        type={"text"}
                        // name={"WsPort"}
                        width={"100%"}
                        placeholder="Enter Web socket Port"
                        value={formik.values.wsPort}
                        onChange={(e) =>
                          formik.setFieldValue("wsPort", e.target.value)
                        }
                        error={
                          formik.touched.wsPort && Boolean(formik.errors.wsPort)
                        }
                        helperText={
                          formik.touched.wsPort && formik.errors.wsPort
                        }
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <FHLabel label={"ESP Password"} />
                      <InputBox
                        style={{ width: "100%" }}
                        type={showPassword ? "text" : "password"}
                        value={password}
                        endadornment={
                          <InputAdornment
                            position="end"
                            className="pointer"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <VisibilityIcon style={{ cursor: "pointer" }} />
                            ) : (
                              <VisibilityOffIcon
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </InputAdornment>
                        }
                        onChange={(event) => setPassword(event.target.value)}
                      />
                      <Typography
                        style={{
                          whiteSpace: "normal",
                          fontSize: 12,
                          color: "red",
                        }}
                      >
                        {password.length < 6 ? "Atleast 6 latters" : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item md={12} textAlign={"right"}>
                <FHButton
                  loading={loading}
                  name={"Save"}
                  variant="outlined"
                  color={colors.success}
                  small
                  w_40
                  // disabled={
                  //   showItemMaxUses || showLocMaxUses || showUserMaxUses
                  // }
                  commonButtonStyle
                  onClick={formik.handleSubmit}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};
