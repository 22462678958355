import React, { useEffect, useState } from "react";
import { Button, Popover, Typography } from "@mui/material";
import { Box } from "@mui/system";
import colors from "../../themes/colors";
import { isEmpty, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getDates, getLastSevenDays } from "../../services/dateTimeConversion";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const customStyles = makeStyles(commonClasses);

const commonStyles = {
  m: 1,
  color: colors.linegrey,
  width: "10rem",
  height: "1rem",
};

export const DateFilter = () => {
  const common = customStyles(commonClasses);
  const dispatch = useDispatch();

  const dateRange = useSelector((state) => state.orderReducer.dateRange);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let today = new Date();
  let newDate = Math.floor(today);
  let yesterday = new Date(newDate - 24 * 60 * 60 * 1000);

  let datesDropdown = [
    { ...getDates(today), day: "Today" },
    { ...getDates(yesterday), day: "Yesterday" },
    { ...getLastSevenDays(), day: "Last 7 days" },
  ];

  useEffect(() => {
    if (!isEmpty(datesDropdown)) {
      dispatch({ type: "STORE_DATE_RANGE", payload: datesDropdown[0] });
    }
  }, []);

  const handleDateChange = (data) => {
    dispatch({ type: "STORE_DATE_RANGE", payload: data });
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        sx={{
          backgroundColor: `${colors.bright}`,
          height: 45,
          width: "100%",
        }}
      >
        <CalendarMonthIcon style={{ height: 30, width: 30, color: "black" }} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ sx: { border: `2px solid ${colors.success}` } }}
      >
        {map(datesDropdown, (u, i) => (
          <Box key={i}>
            <Box
              display="flex"
              justifyContent="center"
              gap="12px"
              key={i}
              p={1}
              sx={{
                ...commonStyles,
                borderBottom: 1,
                width: 300,
                backgroundColor:
                  u.date === dateRange?.date ? colors.success : "",
              }}
              alignItems="center"
              className={common.mousecursor}
              onClick={(e) => handleDateChange(u)}
            >
              <Typography
                textAlign={"center"}
                sx={{
                  color: u.date === dateRange?.date ? "white" : "black",
                }}
              >
                {u.day} -
              </Typography>
              <Typography
                textAlign={"center"}
                sx={{
                  color: u.date === dateRange?.date ? "white" : "black",
                }}
              >
                {u.date}
              </Typography>
            </Box>
          </Box>
        ))}
      </Popover>
    </Box>
  );
};
