import React from "react";
import { Box, Typography, IconButton, useMediaQuery } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Platewash from "./PlateWash";
import TbotPlateWash from "./TbotPlatewash";
import ChutneyBotHopperWash from "./ChutneyBotHopperWash";
import HopperWash from "./HopperWash";
import PlateFill from "./PlateFill";
import HopperFill from "./HopperFill";
import Cook from "./Cook";
import Worm from "./Worm";
import Store from "./Store";
import WashAndFill from "./Wash&Fill";
import StoreAndFill from "./Store&Fill";
import StoreAndWash from "./Store&Wash";
import colors from "../../../themes/colors";
import closeIcon from "../../../assets/icons/Close.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { isEmpty } from "lodash";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const AddPlans = () => {
  const dispatch = useDispatch();
  const mobileView = useMediaQuery("(max-width:920px)");

  const selectedAddPlan = useSelector(
    (state) => state.dailyPlanReducer.selectedAddPlan
  );
  const selectedLocation = useSelector(
    (state) => state.locationReducer.selectedLocation
  );
  const selectedMachine = useSelector(
    (state) => state.dailyPlanReducer.selectedMachine
  );
  const userRole = useSelector((state) => state.userReducer.userRole);
  const user = useSelector((state) => state.userReducer.user);

  const copiedPlan = useSelector((state) => state.dailyPlanReducer.copiedPlan);
  const editPlan = useSelector((state) => state.dailyPlanReducer.editPlan);
  const selectedEditPlan = useSelector(
    (state) => state.dailyPlanReducer.selectedEditPlan
  );
  const duplicatePlan = useSelector(
    (state) => state.dailyPlanReducer.duplicatePlan
  );

  const [open, setOpen] = React.useState(false);

  const closePlan = () => {
    dispatch({ type: "DAILY_PLAN", payload: false });
    dispatch({ type: "EDIT_PLAN", payload: false });
    dispatch({ type: "SELECTED_ADD_PLAN", payload: {} });
    dispatch({ type: "SELECTED_EDIT_PLAN", payload: {} });
    dispatch({ type: "DUPLICATE_PLAN", payload: false });
    dispatch({ type: "ADD_STATUS", payload: false });
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleDuplicatePlan = () => {
    dispatch({ type: "DUPLICATE_PLAN", payload: true });
  };

  let activityInfo = {
    msg: `${selectedAddPlan?.name} is added at ${selectedLocation?.Name} for ${selectedMachine?.name}`,
    activityType: editPlan ? "updates" : "maintenance",
    bot: `${selectedMachine?.name}`,
    created: {
      at: new Date(),
      name: user?.name || user?.email || user?.phoneNumber || "Anonymous",
      uid: user?.uid,
    },
    initiator: "superAdmin",
    logLevel: "info",
  };

  return (
    <Box p={mobileView ? 2 : 2}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography fontSize={23} fontWeight={700}>
            Plan - {selectedAddPlan?.name}
          </Typography>
          <Typography fontSize={17} color={colors.linegrey}>
            {selectedLocation?.Name} - {selectedMachine?.name}
          </Typography>
        </Box>

        <Box>
          <Box display={"row"} justifyContent={"space-between"}>
            {editPlan && userRole === "SuperAdmin" ? (
              <Tooltip title="Duplicate Plan">
                <IconButton onClick={() => handleDuplicatePlan()}>
                  <FileCopyIcon style={{ color: "#4DA731" }} />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
            <IconButton onClick={() => closePlan()}>
              <img src={closeIcon} />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {editPlan && (
        <Box>
          {selectedEditPlan?.hasOwnProperty("status") && (
            <Box display={"flex"} mt={2}>
              <Typography fontSize={16} fontWeight={600} mr={1}>
                Status:{" "}
              </Typography>
              <Typography
                color={
                  selectedEditPlan?.status === "FAILED" ? "#CE0000" : "#4DA731"
                }
              >
                {selectedEditPlan?.status}
              </Typography>
            </Box>
          )}
          {selectedEditPlan?.hasOwnProperty("reason") && (
            <Box display={"flex"} mt={2}>
              <Typography fontSize={16} fontWeight={600} mr={1}>
                Reason:{" "}
              </Typography>
              <Typography>{selectedEditPlan?.reason}</Typography>
            </Box>
          )}
        </Box>
      )}

      <Box mt={2}>
        {(selectedAddPlan?.name === "Wash" &&
          selectedMachine?.machineType === "TCVB") ||
        (selectedAddPlan?.name === "Wash" &&
          selectedMachine?.botInfo?.botType === "TCVB") ? (
          <TbotPlateWash activityInfo={activityInfo} />
        ) : selectedAddPlan?.name === "Plate Wash" &&
          selectedMachine?.machineType !== "TCVB" ? (
          <Platewash activityInfo={activityInfo} />
        ) : (selectedAddPlan?.name === "Drum Wash" &&
            selectedMachine?.machineType === "CSVB") ||
          (selectedAddPlan?.name === "Drum Wash" &&
            selectedMachine?.botInfo?.botType === "CSVB") ? (
          <ChutneyBotHopperWash activityInfo={activityInfo} />
        ) : selectedAddPlan?.name === "Hopper Wash" &&
          selectedMachine?.machineType !== "CSVB" ? (
          <HopperWash activityInfo={activityInfo} />
        ) : selectedAddPlan?.name === "Plate Fill" ? (
          <PlateFill activityInfo={activityInfo} />
        ) : selectedAddPlan?.name === "Hopper Fill" ? (
          <HopperFill activityInfo={activityInfo} />
        ) : selectedAddPlan?.name === "Cook" ? (
          <Cook activityInfo={activityInfo} />
        ) : selectedAddPlan?.name === "Worm" ? (
          <Worm activityInfo={activityInfo} />
        ) : selectedAddPlan?.name === "Store" ? (
          <Store activityInfo={activityInfo} />
        ) : selectedAddPlan?.name === "Wash+Fill" ? (
          <WashAndFill activityInfo={activityInfo} />
        ) : selectedAddPlan?.name === "Store+Fill" ? (
          <StoreAndFill activityInfo={activityInfo} />
        ) : selectedAddPlan?.name === "Store+Wash" ? (
          <StoreAndWash activityInfo={activityInfo} />
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default AddPlans;
