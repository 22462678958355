import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Grid, Card } from "@mui/material";
import Colors from "../themes/colors";
import style from "../themes/commonClasses";
import { intersection, keys, get, map } from "lodash";

const useStyles = makeStyles({
  primary: {
    borderRadius: 6,
    padding: "10px 20px",
    background: Colors.white,
  },
  secondary: {
    borderRadius: 6,
    padding: "10px 20px",
    background: Colors.info,
  },
  default: {
    borderRadius: 6,
    background: Colors.white,
  },
  paddings: {
    padding: "10px 20px",
  },
});

export default function FHCard(props) {
  const { variant = "primary", header, actions, body, padding } = props;
  const classes = useStyles();
  // const mobileView = useMediaQuery("(max-width:920px)");

  let classNames = [];

  for (const property in props) {
    if (props[property] === true) {
      classNames.push(property);
    }
  }
  const matchingStyles = intersection(classNames, keys(style)).map((s) =>
    get(style, s)
  );
  let finalStyles = {};
  map(matchingStyles, (ms) => {
    finalStyles = { ...finalStyles, ...ms };
  });

  // let properties = { ...props };

  return (
    <Card
      elevation={0}
      // properties
      style={finalStyles}
      className={clsx({
        // [classes.paddings]: mobileView ? false : true,
        [classes.default]: variant === "default" ? true : false,
        [classes.primary]: variant === "primary" ? true : false,
        [classes.secondary]: variant === "secondary" ? true : false,
      })}
    >
      <Grid container>
        <Grid item xs>
          {header}
        </Grid>
        <Grid item>{actions}</Grid>
        <Grid item xs={12} p={padding}>
          {body}
        </Grid>
      </Grid>
    </Card>
  );
}
