import { call } from "@redux-saga/core/effects";
import {
  promoCodes,
  promoByLocation,
  promoByUserId,
} from "../services/promoServices";

export function* getPromoCodeData(action) {
  try {
    yield call(promoCodes, action);
  } catch (error) {
    console.log("Error");
  }
}

export function* getPromoByLocation(action) {
  try {
    yield call(promoByLocation, action);
  } catch (error) {
    console.log("Error");
  }
}

export function* getPromoByUserId(action) {
  try {
    yield call(promoByUserId, action);
  } catch (error) {
    console.log("Error");
  }
}
