import React from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { map, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";
import FHCard from "../../components/FHCard";
import { AllAdminsList } from "./AllAdminsList";
import { AllBotsList } from "./AllBotsList";
import { AddedAdminUsers } from "./AddedAdminUsers";
import { AddedBots } from "./AddedBots";

const customStyles = makeStyles(commonClasses);

export const LocationDetailsCard = (props) => {
  const common = customStyles(commonClasses);
  const dispatch = useDispatch();
  const mobileView = useMediaQuery("(max-width:920px)");
  const menuLocation = useSelector((state) => state.menuReducer.menuLocation);
  const allAdminUsers = useSelector((state) => state.userReducer.allAdminUsers);

  return (
    <FHCard
      fullLengthCard
      body={
        isEmpty(menuLocation) ? (
          <Box pb={1}>
            <AllAdminsList />

            <AllBotsList />
          </Box>
        ) : (
          <Box>
            <Box pb={1}>
              <Typography fontSize={18} fontWeight={700}>
                Location
              </Typography>
            </Box>
            <Box
              p={1}
              className={[common.borderRadiusXs, common.bgMain].join(" ")}
            >
              <Typography fontSize={15} fontWeight={600}>
                {menuLocation?.Name}
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                {`${menuLocation?.d?.locationDetails?.addressFiled1}` +
                  ", " +
                  `${menuLocation?.d?.locationDetails?.addressFiled2}`}
              </Typography>
            </Box>
            <Box
              mt={1}
              p={1}
              className={[common.borderRadiusXs, common.bgMain].join(" ")}
            >
              <Grid container spacing={1}>
                {map(menuLocation?.workingHours, (item, idx) => {
                  return (
                    <Grid item md={1.7} textAlign={"center"} key={idx}>
                      {item?.day && (
                        <Typography fontSize={14} fontWeight={400}>
                          {item?.day?.slice(0, 3)}
                        </Typography>
                      )}
                      {item?.day && (
                        <Typography fontSize={6} fontWeight={400}>
                          {item?.opens}-{item?.closes}
                        </Typography>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            <Box>
              <AddedAdminUsers />
            </Box>
            <Box mt={2}>
              <AddedBots />
            </Box>
          </Box>
        )
      }
    />
  );
};
