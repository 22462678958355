import { call } from "@redux-saga/core/effects";
import {
  machinesInfo,
  botStatus,
  masterMachines,
  subscribeToBotReports,
} from "../services/botServices";

export function* getMachinesInfo(action) {
  try {
    yield call(machinesInfo, action);
  } catch (error) {
    console.log("Error", error);
  }
}

export function* getMasterMachines(action) {
  try {
    yield call(masterMachines, action);
  } catch (error) {
    console.log("Error", error);
  }
}

export function* getBotStatus(action) {
  try {
    yield call(botStatus, action);
  } catch (error) {
    console.log("Error", error);
  }
}

export function* getBotReports(action) {
  try {
    yield call(subscribeToBotReports, action);
  } catch (error) {
    console.log("Error in getBotReports", error);
  }
}
