import React from "react";
import {
	Dialog,
	DialogActions,
	DialogTitle,
} from "@mui/material";
import FHButton from "../../components/FHButton";
import { useSelector } from "react-redux";
import { isEmpty, filter} from "lodash";

const OutletSwitchDialog = (props) => {
	const {
		outletDialogOpen,
		onClose,
		button1,
		button2,
		handleOpenOrClose,
		cancelClick,
		outletClosingPopup,
	} = props;

	const locations = useSelector((state) => state.locationReducer.locations);
	const selectedLocation = useSelector(
		(state) => state.locationReducer.selectedLocation
	);
	let outlet = filter(
    locations,
    (item) => item.d?.locId === selectedLocation?.d?.locId
  );

	return (
		<>
			<Dialog
				open={outletDialogOpen}
				onClose={onClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				{!isEmpty(outlet) && outlet[0].outletStatus === "open" ? (
					outletClosingPopup
				) : (
					<DialogTitle id='alert-dialog-title'>{"Are you sure?"}</DialogTitle>
				)}

				<DialogActions>
					<FHButton
						name={button1}
						variant='outlined'
						bgCancelled
						smallButton
						textWhite
						onClick={cancelClick}
					/>
					<FHButton
						name={button2}
						variant='outlined'
						bgSuccess
						smallButton
						textWhite
						onClick={handleOpenOrClose}
					/>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default OutletSwitchDialog;
