import { initial } from "lodash";

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
	dailyPlan: false,
	editPlan: false,
	addStatus: false,
	operationDialog: false,
	selectedMachine: {},
	selectedAddPlan: {},
	calendarDateRange: {},
	selectedEditPlan: {},
	duplicatePlan: false,
	copiedPlan: {},
	pastePlan: false,
	dailyPlans: [],
	archivedPlans: [],
	dailyPlansByFilter: [],
	operations: {},
	selectedOperation: {}
};

// Types
export const GET_DAILY_PLANS = "GET_DAILY_PLANS";
export const GET_ARCHIVED_PLANS = "GET_ARCHIVED_PLANS";
export const GET_OPERATIONS = "GET_OPERATIONS";
//Actions
export const DAILY_PLAN = "DAILY_PLAN";
export const EDIT_PLAN = "EDIT_PLAN";
export const ADD_STATUS = "ADD_STATUS";
export const SELECTED_MACHINE = "SELECTED_MACHINE";
export const SELECTED_ADD_PLAN = "SELECTED_ADD_PLAN";
export const SELECTED_EDIT_PLAN = "SELECTED_EDIT_PLAN";
export const DAILYPLAN_CURRENT_DATE_RANGE = "DAILYPLAN_CURRENT_DATE_RANGE";
export const DUPLICATE_PLAN = "DUPLICATE_PLAN";
export const STORE_DAILY_PLANS = "STORE_DAILY_PLANS";
export const STORE_ARCHIVED_PLANS = "STORE_ARCHIVED_PLANS";
export const DAILY_PLANS_BY_FILTER = "DAILY_PLANS_BY_FILTER";
export const ADD_OPERATION_DIALOG = "ADD_OPERATION_DIALOG";
export const STORE_OPERATIONS = "STORE_OPERATIONS";
export const SELECTED_OPERATION = "SELECTED_OPERATION"
export const CLEAR_DAILY_PLANS = "CLEAR_DAILY_PLANS";
export const LOGOUT = "LOGOUT";
// Reducers
export const dailyPlanReducer = (state = INITIAL_STATE, action = {}) => {
	switch (action.type) {
		case DAILY_PLAN:
			return {
				...state,
				dailyPlan: action.payload,
			};
		case EDIT_PLAN:
			return {
				...state,
				editPlan: action.payload,
			};
		case ADD_STATUS:
			return {
				...state,
				addStatus: action.payload
			}
		case SELECTED_MACHINE:
			return {
				...state,
				selectedMachine: action.payload,
			};
		case SELECTED_ADD_PLAN:
			return {
				...state,
				selectedAddPlan: action.payload,
			};
		case SELECTED_EDIT_PLAN:
			return {
				...state,
				selectedEditPlan: action.payload,
			};
		case DAILYPLAN_CURRENT_DATE_RANGE:
			return {
				...state,
				calendarDateRange: action.payload,
			};
		case DUPLICATE_PLAN:
			return {
				...state,
				duplicatePlan: action.payload,
			};
		case STORE_DAILY_PLANS:
			let res = action.payload.res;
			let locId = action.payload.locId;
			let botId = action.payload.machineId;
			return {
				...state,
				dailyPlans: { ...state.dailyPlans, [`${locId}_${botId}`]: res },
			};
		case STORE_ARCHIVED_PLANS:
			let resData = action.payload.res;
			let locationId = action.payload.locId;
			let bot = action.payload.machineId;
			return {
				...state,
				archivedPlans: {
					...state.archivedPlans,
					[`${locationId}_${bot}`]: resData,
				},
			};
		case DAILY_PLANS_BY_FILTER:
			return {
				...state,
				dailyPlansByFilter: action.payload,
			};
		case STORE_OPERATIONS:
			return {
				...state,
				operations: action.payload,
			};
		case ADD_OPERATION_DIALOG:
			return {
				...state,
				operationDialog: action.payload
			}
		case SELECTED_OPERATION:
			return {
				...state,
				selectedOperation: action.payload
			}
		case CLEAR_DAILY_PLANS:
			return {
				dailyPlans: {},
			};
		case LOGOUT:
			return {
				...INITIAL_STATE,
			};

		default:
			return state;
	}
};
