import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
	Grid,
	Box,
	Typography,
	FormControlLabel,
	Radio,
	RadioGroup,
	Chip,
	IconButton,
} from "@mui/material";
import colors from "../../../themes/colors";
import { useDispatch, useSelector } from "react-redux";
import { map, filter, uniqBy, isEmpty } from "lodash";
import { generateRandomString } from "../../../services/utils";
import FHButton from "../../../components/FHButton";
import { FHLabel } from "../../../components/FormInputs/FHLabel";
import { FHTextfield } from "../../../components/FormInputs/FHTextfield";
import { FHAutocomplete } from "../../../components/FormInputs/FHAutocomplete";
import { FHDateTimePicker } from "../../../components/FormInputs/FHDateTimePicker";
import {
	validationSchema,
	handleAddPromo,
	onDeleteIncLoc,
	onDeleteExcLoc,
	onDeleteIncItems,
	onDeleteExcItems,
	onDeleteIncUsers,
	onDeleteExcUsers,
} from "./AddPromoFunctions";
import {
	getPromoUserInc,
	getPromoUserExc,
} from "../../../services/userServices";
import { EditPromoInitialValues, handleEditPromo } from "./EditPromoFunctions";
import closeIcon from "../../../assets/icons/Close.png";

export const PromoCodes = () => {
	const dispatch = useDispatch();
	const users = useSelector((state) => state.userReducer.user);
	const locations = useSelector((state) => state.locationReducer.locations);
	const historyData = useSelector((state) => state.orderReducer.historyData);
	const Incartdata = useSelector((state) => state.orderReducer.incartData);
	const cartData = useSelector((state) => state.orderReducer.cartData);
	const commonMenu = useSelector((state) => state.menuReducer.commonMenu);
	const editPromo = useSelector((state) => state.promocodeReducer.editPromo);
	const ePromoData = useSelector(
		(state) => state.promocodeReducer.selectedPromoToEdit
	);
	const promoUsersIncluded = useSelector(
		(state) => state.promocodeReducer.promoUsersIncluded
	);
	const promoUsersExcluded = useSelector(
		(state) => state.promocodeReducer.promoUsersExcluded
	);

	const user = useSelector((state) => state.userReducer.selectedUser);
	const addUserSpecificPromo = useSelector(
		(state) => state.promocodeReducer.addUserSpecificPromo
	);
	const editUserPromo = useSelector(
		(state) => state.promocodeReducer.editUserPromo
	);

	const [userIncSearch, setUserIncSearch] = useState("");
	const [userExcSearch, setUserExcSearch] = useState("");
	const [showLocMaxUses, setShowLocMaxUses] = useState(false);
	const [showItemMaxUses, setShowItemMaxUses] = useState(false);
	const [userIncErrMsg, setUserIncErrMsg] = useState(false);
	const [userExcErrMsg, setUserExcErrMsg] = useState(false);
	const [showAllUsers, setShowAllUsers] = useState(true);
	const [showUserMaxUses, setShowUserMaxUses] = useState(false);
	const [showUserSpecific, setShowUserSpecific] = useState(false);

	//generateCode
	const handleGenerateCode = () => {
		let isCreatingDoc = false;
		let newCode = generateRandomString(isCreatingDoc, 5);
		console.log("new code", newCode);
		formik.setFieldValue("code", newCode);
		// doesPromoCodeExist(newCode).then(function (res) {
		//   console.log("response", res);
		//   if (res) {
		//     setShowCodeError(false);
		//   } else {
		//     setShowCodeError(true);
		//   }
		// });
	};

	//discount type
	let discountTypes = [
		{
			id: 1,
			name: "percent",
		},
		{
			id: 2,
			name: "amount",
		},
	];
	const currentDate = new Date();
	currentDate.setHours(4, 0, 0, 0);
	// Add 7 days to the date
	const futureDate = currentDate.setDate(currentDate.getDate() + 7);
	const formik = useFormik({
		// enableReinitialize: editPromo,
		initialValues: {
			code: "",
			codeTerms: "",
			codeText: "",
			locationsIncluded: [],
			locationsExcluded: [],
			locMaxUses: 100,
			itemsIncluded: [],
			itemsExcluded: [],
			itemMaxUses: 100,
			discountType: discountTypes[0],
			discount: 5,
			minDiscount: 5,
			maxDiscount: 5,
			minOrderTotal: 20,
			status: "ACTIVE",
			hide: "false",
			validFrom: new Date(),
			validTill: new Date(futureDate),
			redeemsPerDay: 1,
			users: "all",
			allUsersMaxUses: 100,
			usersIncluded: [],
			usersExcluded: [],
			userMaxUses: 100,
		},
		validationSchema: validationSchema,
		onSubmit: (e) => {
			console.log("formik values on submit", formik.values);
			let userIssuePromo = addUserSpecificPromo || editUserPromo;
			if (editPromo || editUserPromo) {
				console.log(
					"edit promo is in use",
					formik.values,
					users,
					formik,
					ePromoData?.promoI
				);
				handleEditPromo(
					formik.values,
					users,
					formik,
					ePromoData?.promoId,
					userIssuePromo
				);
			} else {
				console.log(
					"add promo is in use",
					formik.values,
					users,
					formik,
					userIssuePromo
				);
				handleAddPromo(formik.values, users, formik, userIssuePromo);
			}
			dispatch({
				type: "SHOW_PROMO_DRAWER",
				payload: false,
			});
			dispatch({
				type: "RESET_SELETED_EDITPROMO",
			});
		},
	});

	//...........................locDropdowns..........................//
	let allLocId = map(locations, (item) => item.d?.locId);
	let allLocations = {
		Name: "all",
		locIds: allLocId,
	};
	let noLocations = {
		Name: "none",
		locIds: [],
	};
	let initialIncLoc = [allLocations, ...(locations || [])];
	let initialExcLoc = [noLocations, ...(locations || [])];

	let locInInc = map(formik.values.locationsIncluded, (item) => item?.Name);
	let locInExc = map(formik.values.locationsExcluded, (item) => item?.Name);
	let incLocations = [];
	let excLocations = [];
	if (locInInc.includes("all")) {
		incLocations = [...(locations || [])];
	} else {
		incLocations = [allLocations, ...(locations || [])];
	}

	if (locInExc.includes("none")) {
		excLocations = [...(locations || [])];
	} else {
		excLocations = [noLocations, ...(locations || [])];
	}

	if (
		(locInInc.length > 0 && !locInInc.includes("all")) ||
		(locInExc.length > 0 && !locInExc.includes("none"))
	) {
		incLocations = [allLocations];
		excLocations = [noLocations];
		map(locations, (item) => {
			if (!locInInc.includes(item.Name) && !locInExc.includes(item?.Name)) {
				incLocations.push(item);
				excLocations.push(item);
			}
		});
	}
	//.........................................................................//

	//.................................menuDropdown...........................//
	let cMenu = map(commonMenu, (item) => item.id);
	let allMenu = {
		name: "all",
		menuIds: cMenu,
	};
	let noMenu = {
		name: "none",
		menuIds: [],
	};
	let initialIncItems = [allMenu, ...(map(commonMenu, (item) => item) || [])];
	let initialExcItems = [noMenu, ...(map(commonMenu, (item) => item) || [])];

	let itemInInc = map(formik.values.itemsIncluded, (item) => item.name);
	let itemInExc = map(formik.values.itemsExcluded, (item) => item.name);
	let incItems = [];
	let excItems = [];
	if (itemInInc.includes("all")) {
		incItems = [...(map(commonMenu, (item) => item) || [])];
	} else {
		incItems = [allMenu, ...(map(commonMenu, (item) => item) || [])];
	}

	if (itemInExc.includes("none")) {
		excItems = [...(map(commonMenu, (item) => item) || [])];
	} else {
		excItems = [noMenu, ...(map(commonMenu, (item) => item) || [])];
	}

	if (
		(itemInInc.length > 0 && !itemInInc.includes("all")) ||
		(itemInExc.length > 0 && !itemInExc.includes("none"))
	) {
		incItems = [allMenu];
		excItems = [noMenu];
		map(commonMenu, (item) => {
			if (!itemInInc.includes(item.name) && !itemInExc.includes(item.name)) {
				incItems.push(item);
				excItems.push(item);
			}
		});
	}
	//......................................................................//

	//..........................userDropdown................................//
	let ordersList = [];
	ordersList = [
		...(map(historyData, (item) => item) || []),
		...(map(Incartdata, (item) => item) || []),
		...(map(cartData, (item) => item) || []),
	];
	let finalOrders = uniqBy(ordersList, (item) => item.cartId);

	const dataFiltered = map(
		filter(
			finalOrders,
			(item, index, arr) =>
				arr.findIndex((t) => t.userId === item.userId) === index
		),
		(data) => data.userDetails
	);
	let uu = uniqBy(dataFiltered, (item) => item.uid);
	let userDropdown = filter(uu, (item) => item.name !== "Anonymous");
	let idsFromOrders = [];
	let userInc = [];
	let userExc = [];
	let userInInc = map(formik.values.usersIncluded, (item) => item.uid);
	let userInExc = map(formik.values.usersExcluded, (item) => item.uid);
	map(userDropdown, (item) => {
		if (!userInInc.includes(item.uid) && !userInExc.includes(item.uid)) {
			idsFromOrders.push(item);
		}
	});
	if (!isEmpty(promoUsersIncluded)) {
		userInc = [...map(promoUsersIncluded, (item) => item), ...idsFromOrders];
		// console.log("userInc", userInc);
	} else if (addUserSpecificPromo || editUserPromo) {
		userInc = [user];
	} else {
		userInc = [...idsFromOrders];
	}
	if (!isEmpty(promoUsersExcluded)) {
		userExc = [...map(promoUsersExcluded, (item) => item), ...idsFromOrders];
	} else {
		userExc = [...idsFromOrders];
	}

	//.............................................................................//

	useEffect(() => {
		let uIncluded = [];
		let uExcluded = [];
		if (
			(editPromo === true && !isEmpty(ePromoData)) ||
			(editUserPromo === true && !isEmpty(ePromoData))
		) {
			if (editUserPromo === true) {
				formik.setFieldValue("users", "specific");
				setShowUserSpecific(true);
			}
			if (ePromoData?.users === "specific") {
				let usersWithSpecPromoInc = filter(
					ePromoData?.userPromos,
					(item) => item.promoCode === "included"
				);
				let userWithspecWithExc = filter(
					ePromoData?.userPromos,
					(item) => item.promoCode === "excluded"
				);
				let currentIncIds = map(userInc, (item) => item.uid);
				let currentExcIds = map(userExc, (item) => item.uid);
				let uIncList = filter(
					usersWithSpecPromoInc,
					(item) => !currentIncIds.includes(item.userId)
				);
				let uExcList = filter(
					userWithspecWithExc,
					(item) => !currentExcIds.includes(item.userId)
				);
				uIncluded = [...userInc, ...uIncList];
				userExc = [...userExc, ...uExcList];
			} else {
				uIncluded = userInc;
				uExcluded = userExc;
			}

			EditPromoInitialValues(
				ePromoData,
				formik,
				initialIncLoc,
				initialExcLoc,
				initialIncItems,
				initialExcItems,
				discountTypes,
				uIncluded,
				uExcluded
			);
			if (ePromoData?.users === "all") {
				setShowAllUsers(true);
			} else if (ePromoData?.users === "specific") {
				setShowAllUsers(false);
			}
		} else if (addUserSpecificPromo === true) {
			formik.setFieldValue("users", "specific");
			setShowUserSpecific(true);
		} else {
			formik.resetForm();
			dispatch({ type: "RESET_SELETED_EDITPROMO" });
			dispatch({ type: "STORE_USERS_INCLUDED", payload: {} });
			dispatch({ type: "STORE_USERS_EXCLUDED", payload: {} });
		}
	}, [editPromo, editUserPromo, addUserSpecificPromo]);
	const hadleLocIncluded = (newValue) => {
		console.log(formik, "ddddddddd", newValue);
		let names = map(newValue, (item) => item.Name);
		let loc = map(formik.values.locationsIncluded, (item) => item.Name);
		let list = [];
		if (loc.includes("all") && newValue.length > 1) {
			list = filter(newValue, (item) => item.Name !== "all");
			formik.setFieldValue("locationsExcluded", []);
		} else if (
			newValue.length > 0 &&
			names.includes("all") &&
			!loc.includes("all")
		) {
			list = [allLocations];
			formik.setFieldValue("locationsExcluded", [noLocations]);
		} else {
			list = newValue;
		}
		formik.setFieldValue("locationsIncluded", list);

		if (list[0].Name === "all") {
			formik.setFieldValue("locationsExcluded", [excLocations[0]]);
		}
		setShowLocMaxUses(true);
	};

	const handleAddLocMaxUses = () => {
		let locWithMaxUses = [];
		map(formik.values.locationsIncluded, (item) => {
			let keys = Object.keys(item);
			if (!keys.includes("locationMaxUses")) {
				locWithMaxUses.push({
					...item,
					locationMaxUses: formik.values.locMaxUses,
				});
			} else {
				locWithMaxUses.push(item);
			}
			return locWithMaxUses;
		});
		formik.setFieldValue("locationsIncluded", locWithMaxUses);
		setShowLocMaxUses(false);
	};

	const hadleLocExcluded = (newValue) => {
		// console.log("handleLocIncluded", newValue);
		let nValues = map(newValue, (value) => value.Name);
		let excloc = map(formik.values.locationsExcluded, (value) => value.Name);
		let list = [];
		if (excloc.includes("none") && newValue.length > 1) {
			list = filter(newValue, (item) => item.Name !== "none");
			formik.setFieldValue("locationsIncluded", []);
		} else if (
			newValue.length > 0 &&
			nValues.includes("none") &&
			!excloc.includes("none")
		) {
			list = [noLocations];
			formik.setFieldValue("locationsExcluded", [noLocations]);
		} else {
			list = newValue;
		}
		formik.setFieldValue("locationsExcluded", list);
		if (list[0].Name === "none") {
			formik.setFieldValue("locationsIncluded", [incLocations[0]]);
		}
	};

	const handleItemIncluded = (newValue) => {
		let itemNames = map(newValue, (item) => item.name);
		let sItems = map(formik.values.itemsIncluded, (item) => item.name);
		let fItems = [];
		if (sItems.includes("all") && newValue.length > 1) {
			fItems = filter(newValue, (item) => item.name !== "all");
			formik.setFieldValue("itemsExcluded", []);
		} else if (
			newValue.length > 0 &&
			itemNames.includes("all") &&
			!sItems.includes("all")
		) {
			fItems = [allMenu];
			formik.setFieldValue("itemsExcluded", [noMenu]);
		} else {
			fItems = newValue;
		}
		formik.setFieldValue("itemsIncluded", fItems);

		if (fItems[0].name === "all") {
			formik.setFieldValue("itemsExcluded", [excItems[0]]);
		}
		setShowItemMaxUses(true);
	};

	const handleAddItemMaxUses = () => {
		let itemsWithMaxUses = [];
		map(formik.values.itemsIncluded, (item) => {
			let keys = Object.keys(item);
			if (!keys.includes("itemMaxUses")) {
				itemsWithMaxUses.push({
					...item,
					itemMaxUses: formik.values.itemMaxUses,
				});
			} else {
				itemsWithMaxUses.push(item);
			}
			return itemsWithMaxUses;
		});
		formik.setFieldValue("itemsIncluded", itemsWithMaxUses);
		setShowItemMaxUses(false);
	};

	const handleItemExcluded = (newValue) => {
		let iValues = map(newValue, (value) => value.name);
		let excItem = map(formik.values.itemsExcluded, (value) => value.name);
		let finalList = [];
		if (excItem.includes("none") && newValue.length > 1) {
			finalList = filter(newValue, (item) => item.name !== "none");
			formik.setFieldValue("itemsIncluded", []);
		} else if (
			newValue.length > 0 &&
			iValues.includes("none") &&
			!excItem.includes("none")
		) {
			finalList = [noMenu];
			formik.setFieldValue("itemsExcluded", [noMenu]);
		} else {
			finalList = newValue;
		}
		formik.setFieldValue("itemsExcluded", finalList);
		if (finalList[0].name === "none") {
			formik.setFieldValue("itemsIncluded", [incItems[0]]);
		}
	};

	const handleUserChange = (e) => {
		formik.setFieldValue("users", e.target.value);
		if (e.target.value === "all") {
			setShowAllUsers(true);
		} else {
			setShowAllUsers(false);
		}
	};

	const handleSearch = () => {
		if (!isEmpty(userIncSearch)) {
			getPromoUserInc([userIncSearch]);
			console.log("userIncSearch", getPromoUserInc([userIncSearch]));
		} else if (!isEmpty(userExcSearch)) {
			getPromoUserExc([userExcSearch]);
			console.log("userIncSearch", getPromoUserExc([userExcSearch]));
		}
	};

	const handleUserIncluded = (e, newValue) => {
		console.log("handleUserIncluded", newValue);

		formik.setFieldValue("usersIncluded", newValue);
		dispatch({ type: "STORE_USERS_INCLUDED", payload: {} });
		setShowUserMaxUses(true);
		setUserIncErrMsg(false);
	};

	const handleAddUserMaxUses = () => {
		let userWithMaxUses = [];
		map(formik.values.usersIncluded, (item) => {
			let keys = Object.keys(item);
			if (!keys.includes("userMaxUses")) {
				userWithMaxUses.push({
					...item,
					userMaxUses: formik.values.userMaxUses,
				});
			} else {
				userWithMaxUses.push(item);
			}
			return userWithMaxUses;
		});
		formik.setFieldValue("usersIncluded", userWithMaxUses);
		setShowUserMaxUses(false);
	};

	const handleUserexcluded = (e, newValue) => {
		formik.setFieldValue("usersExcluded", newValue);
		dispatch({ type: "STORE_USERS_EXCLUDED", payload: {} });
		setUserExcErrMsg(false);
	};

	const closePlan = () => {
		dispatch({ type: "SHOW_PROMO_DRAWER", payload: false });
		dispatch({ type: "ADD_PROMO_CODE", payload: false });
		dispatch({ type: "EDIT_PROMO_CODE", payload: false });
		dispatch({ type: "RESET_SELETED_EDITPROMO" });
	};
	return (
		<Box p={2.5}>
			<Grid container>
				<Grid item xs={12}>
					<Box
						display={"flex"}
						flexDirection={"row"}
						justifyContent={"space-between"}
					>
						<Box>
							{!editPromo || !editUserPromo ? (
								<Typography fontSize={23} fontWeight={700}>
									Add Promo
								</Typography>
							) : (
								<Typography fontSize={23} fontWeight={700}>
									Edit Promo
								</Typography>
							)}
						</Box>
						<Box>
							<IconButton onClick={() => closePlan()}>
								<img src={closeIcon} />
							</IconButton>
						</Box>
					</Box>

					<form onSubmit={formik.handleSubmit}>
						<FHLabel label={"Code"} />
						<Grid container spacing={2}>
							<Grid item sm={8} xs={12}>
								<FHTextfield
									fullWidth
									id="code"
									name="code"
									// label="code"
									// width={"100%"}
									disabled={
										showItemMaxUses ||
										showLocMaxUses ||
										showUserMaxUses ||
										editPromo
									}
									placeholder="Enter Code Name"
									value={formik.values.code}
									onChange={formik.handleChange}
									error={formik.touched.code && Boolean(formik.errors.code)}
									helperText={formik.touched.code && formik.errors.code}
								/>
								{/* {showCodeError && (
                  <Typography color={"red"} fontSize={13}>
                    This code name already exists, choose a different code name
                  </Typography>
                )} */}
							</Grid>
							<Grid item sm={4} xs={12}>
								<FHButton
									name={"Generate Code"}
									variant="outlined"
									color={colors.success}
									small
									w_40
									disabled={
										showItemMaxUses ||
										showLocMaxUses ||
										showUserMaxUses ||
										editPromo
									}
									commonButtonStyle
									onClick={handleGenerateCode}
								// errors={validated ? validate.errors : undefined}
								/>
							</Grid>
						</Grid>
						<FHLabel label={"Code Terms"} />
						<FHTextfield
							fullWidth
							id="codeTerms"
							name="codeTerms"
							multiline
							rows={2}
							// label="Email"
							width={"100%"}
							disabled={showItemMaxUses || showLocMaxUses || showUserMaxUses}
							placeholder="Enter codeTerms"
							value={formik.values.codeTerms}
							onChange={formik.handleChange}
							error={
								formik.touched.codeTerms && Boolean(formik.errors.codeTerms)
							}
							helperText={formik.touched.codeTerms && formik.errors.codeTerms}
						/>

						<FHLabel label={"Code Text"} />
						<FHTextfield
							fullWidth
							id="codeText"
							name="codeText"
							multiline
							rows={2}
							// label="Email"
							// width={"100%"}
							disabled={showItemMaxUses || showLocMaxUses || showUserMaxUses}
							placeholder="Enter codeText"
							value={formik.values.codeText}
							onChange={formik.handleChange}
							error={formik.touched.codeText && Boolean(formik.errors.codeText)}
							helperText={formik.touched.codeText && formik.errors.codeText}
						/>

						<FHLabel label={"Locations"} />
						<Grid container spacing={2}>
							<Grid item sm={6} xs={12}>
								<FHLabel subHeading={"Included"} />
								<FHAutocomplete
									multiple
									options={incLocations}
									defaultValue={[]}
									disabled={
										!isEmpty(formik.values?.locationsExcluded) ||
										showItemMaxUses ||
										showLocMaxUses ||
										showUserMaxUses
									}
									value={formik.values.locationsIncluded}
									id="locationsIncluded"
									name="locationsIncluded"
									placeholder="Select locations"
									onChange={(e, newValue) => hadleLocIncluded(newValue)}
									chip={map(formik.values.locationsIncluded, (v, i) => (
										<Box mb={1} key={i}>
											<Chip
												label={
													v?.locationMaxUses ? (
														<Typography
															style={{ whiteSpace: "normal", fontSize: 14 }}
														>
															{v.Name}, MaxUses - {v?.locationMaxUses}
														</Typography>
													) : (
														<Typography
															style={{ whiteSpace: "normal", fontSize: 14 }}
														>
															{v?.Name}
														</Typography>
													)
												}
												variant="filled"
												onDelete={() => {
													onDeleteIncLoc(v.Name, formik);
													setShowLocMaxUses(false);
												}}
												// onClick={onClickInLoc(v.Name)}
												style={{ background: "#F0F4FF", color: "black" }}
											/>
										</Box>
									))}
									error={
										formik.touched.locationsIncluded &&
										Boolean(formik.errors.locationsIncluded)
									}
									helperText={
										formik.touched.locationsIncluded &&
										formik.errors.locationsIncluded
									}
								/>
							</Grid>

							{showLocMaxUses && (
								<Grid item sm={6} mt={9}>
									<Grid container spacing={0.5}>
										<Grid item sm={7} xs={6}>
											<FHLabel subHeading={"Add max uses"} />
											<FHTextfield
												fullWidth
												id="locMaxUses"
												name="locMaxUses"
												type="number"
												rows={2}
												// label="Email"
												width={"100%"}
												placeholder="Enter locMaxUses"
												value={formik.values.locMaxUses}
												onChange={formik.handleChange}
												error={
													formik.touched.locMaxUses &&
													Boolean(formik.errors.locMaxUses)
												}
												helperText={
													formik.touched.locMaxUses && formik.errors.locMaxUses
												}
											/>
										</Grid>
										<Grid item sm={5} mt={2.8} xs={6}>
											<FHButton
												name={"Add"}
												variant="outlined"
												color={colors.success}
												small
												w_40
												commonButtonStyle
												onClick={handleAddLocMaxUses}
											/>
										</Grid>
									</Grid>
								</Grid>
							)}
						</Grid>
						{((!isEmpty(formik.values?.locationsIncluded) &&
							formik.values?.locationsIncluded[0]?.Name === "all") ||
							!isEmpty(formik.values?.locationsExcluded)) && (
								<Grid container spacing={0.5}>
									<Grid item sm={12} md={6} xs={12}>
										<FHLabel subHeading={"Excluded"} />
										<FHAutocomplete
											options={excLocations}
											defaultValue={[]}
											disabled={
												showItemMaxUses || showLocMaxUses || showUserMaxUses
											}
											value={formik.values.locationsExcluded}
											id="locationsExcluded"
											name="locationsExcluded"
											placeholder="Select locations"
											onChange={(e, newValue) => hadleLocExcluded(newValue)}
											chip={map(formik.values.locationsExcluded, (v, i) => (
												<Box mb={1} key={i}>
													<Chip
														label={
															<Typography
																style={{ whiteSpace: "normal", fontSize: 14 }}
															>
																{v?.Name}
															</Typography>
														}
														variant="filled"
														onDelete={() => onDeleteExcLoc(v.Name, formik)}
														style={{ background: "#F0F4FF", color: "black" }}
													/>
												</Box>
											))}
											error={
												formik.touched.locationsExcluded &&
												Boolean(formik.errors.locationsExcluded)
											}
											helperText={
												formik.touched.locationsExcluded &&
												formik.errors.locationsExcluded
											}
										/>
									</Grid>
								</Grid>
							)}

						<FHLabel label={"Items"} />
						<Grid container spacing={2}>
							<Grid item sm={6} xs={12}>
								<FHLabel subHeading={"Included"} />
								<FHAutocomplete
									options={incItems}
									defaultValue={[]}
									disabled={
										!isEmpty(formik.values?.itemsExcluded) ||
										showItemMaxUses ||
										showLocMaxUses ||
										showUserMaxUses
									}
									value={formik.values.itemsIncluded}
									id="itemsIncluded"
									name="itemsIncluded"
									placeholder="Select Items"
									onChange={(e, newValue) => handleItemIncluded(newValue)}
									chip={map(formik.values.itemsIncluded, (v, i) => (
										<Box mb={1} key={i}>
											<Chip
												label={
													v.itemMaxUses ? (
														<Typography
															style={{ whiteSpace: "normal", fontSize: 14 }}
														>
															{v.name}, MaxUses - {v.itemMaxUses}
														</Typography>
													) : (
														<Typography
															style={{ whiteSpace: "normal", fontSize: 14 }}
														>
															{v.name}
														</Typography>
													)
												}
												variant="filled"
												onDelete={() => {
													onDeleteIncItems(v.name, formik);
													setShowItemMaxUses(false);
												}}
												style={{
													background: "#F0F4FF",
													color: "black",
													height: "100%",
												}}
											/>
										</Box>
									))}
									error={
										formik.touched.itemsIncluded &&
										Boolean(formik.errors.itemsIncluded)
									}
									helperText={
										formik.touched.itemsIncluded && formik.errors.itemsIncluded
									}
								/>
							</Grid>

							{showItemMaxUses && (
								<Grid item sm={6} mt={9}>
									<Grid container spacing={0.5}>
										<Grid item sm={7} xs={6}>
											<FHLabel subHeading={"Add max uses"} />
											<FHTextfield
												fullWidth
												id="itemMaxUses"
												name="itemMaxUses"
												type="number"
												rows={2}
												// label="Email"
												width={"100%"}
												placeholder="Enter itemMaxUses"
												value={formik.values.itemMaxUses}
												onChange={formik.handleChange}
												error={
													formik.touched.itemMaxUses &&
													Boolean(formik.errors.itemMaxUses)
												}
												helperText={
													formik.touched.itemMaxUses &&
													formik.errors.itemMaxUses
												}
											/>
										</Grid>
										<Grid item sm={5} mt={2.8} xs={6}>
											<FHButton
												name={"Add"}
												variant="outlined"
												color={colors.success}
												small
												w_40
												commonButtonStyle
												onClick={handleAddItemMaxUses}
											/>
										</Grid>
									</Grid>
								</Grid>
							)}
						</Grid>

						{((!isEmpty(formik.values?.itemsIncluded) &&
							formik.values?.itemsIncluded[0]?.Name === "all") ||
							!isEmpty(formik.values?.itemsExcluded)) && (
								<Grid container spacing={0.5}>
									<Grid item sm={12} xs={12} md={6}>
										<FHLabel subHeading={"Excluded"} />
										<FHAutocomplete
											options={excItems}
											defaultValue={[]}
											disabled={
												showItemMaxUses || showLocMaxUses || showUserMaxUses
											}
											value={formik.values.itemsExcluded}
											id="itemsExcluded"
											name="itemsExcluded"
											placeholder="Select Items"
											onChange={(e, newValue) => handleItemExcluded(newValue)}
											chip={map(formik.values.itemsExcluded, (v) => (
												<Box mb={1} key={v.name}>
													<Chip
														label={
															<Typography
																style={{ whiteSpace: "normal", fontSize: 14 }}
															>
																{v.name}
															</Typography>
														}
														variant="filled"
														onDelete={() => onDeleteExcItems(v.name, formik)}
														style={{
															background: "#F0F4FF",
															color: "black",
															fontSize: 10,
														}}
													/>
												</Box>
											))}
											error={
												formik.touched.itemsExcluded &&
												Boolean(formik.errors.itemsExcluded)
											}
											helperText={
												formik.touched.itemsExcluded &&
												formik.errors.itemsExcluded
											}
										/>
									</Grid>
								</Grid>
							)}

						<FHLabel label={"Discount Type"} />
						<FHAutocomplete
							options={discountTypes}
							defaultValue={formik.values.discountType}
							value={formik.values.discountType}
							disabled={showItemMaxUses || showLocMaxUses || showUserMaxUses}
							singleselect={"true"}
							id="discountType"
							name="discountType"
							placeholder="Select Items"
							multiple={false}
							onChange={(e, newValue) =>
								formik.setFieldValue("discountType", newValue)
							}
							error={
								formik.touched.discountType &&
								Boolean(formik.errors.discountType)
							}
							helperText={
								formik.touched.discountType && formik.errors.discountType
							}
						/>

						<FHLabel label={"Discount"} />
						<FHTextfield
							fullWidth
							disabled={showItemMaxUses || showLocMaxUses || showUserMaxUses}
							id="discount"
							name="discount"
							type="number"
							// label="Email"
							value={formik.values.discount}
							onChange={formik.handleChange}
							error={formik.touched.discount && Boolean(formik.errors.discount)}
							helperText={formik.touched.discount && formik.errors.discount}
						/>

						<FHLabel label={"Minimum Discount"} />
						<FHTextfield
							fullWidth
							disabled={showItemMaxUses || showLocMaxUses || showUserMaxUses}
							id="minDiscount"
							name="minDiscount"
							type="number"
							// label="Email"
							value={formik.values.minDiscount}
							onChange={formik.handleChange}
							error={
								formik.touched.minDiscount && Boolean(formik.errors.minDiscount)
							}
							helperText={
								formik.touched.minDiscount && formik.errors.minDiscount
							}
						/>

						<FHLabel label={"Maximum Discount"} />
						<FHTextfield
							fullWidth
							disabled={showItemMaxUses || showLocMaxUses || showUserMaxUses}
							id="maxDiscount"
							name="maxDiscount"
							type="number"
							// label="Email"
							value={formik.values.maxDiscount}
							onChange={formik.handleChange}
							error={
								formik.touched.maxDiscount && Boolean(formik.errors.maxDiscount)
							}
							helperText={
								formik.touched.maxDiscount && formik.errors.maxDiscount
							}
						/>

						<FHLabel label={"Minimum Order Total"} />
						<FHTextfield
							fullWidth
							disabled={showItemMaxUses || showLocMaxUses || showUserMaxUses}
							id="minOrderTotal"
							name="minOrderTotal"
							type="number"
							// label="Email"
							value={formik.values.minOrderTotal}
							onChange={formik.handleChange}
							error={
								formik.touched.minOrderTotal &&
								Boolean(formik.errors.minOrderTotal)
							}
							helperText={
								formik.touched.minOrderTotal && formik.errors.minOrderTotal
							}
						/>

						<FHLabel label={"Status"} />
						<RadioGroup
							row
							aria-labelledby="demo-radio-buttons-group-label"
							disabled={showItemMaxUses || showLocMaxUses || showUserMaxUses}
							value={formik.values.status}
							onChange={(e) => formik.setFieldValue("status", e.target.value)}
							error={formik.touched.status && Boolean(formik.errors.status)}
							helperText={formik.touched.status && formik.errors.status}
						>
							<FormControlLabel
								value="ACTIVE"
								control={
									<Radio
										sx={{
											color: colors.lightGrey,
											"&.Mui-checked": {
												color: colors.success,
											},
										}}
									/>
								}
								label="ACTIVE"
								sx={{
									"& .MuiFormControlLabel-label": {
										fontWeight: 600,
										fontSize: 14,
									},
								}}
							/>
							<FormControlLabel
								value="INACTIVE"
								control={
									<Radio
										sx={{
											color: colors.lightGrey,
											"&.Mui-checked": {
												color: colors.success,
											},
										}}
									/>
								}
								label="INACTIVE"
								sx={{
									"& .MuiFormControlLabel-label": {
										fontWeight: 600,
										fontSize: 14,
									},
								}}
							/>
						</RadioGroup>

						<Grid container spacing={2}>
							<Grid item sm={6} xs={9}>
								<FHLabel label={"Valid From"} />
								<FHDateTimePicker
									editForm={editPromo}
									value={formik.values.validFrom}
									disabled={
										showItemMaxUses || showLocMaxUses || showUserMaxUses
									}
									onChange={(newValue) =>
										formik.setFieldValue("validFrom", newValue?.$d)
									}
								/>
							</Grid>
							<Grid item sm={6} xs={9}>
								<FHLabel label={"Valid Till"} />
								<FHDateTimePicker
									value={formik.values.validTill}
									disabled={
										showItemMaxUses || showLocMaxUses || showUserMaxUses
									}
									onChange={(newValue) =>
										formik.setFieldValue("validTill", newValue?.$d)
									}
								/>
							</Grid>
						</Grid>
						<RadioGroup
							row
							aria-labelledby="demo-radio-buttons-group-label"
							disabled={showItemMaxUses || showLocMaxUses || showUserMaxUses}
							value={formik.values.hide}
							onChange={(e) => formik.setFieldValue("hide", e.target.value)}
							error={formik.touched.hide && Boolean(formik.errors.hide)}
							helperText={formik.touched.hide && formik.errors.hide}
						>
							<FormControlLabel
								value={false}
								control={
									<Radio
										sx={{
											color: colors.lightGrey,
											"&.Mui-checked": {
												color: colors.success,
											},
										}}
									/>
								}
								label="Show"
								sx={{
									"& .MuiFormControlLabel-label": {
										fontWeight: 600,
										fontSize: 14,
									},
								}}
							/>
							<FormControlLabel
								value={true}
								control={
									<Radio
										sx={{
											color: colors.lightGrey,
											"&.Mui-checked": {
												color: colors.success,
											},
										}}
									/>
								}
								label="Hide"
								sx={{
									"& .MuiFormControlLabel-label": {
										fontWeight: 600,
										fontSize: 14,
									},
								}}
							/>
						</RadioGroup>
						<FHLabel label={"No. of redeems per day"} />
						<FHTextfield
							fullWidth
							id="redeemsPerDay"
							name="redeemsPerDay"
							type="number"
							disabled={showItemMaxUses || showLocMaxUses || showUserMaxUses}
							// label="Email"
							value={formik.values.redeemsPerDay}
							onChange={formik.handleChange}
							error={
								formik.touched.redeemsPerDay &&
								Boolean(formik.errors.redeemsPerDay)
							}
							helperText={
								formik.touched.redeemsPerDay && formik.errors.redeemsPerDay
							}
						/>

						<FHLabel label={"Users"} />

						<Grid item sm={12}>
							<Grid container spacing={0.5}>
								<Grid item sm={6}>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										value={formik.values.users}
										onChange={(e) => handleUserChange(e)}
										error={formik.touched.users && Boolean(formik.errors.users)}
										helperText={formik.touched.users && formik.errors.users}
									>
										<FormControlLabel
											value="all"
											disabled={showUserSpecific}
											control={
												<Radio
													sx={{
														color: colors.lightGrey,
														"&.Mui-checked": {
															color: colors.success,
														},
													}}
												/>
											}
											label="All Users"
											sx={{
												"& .MuiFormControlLabel-label": {
													fontWeight: 600,
													fontSize: 14,
												},
											}}
										/>
										<FormControlLabel
											value="specific"
											disabled={showUserSpecific}
											control={
												<Radio
													sx={{
														color: colors.lightGrey,
														"&.Mui-checked": {
															color: colors.success,
														},
													}}
												/>
											}
											label="Specific Users"
											sx={{
												"& .MuiFormControlLabel-label": {
													fontWeight: 600,
													fontSize: 14,
												},
											}}
										/>
									</RadioGroup>
								</Grid>
								{showAllUsers && (
									<Grid item sm={6} mt={-2}>
										<FHLabel subHeading={"Add max uses for all users"} />
										<FHTextfield
											fullWidth
											id="allUsersMaxUses"
											name="allUsersMaxUses"
											disabled={
												showItemMaxUses || showLocMaxUses || showUserMaxUses
											}
											type="number"
											rows={2}
											// label="Email"
											width={"100%"}
											placeholder="Enter userMaxUses"
											value={formik.values.allUsersMaxUses}
											onChange={formik.handleChange}
											error={
												formik.touched.allUsersMaxUses &&
												Boolean(formik.errors.allUsersMaxUses)
											}
											helperText={
												formik.touched.allUsersMaxUses &&
												formik.errors.allUsersMaxUses
											}
										/>
									</Grid>
								)}
							</Grid>
							{formik.values.users === "specific" && (
								<>
									<Grid container spacing={0.5}>
										<Grid item sm={6}>
											<FHLabel subHeading={"Included"} />
											<FHAutocomplete
												options={userInc}
												defaultValue={[]}
												searchbar={true}
												disabled={
													showItemMaxUses || showLocMaxUses || showUserMaxUses
												}
												value={formik.values.usersIncluded}
												id="usersIncluded"
												name="usersIncluded"
												placeholder="Search Users"
												onChange={(e, newValue) =>
													handleUserIncluded(e, newValue)
												}
												// searchValue={userIncSearch}
												onInputChange={(e, newValue) =>
													setUserIncSearch(newValue)
												}
												handlesearch={handleSearch}
												chip={map(formik.values.usersIncluded, (v) => (
													<Box mb={1} key={v.uid}>
														<Chip
															label={
																v.userMaxUses ? (
																	<Typography
																		style={{
																			whiteSpace: "normal",
																			fontSize: 14,
																		}}
																	>
																		{v.name}, MaxUses - {v.userMaxUses}
																	</Typography>
																) : (
																	<Typography
																		style={{
																			whiteSpace: "normal",
																			fontSize: 14,
																		}}
																	>
																		{v.name}
																	</Typography>
																)
															}
															variant="filled"
															onDelete={() => {
																onDeleteIncUsers(v.uid, formik);
																setShowUserMaxUses(false);
															}}
															style={{ background: "#F0F4FF", color: "black" }}
														/>
													</Box>
												))}
												error={
													formik.touched.usersIncluded &&
													Boolean(formik.errors.usersIncluded)
												}
												helperText={
													formik.touched.usersIncluded &&
													formik.errors.usersIncluded
												}
											/>
											{userIncErrMsg && (
												<Typography color={"red"} fontSize={13}>
													Selected User is already added
												</Typography>
											)}
										</Grid>
										{showUserMaxUses && (
											<Grid item sm={6} mt={9}>
												<Grid container spacing={0.5}>
													<Grid item sm={7}>
														<FHLabel subHeading={"Add max uses"} />
														<FHTextfield
															fullWidth
															id="userMaxUses"
															name="userMaxUses"
															type="number"
															rows={2}
															// label="Email"
															width={"100%"}
															placeholder="Enter userMaxUses"
															value={formik.values.userMaxUses}
															onChange={formik.handleChange}
															error={
																formik.touched.userMaxUses &&
																Boolean(formik.errors.userMaxUses)
															}
															helperText={
																formik.touched.userMaxUses &&
																formik.errors.userMaxUses
															}
														/>
													</Grid>
													<Grid item sm={5} mt={2.8}>
														<FHButton
															name={"Add"}
															variant="outlined"
															color={colors.success}
															small
															w_40
															commonButtonStyle
															onClick={handleAddUserMaxUses}
														/>
													</Grid>
												</Grid>
											</Grid>
										)}
									</Grid>
									{!showUserSpecific && (
										<Grid container>
											<Grid item sm={6}>
												<FHLabel subHeading={"Excluded"} />
												<FHAutocomplete
													options={userExc}
													defaultValue={[]}
													searchbar={true}
													disabled={
														showItemMaxUses || showLocMaxUses || showUserMaxUses
													}
													value={formik.values.usersExcluded}
													id="usersExcluded"
													name="usersExcluded"
													placeholder="Search Users"
													onChange={(e, newValue) =>
														handleUserexcluded(e, newValue)
													}
													onInputChange={(e, newValue) =>
														setUserExcSearch(newValue)
													}
													handlesearch={handleSearch}
													chip={map(formik.values.usersExcluded, (v) => (
														<Box mb={1} key={v.uid}>
															<Chip
																label={v.name}
																variant="filled"
																onDelete={() => onDeleteExcUsers(v.uid, formik)}
																style={{
																	background: "#F0F4FF",
																	color: "black",
																}}
															/>
														</Box>
													))}
													error={
														formik.touched.usersExcluded &&
														Boolean(formik.errors.usersExcluded)
													}
													helperText={
														formik.touched.usersExcluded &&
														formik.errors.usersExcluded
													}
												/>
												{userExcErrMsg && (
													<Typography color={"red"} fontSize={13}>
														Selected User is already added
													</Typography>
												)}
											</Grid>
										</Grid>
									)}
								</>
							)}

							<Box mt={2} mb={2} display={"flex"} justifyContent={"flex-end"}>
								<FHButton
									name={"Save code"}
									variant="outlined"
									color={colors.success}
									small
									w_40
									disabled={
										showItemMaxUses || showLocMaxUses || showUserMaxUses
									}
									commonButtonStyle
									onClick={formik.handleSubmit}
								// errors={validated ? validate.errors : undefined}
								/>
							</Box>
						</Grid>
					</form>
				</Grid>
			</Grid>
		</Box>
	);
};
