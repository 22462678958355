import { combineReducers, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import storage from "redux-persist/lib/storage";
import rootSaga from "../sagas";
import { persistReducer, persistStore } from "redux-persist";
import { userReducer } from "./userStore";
import { orderReducer } from "./orderStore";
import { locationReducer } from "./locationStore";
import { promocodeReducer } from "./promocodeStore";
import { dashboard } from "./dashboardTransitionStore";
import { menuReducer } from "./menuStore";
import { activityReducer } from "./activityStore";
import { botReducer } from "./botStore";
import { dailyPlanReducer } from "./dailyPlansStore";
import { salesReducer } from './salesSummaryStore'
import { botManagementReducer } from "./botManagementStore";

const persistConfig = {
	key: "root",
	storage,
};
const rootReducer = combineReducers({
	userReducer,
	locationReducer,
	orderReducer,
	promocodeReducer,
	dashboard,
	menuReducer,
	activityReducer,
	botReducer,
	dailyPlanReducer,
	salesReducer,
	botManagementReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
	persistedReducer,
	applyMiddleware(logger, sagaMiddleware)
);

const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export default store;
export { persistor };
