export const planned = [
  {
    id: 1.1,
    reason: "End of the day",
  },
  {
    id: 1.2,
    reason: "Bot Maintanance",
  },
  {
    id: 1.3,
    reason: "Bot Upgrades",
  },
  {
    id: 1.4,
    reason: "Others",
  },
];

export const unplanned = [
  {
    id: 2.1,
    reason: "Software Issue",
  },
  {
    id: 2.2,
    reason: "Technical Issue",
  },
  {
    id: 2.3,
    reason: "IOT Issue",
  },
  {
    id: 2.4,
    reason: "Mechanical Issue",
  },
];
