import React from "react";
import { TextField , useMediaQuery} from "@mui/material";

export const FHTextfield = (props) => {
  const {
    variant,
    type,
    id,
    name,
    value,
    onChange,
    error,
    helperText,
    width,
    min,
    max,
    rows,
  } = props;
	const mobileView = useMediaQuery("(max-width:920px)");

  return (
    <TextField
      variant={variant || "standard"}
      type={type || "text"}
      size={"small"}
      id={id}
      name={name}
      rows={rows}
      value={value}
      onChange={onChange}
      error={error}
      margin="none"
      // disableUnderline
      helperText={helperText}
      style={{
        // width: width,
				width: mobileView ? '80%' : '100%'

      }}
      InputProps={{
        disableUnderline: true,
        inputProps: {
          min: min,
          max: max,
        },

        style: {
          padding: props.multiline === true ? 2 : 6,
          border:
            (props.disabled && props.name !== "itemMaxUses") ||
            (props.disabled && props.name !== "locationMaxUses") ||
            (props.disabled && props.name !== "userMaxUses")
              ? "0.5px solid #ACACAC"
              : "3px solid #ACACAC",
          borderRadius: 9,
          fontSize: 14,
          fontWeight: 600,
					width: mobileView ? '80%' : '100%'
        },
      }}
      {...props}
    />
  );
};
