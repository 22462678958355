import React from "react";
import { Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
	thumb: {
		color: "white",
		width: 100,
		height: 80,
		margin: 1.5,
	},
	track: {
		borderRadius: 5,
		opacity: "1 !important",
		"&:after, &:before": {
			fontSize: "11px",
			position: "absolute",
			top: "6px",
		},
		"&:after": {
			fontSize: 20,
			left: "8px",
		},
		"&:before": {
			fontSize: 20,
			right: "7px",
		},
	},
	checked: {
		transform: "translateX(73px) !important",
	},
});

const FHSwitch = (props) => {
	const { handleSwitch, switchstate } = props;
	const classes = useStyles();

	return (
		<div>
			<Switch
				classes={{
					root: classes.root,
					switchBase: classes.switchBase,
					thumb: classes.thumb,
					track: classes.track,
					checked: classes.checked,
				}}
				sx={{
					marginTop: -1,
					width: 130,
					height: 60,
					"& .MuiSwitch-thumb": {
						height: 26,
						width: 26,
						marginTop: 1,
						marginLeft: 1,
					},
					"& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
						backgroundColor: "#4DA731",
						"&:after": {
							content: "'Open'",
							fontSize: 15,
							color: "#ffff",
							marginLeft: 2,
							// marginTop:2
						},
						"&:before": {
							content: "'Closed'",
							fontSize: 15,
							color: "#4DA731",
							// marginTop:2
						},
					},
					"& .MuiSwitch-track": {
						backgroundColor: "grey",
						borderRadius: 10,
						"&:after": {
							content: "'Open'",
							fontSize: 15,
							color: "grey",
							marginLeft: 2,
							marginTop: 1.8,
						},
						"&:before": {
							content: "'Closed'",
							fontSize: 15,
							color: "#ffff",
							marginRight: 2,
							marginTop: 1.8,
						},
					},
				}}
				checked={switchstate}
				onChange={handleSwitch}
				inputProps={{ "aria-label": "secondary checkbox" }}
			/>
		</div>
	);
};

export default FHSwitch;
