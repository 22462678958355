import React from "react";
import { Slide } from "@mui/material";

const FHSlide = (props) => {
  const { slideIn, container, component } = props;
  return (
    <Slide
      direction={'left'}
      in={slideIn}
      container={container}
      easing={{
        enter: "linear",
        exit: "linear",
      }}
      timeout={{ enter: 300, exit: 0 }}
    >
      <div>{component}</div>
    </Slide>
  );
};

export default FHSlide