import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Box, Typography } from "@mui/material";
import colors from "../../../themes/colors";
import { useDispatch, useSelector } from "react-redux";
import { filter, isEmpty } from "lodash";
import FHButton from "../../../components/FHButton";
import { FHLabel } from "../../../components/FormInputs/FHLabel";
import { FHTextfield } from "../../../components/FormInputs/FHTextfield";
import { FHDateTimePicker } from "../../../components/FormInputs/FHDateTimePicker";
import moment from "moment";
import { AddPlan } from "../../../services/dailyPlanServices";
import { generateRandomString } from "../../../services/utils";
import * as yup from "yup";
import { status } from "../../../data/dailyPlans";
import { FHAutocomplete } from "../../../components/FormInputs/FHAutocomplete";

const validationSchema = yup.object({
  noOfBoxes: yup
    .number()
    .min(1, "No of boxes cannot be less than 1")
    .test(
      "is-even",
      "No of boxes must be an even number",
      (value) => value % 2 === 0
    )
    .required("No of boxes is required"),
  when: yup
    .date()
    .test(
      "is-greater-than-current",
      "Date and time must be in the future",
      (value) => {
        const currentTimeMinus10 = new Date();
        currentTimeMinus10.setMinutes(currentTimeMinus10.getMinutes() - 10);
        return new Date(value) > currentTimeMinus10;
      }
    )
    .required("Date and time is required"),
});

const validationSchema1 = yup.object({
  status: yup.object().required("status is required"),
});

const Store = (props) => {
  const activityInfo = props.activityInfo;
  const dispatch = useDispatch();

  const selectedLocation = useSelector(
    (state) => state.locationReducer.selectedLocation
  );
  const selectedMachine = useSelector(
    (state) => state.dailyPlanReducer.selectedMachine
  );
  const selectedEditPlan = useSelector(
    (state) => state.dailyPlanReducer.selectedEditPlan
  );
  const editPlan = useSelector((state) => state.dailyPlanReducer.editPlan);
  const dailyPlansByFilter = useSelector(
    (state) => state.dailyPlanReducer.dailyPlansByFilter
  );
  const duplicatePlan = useSelector(
    (state) => state.dailyPlanReducer.duplicatePlan
  );
  const addStatus = useSelector((state) => state.dailyPlanReducer.addStatus);

  const [readOnly, setReadOnly] = useState(false);
  const [planExists, setPlanExists] = useState(false);
  const [showAddStatus, setShowAddStatus] = useState(false);
  const [showStatusButton, setShowStatusButton] = useState(false);
  const [showErrMsg, setShowErrMsg] = useState(false);

  const formik = useFormik({
    // enableReinitialize: editPromo,
    initialValues: {
      noOfBoxes: 4,
      when: new Date(),
      status: status[0],
    },
    validationSchema: addStatus ? validationSchema1 : validationSchema,
    onSubmit: (e) => {
      console.log("formik values on submit", formik.values);
      AddStore();
    },
  });

  const AddStore = async () => {
    let locId = selectedLocation?.d?.locId;
    let botId = selectedMachine?.id;
    let endTime = moment(formik.values.when).add(30, "minutes");
    let sTime = new Date(formik.values.when);
    let doesAnyPlanExistAtSelectedTime = filter(
      dailyPlansByFilter,
      (item) =>
        item?.when === sTime ||
        (item?.start < sTime && item?.end > endTime) ||
        (item?.start < sTime && item?.end > sTime) ||
        (item?.start > sTime && item?.start < endTime && item?.end > endTime) ||
        (item?.start > sTime && item?.end < endTime)
    );
    // console.log(
    // 	"doesAnyPlanExistAtSelectedTime",
    // 	doesAnyPlanExistAtSelectedTime
    // );
    if (
      isEmpty(doesAnyPlanExistAtSelectedTime) ||
      (!isEmpty(doesAnyPlanExistAtSelectedTime) &&
        doesAnyPlanExistAtSelectedTime[0]?.status === "FAILED") ||
      (!isEmpty(doesAnyPlanExistAtSelectedTime) &&
        doesAnyPlanExistAtSelectedTime[0]?.status === "COMPLETED")
    ) {
      setPlanExists(false);
      let planData = {
        operation: "Store",
        locId: locId,
        botId: botId,
        start: new Date(formik.values.when),
        end: new Date(endTime),
        when: formik.values.when,
        noOfBoxes: formik.values.noOfBoxes,
      };
      let isCreatingDoc = true;
      let docId;
      if (editPlan && !addStatus) {
        docId = selectedEditPlan?.docId;
      } else if (editPlan && addStatus) {
        docId = selectedEditPlan?.docId;
        let planStatus = formik.values.status;
        planData = {
          ...planData,
          status: planStatus?.name,
        };
      } else {
        docId = generateRandomString(isCreatingDoc, 20);
      }
      // console.log("addStorePlan", planData);
      // AddPlan(locId, botId, docId, planData);
      // dispatch({ type: "DAILY_PLAN", payload: false });
      // dispatch({ type: "EDIT_PLAN", payload: false });
      // dispatch({ type: "SELECTED_ADD_PLAN", payload: {} });
      try {
        const addPlanResult = await AddPlan(locId, botId, docId, planData);
        if (addPlanResult === "success") {
          dispatch({
            type: "LOG_ACTIVITY",
            payload: activityInfo,
          });
          setShowErrMsg(false);
          dispatch({ type: "DAILY_PLAN", payload: false });
          dispatch({ type: "EDIT_PLAN", payload: false });
          dispatch({ type: "SELECTED_ADD_PLAN", payload: {} });
        } else {
          setShowErrMsg(true);
          console.log("Add Plan Error", addPlanResult);
        }
      } catch (error) {
        console.error("Error while adding plan:", error);
      }
    } else {
      setPlanExists(true);
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedEditPlan)) {
      formik.setFieldValue("noOfBoxes", selectedEditPlan?.noOfBoxes);
      formik.setFieldValue("when", selectedEditPlan?.when);
      if (selectedEditPlan?.when < new Date()) {
        setReadOnly(true);
        dispatch({ type: "ADD_STATUS", payload: true });
        setShowStatusButton(true);
      }
    }
  }, []);

  useEffect(() => {
    if (duplicatePlan) {
      setReadOnly(false);
      dispatch({ type: "EDIT_PLAN", payload: false });
      formik.setFieldValue("when", new Date());
      dispatch({ type: "DUPLICATE_PLAN", payload: false });
    }
  }, [duplicatePlan]);

  const handleAddPlanStatus = () => {
    setShowAddStatus(true);
    setShowStatusButton(false);
  };

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <FHLabel label={"No of Boxes"} />
        <FHTextfield
          fullWidth
          id="noOfBoxes"
          name="noOfBoxes"
          type="number"
          disabled={readOnly}
          value={formik.values.noOfBoxes}
          onChange={formik.handleChange}
          error={formik.touched.noOfBoxes && Boolean(formik.errors.noOfBoxes)}
          helperText={formik.touched.noOfBoxes && formik.errors.noOfBoxes}
        />

        <FHLabel label={"When?"} />
        <FHDateTimePicker
          value={formik.values.when}
          editForm={editPlan}
          disabled={readOnly}
          onChange={(newValue) => formik.setFieldValue("when", newValue?.$d)}
          error={formik.touched.when && Boolean(formik.errors.when)}
          helperText={formik.touched.when && formik.errors.when}
        />
        {!readOnly && formik.errors.when && (
          <Typography color={"red"} fontSize={12}>
            {formik.errors.when}
          </Typography>
        )}
        {planExists && (
          <Typography color={"red"} fontSize={13}>
            A plan has been already added at the selected time period.
          </Typography>
        )}

        {(showStatusButton &&
          addStatus &&
          !selectedEditPlan?.hasOwnProperty("status")) ||
        (showStatusButton &&
          addStatus &&
          selectedEditPlan?.hasOwnProperty("status") &&
          selectedEditPlan?.status !== "COMPLETED") ? (
          <Box mt={2} mb={2} display={"flex"} justifyContent={"flex-start"}>
            <FHButton
              name={"Update Plan Status"}
              variant="outlined"
              color={colors.success}
              small
              w_40
              commonButtonStyle
              onClick={handleAddPlanStatus}
            />
          </Box>
        ) : (
          <></>
        )}

        {showAddStatus ? (
          <>
            <FHLabel label={"Add Status"} />
            <FHAutocomplete
              options={status}
              defaultValue={formik.values.status}
              value={formik.values.status}
              singleselect={"true"}
              id="status"
              name="status"
              placeholder="Select Status"
              multiple={false}
              onChange={(e, newValue) =>
                formik.setFieldValue("status", newValue)
              }
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
            />
          </>
        ) : (
          <></>
        )}

        {showErrMsg && (
          <Box mt={2}>
            {" "}
            <Typography className="error-msg" color={"red"} fontSize={12}>
              Something went wrong while adding the plan.
            </Typography>
          </Box>
        )}

        {!readOnly || showAddStatus ? (
          <Box mt={14} mb={2} display={"flex"} justifyContent={"flex-end"}>
            <FHButton
              name={showAddStatus ? "Add Status" : "Save Plan"}
              variant="outlined"
              color={colors.success}
              small
              w_40
              // disabled={showItemMaxUses || showLocMaxUses || showUserMaxUses}
              commonButtonStyle
              onClick={formik.handleSubmit}
              // errors={validated ? validate.errors : undefined}
            />
          </Box>
        ) : (
          <></>
        )}
      </form>
    </Box>
  );
};

export default Store;
