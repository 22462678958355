
/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  menuCard: true,
  promoCard: true,
  ordersCard: true,
  usersCard: true,
  feedbackCard: true,
  orderDetailsCard: false,
  userDetailsCard: false,
  userOrderDetailsCard: false,
  menuDetailsCard: false,
  feedbackView: false,
  mobileMenu: false,
  currentTab: {},
};

// Types

//Actions
export const MENUCARD = "MENUCARD";
export const PROMOCARD = "PROMOCARD";
export const ORDERSCARD = "ORDERSCARD";
export const USERSCARD = "USERSCARD";
export const FEEDBACKCARD = "FEEDBACKCARD";
export const ORDERDETAILS = "ORDERDETAILS";
export const USERDETAILS = "USERDETAILS";
export const USERLASTORDERDETAILS = "USERLASTORDERDETAILS";
export const MENUDETAILS = "MENUDETAILS";
export const FEEDBACKVIEW = "FEEDBACKVIEW";
export const MOBILEMENU = "MOBILEMENU";
export const CURRENT_TAB = "CURRENT_TAB";
export const LOGOUT = "LOGOUT";

// Reducers
export const dashboard = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case MENUCARD:
      return {
        ...state,
        menuCard: action.payload,
      };
    case PROMOCARD:
      return {
        ...state,
        promoCard: action.payload,
      };
    case ORDERSCARD:
      return {
        ...state,
        ordersCard: action.payload,
      };
    case USERSCARD:
      return {
        ...state,
        usersCard: action.payload,
      };
    case FEEDBACKCARD:
      return {
        ...state,
        feedbackCard: action.payload,
      };
    case ORDERDETAILS:
      return {
        ...state,
        orderDetailsCard: action.payload,
      };
    case USERDETAILS:
      return {
        ...state,
        userDetailsCard: action.payload,
      };
    case USERLASTORDERDETAILS:
      return {
        ...state,
        userOrderDetailsCard: action.payload,
      };
    case MENUDETAILS:
      return {
        ...state,
        menuDetailsCard: action.payload,
      };
    case FEEDBACKVIEW:
      return {
        ...state,
        feedbackView: action.payload,
      };
    case MOBILEMENU:
      return {
        ...state,
        mobileMenu: action.payload,
      };
    case CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };
    case LOGOUT:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};
