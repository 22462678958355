import React, { useEffect, useState } from "react";
import {
  Grid,
  ListItem,
  Typography,
  List,
  Popover,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import colors from "../../themes/colors";
import { filter, map, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";
import FHCard from "../../components/FHCard";
import { FHSearchBar } from "../../components/FHSearchBar";
import { FHDrawer } from "../../components/FHDrawer";
import EditOrDuplicate from "./EditOrDuplicate";
import { AddAndEditLocation } from "../../containers/Settings/AddAndEditLocation";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
const customStyles = makeStyles(commonClasses);

export const LocationCard = (props) => {
  const { handleLocations } = props;
  const common = customStyles(commonClasses);
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [allLoc, setAllLoc] = useState([]);

  const menuLocation = useSelector((state) => state.menuReducer.menuLocation);
  const allLocations = useSelector(
    (state) => state.locationReducer.allLocations
  );

  const handleClick = (event, item) => {
    dispatch({ type: "STORE_MENU_LOCATION", payload: item });
    dispatch({
      type: "GET_ACTIVITIES",
      payload: {
        locIds: [item?.d?.locId],
      },
    });
  };
  const handleEditClick = (event, item) => {
    dispatch({ type: "STORE_MENU_LOCATION", payload: item });
    // setAnchorEl(event.currentTarget);
    setDrawer(true);
  };

  const handleClose = (name) => {
    if (name === "Edit") {
      setDrawer(true);
    }
    setAnchorEl(null);
  };

  const handleSearchQuery = (txt) => {
    setAllLoc(filter(allLocations, (itm) => itm.d.locId === txt));
    setSearchQuery(txt);
  };

  useEffect(() => {
    if (isEmpty(searchQuery)) {
      setAllLoc(allLocations);
    }
  }, [searchQuery]);

  const handleSeachClick = () => {
    if (!isEmpty(searchQuery)) {
      setSearchQuery("");
    }
    return;
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;
  const ListItems = (props) => {
    return map(props.data, (u, i) => (
      <ListItem
        className={
          u?.d?.locId === menuLocation?.d?.locId
            ? common.selectedOrderCard
            : common.listItemalign
        }
        key={i}
        onClick={(e) => handleClick(e, u)}
      >
        <Box width="100%">
          <Grid container>
            <Grid item md={12}>
              <Typography
                fontSize={15}
                color={
                  u?.d?.locId === menuLocation?.d?.locId ? "#FFF" : "black"
                }
              >
                {u.Name}
              </Typography>
            </Grid>

            <Grid item md={11}>
              <Typography
                style={{ margin: 0 }}
                mb
                fontSize={12}
                color={
                  u?.d?.locId === menuLocation?.d?.locId
                    ? "#FFF"
                    : colors.LighterGrey
                }
              >
                {u.d.locationDetails.formattedAddress}
              </Typography>
            </Grid>
            {/* <Grid item md={1}>
              <ListItemIcon onClick={(e) => handleEditClick(e, u)}>
                <IconButton edge="start">
                  <EditIcon color="red" />
                </IconButton>
              </ListItemIcon>
            </Grid> */}
          </Grid>
        </Box>
      </ListItem>
    ));
  };

  return (
    <FHCard
      fullLengthCard
      body={
        <>
          <Box display="flex" justifyContent="space-between" p>
            <Typography fontSize={18} fontWeight={700} pr={2} mt={2}>
              Location
            </Typography>
            <FHSearchBar
              placeholder={"Search by Id"}
              value={searchQuery || ""}
              onChange={(event) => handleSearchQuery(event.target.value)}
              handleSearch={handleSeachClick}
            />
          </Box>

          <Box>
            <List className={common.listalign}>
              {!isEmpty(allLoc) ? (
                <>
                  <ListItems data={allLoc} />
                </>
              ) : (
                <Typography>No Locations</Typography>
              )}
              <ListItem
                className={
                  isEmpty(menuLocation)
                    ? common.selectedOrderCard
                    : common.listItemalign
                }
                onClick={() => handleLocations(null)}
              >
                Common
              </ListItem>
            </List>
          </Box>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <EditOrDuplicate
              editAndDuplicate={false}
              anchorEl={anchorEl}
              onPopClose={handleClose}
            />
          </Popover>
          <FHDrawer
            open={drawer}
            onClose={() => {}}
            onOpen={() => {}}
            drawerBorder
            // data={
            //   // <AddAndEditLocation
            //   //   drawer={drawer}
            //   //   onClose={() => setDrawer(false)}
            //   // />
            // }
          />
        </>
      }
    />
  );
};
