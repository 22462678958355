import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {  subDays } from "date-fns";
import moment from "moment";

export default function BasicDateRangePicker() {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const dispatch = useDispatch();
  const selectedLocation = useSelector(
    (state) => state.locationReducer.selectedLocation
  );

  return (
    <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate && moment(endDate).endOf("day").toDate()}
      onChange={(update) => {
        setDateRange(update);
        if (update[0] && update[1]) {
          dispatch({
            type: "GET_ACTIVITIES",
            payload: {
              locIds:
                selectedLocation?.Name === "All Locations"
                  ? selectedLocation?.locIds
                  : [selectedLocation?.d?.locId],
              startDate: update[0],
              endDate: moment(update[1]).endOf("day").toDate(),
            },
          });
        } else if (!update[0] && !update[1]) {
          dispatch({
            type: "GET_ACTIVITIES",
            payload: {
              locIds:
                selectedLocation?.Name === "All Locations"
                  ? selectedLocation?.locIds
                  : [selectedLocation?.d?.locId],
            },
          });
        }
      }}
      dateFormat="MM/dd/yyyy h:mm aa"
      isClearable={true}
      placeholderText="dd/mm/yyyy h:mm aa   -   dd/mm/yyyy h:mm aa"
      maxDate={new Date()}
      minDate={subDays(new Date(), 5)}
      // showTimeSelect
    />
  );
}
