import React, { useState, useMemo } from "react";
import "react-data-grid/lib/styles.css";
import {
  Chip,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  ListItemText,
  Checkbox,
  Button,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DataGrid from "react-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { get, map, intersection } from "lodash";
import { getTime } from "../services/dateTimeConversion";
import { msToTime } from "../services/utils";
import { reportTypesOptions } from "../data/reportType";

export const getStatusChip = (logLevel) => {
  switch (logLevel) {
    case "unresolved":
      return <Chip label={logLevel} color="error" />;
    case "ignored":
      return <Chip label={logLevel} color="warning" />;
    case "resolved":
      return <Chip label={logLevel} color="success" />;
    default:
      return <Chip label={logLevel} color="error" />;
  }
};

const FHReportList = ({ rows, onSelectReport, resolveMethods }) => {
  const classes = useStyles();

  const [filters, setFilters] = useState({
    actionName: "",
    status: "All",
    reportType: "All",
    resolveMethods: [],
  });

  const columns = [
    {
      key: `metaData.actionName`,
      name: "Action",
      renderHeaderCell: (p) => {
        return (
          <TextField
            id="outlined-basic"
            label="Action Name"
            variant="standard"
            value={filters.actionName}
            onChange={(e) =>
              setFilters({
                ...filters,
                actionName: e.target.value,
              })
            }
          />
        );
      },
      renderCell: ({ row }) => {
        return row.metaData.actionName || `SEQ_${row.metaData.seqName}`;
      },
    },
    {
      key: "status",
      name: "Status",
      filter: "agTextColumnFilter",
      renderHeaderCell: (p) => {
        return (
          <Select
            value={filters.status}
            variant="standard"
            onChange={(e) =>
              setFilters({
                ...filters,
                status: e.target.value,
              })
            }
            label="Report Status"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="resolved">Resolved</MenuItem>
            <MenuItem value="ignored">Ignored</MenuItem>
            <MenuItem value="unresolved">Unresolved</MenuItem>
            <MenuItem value="verificationFailed">verificationFailed</MenuItem>
          </Select>
        );
      },
      renderCell: ({ row }) => {
        return getStatusChip(row.status);
      },
    },
    {
      key: "reportType",
      name: "Type",
      filter: "agTextColumnFilter",
      renderHeaderCell: (p) => {
        return (
          <Select
            value={filters.reportType}
            variant="standard"
            onChange={(e) =>
              setFilters({
                ...filters,
                reportType: e.target.value,
              })
            }
            label="Report Type"
          >
            <MenuItem value="All">All</MenuItem>

            {map(reportTypesOptions, (type, idx) => (
              <MenuItem key={idx} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        );
      },
      renderCell: ({ row }) => {
        return row.reportType;
      },
    },
    {
      key: "resolveMethods",
      name: "Resolve Methods",
      filter: "agTextColumnFilter",
      renderHeaderCell: (p) => {
        return (
          <Select
            multiple
            value={
              filters.resolveMethods.length ? filters.resolveMethods : ["All"]
            }
            variant="standard"
            onChange={(e) =>
              setFilters({
                ...filters,
                resolveMethods:
                  get(e.target.value, "0") === "All"
                    ? [...e.target.value.slice(1)]
                    : e.target.value,
              })
            }
            renderValue={(selected) => selected.join(", ")}
          >
            {map(resolveMethods, (count, method) => (
              <MenuItem key={method} value={method}>
                <Checkbox
                  style={{ color: "black" }}
                  checked={filters.resolveMethods.indexOf(method) > -1}
                />
                <ListItemText primary={method} />
              </MenuItem>
            ))}
          </Select>
        );
      },
      renderCell: ({ row }) => {
        return (
          <Tooltip title={row.resolveMethods?.join(", ")} placement="top-start">
            <Button style={{ color: "black", textTransform: "none" }}>
              {row.resolveMethods?.join(", ")}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      key: "created.at",
      name: "Created At",
      renderCell: ({ row }) => {
        return getTime(row.created?.at?.seconds);
      },
    },
    {
      key: "resolveTime",
      name: "Resolved At",
      renderCell: ({ row }) => {
        return getTime(row.resolveTime?.seconds);
      },
    },
    {
      key: "",
      name: "Time taken",
      renderCell: ({ row }) => {
        // Calculate the difference in hours
        const startMoment = get(row, "created.at.seconds");
        const endMoment = get(
          row,
          "resolveTime.seconds",
          new Date().getTime() / 1000
        );
        const timeTaken = msToTime((endMoment - startMoment) * 1000);
        return (
          <Typography
            variant="body3"
            style={{
              color: timeTaken.match(/Hrs/)
                ? "orange"
                : timeTaken.match(/Days/)
                ? "red"
                : "black",
            }}
          >
            {timeTaken}
          </Typography>
        );
      },
    },
    {
      key: "view",
      name: "View",
      renderCell: ({ row }) => {
        return (
          <IconButton
            style={{ width: "100%" }}
            aria-label="view"
            size="large"
            onClick={() => onSelectReport(row)}
          >
            <VisibilityIcon />
          </IconButton>
        );
      },
    },
  ];

  const filteredRows = useMemo(() => {
    return rows.filter((r) => {
      return (
        (filters.actionName
          ? (get(r, "metaData.actionName", "") || `SEQ_${get(r, "metaData.seqName", "")}`)
              .toLowerCase()
              .startsWith(filters.actionName.toLowerCase())
          : true) &&
        (filters.status !== "All" ? r.status === filters.status : true) &&
        (filters.reportType !== "All"
          ? r.reportType === filters.reportType
          : true) &&
        (filters.resolveMethods.length > 0
          ? intersection(r.resolveMethods, filters.resolveMethods).length > 0
          : true)
      );
    });
  }, [rows, filters]);

  if (!filteredRows) return null;

  return (
    <div className={classes.listContainer}>
      <DataGrid
        className="rdg-light"
        columns={columns}
        rows={filteredRows}
        onRowClick={(row) => onSelectReport(row)}
        rowHeight={50}
        style={{ height: "calc(100vh - 360px)", width: "100%" }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  listContainer: {
    marginTop: 5,
    maxHeight: "80vh",
    overflowY: "auto",
    padding: theme.spacing(2),
  },

  listItem: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
  filterContainer: {
    marginBottom: theme.spacing(2),
  },
}));

export default FHReportList;
