import React, { useEffect, useState } from "react";
import { Grid, List, ListItem, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import colors from "../../themes/colors";
import { filter, map, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { sortItemExpiryTime } from "../../services/utils";
import { covertdateformate } from "../../services/dateTimeConversion";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";
import FHButton from "../../components/FHButton";
import FHSelect from "../../components/FHSelect";
import { BiCoinStack } from "react-icons/bi";

const customStyles = makeStyles(commonClasses);

export const MenuCard = (props) => {
  const common = customStyles(commonClasses);
  const dispatch = useDispatch();
  const mobileView = useMediaQuery("(max-width:920px)");

  const locations = useSelector((state) => state.locationReducer.locations);
  const selectedMenuLocation = useSelector(
    (state) => state.menuReducer.menuLocation
  );
  const selectedLocation = useSelector(
    (state) => state.locationReducer.selectedLocation
  );
  // const machinesInfo = useSelector((state) => state.botReducer.machinesInfo);
  const botStatus = useSelector((status) => status.botReducer.botStatus);
  const currentLocMenu = useSelector(
    (state) => state.menuReducer.currentLocationMenu
  );
  const availableItems = useSelector(
    (state) => state.menuReducer.cLocAvailableMenu
  );
  const selectedMenuDetails = useSelector(
    (state) => state.menuReducer.selectedMenuDetails
  );

  let itemsWithStatus = [];
  map(botStatus, (item) =>
    map(item.status, (sItem) =>
      itemsWithStatus.push({
        ...sItem,
        machineId: item.machineId,
        machineName: item.machineName,
        subSystem: item.subSystem,
        locationId: item.locationId,
      })
    )
  );

  //console.log("botStatus",itemsWithStatus);

  const [menuLocation, setMenuLocation] = useState("");
  const [showActive, setShowActive] = useState(true);
  const [showOutOfStock, setShowOutofStock] = useState(false);
  const [showHide, setShowHide] = useState(false);
  const [menuData, setMenuData] = useState(null);

  let menuWithQuantity = [];
  map(currentLocMenu, (item) =>
    map(availableItems, (aItem) => {
      if (item.id === aItem.menuItem) {
        menuWithQuantity.push({ ...item, ...aItem });
      }
      return;
    })
  );

  const getRecentExpiryTime = (data) => {
    let list = filter(
      itemsWithStatus,
      (xyz) => xyz?.itemId === data?.itemId && xyz.hasOwnProperty("expiryAt")
    );
    let sorted = sortItemExpiryTime(list);
    let timeFormat =
      !isEmpty(sorted) && sorted[0].expiryAt !== undefined
        ? `${covertdateformate(sorted[0].expiryAt?.seconds)?.time} | ${
            covertdateformate(sorted[0].expiryAt?.seconds)?.date
          }`
        : "";
    return timeFormat;
  };

  useEffect(() => {
    if (!isEmpty(locations)) {
      setMenuLocation(locations[0]);
      dispatch({ type: "STORE_MENU_LOCATION", payload: locations[0] });
    }
  }, [locations, selectedLocation]);

  useEffect(() => {
    if (!isEmpty(currentLocMenu)) {
      setMenuData(
        filter(
          menuWithQuantity,
          (item) => item.hide === false && item.status === "Active"
        )
      );
    } else {
      setMenuData(null);
    }
    setShowActive(true);
    setShowOutofStock(false);
    setShowHide(false);
  }, [currentLocMenu, availableItems]);

  const handleSelectLocations = (e, v) => {
    if (v) {
      setMenuLocation(v);
      dispatch({ type: "STORE_MENU_LOCATION", payload: v });
    } else {
      setMenuLocation("");
      dispatch({ type: "STORE_MENU_LOCATION", payload: "" });
    }
  };

  // console.log("menuData", menuData);

  const handleShowMenuDetails = (item) => {
    dispatch({ type: "STORE_SELECTED_MENU_DETAILS", payload: item });
    if (mobileView) {
      dispatch({ type: "MENUCARD", payload: false });
      dispatch({ type: "MENUDETAILS", payload: true });
    } else {
      dispatch({ type: "PROMOCARD", payload: false });
      dispatch({ type: "ORDERSCARD", payload: false });
      dispatch({ type: "USERSCARD", payload: false });
      dispatch({ type: "FEEDBACKCARD", payload: false });
      dispatch({ type: "MENUDETAILS", payload: true });
    }
  };

  const handleActive = () => {
    setShowActive(true);
    setShowOutofStock(false);
    setShowHide(false);
    setMenuData(
      filter(
        menuWithQuantity,
        (item) => item.hide === false && item.status === "Active"
      )
    );
  };

  const handleOutOfStock = () => {
    setShowOutofStock(true);
    setShowActive(false);
    setShowHide(false);
    setMenuData(filter(menuWithQuantity, (item) => item.status === "inActive"));
  };

  const handleHide = () => {
    setShowHide(true);
    setShowActive(false);
    setShowOutofStock(false);
    setMenuData(filter(menuWithQuantity, (item) => item.hide === true));
  };

  return (
    <>
      <Grid container mb={1}>
        <Grid item xs={12} sm={6}>
          <Typography fontSize={23} fontWeight={700}>
            Menu
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {selectedLocation && selectedLocation?.Name === "All Locations" ? (
            <FHSelect
              height={40}
              disableClearable={true}
              name="locations"
              placeholder="Select location"
              options={locations || []}
              style={{ backgroundColor: `${colors.main}` }}
              value={(!isEmpty(menuLocation) && menuLocation) || {}}
              onChange={(e, v) => {
                handleSelectLocations(e, v);
              }}
            />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Box>
        <Box mb={0.5}>
          <Grid container gap={1}>
            <Grid width={"auto"}>
              <FHButton
                name={"Active"}
                variant="outlined"
                smallButton
                menuTabButton={showActive ? false : true}
                activeTabButton={showActive ? true : false}
                onClick={() => handleActive()}
              />
            </Grid>
            <Grid width={"auto"}>
              {/* <Badge
                variant="dot"
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "#CE0000",
                    top: -7,
                    right: 108,
                  },
                }}
              > */}

              <FHButton
                name={"Out of Stock"}
                variant="outlined"
                smallButton
                // outofStockTabButton
                menuTabButton={showOutOfStock ? false : true}
                activeTabButton={showOutOfStock ? true : false}
                onClick={() => handleOutOfStock()}
              />
              {/* </Badge> */}
            </Grid>
            <Grid width={"auto"}>
              <FHButton
                name={"Hidden"}
                variant="outlined"
                smallButton
                menuTabButton={showHide ? false : true}
                activeTabButton={showHide ? true : false}
                onClick={() => handleHide()}
              />
            </Grid>
          </Grid>
        </Box>
        {!isEmpty(selectedMenuLocation) ? (
          <List
            className={
              isEmpty(selectedMenuDetails) && !mobileView
                ? common.menuList
                : mobileView
                ? common.listalign
                : !isEmpty(selectedMenuDetails) && !mobileView
                ? common.listalign
                : common.listalign
            }
          >
            {!isEmpty(menuData) ? (
              <>
                {map(
                  filter(menuData, (data) =>
                    selectedLocation?.Name === "All Locations"
                      ? data.locationId === selectedMenuLocation?.d?.locId
                      : data.locationId === selectedLocation?.d?.locId
                  ),
                  (item, i) => (
                    <ListItem
                      key={i}
                      className={[
                        common.menulistItemalign,
                        !isEmpty(selectedMenuDetails) &&
                        item.id === selectedMenuDetails?.id
                          ? common.bgSuccess
                          : common.bgMain,
                        // showActive && isEmpty(selectedMenuDetails)
                        //   ? common.activeHighlight
                        //   : showOutOfStock && isEmpty(selectedMenuDetails)
                        //   ? common.outOfStockHighlight
                        //   : showHide && isEmpty(selectedMenuDetails)
                        //   ? common.hiddenItemHighlight
                        //   : "",
                      ].join(" ")}
                      onClick={() => handleShowMenuDetails(item)}
                    >
                      <Box width="100%">
                        <Grid
                          container
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          gap="10px"
                          alignItems="center"
                        >
                          <Grid
                            item
                            display="flex"
                            alignItems="start"
                            flexDirection="column"
                            overflow="auto"
                          >
                            <Typography
                              fontSize={15}
                              color={
                                !isEmpty(selectedMenuDetails) &&
                                item.id === selectedMenuDetails?.id
                                  ? colors.bright
                                  : colors.darkGrey
                              }
                            >
                              {item.name}
                            </Typography>

                            {!isEmpty(getRecentExpiryTime(item)) && (
                              <Typography
                                fontSize={12}
                                color={
                                  !isEmpty(selectedMenuDetails) &&
                                  item.id === selectedMenuDetails?.id
                                    ? colors.bright
                                    : colors.lightGrey
                                }
                              >
                                Expiry: {getRecentExpiryTime(item)}
                              </Typography>
                            )}
                          </Grid>

                          <Grid
                            display="flex"
                            justifyContent="end"
                            gap="15px"
                            alignItems="center"
                          >
                            <Box>
                              <Typography
                                fontSize="16px"
                                justifyContent={"flex-start"}
                                sx={{
                                  display: {
                                    sm: "block",
                                    md: "none",
                                    lg: "block",
                                    xs: "none",
                                  },
                                }}
                                color={
                                  !isEmpty(selectedMenuDetails) &&
                                  item.id === selectedMenuDetails?.id
                                    ? colors.bright
                                    : colors.darkGrey
                                }
                              >
                                Rs.{item.price}
                              </Typography>
                            </Box>

                            <Box display="flex" justifyContent={"flex-start"}>
                              <Typography
                                fontSize={13}
                                sx={{
                                  display: {
                                    sm: "block",
                                    md: "none",
                                    lg: "block",
                                    xs: "none",
                                  },
                                }}
                                color={
                                  !isEmpty(selectedMenuDetails) &&
                                  item.id === selectedMenuDetails?.id
                                    ? colors.bright
                                    : colors.darkGrey
                                }
                              >
                                {item.itemQuantity}
                              </Typography>
                              <BiCoinStack
                                color={
                                  !isEmpty(selectedMenuDetails) &&
                                  item.id === selectedMenuDetails?.id
                                    ? colors.bright
                                    : colors.success
                                }
                                style={{ height: 14, width: 14, marginTop: 2 }}
                              />
                            </Box>
                          </Grid>
                        </Grid>

                        {/* <Grid>
                      <Typography fontSize={11.5} color={colors.linergrey}>Expiry :1:30 pm | 16/03/2023</Typography>

                    </Grid> */}
                      </Box>
                    </ListItem>
                  )
                )}
              </>
            ) : (
              <Box mt={2} justifyContent={"center"}>
                {showActive && isEmpty(menuData) ? (
                  <Typography>No Active Menu Items.</Typography>
                ) : showOutOfStock ? (
                  <Typography>No Out of stock items found.</Typography>
                ) : showHide ? (
                  <Typography>No Hidden Menu Items.</Typography>
                ) : (
                  <></>
                )}
              </Box>
            )}
          </List>
        ) : (
          <Box mt={2} justifyContent={"center"}>
            <Typography>Select a location to view the menu list</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
