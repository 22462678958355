import React, { useEffect, useState } from "react";
import { Box, Grid, Hidden, Typography } from "@mui/material";
import FHCard from "../../components/FHCard";
import { ToastContainer } from "react-toastify";
import { AddAndEditLocation } from "../../containers/Settings/AddAndEditLocation";
import { MenuItemList } from "../../containers/Settings/MenuItemList";
import { AddOrEditUser } from "../../containers/Settings/AddOrEditUser";
import { LocationCard } from "./LocationsCard";
import { LocationDetailsCard } from "./LocationDetailsCard";
import FHButton from "../../components/FHButton";
import { useSelector, useDispatch } from "react-redux";
import { map, isEmpty } from "lodash";
import { BiStats } from "react-icons/bi";
import FhActivityBar from "../../components/FHActivityBar";
import { FHDrawer } from "../../components/FHDrawer";

export default function DailyPlan() {
	const dispatch = useDispatch();
	const [popUp, setPopUp] = useState(false);
	const [addSelectedSetting, setAddSelectedSetting] = useState();
	const showActivities = useSelector(
		(state) => state.activityReducer.showActivities
	);
	const allLocations = useSelector(
		(state) => state.locationReducer.allLocations
	);

	useEffect(() => {
		if (!isEmpty(allLocations)) {
			let locIds = map(allLocations, (item) => item.d?.locId);
			dispatch({
				type: "GET_ACTIVITIES",
				payload: {
					locIds: [allLocations[0]?.d?.locId],
				},
			});
			dispatch({ type: "GET_COMMON_MENU" });
			dispatch({ type: "GET_CATEGORIES" });
			dispatch({ type: "GET_MASTER_MACHINES" });
			dispatch({ type: "GET_ALL_ADMIN_USERS" });
			dispatch({ type: "GET_LOCATIONS_MENU", payload: locIds });
			dispatch({ type: "GET_MACHINES_INFO", payload: locIds });
			dispatch({ type: "STORE_MENU_LOCATION", payload: allLocations[0] });
		}
	}, []);

	const handleActivities = () => {
		dispatch({ type: "SHOW_ACTIVITIES", payload: !showActivities });
	};
	const handleLocations = (data) => {
		dispatch({
			type: "GET_ACTIVITIES",
			payload: {
				locIds: ["common"],
			},
		});
		dispatch({ type: "STORE_MENU_LOCATION", payload: data });
	};

	return (
		<Box>
			<ToastContainer />
			<Grid container spacing={2}>
				<Grid item sm={6} xs={12}>
					<Grid container spacing={2}>
						<Grid item sm={12} xs={12}>
							<Typography fontSize={23} fontWeight={700}>
								Settings
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={6}>
					<Hidden mdDown>
						<Box mt={1} display={"flex"} justifyContent={"flex-end"}>
							{/* <Box mr={3}>
              <AddSettings handleSelectedAdd={handleSelectedAdd} />
            </Box> */}

							<Box mt={-0.8}>
								<FHButton
									name={<BiStats style={{ height: "30", width: "40" }} />}
									variant="outlined"
									// disabled={true}
									smallButton
									w_50
									viewActivityBtnsmall
									textWhite
									onClick={() => handleActivities()}
								/>
							</Box>
						</Box>
					</Hidden>
				</Grid>
				<Grid item md={4} sm={12} xs={12}>
					<LocationCard handleLocations={handleLocations} />
				</Grid>
				<Grid item md={4} sm={12} xs={12}>
					<LocationDetailsCard />
				</Grid>
				<Grid item md={4} sm={12} xs={12}>
					<FHCard fullLengthCard body={<MenuItemList />} />
				</Grid>
			</Grid>

			{showActivities && (
				<FhActivityBar
					handleActivities={handleActivities}
					activities={showActivities}
				/>
			)}
			{/* <FHDrawer
        open={popUp}
        onClose={() => {}}
        onOpen={() => {}}
        drawerBorder
        data={
          (addSelectedSetting === "Location" && (
            <AddAndEditLocation
              newLoction={true}
              onClose={() => setPopUp(false)}
            />
          )) ||
          (addSelectedSetting === "User" && (
            <AddOrEditUser onClose={() => setPopUp(false)} />
          ))
        }
      /> */}
		</Box>
	);
}
