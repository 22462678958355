import React, { useState } from "react";
import { JsonForms } from "@jsonforms/react";
import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { schema, uischema } from "./MenumasterJson";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Ajv from "ajv";
import FHButton from "../../../components/FHButton";
import colors from "../../../themes/colors";
import { createTheme, ThemeProvider } from "@mui/material";

const ajvInstance = new Ajv();

const customizedTheme = createTheme({
  jsonforms: { input: { backgroundColor: "pink" } },
});

export default function MenuMaster() {
  const navigate = useNavigate();
  const validate = ajvInstance.compile(schema);

  const [data, setdata] = useState({});
  const [properties, setproperties] = useState();
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate(properties);
    setValidated(true);
    if (isValid) {
      console.log("Form is valid", properties);
    } else {
      console.log("Form is not valid");
    }
  };

  const handleOnChange = ({ data }) => {
    setproperties(data);
    setValidated(true);
  };

  function handleCancel() {
    navigate("/");
  }
  return (
    <Box width="50%" p={2} border="1.5px solid grey" bgcolor="white">
      <form>
        <ThemeProvider theme={customizedTheme}>
          <JsonForms
            schema={schema}
            uischema={uischema}
            data={data}
            renderers={materialRenderers}
            cells={materialCells}
            onChange={({ data }) => handleOnChange({ data })}
          />
        </ThemeProvider>
      </form>
      <Box style={{ display: "flex", gap: "10px" }}>
        <FHButton
          name={"Cancel"}
          variant="outlined"
          color={colors.lighter}
          small
          w_40
          commonButtonStyle
          onClick={handleCancel}
          errors={validated ? validate.errors : undefined}
        />

        <FHButton
          name={"Save"}
          variant="outlined"
          color={colors.success}
          small
          w_40
          commonButtonStyle
          onClick={handleSubmit}
          errors={validated ? validate.errors : undefined}
        />
      </Box>
    </Box>
  );
}
