import * as yup from "yup";
import {
  createPromo,
  createPromoAtLocation,
  createPromoForUsers,
  createPromoForItems,
  doesPromoCodeExist,
} from "../../../services/promoServices";
import { map, filter, uniqBy, isEmpty } from "lodash";
import { generateRandomString } from "../../../services/utils";

export const validationSchema = yup.object({
  code: yup.string().required("code is required"),
  codeTerms: yup.string().required("code term is required"),
  codeText: yup.string().required("code text is required"),
  locationsIncluded: yup
    .array()
    .of(yup.object())
    .required("select atleast one location"),
  locMaxUses: yup.number(),
  itemsIncluded: yup
    .array()
    .of(yup.object())
    .required("select atleast one item"),
  itemMaxUses: yup.number(),
  discountType: yup.object().required("discountType is required"),
  discount: yup
    .number()
    .min(1, "Minimum discount discount value must be 1")
    .required("price is required"),
  minDiscount: yup
    .number()
    .min(1, "Minimum minDiscount value must be 1")
    .required("minDiscount is required"),
  maxDiscount: yup
    .number()
    .min(1, "Minimum maxDiscount value must be 1")
    .required("maxDiscount is required"),
  minOrderTotal: yup
    .number()
    .min(1, "Minimum minOrderTotal value must be 1")
    .required("min order total is required"),
  validFrom: yup.date().required("start date is required"),
  validTill: yup
    .date()
    .required("end date is required")
    .min(yup.ref("validFrom"), "End date cannot be earlier than start date"),
  redeemsPerDay: yup
    .number()
    .min(1, "Minimum redeemsPerDay value must be 1")
    .required("redeemsPerDay is required"),
  usersIncluded: yup.array().of(yup.object()),
  users: yup.string().required("users are required"),
  userMaxUses: yup.number().required("users are required"),
  allUsersMaxUses: yup.number(),
});

export const handleAddPromo = (formData, userInfo, formik, userIssuePromo) => {
  console.log("formik", formik);
  let locations = map(formData?.locationsIncluded, (item) => item.Name);
  let locMaxUses = filter(
    formData?.locationsIncluded,
    (item) => item.Name === "all"
  );
  let itemsList = map(formData?.itemsIncluded, (item) => item.name);
  let itemMaxUses = filter(
    formData?.itemsIncluded,
    (item) => item.name === "all"
  );

  //locationDetails
  let locDetails = [];
  !isEmpty(formData.locationsIncluded) &&
    map(formData.locationsIncluded, (item) => {
      item?.d?.locId && locDetails.push({ [item?.d?.locId]: "included" });
    });
  !isEmpty(formData.locationsExcluded) &&
    map(formData.locationsExcluded, (item) => {
      item?.d?.locId && locDetails.push({ [item?.d?.locId]: "excluded" });
    });
  let locationDetails = Object.assign({}, ...locDetails);

  //itemDetails
  let itmDetail = [];
  !isEmpty(formData.itemsIncluded) &&
    map(formData.itemsIncluded, (item) => {
      item?.id && itmDetail.push({ [item?.id]: "included" });
    });
  !isEmpty(formData.itemsExcluded) &&
    map(formData.itemsExcluded, (item) => {
      item?.id && itmDetail.push({ [item?.id]: "excluded" });
    });
  let itemDetails = Object.assign({}, ...itmDetail);

  //userDetails
  let userDetail = [];
  !isEmpty(formData.usersIncluded) &&
    map(formData.usersIncluded, (item) => {
      item?.uid && userDetail.push({ [item?.uid]: "included" });
    });
  !isEmpty(formData.usersExcluded) &&
    map(formData.usersExcluded, (item) => {
      item?.uid && userDetail.push({ [item?.uid]: "excluded" });
    });
  let userDetails = Object.assign({}, ...userDetail);

  let promoData = {
    code: formData?.code,
    codeTerms: formData?.codeTerms,
    codeText: formData?.codeText,
    createdBy: userInfo,
    discount: formData?.discount,
    hide: formData?.hide === ("true" || "false"),
    discountType: formData?.discountType?.name,
    endDate: formData?.validTill,
    locations: isEmpty(locationDetails) ? "all" : "specific",
    locationMaxUses: !isEmpty(locMaxUses)
      ? locMaxUses[0]?.locationMaxUses
      : 100,
    locationDetails: isEmpty(locationDetails) ? {} : { ...locationDetails },
    minDiscount: formData?.minDiscount,
    maxDiscount: formData?.maxDiscount,
    minOrderTotal: formData?.minOrderTotal,
    redeemsPerDay: formData?.redeemsPerDay,
    startDate: formData?.validFrom,
    status: formData?.status,
    users: formData?.users,
    userMaxUses: formData?.users === "all" ? formData?.allUsersMaxUses : null,
    userDetails: formData?.users === "all" ? {} : { ...userDetails },
    items: isEmpty(itemDetails) ? "all" : "specific",
    itemMaxUses: !isEmpty(itemMaxUses) ? itemMaxUses[0]?.itemMaxUses : 100,
    itemDetails: isEmpty(itemDetails) ? {} : { ...itemDetails },
  };

  if (userIssuePromo) {
    promoData.userIssuePromo = userIssuePromo;
  }

  let isCreatingDoc = true;
  let documentId = generateRandomString(isCreatingDoc, 20);
  console.log(promoData, "promo data");
  createPromo(documentId, promoData);

  //add promo at specific location
  if (promoData?.locations === "specific") {
    !isEmpty(formData?.locationsIncluded) &&
      map(formData?.locationsIncluded, (item) => {
        promoData = {
          ...promoData,
          locationMaxUses: item.locationMaxUses,
          userDetails: formData?.users === "all" ? {} : { ...userDetails },
          itemDetails: itemsList.includes("all") ? {} : { ...itemDetails },
          locationDetails: {
            promoCode: "included",
          },
        };
        let locId = item?.d?.locId;
        createPromoAtLocation(locId, documentId, promoData);
      });
    let excludedLocations = map(
      formData?.locationsExcluded,
      (item) => item.Name
    );
    !isEmpty(excludedLocations) &&
      !excludedLocations.includes("none") &&
      map(formData?.locationsExcluded, (item) => {
        promoData = {
          ...promoData,
          locationMaxUses: null,
          userDetails: formData?.users === "all" ? {} : { ...userDetails },
          itemDetails: itemsList.includes("all") ? {} : { ...itemDetails },
          locationDetails: {
            promoCode: "excluded",
          },
        };
        let locId = item?.d?.locId;
        createPromoAtLocation(locId, documentId, promoData);
      });
  }

  //add promo for specofoc user
  if (formData?.users === "specific") {
    !isEmpty(formData?.usersIncluded) &&
      map(formData?.usersIncluded, (item) => {
        promoData = {
          ...promoData,
          userMaxUses: item.userMaxUses,
          itemDetails: itemsList.includes("all") ? {} : { ...itemDetails },
          locationDetails: locations.includes("all")
            ? {}
            : { ...locationDetails },
          userDetails: {
            promoCode: "included",
          },
        };
        let userId = item.uid;
        createPromoForUsers(userId, documentId, promoData);
      });

    !isEmpty(formData?.usersExcluded) &&
      map(formData?.usersExcluded, (item) => {
        promoData = {
          ...promoData,
          userMaxUses: null,
          itemDetails: itemsList.includes("all") ? {} : { ...itemDetails },
          locationDetails: locations.includes("all")
            ? {}
            : { ...locationDetails },
          userDetails: {
            promoCode: "excluded",
          },
        };
        let userId = item?.uid;
        createPromoForUsers(userId, documentId, promoData);
      });
  }

  //add promocode for specific menuItem
  if (promoData?.items === "specific") {
    !isEmpty(formData?.itemsIncluded) &&
      map(formData?.itemsIncluded, (item) => {
        promoData = {
          ...promoData,
          itemMaxUses: item.itemMaxUses,
          locationDetails: locations.includes("all")
            ? {}
            : { ...locationDetails },
          userDetails: formData?.users === "all" ? {} : { ...userDetails },
          itemDetails: {
            promoCode: "included",
          },
        };
        let itemId = item.id;
        createPromoForItems(itemId, documentId, promoData);
      });
    let excludedItems = map(formData?.itemsExcluded, (item) => item.name);
    !isEmpty(formData?.itemsExcluded) &&
      !excludedItems.includes("none") &&
      map(formData?.itemsExcluded, (item) => {
        promoData = {
          ...promoData,
          itemMaxUses: null,
          locationDetails: locations.includes("all")
            ? {}
            : { ...locationDetails },
          userDetails: formData?.users === "all" ? {} : { ...userDetails },
          itemDetails: {
            promoCode: "excluded",
          },
        };
        let itemId = item?.id;
        createPromoForItems(itemId, documentId, promoData);
      });
  }

  formik.resetForm();
};
export const onDeleteIncLoc = (title, formik) => {
  console.log("title and formik", formik, title);
  if (title === "all") {
    formik.setFieldValue("locationsExcluded", []);
  }
  formik.setFieldValue(
    "locationsIncluded",
    filter(formik.values.locationsIncluded, (v) => v.Name !== title)
  );
};
export const onDeleteExcLoc = (title, formik) => {
  if (title === "none") {
    formik.setFieldValue("locationsIncluded", []);
  }
  formik.setFieldValue(
    "locationsExcluded",
    filter(formik.values.locationsExcluded, (v) => v.Name !== title)
  );
};
export const onDeleteIncItems = (title, formik) => {
  if (title === "all") {
    formik.setFieldValue("itemsExcluded", []);
  }
  formik.setFieldValue(
    "itemsIncluded",
    filter(formik.values.itemsIncluded, (v) => v.name !== title)
  );
};
export const onDeleteExcItems = (title, formik) => {
  if (title === "none") {
    formik.setFieldValue("itemsIncluded", []);
  }
  formik.setFieldValue(
    "itemsExcluded",
    filter(formik.values.itemsExcluded, (v) => v.name !== title)
  );
};

export const onDeleteIncUsers = (uid, formik) => {
  formik.setFieldValue(
    "usersIncluded",
    filter(formik.values.usersIncluded, (v) => v.uid !== uid)
  );
};

export const onDeleteExcUsers = (uid, formik) => {
  formik.setFieldValue(
    "usersExcluded",
    filter(formik.values.usersExcluded, (v) => v.uid !== uid)
  );
};
