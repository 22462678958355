import React from 'react'
import { makeStyles } from '@mui/styles'
import TextField from '@mui/material/TextField';
// import FormControlUnstyled from '@mui/base/FormControlUnstyled'
import Color from '../themes/colors'

const useStyles = makeStyles(() => ({
	cssOutlinedInput: {
		'&$cssFocused $notchedOutline': {
			border: `1px solid ${Color.dark} !important`,
		},
	},
	cssFocused: {},
	notchedOutline: {},
	HelperTextContained: {
		margin: 0,
		color:'red'
	},
	helperTextRoot: {
		color: 'red',
	},
	inputDense: {
		paddingRight: 2,
		paddingLeft: 2,
	},
}))

const InputBox = (props) => {
	const classes = useStyles()

	return (
		// <FormControlUnstyled fullWidth size='small'>
			<TextField
				variant={props.variant || 'outlined'}
				type={props.type || 'text'}
				size='small'
				InputLabelProps={{
					classes: {
						root: classes.cssLabel,
						focused: classes.cssFocused,
					},
				}}
				InputProps={{
					classes: {
						root: classes.cssOutlinedInput,
						focused: classes.cssFocused,
						notchedOutline: classes.notchedOutline,
						inputMarginDense: props.type === 'date' ? classes.inputDense : null,
					},
					endAdornment: props.endadornment || '',
					form: {
						autocomplete: 'off',
					},
				}}
				FormHelperTextProps={{
					classes: {
						contained: classes.HelperTextContained,
						root: classes.helperTextRoot,
					},
				}}
				className={props.className}
				disabled={props.disabled ? true : false}
				{...props}
			/>
		// </FormControlUnstyled>
	)
}

export default InputBox
