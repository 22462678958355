import { call } from "@redux-saga/core/effects";
import {
  getHistoryOrders,
  getIncartOrders,
  getItemsInCart,
  getOldOrdersByFilter,
} from "../services/orderServices";

export function* orderData(action) {
  try {
    yield call(getHistoryOrders, action);
  } catch (error) {
    console.log("Error", error);
  }
}

export function* orderIncart(action) {
  try {
    yield call(getIncartOrders, action);
  } catch (error) {
    console.log("Error", error);
  }
}

export function* itemsIncart(action) {
  try {
    yield call(getItemsInCart, action);
  } catch (error) {
    console.log("Error", error);
  }
}

export function* oldOrdersByFilter(action) {
  try {
    yield call(getOldOrdersByFilter, action);
  } catch (error) {
    console.log("Error",error);
  }
}
