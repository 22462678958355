import React, { useEffect, useState} from "react";
import {
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import colors from "../../themes/colors";
import { filter, map, isEmpty, uniqBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  datetemplate,
  convertTimestamp,
} from "../../services/dateTimeConversion";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";
import FHButton from "../../components/FHButton";
import AddIcon from "@mui/icons-material/Add";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import { FHSearchBar } from "../../components/FHSearchBar";
import { getSubArray, maxInArray } from "../../services/utils";

const customStyles = makeStyles(commonClasses);

export const PromoCode = () => {
  const common = customStyles(commonClasses);
  const dispatch = useDispatch();
  const mobileView = useMediaQuery("(max-width:920px)");

  let today = new Date();
  let newDate = Math.floor(today);
  let yesterday = new Date(newDate - 24 * 60 * 60 * 1000);

  const selectedLocation = useSelector(
    (state) => state.locationReducer.selectedLocation
  );
  const promos = useSelector((state) => state.promocodeReducer.promoCodes);
  const dateRange = useSelector((state) => state.orderReducer.dateRange);
  const addPromo = useSelector((state) => state.promocodeReducer.addPromo);
  const editPromo = useSelector((state) => state.promocodeReducer.editPromo);
  const locationPromos = useSelector(
    (state) => state.promocodeReducer.locationPromos
  );
  const userPromos = useSelector((state) => state.promocodeReducer.userPromos);
  const userRole = useSelector((state) => state.userReducer.userRole);

  const [searchQuery, setSearchQuery] = useState("");

  let locSpecPromos = filter(promos, (item) => item.locations === "specific");
  let locationPromo = map(locSpecPromos, (item) =>
    map(
      filter(
        item.locationPromos,
        (sItem) => sItem.locationId === selectedLocation?.d?.locId
      ),
      (r) => item
    )
  );

  let promocodes = [];
  if (selectedLocation?.Name === "All Locations" && isEmpty(searchQuery)) {
    promocodes = promos;
  } else if (
    selectedLocation?.Name !== "All Locations" &&
    isEmpty(searchQuery)
  ) {
    promocodes.push(...getSubArray(locationPromo));
    promocodes.push(...filter(promos, (item) => item?.locations === "all"));
  } else if (
    selectedLocation?.Name === "All Locations" &&
    !isEmpty(searchQuery)
  ) {
    promocodes.push(promos);
    promocodes.push(...(userPromos || []));
  } else if (
    selectedLocation?.Name !== "All Locations" &&
    !isEmpty(searchQuery)
  ) {
    promocodes.push(...getSubArray(locationPromo));
    promocodes.push(...(userPromos || []));
  }

  useEffect(() => {
    if (!isEmpty(searchQuery)) {
      let userId = searchQuery.trim();
      dispatch({ type: "GET_USER_PROMOS", payload: userId });
    }
  }, [searchQuery]);

  let filterByDate = (promos) => {
    let promoByDate = [];
    if (isEmpty(dateRange)) {
      return promos;
    } else {
      map(promos, (item) => {
        let startDate = convertTimestamp(
          dateRange?.startDateInSeconds
        )?.pattern2;
        let promoStartDate = convertTimestamp(
          item?.startDate?.seconds
        )?.pattern2;
        // if (
        //   (dateRange?.startDateInSeconds >= item?.startDate?.seconds &&
        //     dateRange?.endDateInSeconds <= item?.endDate?.seconds) ||
        //   (startDate === promoStartDate &&
        //     dateRange?.endDateInSeconds <= item?.endDate?.seconds)
        // ) {
        promoByDate.push(item);
        // }
      });
      return promoByDate;
    }
  };
  let prommo = uniqBy(promocodes, (item) => item.promoId);
  let promoData = filterByDate(prommo);

  const handleSearchQuery = (txt) => {
    setSearchQuery(txt);
  };

  const handleSeachClick = () => {
    if (!isEmpty(searchQuery)) {
      setSearchQuery("");
      dispatch({ type: "STORE_USER_PROMOS", payload: [] });
    }
    return;
  };

  const handleAddPromo = () => {
    dispatch({
      type: "SHOW_PROMO_DRAWER",
      payload: true,
    });
    dispatch({
      type: "ADD_PROMO_CODE",
      payload: true,
    });
  };

  const handleEditPromo = (selectedPromo) => {
    dispatch({
      type: "SHOW_PROMO_DRAWER",
      payload: true,
    });
    dispatch({
      type: "EDIT_PROMO_CODE",
      payload: true,
    });
    dispatch({
      type: "PROMO_SELECTED_TO_EDIT",
      payload: selectedPromo,
    });
  };

  return (
    <>
      <Box>
        <Box width="100%">
          <Grid
            container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography fontSize={23} fontWeight={700}>
                Promo
              </Typography>
            </Grid>
            {userRole === "SuperAdmin" && (
              <Grid item>
                <FHButton
                  name={"Add Code"}
                  variant="outlined"
                  smallButton
                  addCodeButton
                  endIcon={<AddIcon />}
                  onClick={handleAddPromo}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <Box>
          <FHSearchBar
            placeholder={"Search by userId..."}
            value={searchQuery || ""}
            onChange={(event) => handleSearchQuery(event.target.value)}
            handleSearch={handleSeachClick}
          />
        </Box>
        {!isEmpty(promoData) ? (
          <List
            className={mobileView ? common.listalign : common.listalignPromo}
          >
            {map(promoData, (data, i) => (
              <ListItem
                className={
                  data.status === "ACTIVE"
                    ? common.activePromo
                    : common.inactivePromo
                }
                key={i}

                // onClick={() => handleShowOrderDetails(u)}
              >
                <Box width="100%">
                  <Grid>
                    <Grid
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                        gap={3}
                      >
                        <Typography fontSize={15} fontWeight={600}>
                          {data.code}
                        </Typography>
                        {!isEmpty(data?.startDate) &&
                        !isEmpty(data?.endDate) ? (
                          <Typography
                            fontSize={12}
                            sx={{
                              display: {
                                sm: "block",
                                md: "none",
                                lg: "block",
                                xs: "none",
                              },
                            }}
                          >
                            {datetemplate(data?.startDate?.seconds)} -
                            {datetemplate(data?.endDate?.seconds)}
                          </Typography>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </Grid>

                      <Grid
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={2}
                      >
                        <Grid>
                          <Typography fontSize={12}>
                            {data.usedPromo}/{maxInArray(data)}
                          </Typography>
                        </Grid>
                        {userRole === "SuperAdmin" && (
                          <Box>
                            <DriveFileRenameOutlineRoundedIcon
                              fontSize="small"
                              className={common.promolistIt}
                              style={{ color: colors.success, padding: 2 }}
                              onClick={() => handleEditPromo(data)}
                            />
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      display: {
                        sm: "none",
                        md: "block",
                        lg: "none",
                        xs: "block",
                      },
                    }}
                  >
                    <Typography fontSize={10.5}>
                      {datetemplate(data?.startDate?.seconds)} -
                      {datetemplate(data?.endDate?.seconds)}
                    </Typography>
                  </Grid>
                  <Grid alignItems="start">
                    <Typography fontSize={11.5} color={colors.linergrey}>
                      {data.couponName}
                    </Typography>
                  </Grid>

                  <Grid>
                    <Typography
                      fontSize={11.5}
                      display="inline"
                      color={colors.linergrey}
                    >
                      created by :{" "}
                    </Typography>
                    <Typography display="inline" fontSize={12}>
                      {!isEmpty(data?.createdBy?.name)
                        ? data?.createdBy?.name
                        : data?.createdBy?.email}
                    </Typography>
                  </Grid>
                </Box>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>No Promocodes available in this location</Typography>
        )}
      </Box>
    </>
  );
};
