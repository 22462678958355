import {  call } from "redux-saga/effects";
import { locations, allLocations } from "../services/locationServices";

export function* getLocations(action) {
  try {
    yield call(locations, action);
  } catch (error) {
    console.log("locations error", error);
  }
}

export function* getAllLocations(action) {
  try {
    yield call(allLocations, action);
  } catch (error) {
    console.log("Usersaga getAllUsers Error", error);
  }
}
