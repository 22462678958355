//time conversions

export const getTime = function (data) {
  const givenTimestamp = data * 1000;
  if (data) {
    // Get the current timestamp in milliseconds
    const currentTimestamp = new Date().getTime();
    // Calculate the difference between the given timestamp and the current timestamp in milliseconds
    const difference = currentTimestamp - givenTimestamp;
    // Calculate the difference in minutes, hours, and days
    const minutes = Math.floor(difference / (1000 * 60));
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    // Display the difference in the appropriate unit
    let differenceString;
    if (days > 0) {
      const date = new Date(data * 1000);
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "short" }); // use toLocaleString() to get the month name
      const year = date.getFullYear();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const hourformat = hour >= 12 ? "PM" : "AM";
      differenceString = `${day}, ${month} ${year % 1000} ${
        hour % 12 || 12
      }:${minute.toString().padStart(2, "0")} ${hourformat} `;
    } else if (hours > 0) {
      differenceString = `${hours} hrs`;
    } else {
      differenceString = `${minutes} min`;
    }
    return differenceString;
  }
};

export const covertdateformate = function (data) {
  const date = new Date(data * 1000);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" }); // use toLocaleString() to get the month name
  const monthNo = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const hourformat = hour >= 12 ? "PM" : "AM";
  const newDate = new Date(date);
  let dates = {
    time: `${hour % 12 || 12}:${minute
      .toString()
      .padStart(2, "0")} ${hourformat}`,
    date: `${day}/${monthNo}/${year}`,
    dateMonth: `${day}/${monthNo}`,
    dateTime: `${month} ${day}, ${year} ${hour % 12 || 12}:${minute
      .toString()
      .padStart(2, "0")} ${hourformat} `,
    standardDate: newDate,
  };
  return dates;
};

export const datetemplate = function (data) {
  const date = new Date(data * 1000);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const convertTimestamp = (timestamp) => {
  // Convert Unix timestamp to milliseconds
  var date = new Date(timestamp * 1000);

  // Define months as an array for human-readable output
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get month, day, year, and time values from Date object
  var month = months[date.getMonth()];
  var day = date.getDate();
  var year = date.getFullYear();
  var hour = date.getHours();
  var minute = date.getMinutes();
  var ampm = hour >= 12 ? "PM" : "AM";
  if (hour > 12) {
    hour -= 12;
  } else if (hour === 0) {
    hour = 12;
  }
  if (minute < 10) {
    minute = "0" + minute;
  }
  let monthNum = months.indexOf(month) + 1;
  var dateString = {
    pattern1:
      month + " " + day + ", " + year + " " + hour + ":" + minute + " " + ampm,
    pattern2: day + "/" + monthNum + "/" + year,
  };
  return dateString;
};

export const getDates = (date) => {
  let dateStart = date;
  let startDate = Math.floor(dateStart);
  let dateEnd = new Date(startDate + 24 * 60 * 60 * 1000);
  dateStart.setHours(4, 0, 0, 0);
  dateEnd.setHours(3, 59, 0, 0);
  let startDateInSeconds = dateStart.getTime() / 1000;
  let endDateInSeconds = dateEnd.getTime() / 1000;
  let dateInfo = {
    date: `${dateStart.getDate()}/${
      dateStart.getMonth() + 1
    }/${dateStart.getFullYear()}`,
    startDate: dateStart,
    endDate: dateEnd,
    startDateInSeconds: startDateInSeconds,
    endDateInSeconds: endDateInSeconds,
  };
  return dateInfo;
};

export const getLastSevenDays = () => {
  let today = new Date();
  let newDate = Math.floor(today);
  let lastDay = new Date(newDate - 24 * 60 * 60 * 1000);
  const firstDay = new Date(lastDay.getTime() - 6 * 86400000);
  firstDay.setHours(4, 0, 0, 0);
  lastDay.setHours(23, 59, 0, 0);
  let startDateInSeconds = firstDay.getTime() / 1000;
  let endDateInSeconds = lastDay.getTime() / 1000;
  let dateInfo = {
    date: `${firstDay.getDate()}/${
      firstDay.getMonth() + 1
    }/${firstDay.getFullYear()} - ${lastDay.getDate()}/${
      lastDay.getMonth() + 1
    }/${lastDay.getFullYear()} `,
    startDate: firstDay,
    endDate: lastDay,
    startDateInSeconds: startDateInSeconds,
    endDateInSeconds: endDateInSeconds,
  };
  return dateInfo;
};

export function dailyPlanCalenderDates(date) {
  const currentDayOfWeek = date.getDay();
  const previousWeekStart = new Date(date);
  previousWeekStart.setDate(date.getDate() - currentDayOfWeek - 6);
  previousWeekStart.setHours(0, 0, 0, 0);

  const nextWeekEnd = new Date(previousWeekStart);
  nextWeekEnd.setDate(nextWeekEnd.getDate() + 19);
  nextWeekEnd.setHours(23, 59, 59, 999);

  return {
    lastWeekStart: previousWeekStart.toISOString().slice(0, 10),
    nextWeekEnd: nextWeekEnd.toISOString().slice(0, 10),
  };
}

export const getFirstAndLastDayOfWeek = (date) => {
  var firstDayOfWeek = new Date(date);
  firstDayOfWeek.setDate(date.getDate() - date.getDay());

  var lastDayOfWeek = new Date(date);
  lastDayOfWeek.setDate(date.getDate() + (6 - date.getDay()));

  return {
    firstDay: firstDayOfWeek,
    lastDay: lastDayOfWeek,
  };
};

export const getDailyPlanStartEndTime = (seconds) => {
  const date = new Date(seconds * 1000);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const secondsFormatted = String(date.getSeconds()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${secondsFormatted}`;

  return formattedDate;
};
