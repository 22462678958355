export const uischema = {
  type: "VerticalLayout",
  type: "Group",
  label: "Machine",
  elements: [
    {
      type: "Control",
      scope: "#/properties/name",
    },
    {
      type: "Control",
      scope: "#/properties/controlDevice",
    },

    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/id",
        },
        {
          type: "Control",
          scope: "#/properties/espPassword",
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/ipAddress",
    },
    {
      type: "Control",
      scope: "#/properties/machineType",
    },
    {
      type: "Control",
      scope: "#/properties/description",
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/publicKey",
        },
        {
          type: "Control",
          scope: "#/properties/secretKey",
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/configuration/properties/connectionType",
    },

    {
      type: "HorizontalLayout",
      elements: [
        ,
        {
          type: "Control",
          scope: "#/properties/configuration/properties/mqttBroker",
        },
        ,
        {
          type: "Control",
          scope: "#/properties/configuration/properties/mqttBrokerPort",
        },
      ],
    },

    {
      type: "HorizontalLayout",
      elements: [
        ,
        {
          type: "Control",
          scope: "#/properties/configuration/properties/wsIP",
        },
        ,
        {
          type: "Control",
          scope: "#/properties/configuration/properties/wsPort",
        },
      ],
    },
  ],
};

export const schema = {
  type: "object",
  properties: {
    configuration: {
      type: "object",
      properties: {
        connectionType: {
          type: "string",
        },
        mqttBroker: {
          type: "string",
        },
        mqttBrokerPort: {
          type: "string",
        },
        wsIP: {
          type: "string",
        },
        wsPort: {
          type: "string",
        },
      },
    },
    name: {
      type: "string",
    },
    controlDevice: {
      type: "string",
    },
    description: {
      type: "string",
    },
    espPassword: {
      type: "string",
    },
    id: {
      type: "string",
    },
    ipAddress: {
      type: "string",
    },
    machineType: {
      type: "string",
    },
    publicKey: {
      type: "string",
    },
    secretKey: {
      type: "string",
    },
  },
};
