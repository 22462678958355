import React, { useEffect, useState } from "react";
import {
	Grid,
	IconButton,
	List,
	ListItem,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import colors from "../../themes/colors";
import { filter, map, isEmpty, uniqBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
	sortOrders,
	getIcons,
	filterData,
	getOrdersByDateFilter,
	getOrderProcessKey
} from "../../services/utils";
import { getTime } from "../../services/dateTimeConversion";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";
import FHCard from "../../components/FHCard";
import FHButton from "../../components/FHButton";
import { StatusFilter } from "./StatusFilter";
import { FHSearchBar } from "../../components/FHSearchBar";
const customStyles = makeStyles(commonClasses);

export const OrdersCard = (props) => {
	const common = customStyles(commonClasses);
	const dispatch = useDispatch();
	const mobileView = useMediaQuery("(max-width:920px)");

	const [searchQuery, setSearchQuery] = useState("");

	const locations = useSelector((state) => state.locationReducer.locations);
	const historyData = useSelector((state) => state.orderReducer.historyData);
	const Incartdata = useSelector((state) => state.orderReducer.incartData);
	const cartData = useSelector((state) => state.orderReducer.cartData);
	const cardDetails = useSelector(
		(state) => state.orderReducer.detailsCardData
	);
	const selectedLocation = useSelector(
		(state) => state.locationReducer.selectedLocation
	);
	const oldOrders = useSelector((state) => state.orderReducer.loadOldOrders);
	const usersCard = useSelector((state) => state.dashboard.usersCard);
	const ordersDetailCard = useSelector(
		(state) => state.dashboard.orderDetailsCard
	);
	const ordersCard = useSelector((state) => state.dashboard.ordersCard);
	const selectedFilter = useSelector(
		(state) => state.orderReducer.selectedIcon
	);
	const dateRange = useSelector((state) => state.orderReducer.dateRange);
	// const ordersByDate = useSelector((state) => state.orderReducer.ordersByDate);
	const userDetailsCard = useSelector(
		(state) => state.dashboard.userDetailsCard
	);
	const [loading, setLoading] = useState(false);

	let ordersList = [];
	ordersList = [
		...(map(historyData, (item) => { return { ...item, historyData: true } }) || []),
		...(map(Incartdata, (item) => { return { ...item, incartData: true } }) || []),
		...(map(cartData, (item) => item) || []),
	];
	// console.log("ordersList", ordersList)
	let finalOrders = uniqBy(ordersList, (item) => item.cartId);

	let ordersByDate = [];
	if (!isEmpty(finalOrders) && !isEmpty(dateRange)) {
		ordersByDate = getOrdersByDateFilter(finalOrders, dateRange);
	}
	// useEffect(() => {
	//   if (!isEmpty(finalOrders)) {
	//     dispatch({
	//       type: "STORE_ORDERS_BY_DATE",
	//       // payload: getOrdersByDateFilter(finalOrders, dateRange),
	//     });
	//   }
	// }, [dateRange, historyData, Incartdata, cartData]);

	const matches = useMediaQuery("(min-width:1200px)");
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleShowOrderDetails = (data) => {
		dispatch({ type: "STORE_DETAILS_DATA", payload: data });
		dispatch({ type: "ORDERDETAILS", payload: true });

		if (mobileView) {
			dispatch({ type: "ORDERSCARD", payload: false });
		} else {
			if (ordersCard && usersCard && userDetailsCard) {
				dispatch({ type: "USERSCARD", payload: false });
				dispatch({ type: "USERDETAILS", payload: false });
			} else {
				dispatch({ type: "MENUCARD", payload: false });
				dispatch({ type: "PROMOCARD", payload: false });
				dispatch({ type: "USERSCARD", payload: false });
				dispatch({ type: "FEEDBACKCARD", payload: false });
				dispatch({ type: "CLEAR_SELECTED_USERDATA" });
			}
		}
	};

	const handleSearchQuery = (txt) => {
		setSearchQuery(txt);
		dispatch({ type: "CLEAR_OLD_ORDERS" });
	};

	///.................searchbar filter......................................//
	let ordersByLocation =
		selectedLocation?.Name === "All Locations"
			? map(ordersByDate, (item) => item)
			: filter(
				ordersByDate,
				(item) => item.locationId === selectedLocation?.d?.locId
			);

	const dataFiltered = filterData(
		searchQuery,
		sortOrders(getIcons(ordersByLocation))
	);

	// console.log('dataFiltered', dataFiltered);

	const filterBasedOnDropdown = (data) => {
		if (isEmpty(selectedFilter)) {
			return data;
		} else {
			let filtered = filter(data, (d) => {
				let key = getOrderProcessKey(d)
				if (selectedFilter === "Delivered" ||
					selectedFilter === "cancelled" ||
					selectedFilter === "Payment Failed" ||
					selectedFilter === "Refund request" ||
					selectedFilter === "Expired"
					? d.currentStatus === selectedFilter
					: selectedFilter === "Dispensing"
						? (d.currentStatus === "Payment Success" && d[key]?.status === true) ||
						(d.currentStatus === "COD" && d[key]?.status === true)
						: selectedFilter === "Waiting"
							? (d.currentStatus === "Payment Success" && key === undefined) || (d.currentStatus === "Payment Success" && d[key]?.status === false) || (d.currentStatus === "COD" && key === undefined) || (d.currentStatus === "COD" && d[key]?.status === false)
							: selectedFilter === "Incart"
								? !d.currentStatus
								: "") {
					return d
				}

			})
			return filtered
		}
	};
	//......................................................................//

	useEffect(() => {
		if (isEmpty(searchQuery)) {
			dispatch({ type: "CLEAR_OLD_ORDERS" });
			setLoadMore(false);
		}
	}, [searchQuery]);

	useEffect(() => {
		if (!isEmpty(oldOrders)) {
			setLoading(false);
		} else {
			setLoadMore(true);
		}
	}, [oldOrders]);

	const [loadMore, setLoadMore] = useState(true);
	const handleLoadMoreOrders = () => {
		let locIds = map(locations, (item) => item?.d?.locId);
		let locId = selectedLocation.d?.locId;
		if (!isEmpty(searchQuery)) {
			dispatch({
				type: "LOAD_OLD_ORDERS",
				payload: { locIds: locIds, searchQuery: searchQuery, date: dateRange },
			});
			setLoading(true);
		}
	};

	const handleSeachClick = () => {
		if (!isEmpty(searchQuery)) {
			setSearchQuery("");
		}
		return;
	};

	const ListItems = (props) => {
		return map(props.data, (u, i) => (
			<ListItem
				className={
					!isEmpty(cardDetails) && cardDetails?.cartId === u.cartId
						? matches
							? common.selectedOrderCard
							: common.selectedOrderCardsize
						: matches
							? common.listItemalign
							: common.listSizeItemalign
				}
				key={i}
				onClick={() => handleShowOrderDetails(u)}
			>
				<Box width="100%">
					<Grid
						container
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<Grid item alignItems="center">
							{u?.historyData && u?.txnId === '' || u?.incartData && u?.txnId === '' ? (
								<>
									{!isEmpty(u?.userDetails?.name) &&
										u?.userDetails?.name.length > 20 ? (
										<Box display='flex'>
											<Typography
												fontSize={15}
												color={
													!isEmpty(cardDetails) && cardDetails?.cartId === u.cartId
														? "white"
														: "black"
												}
											>
												{u?.userDetails?.name.slice(0, 20)}...
											</Typography>
											<Typography
												fontSize={12}
												color={
													!isEmpty(cardDetails) && cardDetails?.cartId === u.cartId
														? "white"
														: "#4DA731"
												}
											>
												[COD]
											</Typography>
										</Box>
									) : (
										<Box display='flex'>
											<Typography
												fontSize={15}
												color={
													!isEmpty(cardDetails) && cardDetails?.cartId === u.cartId
														? "white"
														: "black"
												}
											>
												{!isEmpty(u?.userDetails?.name)
													? u?.userDetails?.name
													: isEmpty(u?.userDetails?.name) &&
														!isEmpty(u?.userDetails?.mobileNo)
														? u?.userDetails?.mobileNo
														: "Anonymous"}
											</Typography>
											<Typography
												fontSize={12}
												ml={1}
												mt={0.2}
												color={
													!isEmpty(cardDetails) && cardDetails?.cartId === u.cartId
														? "white"
														: "#4DA731"
												}
											>
												[COD]
											</Typography>
										</Box>
									)}

									{selectedLocation.Name === "All Locations" ? (
										<Typography
											style={{ margin: 0 }}
											mb
											fontSize={12}
											color={
												!isEmpty(cardDetails) && cardDetails?.cartId === u.cartId
													? "white"
													: colors.LighterGrey
											}
										>
											{u.locationName}
										</Typography>
									) : (
										<></>
									)}
								</>
							) : (


								<>
									{!isEmpty(u?.userDetails?.name) &&
										u?.userDetails?.name.length > 20 ? (
										<Typography
											fontSize={15}
											color={
												!isEmpty(cardDetails) && cardDetails?.cartId === u.cartId
													? "white"
													: "black"
											}
										>
											{u?.userDetails?.name.slice(0, 20)}...
										</Typography>
									) : (
										<Typography
											fontSize={15}
											color={
												!isEmpty(cardDetails) && cardDetails?.cartId === u.cartId
													? "white"
													: "black"
											}
										>
											{!isEmpty(u?.userDetails?.name)
												? u?.userDetails?.name
												: isEmpty(u?.userDetails?.name) &&
													!isEmpty(u?.userDetails?.mobileNo)
													? u?.userDetails?.mobileNo
													: "Anonymous"}
										</Typography>
									)}

									{selectedLocation.Name === "All Locations" ? (
										<Typography
											style={{ margin: 0 }}
											mb
											fontSize={12}
											color={
												!isEmpty(cardDetails) && cardDetails?.cartId === u.cartId
													? "white"
													: colors.LighterGrey
											}
										>
											{u.locationName}
										</Typography>
									) : (
										<></>
									)}
								</>
							)}
						</Grid>

						<Grid display="flex" justifyContent="end" alignItems="center">
							<Typography
								fontSize="14px"
								sx={{
									display: {
										sm: "block",
										md: "none",
										lg: "block",
										xs: "none",
									},
									margin: 0,
								}}
								color={
									!isEmpty(cardDetails) && cardDetails?.cartId === u.cartId
										? "white"
										: "black"
								}
							// style={{margin:0}}
							>
								Rs.{u.orderTotal}
							</Typography>
							<Grid mt={0.5}>
								<IconButton color="primary">
									{cardDetails?.cartId === u.cartId ? (
										<u.icon.src style={{ color: "white" }} />
									) : (
										<u.icon.src style={{ color: u.icon.color }} />
									)}
								</IconButton>
							</Grid>
						</Grid>
					</Grid>

					<Box
						sx={{
							display: {
								sm: "none",
								md: "block",
								lg: "none",
								xs: "block",
							},
						}}
					>
						<Grid
							display="flex"
							justifyContent="space-between"
							width="100%"
							alignItems="center"
						>
							<Typography
								fontSize="16px"
								color={
									!isEmpty(cardDetails) && cardDetails?.cartId === u.cartId
										? "white"
										: "black"
								}
							>
								Rs.{u.orderTotal}
							</Typography>

							<Typography
								mr
								fontSize={10}
								color={
									!isEmpty(cardDetails) && cardDetails?.cartId === u.cartId
										? "white"
										: colors.LighterGrey
								}
							>
								{getTime(u.createdAt?.seconds)}
							</Typography>
						</Grid>
					</Box>

					<Grid
						container
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<Grid item>
							<Typography
								mb
								fontSize={12}
								color={
									!isEmpty(cardDetails) && cardDetails?.cartId === u.cartId
										? "white"
										: colors.LighterGrey
								}
							>
								{u.cartId}
							</Typography>
						</Grid>
						<Grid>
							<Typography
								mr
								mb
								sx={{
									display: {
										sm: "block",
										md: "none",
										lg: "block",
										xs: "none",
									},
								}}
								fontSize={10}
								color={
									!isEmpty(cardDetails) && cardDetails?.cartId === u.cartId
										? "white"
										: colors.LighterGrey
								}
							>
								{getTime(u.createdAt?.seconds)}
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</ListItem>
		));
	};

	return (
		<FHCard
			fullLengthCard
			body={
				<>
					<Box display="flex" justifyContent="space-between" p>
						<Typography fontSize={23} fontWeight={700}>
							Orders
						</Typography>

						<Box display="flex" justifyContent="flex-end">
							<StatusFilter />
						</Box>
					</Box>

					<Box>
						<FHSearchBar
							placeholder={"Search by orderId, name, mobile, userId..."}
							value={searchQuery || ""}
							onChange={(event) => handleSearchQuery(event.target.value)}
							handleSearch={handleSeachClick}
						/>
						{!isEmpty(selectedLocation) ? (
							<List className={common.listalign}>
								{!isEmpty(dataFiltered) ? (
									<ListItems data={filterBasedOnDropdown(dataFiltered)} />
								) : (
									<Typography>No orders</Typography>
								)}

								{!isEmpty(searchQuery) && isEmpty(oldOrders) && (
									<Box mt={2} mb={2}>
										<FHButton
											name={"Load more"}
											variant="outlined"
											w_50
											bgSuccess
											extraSmallButton
											onClick={() => handleLoadMoreOrders()}
										></FHButton>
									</Box>
								)}
								{!isEmpty(searchQuery) && loading && isEmpty(oldOrders) ? (
									<Typography color={"black"}>Loading...</Typography>
								) : !isEmpty(searchQuery) && loadMore && isEmpty(oldOrders) ? (
									<Typography color={"black"}>
										No older orders found.
									</Typography>
								) : !isEmpty(searchQuery) && loadMore && !isEmpty(oldOrders) ? (
									<>
										<Box mt={0.5} mb={0.5}>
											<Typography variant="body2" color={colors.success}>
												Older orders
											</Typography>
										</Box>
										<ListItems data={sortOrders(getIcons(oldOrders))} />
									</>
								) : (
									<></>
								)}
							</List>
						) : (
							<Box justifyContent={"centeter"}>
								<Typography>
									Select a location to view the order details
								</Typography>
							</Box>
						)}
					</Box>
				</>
			}
		/>
	);
};
