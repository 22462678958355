export const INITIAL_STATE = {
  dateRange: {},
  historyData: {},
  incartData: {},
  cartData: {},
	ordersByDate: {},
  loadOldOrders: {},
  detailsCardData: {},
  userOrderDetails: {},
  selectedIcon: {},
};

// TYPES
export const GET_ORDER_DATA = "GET_ORDER_DATA";
export const GET_INCART_DATA = "GET_INCART_DATA";
export const GET_CART_DATA = "GET_CART_DATA";
export const LOAD_OLD_ORDERS = "LOAD_OLD_ORDERS";

// ACTIONS
export const STORE_DATE_RANGE = "STORE_DATE_RANGE";
export const STORE_ORDER_DATA = "STORE_ORDER_DATA";
export const STORE_INCART_DATA = "STORE_INCART_DATA";
export const STORE_CART_DATA = "STORE_CART_DATA";
export const STORE_ORDERS_BY_DATE = "STORE_ORDERS_BY_DATE";
export const STORE_DETAILS_DATA = "STORE_DETAILS_DATA";
export const CLEAR_DETAILS_CARD = "CLEAR_DETAILS_CARD";
export const STORE_LOAD_MORE_ORDERS = "STORE_LOAD_MORE_ORDERS";
export const STORE_USER_ORDER_DETAILS = "STORE_USER_ORDER_DETAILS";
export const CLEAR_USER_ORDER_DETAILS = "CLEAR_USER_ORDER_DETAILS";
export const CLEAR_OLD_ORDERS = "CLEAR_OLD_ORDERS";
export const SHOW_SELECTED_ICON_DATA = "SHOW_SELECTED_ICON_DATA";
export const CLEAR_SELECTED_ICON_DATA = "CLEAR_SELECTED_ICON_DATA";
export const CLEAR_ORDER_DATA = "CLEAR_ORDER_DATA";
export const CLEAR_INCART_DATA = "CLEAR_INCART_DATA";
export const CLEAR_CART_DATA = "CLEAR_CART_DATA";
export const LOGOUT = "LOGOUT";

export const orderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STORE_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      };
    case STORE_ORDER_DATA:
      return {
        ...state,
        historyData: action.payload,
      };
    case STORE_INCART_DATA:
      return {
        ...state,
        incartData: action.payload,
      };
    case STORE_CART_DATA:
      return {
        ...state,
        cartData: action.payload,
      };
		case STORE_ORDERS_BY_DATE:
			return {
				...state,
				ordersByDate: action.payload,
			}
    case STORE_DETAILS_DATA:
      return {
        ...state,
        detailsCardData: action.payload,
      };
    case CLEAR_DETAILS_CARD:
      return {
        ...state,
        detailsCardData: {},
      };
    case STORE_LOAD_MORE_ORDERS:
      return {
        ...state,
        loadOldOrders: action.payload,
      };
    case STORE_USER_ORDER_DETAILS:
      return {
        ...state,
        userOrderDetails: action.payload,
      };
    case SHOW_SELECTED_ICON_DATA:
      return {
        ...state,
        selectedIcon: action.payload,
      };
    case CLEAR_SELECTED_ICON_DATA:
      return {
        ...state,
        selectedIcon: {},
      };
    case CLEAR_USER_ORDER_DETAILS:
      return {
        ...state,
        userOrderDetails: action.payload,
      };
    case CLEAR_OLD_ORDERS:
      return {
        ...state,
        loadOldOrders: {},
      };
    case CLEAR_ORDER_DATA:
      return {
        ...state,
        historyData: {},
      };
    case CLEAR_INCART_DATA:
      return {
        ...state,
        incartData: {},
      };
    case CLEAR_CART_DATA:
      return {
        ...state,
        cartData: {},
      };
    case LOGOUT:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};
