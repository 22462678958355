import { get } from "lodash";

const INITIAL_STATE = {
	botManagementStatus: [],
	machineSelected: {},
	statusLastUpdated: ''
};

//Types
export const GET_BOT_MANAGEMENT_STATUS = 'GET_BOT_MANAGEMENT_STATUS'

//actions
export const STORE_BOT_MANAGEMENT_STATUS = 'STORE_BOT_MANAGEMENT_STATUS'
export const STORE_MACHINE_SELECTED = 'STORE_MACHINE_SELECTED'
export const STATUS_LAST_UPDATED = 'STATUS_LAST_UPDATED'
export const CLEAR_UPADTES = "CLEAR_UPADTES";
export const LOGOUT = "LOGOUT";

export const botManagementReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case STORE_BOT_MANAGEMENT_STATUS:
			let bStatusRes = action.payload;
			let botStat = [];
			bStatusRes.forEach((docx) => {
				botStat = {
					...state.botManagementStatus,
					...botStat,
					[`${docx.locationId}_${docx.machineName}_${docx.subSystem}`]: {
						...docx,
					},
				};
			});
			return {
				...state,
				botManagementStatus: botStat,
			};
		case STORE_MACHINE_SELECTED:
			return {
				...state,
				machineSelected: action.payload
			}
		case STATUS_LAST_UPDATED:
			return {
				...state,
				statusLastUpdated: action.payload
			}
		case LOGOUT:
			return {
				...INITIAL_STATE,
			};
		default:
			return state;
	}
};
