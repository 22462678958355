import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import HistoryIcon from "@mui/icons-material/History";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import colors from "../themes/colors";
import { map, get, isEmpty, uniqBy, groupBy } from "lodash";
//   ICONS
import { toast } from "react-toastify";
import VeryBad from "../assets/feedbackIcons/veryBad.png";
import Bad from "../assets/feedbackIcons/bad.png";
import Average from "../assets/feedbackIcons/average.png";
import Good from "../assets/feedbackIcons/good.png";
import Excellent from "../assets/feedbackIcons/excellent.png";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// import { IoWarningOutline } from "@react-icons/all-files/io5/IoWarningOutline";
// import { RiRefund2Fill } from "@react-icons/all-files/ri/RiRefund2Fill";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { BiLoader } from "react-icons/bi";
import RotateRightOutlinedIcon from "@mui/icons-material/RotateRightOutlined";
import TimerOffOutlinedIcon from "@mui/icons-material/TimerOffOutlined";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

export const sortOrders = (orders) => {
  let sort = orders.sort(function (a, b) {
    return b.createdAt?.seconds - a.createdAt?.seconds;
  });
  return sort;
};

export const toTitleCase = (str) => {
  // Split the addressCity string based on capital letters
  return str.replace(/\w\S*/g, function (txt) {
    let title = txt.charAt(0).toUpperCase() + txt.substr(1);
    const splitWords = title
      .replace(/([A-Z])/g, " $1")
      .trim()
      .split(" ");
    return splitWords.join(" ");
  });
};

export const maxInArray = (item) => {
  let arry = [
    item?.itemMaxUses,
    item?.locationMaxUses,
    item?.userMaxUses,
    item.locationPromos[0]?.locationMaxUses,
    item.userPromos[0]?.userMaxUses,
    item.itemPromos[0]?.itemMaxUses,
  ];
  let filteredArray = arry.filter((item) => item !== (null || undefined));
  return Math.max(...filteredArray);
};

export const sortItemExpiryTime = (item) => {
  let sort = item.sort(function (a, b) {
    return a.expiryAt?.seconds - b.expiryAt?.seconds;
  });
  return sort;
};

export const getOrdersByDateFilter = (data, dateRange) => {
  // console.log("filter function inputs", data, dateRange);
  let startDate = new Date(dateRange?.startDate).getTime() / 1000;
  let endDate = new Date(dateRange?.endDate).getTime() / 1000;
  let ordersByDate = [];
  map(data, (item) => {
    if (
      item.createdAt?.seconds > startDate &&
      item.createdAt?.seconds < endDate
    ) {
      ordersByDate.push(item);
    }
    return ordersByDate;
  });
  // console.log('ordersByDate filter return', startDate, endDate, ordersByDate);
  return ordersByDate;
};

export const getOrderProcessKey = (object) => {
  const keysWithUnderscore = Object.keys(object).filter((key) =>
    key.includes("underProcess_")
  );
  return keysWithUnderscore[0];
};

export const getStatusBar = (data, orderPaymentDone, ordersDispensing) => {
  // console.log("statusbar", data);
  // const currentStatus = groupBy(ordersByLocation, (u) => u.currentStatus);

  const result = [
    {
      lengths: data.undefined?.length ? data.undefined?.length : 0,
      icon: ShoppingCartOutlinedIcon,
      color: colors.incartblue,
      status: "Incart",
    },
    {
      lengths: orderPaymentDone?.length ? orderPaymentDone?.length : 0,
      icon: HourglassTopIcon,
      color: colors.waitingOrder,
      status: "Waiting",
    },
    {
      lengths: ordersDispensing?.length ? ordersDispensing?.length : 0,
      icon: RotateRightOutlinedIcon,
      color: colors.pendingorder,
      status: "Dispensing",
    },
    {
      lengths: data.Delivered?.length ? data.Delivered?.length : 0,
      icon: CheckCircleOutlineSharpIcon,
      color: colors.success,
      status: "Delivered",
    },
    {
      lengths: data.Expired?.length ? data.Expired?.length : 0,
      icon: TimerOffOutlinedIcon,
      color: colors.pendingorder,
      status: "Expired",
    },
    {
      lengths: data["Payment Failed"]?.length
        ? data["Payment Failed"]?.length
        : 0,
      icon: WarningAmberRoundedIcon,
      color: colors.cancelled,
      status: "Payment Failed",
    },
    {
      lengths: data.canceled?.length ? data.canceled?.length : 0,
      icon: HighlightOffIcon,
      color: colors.cancelled,
      status: "cancelled",
    },

    {
      lengths: data["Refund request"]?.length
        ? data["Refund request"]?.length
        : 0,
      icon: CurrencyExchangeIcon,
      color: colors.success,
      status: "Refund Request",
    },
  ];
  return result;
};

export const filterDropdown = [
  {
    id: 1,
    name: "In cart",
    status: "Incart",
    icon: ShoppingCartOutlinedIcon,
    color: colors.incartblue,
  },
  {
    id: 2,
    name: "Waiting",
    status: "Waiting",
    icon: HourglassTopIcon,
    color: colors.waitingOrder,
  },
  {
    id: 3,
    name: "Dispensing",
    status: "Dispensing",
    icon: RotateRightOutlinedIcon,
    color: colors.pendingorder,
  },
  {
    id: 4,
    name: "Deliverd",
    status: "Delivered",
    icon: CheckCircleOutlineSharpIcon,
    color: colors.success,
  },
  {
    id: 5,
    name: "Order Expired",
    status: "Expired",
    icon: TimerOffOutlinedIcon,
    color: colors.pendingorder,
  },
  {
    id: 6,
    name: "Failed",
    status: "Payment Failed",
    icon: WarningAmberRoundedIcon,
    color: colors.cancelled,
  },
  {
    id: 7,
    name: "Canceled",
    status: "cancelled",
    icon: HighlightOffIcon,
    color: colors.cancelled,
  },
  {
    id: 8,
    name: "Refunded",
    status: "Refund request",
    icon: CurrencyExchangeIcon,
    color: colors.success,
  },
];

export const locDataStracture = {
  Name: "",
  actions: "",
  buzStatus: "",
  d: {
    locId: "",
    locationDetails: {
      actions: "",
      addressFiled1: "",
      addressFiled2: "",
      city: "",
      contactNumber: "",
      description: "",
      email: "",
      formattedAddress: "",
      franchiseContactNum: "",
      franchiseDesc: "",
      franchiseEmail: "",
      franchiseName: "",
      immediateActionNeeded: "",
      issues: "",
      latitude: "",
      locationType: "",
      longitude: "",
      outletCategory: "",
      outletName: "",
      place_id: "",
      qrCode: "",
      state: "",
      toEarnLoyalityPoint: "",
      websiteURL: "",
      zip: "",
    },
  },
  systemUserEmail: "",
  g: "",
  geoHash: "",
  immediateActionNeeded: "",
  issues: "",
  l: { _latitude: "", _longitude: "" },
  nearby: "",
  outletStatus: "",
  paymentMethods: [
    {
      name: "",
      status: "",
    },
    {
      name: "",
      status: "",
    },
  ],
  promoCodes: true,
  toEarnLoyalityPoint: 0,
  workingHours: [
    {
      closes: "10:00 PM",
      day: "Sunday",
      opens: "9:30 AM",
    },
    {
      closes: "10:00 PM",
      day: "Monday",
      opens: "10:00 AM",
    },
    {
      closes: "10:00 PM",
      day: "Tuesday",
      opens: "10:00 AM",
    },
    {
      closes: "10:00 PM",
      day: "Wednesday",
      opens: "10:00 AM",
    },
    {
      closes: "10:00 PM",
      day: "Thursday",
      opens: "10:00 AM",
    },
    {
      closes: "10:00 PM",
      day: "Friday",
      opens: "10:00 AM",
    },
    {
      closes: "10:00 PM",
      day: "Saturday",
      opens: "10:00 AM",
    },
  ],
};

export const filterDropdownActivities = [
  {
    type: "Activity Type",
    activities: [
      {
        id: 1,
        name: "Updates",
        status: "updates",
        icon: ShoppingCartIcon,
        color: colors.incartblue,
      },
      {
        id: 8,
        name: "Txn",
        status: "txn",
        icon: HourglassTopIcon,
        color: colors.waitingOrder,
      },
      {
        id: 9,
        name: "Maintenance",
        status: "maintenance",
        icon: HourglassTopIcon,
        color: colors.waitingOrder,
      },
    ],
  },
  {
    type: "Initiator",
    activities: [
      {
        id: 2,
        name: "Bot",
        status: "fhBot",
        icon: RotateLeftIcon,
        color: colors.pendingorder,
      },
      {
        id: 3,
        name: "User",
        status: "user",
        icon: CheckCircleOutlineSharpIcon,
        color: colors.success,
      },
      {
        id: 4,
        name: "Super Admin",
        status: "superAdmin",
        icon: HighlightOffIcon,
        color: colors.cancelled,
      },
      {
        id: 5,
        name: "Admin",
        status: "admin",
        icon: WarningAmberRoundedIcon,
        color: colors.cancelled,
      },
      {
        id: 6,
        name: "LOT",
        status: "lot",
        icon: CurrencyExchangeIcon,
        color: colors.success,
      },
      {
        id: 7,
        name: "Vendor",
        status: "vendor",
        icon: HistoryIcon,
        color: colors.pendingorder,
      },
    ],
  },
  {
    type: "Log Level",
    activities: [
      {
        id: 10,
        name: "Info",
        status: "info",
        icon: HistoryIcon,
        color: colors.pendingorder,
      },
      {
        id: 8,
        name: "Error",
        status: "error",
        icon: HourglassTopIcon,
        color: colors.waitingOrder,
      },
      {
        id: 9,
        name: "Warning",
        status: "warning",
        icon: HourglassTopIcon,
        color: colors.waitingOrder,
      },
    ],
  },
];

export const getIcons = (data) => {
  let dataWithIcons = map(data, (item) => {
    let statusIcon = {
      Delivered: CheckCircleOutlineSharpIcon,
      cancelled: HighlightOffIcon,
      Expired: TimerOffOutlinedIcon,
      "Payment Failed": WarningAmberRoundedIcon,
      "Refund request": CurrencyExchangeIcon,
    };

    let statusColor = {
      Delivered: colors.success,
      cancelled: colors.cancelled,
      Expired: colors.pendingorder,
      "Payment Failed": colors.cancelled,
      "Refund request": colors.success,
    };
    let currentStatus = get(item, "currentStatus");
    let icon = { src: ShoppingCartOutlinedIcon, color: colors.incartblue };
    if (statusIcon[currentStatus]) {
      icon = {
        src: statusIcon[currentStatus],
        color: statusColor[currentStatus],
      };
    } else if (
      item?.underProcess_TCVB?.status === true ||
      item?.underProcess_ICVB?.status === true
    ) {
      icon = { src: RotateRightOutlinedIcon, color: colors.pendingorder };
    } else if (currentStatus === "Payment Success" || currentStatus === "COD") {
      icon = { src: HourglassTopIcon, color: colors.waitingOrder };
    }
    return {
      ...item,
      icon,
    };
  });
  return dataWithIcons;
};

export const getIconsusers = (data) => {
  let dataWithIcons = map(data, (item) => {
    let key = getOrderProcessKey(item);
    let statusIcon = {
      Delivered: CheckCircleOutlineSharpIcon,
      cancelled: HighlightOffIcon,
      Expired: TimerOffOutlinedIcon,
      "Payment Failed": WarningAmberRoundedIcon,
      "Refund request": CurrencyExchangeIcon,
      "No Orders": "nothing",
    };

    let statusColor = {
      Delivered: colors.success,
      cancelled: colors.cancelled,
      Expired: colors.pendingorder,
      "Payment Failed": colors.cancelled,
      "Refund request": colors.success,
    };

    let icon = { src: ShoppingCartOutlinedIcon, color: colors.incartblue };
    if (statusIcon[item.currentStatus]) {
      icon = {
        src: statusIcon[item.currentStatus],
        color: statusColor[item.currentStatus],
      };
    } else if (item[key]?.status === true) {
      icon = { src: RotateRightOutlinedIcon, color: colors.pendingorder };
    } else if (
      (item.currentStatus === "Payment Success" && key === undefined) ||
      (item.currentStatus === "Payment Success" &&
        item[key]?.status === false) ||
      (item.currentStatus === "COD" && key === undefined) ||
      (item.currentStatus === "COD" && item[key]?.status === false)
    ) {
      icon = { src: HourglassTopIcon, color: colors.waitingOrder };
    }
    return {
      ...item,
      icon,
    };
  });
  return dataWithIcons;
};

export const statusColos = (data) => {
  const result =
    data === "Delivered"
      ? colors.success
      : data === "Payment Failed"
      ? colors.cancelled
      : data === "COD"
      ? colors.waitingOrder
      : data === "Refund request"
      ? colors.success
      : data === "Expired"
      ? colors.pendingorder
      : colors.incartblue;
  return result;
};

export const getFeedbackIcons = (data) => {
  let dataWithFeedback = map(data, (item) => {
    let feedbackColors = {
      1: colors.lighterGrey,
      2: colors.danger,
      3: colors.lightYellow,
      4: colors.good,
      5: colors.success,
    };
    let feebackIcons = {
      1: VeryBad,
      2: Bad,
      3: Average,
      4: Good,
      5: Excellent,
    };
    let feedbackText = {
      1: "VeryBad",
      2: "Bad",
      3: "Average",
      4: "Good",
      5: "Excellent",
    };
    let feedback = {};
    if (item.feedbackInfo) {
      if (feebackIcons[item.feedbackInfo?.experienceRating]) {
        feedback = {
          feedbackText: feedbackText[item.feedbackInfo?.experienceRating],
          icon: feebackIcons[item.feedbackinfo?.experienceRating],
          color: feedbackColors[item.feedbackInfo?.experienceRating],
        };
      }
      return {
        ...item,
        feedback,
      };
    } else {
      if (feebackIcons[item.rating]) {
        feedback = {
          feedbackText: feedbackText[item.rating],
          icon: feebackIcons[item.rating],
          color: feedbackColors[item.rating],
        };
      }
      return {
        ...item,
        feedback,
      };
    }
  });
  return dataWithFeedback;
};

// CONVERT FIRST LETTER TO UPPERCASE.....

export const TextCapalize = (data) => {
  const arr = data;
  const str = arr.split(" ");

  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1).toLowerCase();
  }
  const name = str.join(" ");
  return name;

  // const name = data;
  // const capitalized = name ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase() : "";
  // return capitalized
};

export const filterData = (query, data) => {
  // console.log("query and data", query, data);
  if (isEmpty(query)) {
    return data;
  } else {
    return data.filter(
      (d) =>
        (d.cartId && d.cartId.trim() === query.trim()) ||
        (d?.userDetails?.name &&
          d?.userDetails?.name.toLowerCase().trim() ===
            query.toLowerCase().trim()) ||
        (d?.userDetails?.mobileNo &&
          d?.userDetails?.mobileNo.trim() === query.trim()) ||
        (d.userId && d.userId.trim() === query.trim())
    );
  }
};

export const generateRandomString = (isCreatingDoc, length) => {
  let result = "";
  let characters;
  if (isCreatingDoc) {
    characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  } else {
    characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  }
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  console.log("generate code", result);
  return result;
};

export const getSubArray = (data) => {
  let finalData = [];
  data.map(function (subarray) {
    return subarray.map(function (item) {
      return finalData.push(item);
    });
  });
  return finalData;
};

export const getAllOrdersTogether = (historyData, Incartdata, cartData) => {
  let ordersList = [];
  ordersList = [
    ...(map(historyData, (item) => item) || []),
    ...(map(Incartdata, (item) => item) || []),
    ...(map(cartData, (item) => item) || []),
  ];
  let finalOrders = uniqBy(ordersList, (item) => item.cartId);

  return finalOrders;
};

export const timing = (time) => {
  const defaultTime = time; // Default time in HH:MM:SS format
  const [hours, minutes, seconds] = defaultTime.split(":");

  const toDate = new Date();
  toDate.setHours(hours);
  toDate.setMinutes(minutes);
  toDate.setSeconds(seconds);
  toDate.setMilliseconds(0);
  return toDate;
};
let ids = {};

export const notify = (msg) => {
  toast(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

export function msToTime(ms) {
  let seconds = (ms / 1000).toFixed(1);
  let minutes = (ms / (1000 * 60)).toFixed(1);
  let hours = (ms / (1000 * 60 * 60)).toFixed(1);
  let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
  if (seconds < 60) return seconds + " Sec";
  else if (minutes < 60) return minutes + " Min";
  else if (hours < 24) return hours + " Hrs";
  else return days + " Days";
}
