import { call, take, select, actionChannel } from "redux-saga/effects";
import { addDoc, collection } from "firebase/firestore";
import { activities } from "../services/activityServices";
import { db } from "../App";
import { buffers } from "redux-saga";

export function* getActivities(action) {
  try {
    yield call(activities, action);
  } catch (error) {
    console.log("Error", error);
  }
}

export function* watchActivities() {
  const chan = yield actionChannel("LOG_ACTIVITY", buffers.sliding(50));
  while (true) {
    const action = yield take(chan);
    yield call(processActivity, action);
  }
}

function* processActivity(action) {
  const selectedLocation = yield select(
    (state) => state.locationReducer.selectedLocation
  );
  addDoc(
    collection(
      db,
      "activities",
      action.payload?.metaData?.locId
        ? action.payload?.metaData?.locId
        : selectedLocation?.d?.locId || "common",
      "_"
    ),
    action.payload
  );
}
