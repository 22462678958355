import {
  collection,
  getDocs,
  getDoc,
  where,
  query,
  doc,
  onSnapshot,
  limit,
  orderBy,
} from "firebase/firestore";
import { db } from "../App";
import store from "../stores";
import { map } from "lodash";
import { TextCapalize } from "./utils";
import { getLastSevenDays } from "./dateTimeConversion";

let today = new Date();
let lastweek = getLastSevenDays(today);

export const getHistoryOrders = (data) => {
  let locIds = data.payload.locId;
  let startDateRange = new Date(lastweek?.startDate);
  let historyData = [];
  let docData = {};

  for (let locId of locIds) {
    const q = query(
      collection(db, `/orders/${locId}/history`),
      where("createdAt", ">", startDateRange),
      orderBy("createdAt")
    );
    onSnapshot(q, (querySnapshot) => {
      let snapData = querySnapshot.docs;
      snapData.forEach((docx) => {
        docData = docx.data();
        historyData = {
          ...historyData,
          [docx.id]: { ...docx.data(), locationId: locId },
        };
        // if (docData?.rating) {
				// 	userTodaysOrderFeedbacks(docData)
        // }
      });
      store.dispatch({ type: "STORE_ORDER_DATA", payload: historyData });
    });
  }
};

export const getIncartOrders = (data) => {
  let locIds = data.payload.locId;
  let startDateRange = new Date(lastweek?.startDate);
  let incartData = [];
  // console.log("incart", startDateRange);

  for (let locId of locIds) {
    const q = query(
      collection(db, `/orders/${locId}/incart`),
      where("createdAt", ">", startDateRange),
      orderBy("createdAt")
    );
    onSnapshot(q, (querySnapshot) => {
      let snapData = querySnapshot.docs;
      snapData.forEach((docx) => {
        incartData = {
          ...incartData,
          [docx.id]: { ...docx.data(), locationId: locId },
        };
      });
      store.dispatch({ type: "STORE_INCART_DATA", payload: incartData });
    });
  }
};

export const getItemsInCart = (data) => {
  let locIds = data.payload.locId;
  let startDateRange = new Date(lastweek?.startDate);
  let cartData = [];
  // console.log("cart", startDateRange, endDateRange);

  for (let locId of locIds) {
    const q = query(
      collection(db, `/cart/${locId}/incart`),
      where("createdAt", ">", startDateRange),
      orderBy("createdAt")
    );
    onSnapshot(q, (querySnapshot) => {
      let snapData = querySnapshot.docs;
      snapData.forEach((docx) => {
        cartData = {
          ...cartData,
          [docx.id]: { ...docx.data(), locationId: locId },
        };
      });
      store.dispatch({ type: "STORE_CART_DATA", payload: cartData });
    });
  }
};

export const getOldOrdersByFilter = async (data) => {
  let searchQuery = data.payload.searchQuery;
  let locIds = data.payload.locIds;
  let startDateRange = new Date(lastweek?.startDate);
  let fData = [];

  //orderId
  const searchqueryFilter = async () => {
    for (let locId of locIds) {
      const orderRef = query(
        doc(db, `/orders/${locId}/history/${searchQuery.trim()}`)
      );
      const querySnapForOrderId = await getDoc(orderRef);
      if (querySnapForOrderId.exists()) {
        let oldOrders = querySnapForOrderId.data();
        store.dispatch({
          type: "STORE_LOAD_MORE_ORDERS",
          payload: [oldOrders],
        });
      } else {
        console.log("no doc for the searched orderId");
      }
    }

    //name
    for (let locId of locIds) {
      const nameRef = query(
        collection(db, `/orders/${locId}/history`),
        where("userDetails.name", "==", TextCapalize(searchQuery.trim())),
        where("createdAt", "<", startDateRange),
        limit(50)
      );
      const querySnapForName = await getDocs(nameRef);
      if (querySnapForName.docs.length !== 0) {
        map(querySnapForName.docs, (doc) => {
          fData = { ...fData, [doc.id]: doc.data() };
        });
        store.dispatch({
          type: "STORE_LOAD_MORE_ORDERS",
          payload: fData,
        });
      } else {
        console.log("no doc for the searched name");
      }
    }

    //mobileNo
    for (let locId of locIds) {
      const mobileNoRef = query(
        collection(db, `/orders/${locId}/history`),
        where("userDetails.mobileNo", "==", searchQuery.trim()),
        where("createdAt", "<", startDateRange),
        limit(50)
      );
      const querySnapForNum = await getDocs(mobileNoRef);
      if (querySnapForNum.docs.length !== 0) {
        map(querySnapForNum.docs, (doc) => {
          fData = { ...fData, [doc.id]: doc.data() };
        });
        store.dispatch({
          type: "STORE_LOAD_MORE_ORDERS",
          payload: fData,
        });
      } else {
        console.log("no doc for the searched mobile");
      }
    }

    //userId
    for (let locId of locIds) {
      const userIdRef = query(
        collection(db, `/orders/${locId}/history`),
        where("userId", "==", searchQuery.trim()),
        where("createdAt", "<", startDateRange),
        limit(50)
      );
      const querySnapForUserId = await getDocs(userIdRef);
      if (querySnapForUserId.docs.length !== 0) {
        map(querySnapForUserId.docs, (doc) => {
          fData = { ...fData, [doc.id]: doc.data() };
        });
        store.dispatch({
          type: "STORE_LOAD_MORE_ORDERS",
          payload: fData,
        });
      } else {
        console.log("no doc for the searched userId");
      }
    }
  };

  return searchqueryFilter();
};
