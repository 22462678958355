import Colors from "./colors";
import Graphics from "../assets/images/bgGraphics.png";
import colors from "./colors";

const style = {
  smarginRight: {
    marginRight: 10,
  },
  smarginLeft: {
    marginLeft: 10,
  },
  bgMain: {
    backgroundColor: Colors.main,
  },
  bgDisabled: {
    backgroundColor: Colors.disabled,
  },
  bgPrimary: {
    backgroundColor: Colors.primary,
  },
  bgSecondary: {
    backgroundColor: Colors.secondary,
  },
  bgWhite: {
    backgroundColor: Colors.white,
  },
  bgDanger: {
    backgroundColor: Colors.danger,
  },
  bgSuccess: {
    backgroundColor: Colors.success,
  },
  bgDark: {
    backgroundColor: Colors.dark,
  },
  bgLight: {
    backgroundColor: Colors.light,
  },
  bgInfo: {
    backgroundColor: Colors.info,
  },
  bgExLight: {
    backgroundColor: Colors.exLight,
  },
  bgCancelled: {
    backgroundColor: Colors.cancelled,
  },
  bgLightGrey: {
    background: Colors.lightGrey,
  },
  textPrimary: {
    color: Colors.primary,
  },
  textSecondary: {
    color: Colors.secondary,
  },
  textWhite: {
    color: Colors.bright,
  },
  textRed: {
    color: Colors.cancelled,
  },
  textBlack: {
    color: Colors.darkGrey,
  },
  textDanger: {
    color: Colors.danger,
  },
  textSuccess: {
    color: Colors.success,
  },
  textDark: {
    color: Colors.dark,
  },
  textLight: {
    color: Colors.light,
  },
  textRatings: {
    color: Colors.ratings,
  },
  textTLight: {
    color: Colors.tLight,
  },
  inactive: {
    backgroundColor: Colors.inactive,
  },
  white: {
    color: Colors.white,
  },

  textCenter: {
    textAlign: "center !important",
  },
  padding_0: {
    padding: 0,
  },
  h_100: {
    height: "100%",
  },
  vh_100: {
    height: "100vh",
  },
  mh_100: {
    maxHeight: "100%",
  },
  w_30: {
    width: "30%",
  },
  w_50: {
    width: "50%",
  },
  w_100: {
    width: "100%",
  },
  mw_100: {
    maxWidth: "100%",
  },
  textL: {
    fontSize: 24,
  },
  textXs: {
    fontSize: 10,
  },
  textSm: {
    fontSize: 12,
  },
  textM: {
    fontSize: 18,
  },
  textCapitalize: {
    textTransform: "capitalize",
  },
  textLowerCase: {
    textTransform: "lowercase",
  },
  flex_row_sb: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flex_sb: {
    display: "flex",
    justifyContent: "space-between",
  },
  flex_start: {
    display: "flex",
    justifyContent: "start",
  },
  flex_end: {
    display: "flex",
    justifyContent: "end",
  },
  flex_evenly: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  flex_column_sb: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  flex_column_sa: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  flex_row_start: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },
  flex_row_center: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  flex_column_se: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  flex_row_se: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  mousecursor: { cursor: "pointer" },
  borderRadiusXs: {
    borderRadius: 5,
  },
  borderRadiusM: {
    borderRadius: 10,
  },
  commonButtonStyle: {
    color: "#ffffff",
    background: Colors.darkGreen,
    border: "1px solid solid",
    borderRadius: 5,
  },
  buttonStyle: {
    color: "#ffffff",
    border: "1px solid solid",
    borderRadius: 5,
  },
  button: {
    borderRadius: 20,
    height: 40, // added new
    textTransform: "capitalize",
    paddingLeft: 30,
    paddingRight: 30,
    color: Colors.dark,
  },
  smallButton: {
    borderRadius: 5,
    height: 30,
    textTransform: "capitalize",
    paddingLeft: 14,
    paddingRight: 14,
    color: Colors.dark,
  },
	smallRedButton: {
    borderRadius: 5,
    height: 30,
    textTransform: "capitalize",
    paddingLeft: 14,
    paddingRight: 14,
    color: '#fffff',
		background:'#CE0202'
  },
  smallAddButton: {
    borderRadius: 5,
    height: 30,
    textTransform: "capitalize",
    paddingLeft: 30,
    paddingRight: 30,
    color: Colors.dark,
  },
  extraSmallButton: {
    borderRadius: 5,
    minHeight: 20,
    maxHeight: "auto",
    // width:50,
    textTransform: "capitalize",
    paddingLeft: 10,
    paddingRight: 10,
  },

  smallButtonGreen: {
    borderRadius: 5,
    height: 30,
    textTransform: "capitalize",
    paddingLeft: 14,
    paddingRight: 14,
    color: "white",
    backgroundColor: Colors.success,
  },
  centerAlign: {
    justifyContent: "center",
  },
  mainbody: {
    height: "100%",
    margin: 0,
  },
  rootLogin: {
    backgroundImage: `url(${Graphics})`,
    minHeight: "100vh",
    height: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "sticky",
    backgroundColor: Colors.success,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loginCard: {
    margin: "auto",
    width: "60%",
    minWidth: 300,
    maxWidth: 400,
    // marginTop: 32,
    backgroundColor: Colors.bright,
    maxHeight: "90vh",
    borderRadius: 20,

    // padding: 10px;
  },
  menuHeading: {
    fontWeight: 2000,
    fontSize: 90,
    lineHeight: 60,
    color: Colors.darkGrey,
  },
  autoComplete: {
    backgroundColor: "#ffffff",
    border: "1px, solod, #ffffff",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
  },
  // viewActivityBtn: {
  //   width: 160,
  //   height: 50,
  //   background: "#CE0000",
  //   border: "4px solid #FFFFFF",
  //   boxShadow: "0px 1px 1.5px rgba(0, 0, 0, 0.25)",
  //   borderRadius: 10,
  // },

  viewActivityBtnsmall: {
    width: 50,
    height: 50,
    background: "#CE0000",
    border: "4px solid #FFFFFF",
    boxShadow: "0px 1px 1.5px rgba(0, 0, 0, 0.25)",
    borderRadius: 25,
  },
  miniCard: {
    height: "38vh",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
  },
  fullLengthCard: {
    height: "81vh",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
  },
  cards: {
    width: "100%",
    height: "80vh",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
  },

  Griddisplay: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "5px",
  },

  Filteralign: {
    backgroundColor: "#F0F4FF",
    display: "flex",
    width: "23%",
    height: "25px",
    justifyContent: "space-around",
    alignItems: "center",
    "border-radius": "5px",
  },
  // listItemalign: {
  //   backgroundColor: "#F0F4FF",
  //   height: 55,
  //   marginBottom: 8,
  //   borderRadius: 5,
  //   width: "100%",
  // },
  selectedOrderCard: {
    backgroundColor: "#4DA731",
    height: 55,
    borderRadius: 5,
    boxShadow: "0px 1px 1.5px rgba(0, 0, 0, 0.25)",
    width: "100%",
  },

  listfeedbackItemalign: {
    backgroundColor: "#F0F4FF",
    // height: 130,
    height: "auto",
    maxHeight: "auto",
    marginBottom: 6,
    borderRadius: 5,
    padding: 5,
    width: "100%",
    cursor: "pointer",
  },

  selectedfeedbackCard: {
    backgroundColor: "#4DA731",
    // height: 130,
    // maxHeight: "auto",
    height: "auto",
    borderRadius: 5,
    marginBottom: 6,
    marginTop: 5,
    padding: 5,
    boxShadow: "0px 1px 1.5px rgba(0, 0, 0, 0.25)",
    width: "100%",
    cursor: "pointer",
  },

  viewActivityBtn: {
    width: 180,
    height: 50,
    background: "#CE0000",
    border: "4px solid #FFFFFF",
    boxShadow: "0px 1px 1.5px rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
  },
  listalign: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    overflow: "scroll",
    gap: "8px",
    height: "62vh",
  },
  list50align: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    overflow: "scroll",
    gap: "8px",
    height: "33vh",
  },

  height18Vh: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    overflow: "scroll",
    gap: "8px",
    height: "18vh",
  },
  feedbacklistalign: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    overflow: "scroll",
    // gap: "8px",
    height: "72vh",
  },
  listalignUser: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    overflow: "scroll",
    gap: "8px",
    height: "21vh",
  },
  selectedListalignUser: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    overflow: "scroll",
    gap: "8px",
    height: "62vh",
  },

  activityList: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    overflow: "scroll",
    gap: "8px",
    height: "95vh",
  },
  listalignPromo: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    overflow: "scroll",
    gap: "8px",
    height: "22vh",
  },
  listItemalign: {
    backgroundColor: "#F0F4FF",
    height: 64,
    borderRadius: 5,
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#dfdadc",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
  },
  selectedOrderCardsize: {
    backgroundColor: "#4DA731",
    height: 80,
    borderRadius: 5,
    boxShadow: "0px 1px 1.5px rgba(0, 0, 0, 0.25)",
    width: "100%",
  },

  listSizeItemalign: {
    backgroundColor: "#F0F4FF",
    height: 80,
    borderRadius: 5,
    width: "100%",
    marginBottom: 5,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#dfdadc",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
  },

  flexItemalign: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  flexSizeItemalign: {
    display: "flex",
    flexDirection: "column",
    gap: "1px",
  },
  flexgap1: {
    gap: "10px",
  },
  flexgap2: {
    gap: "1px",
  },
  viewnamebutton: {
    height: "40px",
    minWidth: "30%",
    maxWidth: "auto",
    color: "black",
    backgroundColor: Colors.bright,
    borderRadius: "1px",
    textAlign: "start",
  },
  viewnamebutton1: {
    width: "180px",
    height: "30px",
    color: "black",
    backgroundColor: Colors.success,
    borderRadius: "5px",
    justifyContent: "flex-center",
  },
  detailsCard: {
    overflow: "scroll",
    height: "78vh",
    // backgroundColor: Colors.main,
  },
  userOldOrders: {
    width: "100%",
    position: "relative",
    overflow: "scroll",
    gap: "8px",
    height: "38vh",
  },
  menuList: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    overflow: "scroll",
    gap: "8px",
    height: "24vh",
  },
  feedbackList: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    overflow: "scroll",
    gap: "8px",
    height: "27vh",
  },
  menulistItemalign: {
    height: 45,
    borderRadius: 5,
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#dfdadc",
      color: "black",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
  },
  accordionHeader: {
    backgroundColor: "red",
  },
  activeHighlight: {
    borderRight: "10px solid #4DA731",
  },
  outOfStockHighlight: {
    borderRight: "10px solid #CE0000",
  },
  hiddenItemHighlight: {
    borderRight: "10px solid #787A80",
  },
  activeTabButton: {
    background: "#4DA731",
    boxShadow: "0px 1px 1.5px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    color: "#ffff",
  },
  outofStockTabButton: {
    background: "#F0F4FF",
    border: "1px solid #CE0000",
    borderRadius: 5,
  },
  menuTabButton: {
    background: "#F0F4FF",
    boxShadow: "0px 1px 1.5px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
  },
  inactivePromo: {
    backgroundColor: "#F2F2F2",
    boxShadow: "0px 1px 1.5px rgba(0, 0, 0, 0.25)",
    height: 70,
    borderRadius: 5,
    width: "100%",
  },
  activePromo: {
    backgroundColor: Colors.main,
    height: 70,
    borderRadius: 5,
    width: "100%",
  },
  promolistIt: {
    cursor: "pointer",
    padding: "1px",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#dfdadc",
      color: "black",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
  },

  activeCodeButton: {
    width: 115,
    height: 31,
    background: "#4DA731",
    boxShadow: "0px 1px 1.5px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    color: "#ffff",
  },
  addCodeButton: {
    width: 115,
    height: 31,
    background: "#F0F4FF",
    boxShadow: "0px 1px 1.5px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
  },
  userlistItemalign: {
    backgroundColor: "#F0F4FF",
    height: 85,
    borderRadius: 5,
    width: "100%",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#dfdadc",
      color: "black",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
  },
  selectedUser: {
    backgroundColor: colors.success,
    height: 85,
    borderRadius: 5,
    width: "100%",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#dfdadc",
      color: "black",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
  },
  selectedMenuBorder: {
    border: `2px solid ${Colors.cancelled}`,
  },
  mediaIcon: {
    // marginRight: 47,
    // marginRight:10,
    position: "sticky",
  },
  image: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    height: 170,
    width: 170,
    justifyContent: "center",
    backgroundColor: "#D9D9D9",
    borderRadius: 5,
    marginTop: -35,
  },
  feedbacklistItemalign: {
    backgroundColor: "#F0F4FF",
    height: 110,
    borderRadius: 5,
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#dfdadc",
      color: "black",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
  },
  userCartActive: {
    backgroundColor: Colors.lighter,
  },
  datePicker: {
    width: "50%",
    color: "green",
    border: "1px solid red",
  },
  drawerBorder: {
    border: "4px solid #ACACAC",
    borderRadius: 8,
  },
};

export default style;
