import { map, uniqBy, isEmpty } from "lodash";
import _ from "lodash";
import {
	collection,
	getDocs,
	getDoc,
	where,
	query,
	setDoc,
	doc,
	updateDoc,
	onSnapshot,
	limit,
	orderBy,
} from "firebase/firestore";
import {
	getAuth,
	signInWithEmailAndPassword,
	fetchSignInMethodsForEmail,
} from "firebase/auth";
import { db } from "../App";
import store from "../stores";
import { TextCapalize } from "./utils";
import { getLastSevenDays } from "./dateTimeConversion";

let today = new Date();
let lastweek = getLastSevenDays(today);

export const userLogin = async (user) => {
	const email = user.loginInfo.email;
	const password = user.loginInfo.password;
	console.log("username, password", email, password);
	const auth = getAuth();
	const getUser = signInWithEmailAndPassword(auth, email, password)
		.then((user) => {
			// console.log(`the userCredentials: ${JSON.stringify(user)}`);
			console.log("user data", user);
			return user;
		})
		.catch(function (error) {
			// Handle Errors here.
			var errorCode = error.code;
			var errorMessage = error.message;
			store.dispatch({ type: "LOGIN_FAILED", payload: errorMessage });
			console.log("firebase auth error. Login failed", errorCode, errorMessage);
			return false;
		});

	return getUser;
};

export const admins = async (userId) => {
	let docId = userId;

	const getAdmin = await getDoc(query(doc(db, "admins", docId)));
	if (getAdmin.data()) {
		let res = getAdmin.data();
		console.log("Admin info", res);
		let locIds = [];
		let role = res?.role;
		locIds = res?.locIds;
		if (res?.status === "Active" && !isEmpty(locIds)) {
			store.dispatch({ type: "LOGIN_SUCCESS", payload: res });
			store.dispatch({ type: "STORE_USER_ROLE", payload: role });
			store.dispatch({ type: "CLEAR_ERROR_ONSUCCESS", payload: "" });
			console.log("response", res, locIds);
			store.dispatch({ type: "GET_LOCATIONS", payload: locIds });
		} else if (res?.status === "Active" && isEmpty(locIds)) {
			store.dispatch({ type: "LOGIN_FAILED", payload: "No locations are added for the user" });
		} else {
			store.dispatch({ type: "LOGIN_FAILED", payload: "User not active" });
		}
	}
};

export const selectedUser = async (action) => {
	let docId = action.payload;

	const res = onSnapshot(doc(db, "users", docId), (querySnapshot) => {
		if (querySnapshot) {
			let res = querySnapshot.data();
			store.dispatch({ type: "STORE_SELECTED_USER", payload: res });
		}
	});
	return () => res();
};

export const userFeedback = async (data) => {
	let userId = data.payload.docId;
	let orderId = data.payload.orderId;
	console.log("userId and orderId", data, userId, orderId);
	const q = query(doc(db, `/users/${userId}/feedbacks/${orderId}`));
	const userFeedback = onSnapshot(q, (querySnapshot) => {
		if (querySnapshot) {
			let res = querySnapshot.data();
			store.dispatch({
				type: "STORE_USER_FEEDBACK",
				payload: res,
			});
		}
	});
	return () => userFeedback();
};

export const userOldOrders = async (data) => {
	let uId = data.payload;

	const q = query(collection(db, `/users/${uId}/orders`), limit(50));
	const getcart = onSnapshot(q, (querySnapshot) => {
		const cartData = map(querySnapshot.docs, (doc) => {
			return doc.data();
		});
		// console.log("cartData", cartData);
		store.dispatch({ type: "STORE_USER_OLD_ORDERS", payload: cartData });
	});
	return () => getcart();
};

export const newRegisteredUsers = async () => {
	let startDateRange = new Date(lastweek?.startDate);
	const q = query(
		collection(db, `users`),
		where("createdAt", ">", startDateRange),
		orderBy("createdAt")
	);

	const registeredData = onSnapshot(q, (querySnapshot) => {
		const userdata = map(querySnapshot.docs, (doc) => {
			return doc.data();
		});
		store.dispatch({ type: "STORE_NEWREGISTERED_USERS", payload: userdata });
	});
	return () => registeredData();
};

// export const userTodaysOrderFeedbacks = async (data) => {
//   const dataList = data.payload;
//   let feedbackRes = [];
//   for (let item of dataList) {
//     let q = query(doc(db, `/users/${item?.userId}/feedbacks/${item?.cartId}`));
//     onSnapshot(q, (querySnapshot) => {
//       feedbackRes = {
//         ...feedbackRes,
//         [item?.cartId]: { ...item, feedbackInfo: querySnapshot.data() },
//       };
//       store.dispatch({
//         type: "STORE_FEEDBACKS",
//         payload: feedbackRes,
//       });
//     });
//   }
// };

export const userTodaysOrderFeedbacks = async (data) => {
	const dataList = data;
	let feedbackRes = [];
	let q = query(
		doc(db, `/users/${dataList?.userId}/feedbacks/${dataList?.cartId}`)
	);
	onSnapshot(q, (querySnapshot) => {
		feedbackRes = {
			...dataList,
			feedbackInfo: querySnapshot.data(),
		};
		store.dispatch({
			type: "STORE_FEEDBACKS",
			payload: feedbackRes,
		});
	});
};

export const allUsers = async (data) => {
	let userList = data.payload;
	let user = uniqBy(userList, (user) => user.userId);
	let uids = map(user, (item) => item.userId);
	let startDateRange = new Date(lastweek?.startDate);
	let snapData = [];
	// console.log("allUsers api", uids, uids.length, startDateRange);

	for (let id of uids) {
		const userDoc = query(doc(db, `/users/${id}`));
		let res = await getDoc(userDoc);
		snapData = { ...snapData, [res.data()?.uid]: res.data() };
		// console.log("snapData", snapData);
	}
	store.dispatch({ type: "STORE_ALL_USERS", payload: snapData });
};

export const allAdminUsers = async () => {
	const q = query(collection(db, `admins`), orderBy("createdAt", "desc"));

	const usersData = onSnapshot(q, (querySnapshot) => {
		const userdata = map(querySnapshot.docs, (doc) => {
			return { ...doc.data(), id: doc.id };
		});
		store.dispatch({ type: "STORE_ALL_ADMIN_USERS", payload: userdata });
	});
	// return () => usersData();
};

export const userSearchData = async (data) => {
	let searchQuery = data.payload.searchQuery;
	let userData = [];

	const nameRef = query(
		collection(db, "users"),
		where("name", "==", TextCapalize(searchQuery.trim()))
	);
	const querySnapForName = await getDocs(nameRef);

	const mobileNoRef = query(
		collection(db, "users"),
		where("mobileNo", "==", searchQuery.trim())
	);
	const querySnapForNum = await getDocs(mobileNoRef);

	const emailRef = query(
		collection(db, "users"),
		where("email", "==", searchQuery.trim())
	);
	const querySnapForEmail = await getDocs(emailRef);

	const userIdRef = query(
		collection(db, "users"),
		where("uid", "==", searchQuery.trim())
	);
	const querySnapForUserId = await getDocs(userIdRef);

	if (querySnapForName.docs.length !== 0) {
		map(querySnapForName.docs, (doc) => {
			userData = { ...userData, [doc.id]: doc.data() };
		});
		store.dispatch({
			type: "STORE_USER_SEARCH_DATA",
			payload: userData,
		});
		store.dispatch({
			type: "STORE_USER_SEARCH_DATA",
			payload: userData,
		});
	} else if (querySnapForNum.docs.length !== 0) {
		map(querySnapForNum.docs, (doc) => {
			userData = { ...userData, [doc.id]: doc.data() };
		});
		store.dispatch({
			type: "STORE_USER_SEARCH_DATA",
			payload: userData,
		});
	} else if (querySnapForEmail.docs.length !== 0) {
		map(querySnapForEmail.docs, (doc) => {
			userData = { ...userData, [doc.id]: doc.data() };
		});
		store.dispatch({
			type: "STORE_USER_SEARCH_DATA",
			payload: userData,
		});
	} else if (querySnapForUserId.docs.length !== 0) {
		map(querySnapForUserId.docs, (doc) => {
			userData = { ...userData, [doc.id]: doc.data() };
		});
		store.dispatch({
			type: "STORE_USER_SEARCH_DATA",
			payload: userData,
		});
	} else {
		console.log("no user found");
	}
};

export const getPromoUserInc = async (userIds) => {
	console.log("userIncDrop api", userIds);
	let incUserData = [];
	let newData = [];
	for (let userId of userIds) {
		const q = query(collection(db, `users`), where("uid", "==", userId));
		// onSnapshot(q, (querySnapshot) => {
		let incSnap = await getDocs(q);
		let snapData = incSnap.docs;
		snapData.forEach((docx) => {
			incUserData = {
				...incUserData,
				[docx.id]: docx.data(),
			};
			newData.push({ ...incUserData, [docx.id]: docx.data() });
			// console.log("incUserData: ", incUserData);
		});
		store.dispatch({
			type: "STORE_USERS_INCLUDED",
			payload: incUserData,
		});
		// });
	}
};

export const getPromoUserExc = async (userIds) => {
	console.log("userExcDrop api", userIds);

	let excUserdata = [];
	for (let userId of userIds) {
		const q = query(collection(db, `users`), where("uid", "==", userId));
		onSnapshot(q, (querySnapshot) => {
			let snapData = querySnapshot.docs;
			snapData.forEach((docx) => {
				excUserdata = {
					...excUserdata,
					[docx.id]: docx.data(),
				};
			});
			store.dispatch({
				type: "STORE_USERS_EXCLUDED",
				payload: excUserdata,
			});
		});
	}
};

export const updateAdmin = async (data) => {
	console.log("Update Admin api", data);
	let adminUid = data?.uid;
	if (adminUid) {
		await updateDoc(doc(db, `/admins/${adminUid}`), data, {
			merge: true,
		});
	}
};

const toTitleCase = (str) => {
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
};
export const createAdmin = async (data) => {
	console.log("create Admin api", data);
	let adminUid = data?.uid;
	if (adminUid) {
		await setDoc(
			doc(db, `/admins/${adminUid}`),
			{ ...data, createdAt: today },
			{
				merge: true,
			}
		);
		await setDoc(
			doc(db, `/users/${adminUid}`),
			{
				mobileNo: "",
				createdAt: today,
				email: data.email,
				name: toTitleCase(data?.name),
				ageGroup: "",
				uid: adminUid,
			},
			{
				merge: true,
			}
		);
		return;
	}
};

export const checkEmailExists = async (email) => {
	const auth = getAuth();
	try {
		const methods = await fetchSignInMethodsForEmail(auth, email);
		if (methods.length > 0) {
			console.log("In auth email id found");
			const q = query(collection(db, "users"), where("email", "==", email));
			let snapshot = await getDocs(q);
			console.log(
				"UID:",
				snapshot?.docs?.map((doc) => ({ ...doc.data(), id: doc.id }))
			);
		}
		return methods.length > 0; // Return true if email exists, false otherwise
	} catch (error) {
		console.error("Error checking email existence:", error);
		return false;
	}
};

// export const createUser = async (number, item) => {
// 	await setDoc(doc(db, "users", number), item, { merge: true });
// };
