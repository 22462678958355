import React, { useState } from "react";
import { Box, Button, Popover, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { isEmpty, map, filter } from "lodash";
import colors from "../themes/colors";
import VideocamIcon from "@mui/icons-material/Videocam";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { videoCallUpdates } from "../services/botServices";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import commonClasses from "../themes/commonClasses";
import { makeStyles } from "@mui/styles";

const customStyles = makeStyles(commonClasses);

const commonStyles = {
  m: 1,
  color: colors.linegrey,
  width: "10rem",
  height: "1rem",
};

export const VideoCallButton = () => {
  const common = customStyles(commonClasses);

  const user = useSelector((state) => state.userReducer.user);
  const selectedLocation = useSelector(
    (state) => state.locationReducer.selectedLocation
  );
  const machines = useSelector((state) => state.botReducer.machinesInfo);

  const [selectedMachine, setSelectedMachine] = useState(null);
  const [machineOnCall, setMachineOnCall] = useState(false);
  const [linkIsEmpty, setLinkIsEmpty] = useState(false);

  let machineList = [];
  if (
    !isEmpty(machines) &&
    !isEmpty(selectedLocation) &&
    selectedLocation.Name === "All Locations"
  ) {
    machineList = machines;
  } else {
    machineList = filter(
      machines,
      (item) => item.locId === selectedLocation?.d?.locId
    );
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleVideoCall = (data) => {
    console.log("data", data);
    setSelectedMachine(data);
    if (
      !data.videoInfo?.appOnCall &&
      data.videoInfo?.link &&
      !isEmpty(data.videoInfo?.link)
    ) {
      setMachineOnCall(false);
      setLinkIsEmpty(false);
      console.log("machine not on call");
      let locId = data.locId;
      let machineId = data.id;
      let userName = user?.name;
      let uid = user?.uid;
      let appOnCall = data.videoInfo?.appOnCall;
      let link = data.videoInfo?.link;
      window.open(
        data?.videoInfo?.link,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400"
      );
      videoCallUpdates(locId, machineId, uid, userName, appOnCall, link).then(
        (res) => {
          if (data?.videoInfo?.link) {
            console.log("call details updated");
          }
        }
      );
    } else if (data.videoInfo?.appOnCall && !isEmpty(data.videoInfo?.link)) {
      setLinkIsEmpty(false);
      setMachineOnCall(true);
      console.log("machine on call");
    } else if (!data.videoInfo?.appOnCall && isEmpty(data.videoInfo?.link)) {
      setMachineOnCall(false);
      setLinkIsEmpty(true);
      console.log("link is not available");
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMachineOnCall(false);
    setLinkIsEmpty(false);
  };

  return (
    <Box>
      <Box>
        <Button
          style={{
            backgroundColor: "#CE0000",
            color: "white",
            borderRadius: "25px",
            border: "3px white solid",
            height: 50,
            width: 50,
          }}
          variant="contained"
          // disabled={true}
          onClick={handleClick}
        >
          <VideocamIcon />
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            sx: { border: `2px solid ${colors.success}`, padding: 2 },
          }}
        >
          {map(machineList, (u, idx) => (
            <Box key={idx}>
              <Box
                display="flex"
                justifyContent="center"
                key={idx}
                p={1}
                sx={{
                  ...commonStyles,
                  marginBottom: 1,
                  width: 200,
                  cursor: "pointer",

                  backgroundColor: colors.success,
                  borderRadius: 2,
                }}
                alignItems="center"
                className={common.mousecursor}
                onClick={() => handleVideoCall(u)}
              >
                <Typography
                  textAlign={"center"}
                  sx={{
                    color: "white",
                  }}
                >
                  {u?.name}
                </Typography>
              </Box>
            </Box>
          ))}
        </Popover>
      </Box>
      <Snackbar
        duration={500}
        open={machineOnCall}
        onClose={handleClose}
        sx={{ backroundColor: "#62BB46", color: "white" }}
        message={`${selectedMachine?.name} is on call.`}
        key={"top" + "right"}
      />
      <Snackbar
        duration={500}
        open={linkIsEmpty}
        onClose={handleClose}
        sx={{ backroundColor: "#62BB46", color: "white" }}
        message={`Link is not available for ${selectedMachine?.name}.`}
        key={"top" + "right"}
      />
    </Box>
  );
};
