export const dailyPlans = [
  {
    id: 1,
    name: "Plate Wash",
    color: "#EBA900",
    label: "wash",
  },
  {
    id: 2,
    name: "Hopper Wash",
    color: "#EBA900",
    label: "wash",
  },
  {
    id: 3,
    name: "Plate Fill",
    color: "#46BAAC",
    label: "fill",
  },
  {
    id: 4,
    name: "Hopper Fill",
    color: "#46BAAC",
    label: "fill",
  },
  {
    id: 5,
    name: "Cook",
    color: "#EBA900",
    label: "cook",
  },
  {
    id: 6,
    name: "Worm",
    color: "#EBA900",
    label: "worm",
  },
  {
    id: 7,
    name: "Store",
    color: "#BA7046",
    label: "store",
  },
  {
    id: 8,
    name: "Wash+Fill",
  },
  {
    id: 9,
    name: "Store+Fill",
  },
  {
    id: 10,
    name: "Store+Wash",
  },
];

export const dailyPlansForTbot = [
  {
    id: 1,
    name: "Wash",
  },
];

export const dailyPlansForChunteyBot = [
  {
    id: 1,
    name: "Drum Wash",
  },
];

export const dailyPlansBackground = [
  {
    color: "#EBA900",
    operation: "Plate Wash",
  },
  {
    color: "#EBA900",
    operation: "Wash",
  },
  {
    color: "#EBA900",
    operation: "Hopper Wash",
  },
  {
    color: "#EBA900",
    operation: "Drum Wash",
  },
  {
    color: "#46BAAC",
    operation: "Plate Fill",
  },
  {
    color: "#46BAAC",
    operation: "Hopper Fill",
  },
  {
    color: "#1A81E0",
    operation: "Cook",
  },
  {
    color: "#BA7046",
    operation: "Store",
  },
  {
    color: "#F37E78",
    operation: "Wash+Fill",
  },
  {
    color: "#BA4685",
    operation: "Store+Fill",
  },
  {
    color: "#8746BA",
    operation: "Store+Wash",
  },
];

export const events = [
  {
    id: 1,
    title: "Cooking",
    start: new Date(2023, 6, 27, 10, 0),
    end: new Date(2023, 6, 27, 10, 20),
    botname: "Idli bot",
    locationId: "12_1298_0011",
    plan: "cook",
    time: "9 min",
  },
  {
    id: 2,
    title: "Washing",
    start: new Date(2023, 6, 28, 12, 0), // June 25th, 2023, 8:00 AM
    end: new Date(2023, 6, 28, 16, 0), // June 25th, 2023, 12:00 PM
    botname: "Tea bot",
    locationId: "12_1298_5704",
    plan: "wash",
    time: "10 min",
  },
  // ...
];

export const WashTypes = [
  {
    name: "Rinse Wash",
    timePeriod: 10,
  },
  {
    name: "Deep Wash",
    timePeriod: 20,
  },
];

export let plateNos = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
];

export const status = [
  {
    id: 1,
    name: "FAILED",
  },
];

export const OperationForIdlibot = [
  {
    id: 1,
    name: "Cook",
  },
  {
    id: 2,
    name: "Wash",
  },
  {
    id: 3,
    name: "Hopper Wash",
  },
];

export const OperationForCbot = [
  {
    id: 1,
    name: "Drum Wash",
  },
];

export const OperationForTbot = [
  {
    id: 1,
    name: "Wash",
  },
];

export const operations = [
  {
    name: "Stacker close",
    type: "EXECUTE_ACTION",
    topic: "fh/icvb/stacker/stackerClose",
    params: {},
    paramsList: [],
    botType: "ICVB",
  },
  {
    name: "All subSubsystem home",
    type: "VERIFY_SEQ",
    topic: "fh/icvb/pickAndPlace/allSubSystemHome",
    params: {},
    paramsList: [],
    botType: "ICVB",
  },
];
