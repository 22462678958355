import React from "react";
import { useMediaQuery } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

export const FHDateTimePicker = (props) => {
  const { value, onChange, editForm = false, helperText } = props;
	const mobileView = useMediaQuery("(max-width:920px)");


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        renderInput={(props) => (
          <TextField
            style={{
							width:'100%',
              border: props.disabled  ? "0.5px solid #ACACAC" : "2px solid #ACACAC",
              borderRadius: 9,
							padding:-2,
							width: mobileView ? '80%' : '100%'

            }}
						// helperText={helperText}
            {...props}
          />
        )}
				minDate={editForm === false && new Date()}
        value={value}
        onChange={onChange}
				disabled ={ props.disabled}
      />
    </LocalizationProvider>
  );
};

// "MuiInputBase-root MuiOutlinedInput-root
