import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  FormHelperText,
  Snackbar,
} from "@mui/material";
import { db } from "../../App";
import { setDoc, doc, addDoc, collection } from "firebase/firestore";
import { LocationSelect } from "../LocationSelect";
import FHSelect from "../../components/FHSelect";
import FHButton from "../../components/FHButton";
import { useSelector, useDispatch } from "react-redux";
import { filter, isEmpty, get } from "lodash";
import { AddPlanFilter } from "./AddPlanFilter";
import { BiStats } from "react-icons/bi";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import FhActivityBar from "../../components/FHActivityBar";
import { DailyPlanCalender } from "./DailyPlanCalender";
import { FHDrawer } from "../../components/FHDrawer";
import AddPlans from "./AddPlans";
import colors from "../../themes/colors";
import { AddOperationFilter } from "./AddOperationFilter";
import { generateRandomString } from "../../services/utils";
import { AddOperation } from "../../services/dailyPlanServices";

export const DailyPlan = () => {
  const dispatch = useDispatch();
  const [selectedMachine, setSelectedMachine] = useState("");
  const [activities, setActivities] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [confirmationError, setConfirmationError] = useState("");
  const [operationAdded, setOperationAdded] = useState(false);
  const [adOpError, setAdOpError] = useState(false);

  const mobileView = useMediaQuery("(max-width:920px)");

  const selectedLocation = useSelector(
    (state) => state.locationReducer.selectedLocation
  );
  const machinesList = useSelector((state) => state.botReducer.machinesInfo);
  let machinesDropdown = filter(
    machinesList,
    (item) => item?.locId === selectedLocation?.d?.locId
  );
  const dailyPlan = useSelector((state) => state.dailyPlanReducer.dailyPlan);
  const user = useSelector((state) => state.userReducer.user);
  const userRole = useSelector((state) => state.userReducer.userRole);
  const showActivities = useSelector(
    (state) => state.activityReducer.showActivities
  );
  const operationDialog = useSelector(
    (state) => state.dailyPlanReducer.operationDialog
  );
  const selectedOperation = useSelector(
    (state) => state.dailyPlanReducer.selectedOperation
  );
  // console.log("machinesList", machinesList);

  let defaultMachine;

  let getIdliBot = filter(
    machinesDropdown,
    (item) => item?.botInfo?.botType === "ICVB"
  );
  // console.log("machinesList", machinesList, getIdliBot);

  if (!isEmpty(machinesDropdown)) {
    if (!isEmpty(getIdliBot)) {
      defaultMachine = getIdliBot[0];
    } else {
      defaultMachine = machinesDropdown[0];
    }
  }

  useEffect(() => {
    if (!isEmpty(defaultMachine)) {
      setSelectedMachine(defaultMachine);
      dispatch({ type: "SELECTED_MACHINE", payload: defaultMachine });
    }
  }, [selectedLocation]);

  useEffect(() => {
    dispatch({ type: "DAILY_PLAN", payload: false });
    dispatch({ type: "EDIT_PLAN", payload: false });
  }, []);

  const handleMachine = (e, v) => {
    dispatch({ type: "SELECTED_MACHINE", payload: v });
    setSelectedMachine(v);
  };

  useEffect(() => {
    dispatch({ type: "SELECTED_ADD_PLAN", payload: {} });
    dispatch({ type: "SELECTED_EDIT_PLAN", payload: {} });
    dispatch({ type: "GET_OPERATIONS" });
  }, []);

  const handleActivities = () => {
    setActivities(!activities);
    dispatch({ type: "SHOW_ACTIVITIES", payload: !showActivities });
    dispatch({
      type: "GET_ACTIVITIES",
      payload: {
        locIds:
          selectedLocation?.Name === "All Locations"
            ? selectedLocation?.locIds
            : [selectedLocation?.d?.locId],
      },
    });
  };

  const handleClose = () => {
    dispatch({ type: "ADD_OPERATION_DIALOG", payload: false });
    dispatch({ type: "SELECTED_OPERATION", payload: {} });
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const restartApp = () => {
    const locId = get(selectedLocation, "d.locId", "unknown");
    const botId = get(selectedMachine, "id", "unknown");

    if (!locId || !botId) {
      console.log("locId or botId is missing");
      return;
    }

    // Check for confirmation
    if (confirmationText !== "RESTART") {
      setConfirmationError('Please type "RESTART" to confirm');
      return;
    }
    setConfirmationError("");

    setDoc(
      doc(db, `/acvms/${locId}/machines/${botId}`),
      {
        appInfo: {
          restart: true,
        },
      },
      {
        merge: true,
      }
    )
      .then((res) => {
        console.log("Successfully restarted app");
        setOpenDialog(false);
        setConfirmationText("");
        const reportDetails = {
          metaData: {
            actionName: "App Restart",
          },
          created: {
            at: new Date(),
            name: user.email,
            uid: user.id,
          },
          msg: `App restarted by ${user.email}`,
          status: "unresolved",
          initiator: "admin",
        };
        addDoc(collection(db, `/acvms/${locId}/machines/${botId}/reports`), {
          ...reportDetails,
        })
          .then((res) => {
            console.log("Successfully added report for restart");
          })
          .catch((err) => {
            console.log("error while adding report for restart", err.message);
          });
      })
      .catch((err) => {
        console.log("error while restarting", err.message);
        setOpenDialog(false);
        setConfirmationText("");
      });
  };

  const handleAddOperation = () => {
    let isCreatingDoc = true;
    let locId = selectedLocation?.d?.locId;
    let botId = selectedMachine?.id;
    let docId = generateRandomString(isCreatingDoc, 20);
    let instructions = {
      type: selectedOperation?.type,
      payload: {
        topic: selectedOperation?.topic,
        params: selectedOperation?.params,
        paramsList: selectedOperation?.paramsList,
        conditions: [],
      },
      addedTime: new Date().getTime(),
    };
    // console.log("instructions", instructions, JSON.stringify(instructions));
    AddOperation(locId, botId, docId, instructions)
      .then((result) => {
        setOperationAdded(true);
        dispatch({ type: "ADD_OPERATION_DIALOG", payload: false });
        dispatch({ type: "SELECTED_OPERATION", payload: {} });
        let payload = {
          msg: `${selectedOperation?.name} is added at ${selectedLocation?.Name} for ${selectedMachine?.name}`,
          activityType: "maintenance",
          bot: `${selectedMachine?.name}`,
          created: {
            at: new Date(),
            name: user?.name || user?.email || user?.phoneNumber || "Anonymous",
            uid: user?.uid,
          },
          initiator: "superAdmin",
          logLevel: "info",
        };
        dispatch({
          type: "LOG_ACTIVITY",
          payload: payload,
        });
      })
      .catch((error) => {
        console.log("Function encountered an error:", error);
        setAdOpError(true);
        setOperationAdded(true);
      });
  };

  const snackbarClose = () => {
    setOperationAdded(false);
    setAdOpError(false);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12} xs={12} mt={mobileView ? 2 : 0}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <LocationSelect />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FHSelect
                height={45}
                disableClearable={true}
                name="machines"
                placeholder="Select machine"
                options={machinesDropdown || []}
                value={(!isEmpty(selectedMachine) && selectedMachine) || {}}
                onChange={(e, v) => {
                  handleMachine(e, v);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box
            mt={1}
            display={"flex"}
            justifyContent={mobileView ? "flex-start" : "flex-end"}
          >
            <Box mr={2}>
              <FHButton
                name={<RestartAltIcon style={{ height: "30", width: "40" }} />}
                variant="outlined"
                // disabled={true}
                smallButton
                w_50
                viewActivityBtnsmall
                textWhite
                onClick={() => setOpenDialog(true)}
              />
            </Box>
            {userRole === "SuperAdmin" && (
              <>
                {selectedMachine?.machineType === "ICVB" ||
                selectedMachine?.botInfo?.botType === "ICVB" ||
                selectedMachine?.botInfo?.botType === "TEST" ? (
                  <Box mr={2}>
                    <AddOperationFilter />
                  </Box>
                ) : (
                  <></>
                )}
              </>
            )}
            {userRole === "SuperAdmin" && (
              <Box mr={1}>
                <AddPlanFilter />
              </Box>
            )}
            {!mobileView && (
              <Box mt={-0.8}>
                <FHButton
                  name={<BiStats style={{ height: "30", width: "40" }} />}
                  variant="outlined"
                  // disabled={true}
                  smallButton
                  w_50
                  viewActivityBtnsmall
                  textWhite
                  onClick={() => handleActivities()}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12}>
          <Box mt={2} p={mobileView ? 0 : 1}>
            <DailyPlanCalender />
          </Box>
        </Grid>
      </Grid>
      {showActivities && (
        <FhActivityBar
          handleActivities={handleActivities}
          activities={showActivities}
        />
      )}
      <FHDrawer
        open={dailyPlan}
        onOpen={() => dispatch({ type: "DAILY_PLAN", payload: true })}
        onClose={() => {
          dispatch({ type: "DAILY_PLAN", payload: false });
          dispatch({ type: "SELECTED_ADD_PLAN", payload: {} });
          dispatch({ type: "EDIT_PLAN", payload: false });
          dispatch({ type: "SELECTED_EDIT_PLAN", payload: {} });
          dispatch({ type: "DUPLICATE_PLAN", payload: false });
          dispatch({ type: "ADD_STATUS", payload: false });
        }}
        data={<AddPlans />}
      />

      <Dialog
        open={operationDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Add ${selectedOperation?.name} for ${selectedMachine?.name}`}
        </DialogTitle>

        <DialogActions>
          <FHButton
            name={"Cancel"}
            variant="outlined"
            color={colors.success}
            smallButton
            commonButtonStyle
            onClick={handleClose}
          />
          <FHButton
            name={"Add Operation"}
            variant="outlined"
            color={colors.success}
            smallButton
            commonButtonStyle
            onClick={() => {
              handleAddOperation();
            }}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to restart the bot app?
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            Confirm that you want to restart bot app by typing "RESTART"
          </DialogContentText>
          <TextField
            autoFocus
            required
            fullWidth
            margin="dense"
            label="RESTART"
            type="text"
            variant="standard"
            rows={4}
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
          />
          {confirmationError && (
            <FormHelperText error={true}>{confirmationError}</FormHelperText>
          )}
        </DialogContent>

        <DialogActions>
          <FHButton
            name={"Cancel"}
            variant="outlined"
            color={colors.success}
            smallButton
            commonButtonStyle
            onClick={closeDialog}
          />
          <FHButton
            name={"Restart"}
            variant="outlined"
            color={colors.success}
            smallButton
            commonButtonStyle
            onClick={() => {
              restartApp();
            }}
          />
        </DialogActions>
      </Dialog>

      <Snackbar
        duration={200}
        open={operationAdded}
        onClose={snackbarClose}
        sx={{ backroundColor: "#62BB46", color: "white" }}
        message={
          adOpError ? `Something went wrong.` : `Operation added successfully!`
        }
        key={"top" + "right"}
      />
    </Box>
  );
};
