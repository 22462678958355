import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import colors from "../../../themes/colors";
import { isEmpty, map } from "lodash";
// import commonClasses from "../../../themes/commonClasses";
// import { makeStyles } from "@mui/styles";

// const customStyles = makeStyles(commonClasses);
const IdliBotStatusTable = (props) => {
  let idliBotStatus = props.idliBotStatus;
  // console.log("itemsWithStatus: " , itemsWithStatus)
  // const common = customStyles(commonClasses);
  // const dispatch = useDispatch();
  const mobileView = useMediaQuery("(max-width:920px)");

  return (
    <>
      {!isEmpty(idliBotStatus) && (
        <Grid item p={2} lg={12} md={12} sm={12} xs={12} mt={4}>
          <Grid
            sx={{ maxWidth: mobileView ? 320 : "75%", overflow: "scroll" }}
            bgcolor={mobileView ? colors.main : colors.bright}
          >
            <TableContainer>
              <Table
                sx={{
                  minWidth: mobileView ? 0 : "100%",
                  maxWidth: mobileView ? 150 : "100%",
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <TableCell align="left">
                      <Typography
                        color={colors.linergrey}
                        fontSize={15}
                        fontWeight={600}
                      >
                        Bot Name
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography
                        color={colors.linergrey}
                        fontSize={15}
                        fontWeight={600}
                      >
                        Quantity
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(idliBotStatus, (item, idx) => (
                    <TableRow
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: "none",
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        width="12%"
                      >
                        <Typography
                          color={colors.success}
                          fontSize={15}
                          fontWeight={600}
                          display="inline"
                        >
                          {item.machineName}
                        </Typography>
                      </TableCell>

                      <TableCell align="center" style={{ width: "15%" }}>
                        <Typography
                          fontSize={15}
                          fontWeight={600}
                          style={{ marginRight: 30 }}
                        >
                          {item.quantity}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default IdliBotStatusTable;
