import _, { isEmpty } from "lodash";
import {
  collection,
  onSnapshot,
  query,
  setDoc,
  doc,
  getDocs,
  where,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../App";
import store from "../stores";
import { map, filter } from "lodash";

export const promoCodes = async (data) => {
  let adminLocIds = data.payload;
  const q = query(collection(db, `promoCodes`), where("hide", "==", false));
  let promos = [];
  onSnapshot(q, async (querySnapshot) => {
    promos = map(querySnapshot.docs, async (docx) => {
      let usedPromo = collection(db, `promoCodes`, docx.id, "usedBy");
      let usedCount = [];
      let snapshot = await getDocs(usedPromo);
      snapshot.docs.map((doc) => usedCount.push({ ...doc.data(), id: doc.id }));
      let promoRes = docx.data();
      let promoId = docx.id;
      let locIds = !isEmpty(promoRes?.locationDetails)
        ? [...Object.keys(promoRes?.locationDetails)]
        : [];
      let itemIds = !isEmpty(promoRes?.itemDetails)
        ? [...Object.keys(promoRes?.itemDetails)]
        : [];
      let userIds = !isEmpty(promoRes?.userDetails)
        ? [...Object.keys(promoRes?.userDetails)]
        : [];
      let promosAtLocations = [];
      let promoAtMenu = [];
      let promoForUser = [];
      if (promoRes?.locations === "specific") {
        for (let locId of locIds) {
          if (adminLocIds.includes(locId)) {
            const locRes = await getDoc(
              query(doc(db, `/locations/${locId}/promoCodes/${promoId}`))
            );
            if (locRes.exists()) {
              let dataRes = locRes.data();
              promosAtLocations = {
                ...promosAtLocations,
                [locId]: {
                  locationId: locId,
                  promoId: promoId,
                  locationMaxUses: dataRes?.locationMaxUses,
                  promoCode: dataRes?.locationDetails?.promoCode,
                },
              };
            }
          }
        }
      }
      if (promoRes?.items === "specific") {
        for (let itemId of itemIds) {
          const menuRes = await getDoc(
            query(doc(db, `/menu/common/items/${itemId}/promoCodes/${promoId}`))
          );
          if (menuRes.exists()) {
            let dataRes = menuRes.data();
            promoAtMenu = {
              ...promoAtMenu,
              [itemId]: {
                menuItemId: itemId,
                promoId: promoId,
                itemMaxUses: dataRes?.itemMaxUses,
                promoCode: dataRes?.itemDetails?.promoCode,
              },
            };
          }
          // console.log("promoAtMenu", promoAtMenu);
        }
      }
      if (promoRes?.users === "specific") {
        for (let userId of userIds) {
          const userRes = await getDoc(
            query(doc(db, `/users/${userId}/promoCodes/${promoId}`))
          );
          const userInfo = await getDoc(query(doc(db, `/users/${userId}`)));
          if (userRes.exists()) {
            let dataRes = userRes.data();
            let userNameRes = userInfo.data();
            promoForUser = {
              ...promoForUser,
              [userId]: {
                userId: userId,
                promoId: promoId,
                name: userNameRes.name,
                uid: userNameRes.uid,
                userMaxUses: dataRes?.userMaxUses,
                promoCode: dataRes?.userDetails?.promoCode,
              },
            };
          }
          // console.log("promoForUser", promoForUser);
        }
      }
      let currentLocIds = map(promosAtLocations, (item) => item.locationId);
      let promoIncludesAdminLoc = map(adminLocIds, (item) => {
        if (currentLocIds.includes(item)) {
          return true;
        }
        return false;
      });
      if (
        promoRes?.locations === "all" ||
        promoIncludesAdminLoc.includes(true)
      ) {
        return {
          ...promoRes,
          usedPromo: usedCount?.length > 0 ? usedCount?.length : 0,
          promoId: docx.id,
          locationPromos: map(promosAtLocations, (item) => item),
          itemPromos: map(promoAtMenu, (item) => item),
          userPromos: map(promoForUser, (item) => item),
        };
      }
    });
    const results = await Promise.all(promos);
    let finalRes = filter(results, (item) => item !== undefined);
    store.dispatch({ type: "STORE_PROMO_CODES", payload: finalRes });
  });
};

export const promoByLocation = (data) => {
  let locIds = data.payload;
  let locPromos = [];
  console.log("promoByLocation", data, locIds);
  for (let locId of locIds) {
    const q = query(collection(db, `/locations/${locId}/promoCodes`));
    onSnapshot(q, (querySnapshot) => {
      let snapData = querySnapshot.docs;
      snapData.forEach((docx) => {
        locPromos = {
          ...locPromos,
          [docx.id]: { ...docx.data(), locationId: locId, promoId: docx.id },
        };
      });
      store.dispatch({
        type: "STORE_LOCATION_BASED_PROMOS",
        payload: locPromos,
      });
    });
  }
};

export const promoByItems = (data) => {
  let itemPromos = [];
  let q = query(collection(db, `/menu/common/items/${data?.id}/promoCodes`));
  onSnapshot(q, (querySnapshot) => {
    let snapData = querySnapshot.docs;
    snapData.forEach((docx) => {
      itemPromos = {
        ...itemPromos,
        [`${data?.id}_${docx.id}`]: {
          ...docx.data(),
          menuItemId: data?.id,
          promoId: docx.id,
        },
      };
    });
    store.dispatch({
      type: "STORE_MENUITEM_BASED_PROMOS",
      payload: { menuId: data?.id, menuPromos: itemPromos },
    });
  });
};

export const promoByUserId = async (data) => {
  let userId = data.payload;
  console.log("userId api", userId);
  const q = query(collection(db, `/users/${userId}/promoCodes`));
  const userPromos = onSnapshot(q, (querySnapshot) => {
    const promos = map(querySnapshot.docs, (doc) => {
      return { ...doc.data(), promoId: doc.id, userId: userId };
    });
    store.dispatch({ type: "STORE_USER_PROMOS", payload: promos });
  });
  return () => userPromos();
};

export const doesPromoCodeExist = async (codeName) => {
  console.log("codeName".codeName);
  const codeQuery = query(
    collection(db, `promoCodes`),
    where("name", "==", codeName)
  );
  let codeRef = await getDocs(codeQuery);
  if (codeRef.docs.length === 0) {
    return true;
  }
  return false;
};

export const createPromo = async (documentId, promoData) => {
  console.log("formData", documentId, promoData);
  await setDoc(doc(db, `/promoCodes/${documentId}`), promoData, {
    merge: true,
  })
    .then((e) => {
      console.log("Document successfully added/updated");
      return "success!";
    })
    .catch((e) => {
      console.log("something went wrong", e);
    });
};

export const createPromoAtLocation = async (locId, docId, promoData) => {
  console.log("locatrin promo api", locId, docId, promoData);
  await setDoc(doc(db, `/locations/${locId}/promoCodes/${docId}`), promoData, {
    merge: true,
  });
};

export const createPromoForUsers = async (userId, docId, promoData) => {
  console.log("users promo api", userId, docId, promoData);
  await setDoc(doc(db, `/users/${userId}/promoCodes/${docId}`), promoData, {
    merge: true,
  });
};

export const createPromoForItems = async (itemId, docId, promoData) => {
  console.log("users promo api", itemId, docId, promoData);
  await setDoc(
    doc(db, `/menu/common/items/${itemId}/promoCodes/${docId}`),
    promoData,
    {
      merge: true,
    }
  );
};

//deletePromos

// export const deletePromo = async (documentId) => {
//   console.log("formData", documentId);
//   await deleteDoc(doc(db, `/promoCodes/${documentId}`));
// };

export const deletePromoAtLocation = async (locId, docId) => {
  console.log("locatrin promo api", locId, docId);
  await deleteDoc(doc(db, `/locations/${locId}/promoCodes/${docId}`));
};

export const deletePromoForUsers = async (userId, docId) => {
  console.log("users promo api", userId, docId);
  await deleteDoc(doc(db, `/users/${userId}/promoCodes/${docId}`));
};

export const deletePromoForItems = async (itemId, docId) => {
  console.log("users promo api", itemId, docId);
  await deleteDoc(doc(db, `/menu/common/items/${itemId}/promoCodes/${docId}`));
};
