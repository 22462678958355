import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    // useNextVariants: true,
    fontFamily: "Rubic-Regular",
    body2: {
      fontFamily: "Rubic-Regular",
      fontSize: 16,
      color: "#464646",
    },
    subtitle1: {
      fontFamily: "Rubic-Regular",
      fontSize: 14,
      color: "#7E7C7C",
    },
    subtitle2: {
      fontFamily: "Rubic-Regular",
      fontSize: 14,
    },
  },
  palette: {
    // primary: {
    // 	main: '#FFFFFF'
    // }
  },
});
export default theme;
