import React from "react";
import { InputLabel } from "@mui/material";
import { isEmpty } from "lodash";

export const FHLabel = (props) => {
  const { label, subHeading } = props;
  return (
    <InputLabel
      sx={{
        fontStyle: "normal",
        fontWeight: isEmpty(subHeading) ? 600 : 500,
        fontSize: isEmpty(subHeading) ? 15 : 14,
        color: isEmpty(subHeading) ? "#474747" : "#ACACAC",
        marginTop: isEmpty(subHeading) ? 1 : 0,
        marginBottom: isEmpty(subHeading) ? 0.5 : 0,
      }}
    >
      {label || subHeading}
    </InputLabel>
  );
};
