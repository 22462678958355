import React from "react";
import {
  Grid,
  List,
  ListItem,
  Typography,
  Rating,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import colors from "../../themes/colors";
import { filter, map, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  sortOrders,
  getFeedbackIcons,
  getOrdersByDateFilter,
} from "../../services/utils";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";
import StarIcon from "@mui/icons-material/Star";

const customStyles = makeStyles(commonClasses);

export const FeedbackCard = () => {
  const common = customStyles(commonClasses);
  const dispatch = useDispatch();
  const mobileView = useMediaQuery("(max-width:920px)");

  const selectedLocation = useSelector(
    (state) => state.locationReducer.selectedLocation
  );
  const dateRange = useSelector((state) => state.orderReducer.dateRange);
  // const allFeedbacks = useSelector((state) => state.userReducer.allFeedbacks);
  const historyData = useSelector((state) => state.orderReducer.historyData);
  const allFeedbacks = map(historyData, (item) => item).filter((i) => i.rating);
  let feedsByLocation =
    selectedLocation?.Name === "All Locations"
      ? map(allFeedbacks, (item) => item)
      : filter(
          allFeedbacks,
          (item) => item.locationId === selectedLocation?.d?.locId
        );

  const selectedOrderDetails = useSelector(
    (state) => state.orderReducer.detailsCardData || []
  );

  const handleShowOrderDetails = (data) => {
    if (mobileView) {
      dispatch({ type: "FEEDBACKCARD", payload: false });
    } else {
      dispatch({ type: "FEEDBACKVIEW", payload: true });
      dispatch({ type: "USERSCARD", payload: false });
      dispatch({ type: "ORDERSCARD", payload: false });
      dispatch({ type: "MENUCARD", payload: false });
      dispatch({ type: "PROMOCARD", payload: false });
      dispatch({ type: "USERSCARD", payload: false });
    }

    dispatch({ type: "STORE_DETAILS_DATA", payload: data });

    dispatch({ type: "ORDERDETAILS", payload: true });
  };

  const feedbacksByDate = getOrdersByDateFilter(feedsByLocation, dateRange);
  const todaysfeedback = sortOrders(getFeedbackIcons(feedbacksByDate));

  return (
    <>
      <Box display="flex" justifyContent="space-between" p>
        <Box>
          <Typography fontSize={23} fontWeight={700}>
            Feedbacks
          </Typography>
        </Box>
      </Box>
      <Box>
        <List
          className={
            !isEmpty(selectedOrderDetails) || mobileView
              ? common.feedbacklistalign
              : common.feedbackList
          }
        >
          {!isEmpty(todaysfeedback) ? (
            map(todaysfeedback, (u, i) => (
              <Box key={i}>
                {!isEmpty(u) && (
                  <ListItem
                    key={i}
                    className={
                      !isEmpty(selectedOrderDetails) &&
                      selectedOrderDetails?.cartId === u.cartId
                        ? common.selectedfeedbackCard
                        : common.listfeedbackItemalign
                    }
                    onClick={() => handleShowOrderDetails(u)}
                  >
                    <Box width="100%">
                      <Grid container spacing={1} display="flex">
                        <Grid item xs={6}>
                          <Typography
                            color={
                              !isEmpty(selectedOrderDetails) &&
                              selectedOrderDetails?.cartId === u.cartId
                                ? "white"
                                : "black"
                            }
                            fontSize={16}
                            fontWeight={600}
                          >
                            {u.userDetails?.name ||
                              u.userDetails?.mobileNo ||
                              u.email ||
                              "Anonymous"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} className={common.flex_end}>
                          <Typography
                            fontSize={12}
                            color={
                              !isEmpty(selectedOrderDetails) &&
                              selectedOrderDetails?.cartId === u.cartId
                                ? "white"
                                : colors.LighterGrey
                            }
                            mt={0.5}
                          >
                            {u.cartId}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} mt={0.5}>
                        <Typography
                          fontSize={14}
                          color={
                            !isEmpty(selectedOrderDetails) &&
                            selectedOrderDetails?.cartId === u.cartId
                              ? "white"
                              : "black"
                          }
                        >
                          {u.feedbackInfo?.comment}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Rating
                          name="text-feedback"
                          value={u?.rating}
                          readOnly
                          precision={0.5}
                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 0.55 }}
                              fontSize="inherit"
                            />
                          }
                        />
                        <Typography
                          fontSize={16}
                          fontWeight={600}
                          padding={0.3}
                          backgroundColor={
                            !isEmpty(selectedOrderDetails) &&
                            selectedOrderDetails?.cartId === u.cartId
                              ? "white"
                              : ""
                          }
                          color={u.feedback?.color}
                        >
                          {u.feedback?.feedbackText}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Box>
                          <Grid container spacing={0.5}>
                            {map(u.feedbackInfo?.reasons, (u, i) => (
                              <Grid item xs="auto" key={i}>
                                <Typography
                                  bgcolor={colors.bright}
                                  borderRadius={5}
                                  mt={0.5}
                                  fontSize={10}
                                  p={0.7}
                                  mr={0.7}
                                  color="black"
                                >
                                  {u}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  </ListItem>
                )}
              </Box>
            ))
          ) : (
            <Typography>No feedbacks for today's orders</Typography>
          )}
        </List>
      </Box>
    </>
  );
};
