export const roles = [
  {
    id: "Admin",
    name: "Admin",
  },
  {
    id: "CoOwner",
    name: "Co-Owner",
  },
  {
    id: "Vendor",
    name: "Vendor",
  },
  {
    id: "SuperAdmin",
    name: "Super Admin",
  },
  {
    id: "BotAdmin",
    name: "Bot Admin",
  },
  {
    id: "TvAdmin",
    name: "Tv Admin",
  },
  {
    id: "CustomerSupport",
    name: "Customer Support",
  },
];
