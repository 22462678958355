import {  call } from "redux-saga/effects";
import {
  newRegisteredUsers,
  selectedUser,
  userFeedback,
  userOldOrders,
  userTodaysOrderFeedbacks,
  userSearchData,
  allUsers,
  allAdminUsers,
} from "../services/userServices";

export function* getAllUsers(action) {
  try {
    yield call(allUsers, action);
  } catch (error) {
    console.log("Usersaga getAllUsers Error", error);
  }
}

export function* getAllAdminUsers(action) {
  try {
    yield call(allAdminUsers, action);
  } catch (error) {
    console.log("Usersaga getAllAdminUsers Error", error);
  }
}

export function* getSelectedUSer(action) {
  try {
    yield call(selectedUser, action);
  } catch (error) {
    console.log("Error", error);
  }
}

export function* getUserFeedback(action) {
  try {
    yield call(userFeedback, action);
  } catch (error) {
    console.log("Error", error);
  }
}

export function* getUserOldOrders(action) {
  try {
    yield call(userOldOrders, action);
  } catch (error) {
    console.log("Error", error);
  }
}

export function* getRegisteredUsers(action) {
  try {
    yield call(newRegisteredUsers, action);
  } catch (error) {
    console.log("Error", error);
  }
}

export function* getTodaysFeedback(action) {
  try {
    yield call(userTodaysOrderFeedbacks, action);
  } catch (error) {
    console.log("Error", error);
  }
}

export function* getUserSearchData(action) {
  try {
    yield call(userSearchData, action);
  } catch (error) {
    console.log("Error", error);
  }
}
