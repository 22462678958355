import * as React from "react";
import {  useMediaQuery} from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { intersection, keys, get, map } from "lodash";
import style from "../themes/commonClasses";

export const FHDrawer = (props) => {
  const { open, onClose, onOpen, data } = props;
  let classNames = [];
	const mobileView = useMediaQuery("(max-width:920px)");


  for (const property in props) {
    if (props[property] === true) {
      classNames.push(property);
    }
  }
  const matchingStyles = intersection(classNames, keys(style)).map((s) =>
    get(style, s)
  );
  let finalStyles = {};
  map(matchingStyles, (ms) => {
    finalStyles = { ...finalStyles, ...ms };
  });

  // let properties = { ...props };
  return (
    <SwipeableDrawer
      anchor={"right"}
      PaperProps={{
        sx: { width: !mobileView ? "600px" : "100%" },
      }}
      style={{ width: !mobileView ? "600px" : "100%" }}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      // style={finalStyles}
    >
      {data}
    </SwipeableDrawer>
  );
};
