import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { map } from "lodash";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function MultiSelectGroupBy({
  data,
  handleSelectedFilter,
  selectedFilter,
}) {
  const [selectedGroupBy, setSelectedGroupBy] = useState(selectedFilter);

  const handleGroupByChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedGroupBy([...selectedGroupBy, value]);
      handleSelectedFilter([...selectedGroupBy, value]);
    } else {
      setSelectedGroupBy(selectedGroupBy.filter((option) => option !== value));
      handleSelectedFilter(
        selectedGroupBy.filter((option) => option !== value)
      );
    }
  };

  return (
    <FormGroup>
      <Typography>{data.type}</Typography>
      {map(data?.activities, (option, i) => (
        <Box ml={1}>
          <FormControlLabel
            key={option}
            control={
              <Checkbox
                color={"success"}
                checked={selectedGroupBy.includes(option.status)}
                onChange={handleGroupByChange}
                value={option.status}
              />
            }
            label={option.name}
          />
        </Box>
      ))}
    </FormGroup>
  );
}
