import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Grid, Popover, useMediaQuery, Hidden } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Filter from "../assets/icons/ordersMenu/Filter.png";
import closeIcon from "../assets/icons/Close.png";
import { map } from "lodash";
import { useSelector } from "react-redux";
import commonClasses from "../themes/commonClasses";
import { makeStyles } from "@mui/styles";
import FHDatepickerFrmTo from "../components/FHDatepickerFrmTo";
import FHGroupbyFilter from "../components/FHGroupbyFilter";
import FHAccordion from "../components/FHAccordion";
import colors from "../themes/colors";
import { filterDropdownActivities } from "../services/utils";
import ClickAwayListener from "@mui/base/ClickAwayListener";

// const drawerWidth = 650;
const customStyles = makeStyles(commonClasses);


// const openedMixin = (theme) => ({
//   minWidth: drawerWidth,
//   // marginTop: "90px",
//   borderRadius: "10px",
//   // left: "10px",
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   overflowX: "hidden",
// });

// const closedMixin = (theme) => ({
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: "hidden",
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up("sm")]: {
//     width: `calc(${theme.spacing(8)} + 1px)`,
//   },
// });

const DrawerHeader = styled("div")(({ theme }) => ({
	// position:"fixed",
	display: "flex",
	// width:"100%",
	// background:"lightGray",
	alignItems: "center",
	justifyContent: "space-between",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	// width: "500px",
	// flexShrink: 0,
	// whiteSpace: "nowrap",
	// boxSizing: "border-box",
	// ...(open && {
	//   ...openedMixin(theme),
	//   "& .MuiDrawer-paper": openedMixin(theme),
	// }),
	// ...(!open && {
	//   ...closedMixin(theme),
	//   "& .MuiDrawer-paper": closedMixin(theme),
	// }),
}));

const NavigationBar = ({ activities, handleActivities }) => {
	// const classes = useStyles();
	const common = customStyles(commonClasses);
	const mobileView = useMediaQuery("(max-width:920px)");
	// const dispatch = useDispatch();
	// const mobileView = useMediaQuery("(max-width:920px)");
	const [anchorEl, setAnchorEl] = useState(null);
	const activitys = useSelector((state) => state.activityReducer.activities);

	// const selectedLocation = useSelector(
	//   (state) => state.locationReducer.selectedLocation
	// );
	const [selectedFilter, setSelectedFilter] = useState([]);

	const filterData = (query, data) => {
		if (query.length === 0) {
			return data;
		} else {
			return data.filter(
				(d) =>
					query.includes(d?.activityType) ||
					query.includes(d?.initiator) ||
					query.includes(d?.logLevel)
			);
		}
	};
	const activits = filterData(selectedFilter, activitys);

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	// const profileIcon = (data) => {
	//   let profileColor = "";
	//   if (data?.initiator === "superAdmin") {
	//     profileColor = "#EBA900";
	//   } else if (data?.initiator === "user") {
	//     profileColor = "#4DA731";
	//   } else if (data?.initiator === "admin") {
	//     profileColor = "#CE0000";
	//   } else {
	//     profileColor = "";
	//   }
	//   return {
	//     color: profileColor,
	//   };
	// };

	return (
		<ClickAwayListener onClickAway={handleActivities}>
			<Drawer
				PaperProps={{
					sx: { width: !mobileView ? "600px" : "100%" },
				}}
				anchor="right"
				open={activities}
				variant="permanent"
			>
				<Box pl={1} pt={1}>
					<DrawerHeader>
						<Grid container>
							<Grid
								item
								xs={12}
								md={12}
								display={"flex"}
								justifyContent={"space-between"}
							>
								<Button
									aria-describedby={id}
									variant="contained"
									onClick={handleClick}
									display={"flex"}
									justifyContent={"space-between"}
								>
									<Typography fontSize={15}>Filter</Typography>
									<img src={Filter} alt="Filter" />
								</Button>
								<Hidden only={'xs'}>
									<Box mt={0.5}>
										<FHDatepickerFrmTo />
									</Box>
								</Hidden>
								<img
									style={{
										paddingRight: 10,
										height: 20,
										width: 20,
										paddingTop: 5,
									}}
									onClick={() => handleActivities()}
									alt="logo"
									src={closeIcon}
								/>
								
								<Popover
									id={id}
									open={open}
									anchorEl={anchorEl}
									onClose={() => setAnchorEl(null)}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "right",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									PaperProps={{ sx: { border: `2px solid ${colors.success}` } }}
								>
									{map(filterDropdownActivities, (u, i) => (
										<Box>
											<Box
												display="flex"
												justifyContent="space-end"
												gap="20px"
												key={i}
												p={1}
												sx={{
													borderBottom: 1,
												}}
												alignItems="center"
												className={common.mousecursor}
												onClick={() => {
													// setSelectedFilter(u.status);
													// setAnchorEl(null);
												}}
											>
												<FHGroupbyFilter
													data={u}
													handleSelectedFilter={(name) =>
														setSelectedFilter(name)
													}
													selectedFilter={selectedFilter}
												/>
											</Box>
										</Box>
									))}
									<Box
										p={0.5}
										display={"flex"}
										justifyContent={"flex-end"}
										className={common.mousecursor}
										onClick={() => {
											setSelectedFilter([]);
											setAnchorEl(null);
										}}
									>
										<Typography>...Clear Filter</Typography>
									</Box>
								</Popover>
							</Grid>
							<Hidden smUp>
									<Box mt={1.5}>
										<FHDatepickerFrmTo />
									</Box>
								</Hidden>
						</Grid>
					</DrawerHeader>
					<Box mb={2} className={common.activityList}>
						{/* {map(activits, (data, i) => (
              <Box p={1}>
                <Grid container>
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    textAlign="center"
                  >
                    <Typography fontSize="16px" color={"black"} pr={1}>
                      <PersonIcon style={profileIcon(data)} />
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={8}>
                    <Typography fontSize="14px" color={"black"}>
                      {data.msg}
                      {data?.metaData?.reason
                        ? ` due to ${data?.metaData?.reason}`
                        : data?.metaData?.locName &&
                          ` from ${data?.metaData?.locName}`}
                    </Typography>
                    <Hidden smUp>
                      <Typography fontSize="14px">
                        By {data?.created?.name}
                      </Typography>
                    </Hidden>
                    <Typography fontSize={10} color={"#AEAEAE"}>
                      {getTime(data.created?.at?.seconds)}
                    </Typography>
                  </Grid>
                  <Hidden only={"xs"}>
                    <Grid item xs={3} sm={3}>
                      <Typography fontSize="14px">
                        By {data?.created?.name}
                      </Typography>
                    </Grid>
                  </Hidden>
                </Grid>
                <Divider classes={{ root: classes.divider }} />
              </Box>
              ))} */}
						<FHAccordion activits={activits} />

						{activits?.length <= 0 && (
							<Box textAlign="center">No Activities</Box>
						)}
					</Box>
				</Box>
			</Drawer>
		</ClickAwayListener>
	);
};

export default NavigationBar;
