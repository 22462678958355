import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, List, ListItemButton, ListItemText, TextField } from '@mui/material';
import React from 'react'
import FHButton from './FHButton';
import colors from '../themes/colors';

function FHModal({ 
    openDialog=true, 
    isConfirmationPage=false, 
    handleClose, 
    dialogTitle='Are you sure you want to add new bot?', 
    dialogContent='Confirm that you want to add new bot by typing "CONFIRM"',
    submitHandler
}) {
    const [confirmationText, setConfirmationText] = React.useState("");
    const [confirmationError, setConfirmationError] = React.useState('');
  return (
    <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogTitle}
        </DialogTitle>
            
            {
            isConfirmationPage?
            (<DialogContent>
            <DialogContentText>
                {
                    dialogContent
                }
            </DialogContentText>
            <TextField
                autoFocus
                required
                fullWidth
                margin="dense"
                label="CONFIRM"
                type="text"
                variant="standard"
                rows={4}
                value={confirmationText}
                onChange={(e) => setConfirmationText(e.target.value)}
            />
            {confirmationError && (
                <FormHelperText error={true}>{confirmationError}</FormHelperText>
            )}
            </DialogContent>):
                
                    typeof dialogContent === "string"?
                    (
                        <DialogContentText>
                            {
                                dialogContent
                            }
                        </DialogContentText>
                    ):    
            (<DialogContent>
                <List component="nav" aria-label="secondary mailbox folder">
                 {
                    dialogContent?.map((item, index) => (
                        <ListItemButton key={item?.d?.locId  || index} onClick={ ()=>submitHandler(item)}>
                        
                            <ListItemText primary={item.Name || item.botInfo?.name} />
                        </ListItemButton>
                    ))

                }   
                </List>
                
            </DialogContent>)
        }
        <DialogActions>
            <FHButton
                name={"Cancel"}
                variant="outlined"
                color={colors.danger}
                smallButton
                commonButtonStyle
                onClick={handleClose}
            />
            {
            isConfirmationPage?(<FHButton
                disabled={confirmationText !== "CONFIRM"}
                name={"Continue"}
                variant="outlined"
                color={confirmationText !== "CONFIRM"? colors.disable : colors.success}
                smallButton
                commonButtonStyle
                onClick={submitHandler}
            />):null
            }
            </DialogActions>
        
      </Dialog>
  )
}

export default FHModal
