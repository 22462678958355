import { isUndefined } from "lodash";
import { createAction } from "redux-actions";
/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  userName: "",
  authSessionKey: false,
  inProgress: false,
  signedIn: false,
  errMsg: "",
  uId: "",
	userRole : [],
  allUsers: {},
  allAdminUsers: [],
  selectedUser: {},
  userOldOrders: {},
  userFeedback: {},
  registeredusers: {},
  allFeedbacks: {},
  feedbacksByDate: {},
  slectedRegisteredUsers: {},
  userSearchData: {},
  selectedAdminDetails: {},
};

// Types
export const SIGNUP = "SIGNUP";
export const LOGIN = "LOGIN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_IN_PROGRESS = "LOGIN_IN_PROGRESS";
export const UPDATE_LOGIN = "UPDATE_LOGIN";
export const LOGIN_RESET = "LOGIN_RESET";
export const LOGOUT = "LOGOUT";
export const FORGOTPASSWORD = "FORGOTPASSWORD";
export const CLEAR_ERROR_ONSUCCESS = "CLEAR_ERROR_ONSUCCESS";
export const GET_SELECTED_USER = "GET_SELECTED_USER";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_ADMIN_USERS = "GET_ALL_ADMIN_USERS";
export const GET_USER_OLD_ORDERS = "GET_USER_OLD_ORDERS";
export const GET_USER_FEEDBACK = "GET_USER_FEEDBACK";
export const GET_NEWREGISTERED_USERS = "GET_NEWREGISTERED_USERS";
export const GET_FEEDBACKS = "GET_FEEDBACKS";
export const GET_USER_SEARCH_DATA = "GET_USER_SEARCH_DATA";

//actions
export const STORE_USER_ROLE = "STORE_USER_ROLE";
export const STORE_ALL_USERS = "STORE_ALL_USERS";
export const STORE_ALL_ADMIN_USERS = "STORE_ALL_ADMIN_USERS";
export const STORE_SELECTED_USER = "STORE_SELECTED_USER";
export const CLEAR_SELECTED_USERDATA = "CLEAR_SELECTED_USERDATA";
export const STORE_USER_OLD_ORDERS = "STORE_USER_OLD_ORDERS";
export const CLEAR_USER_OLD_ORDERS = "CLEAR_USER_OLD_ORDERS";
export const STORE_USER_FEEDBACK = "STORE_USER_FEEDBACK";
export const STORE_NEWREGISTERED_USERS = "STORE_NEWREGISTERED_USERS";
export const STORE_FEEDBACKS = "STORE_FEEDBACKS";
export const STORE_FEEDBACKS_BY_DATE = "STORE_FEEDBACKS_BY_DATE";
export const SHOW_REGISTERED_USER_DATA = "SHOW_REGISTERED_USER_DATA";
export const CLEAR_FEEDBACK_DETAILS = "CLEAR_FEEDBACK_DETAILS";
export const STORE_USER_SEARCH_DATA = "STORE_USER_SEARCH_DATA";
export const CLEAR_USER_SEARCH_DATA = "CLEAR_USER_SEARCH_DATA";
export const CLEAR_TODAYS_FEEDBACK = "CLEAR_TODAYS_FEEDBACK";
export const STORE_SELECTED_ADMIN_DETAILS = "STORE_SELECTED_ADMIN_DETAILS";

// Reducers
export const userReducer = (state = INITIAL_STATE, action = {}) => {
  const payload = action.loginInfo || action.payload || {};
  switch (action.type) {
    case LOGIN_IN_PROGRESS:
      console.log("userStore: LOGIN_IN_PROGRESS");
      return {
        ...state,
        signedIn: false,
        errMsg: "",
        user: {},
        relogin: false,
        inProgress: true,
      };
    case STORE_SELECTED_ADMIN_DETAILS:
      return {
        ...state,
        selectedAdminDetails: action.payload,
      };
    case LOGIN_SUCCESS:
      console.log("userStore: LOGIN_SUCCESS", payload);
      return {
        ...state,
        signedIn: true,
        errMsg: "",
        // authSessionKey: payload.authSessionKey || state.authSessionKey,
        // rememberMe: isUndefined(payload.rememberMe)
        //   ? state.rememberMe
        //   : payload.rememberMe,
        inProgress: false,
        user: payload,
        uId: payload.uid || state.userId,
      };
    case LOGIN_FAILED:
      console.log("userStore: LOGIN_FAILED");
      return {
        ...state,
        signedIn: false,
        errMsg: payload,
        authSessionKey: false,
        inProgress: false,
      };
    case CLEAR_ERROR_ONSUCCESS:
      return {
        ...state,
        errMsg: "",
      };
    case UPDATE_LOGIN:
      return {
        ...state,
        authSessionKey: payload.authSessionKey,
        singedIn: true,
        uId: payload.userId,
      };
    case LOGIN_RESET:
      return {
        ...INITIAL_STATE
      };
		case STORE_USER_ROLE:
			return {
				...state,
				userRole: action.payload
			}
    case STORE_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case STORE_ALL_ADMIN_USERS:
      return {
        ...state,
        allAdminUsers: action.payload,
      };
    case STORE_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case STORE_USER_FEEDBACK:
      return {
        ...state,
        userFeedback: action.payload,
      };
    // case STORE_FEEDBACKS:
    //   return {
    //     ...state,
    //     allFeedbacks: action.payload,
    //   };
    case STORE_FEEDBACKS:
      let feedbackRes = action.payload;
      return {
        ...state,
        allFeedbacks: {
          ...state.allFeedbacks,
          [feedbackRes?.cartId]: feedbackRes,
        },
      };
    case STORE_FEEDBACKS_BY_DATE:
      return {
        ...state,
        feedbacksByDate: action.payload,
      };
    case CLEAR_SELECTED_USERDATA:
      return {
        ...state,
        selectedUser: {},
      };
    case STORE_USER_OLD_ORDERS:
      return {
        ...state,
        userOldOrders: action.payload,
      };
    case CLEAR_USER_OLD_ORDERS:
      return {
        ...state,
        userOldOrders: {},
      };
    case SHOW_REGISTERED_USER_DATA:
      return {
        ...state,
        slectedRegisteredUsers: action.payload,
      };
    case STORE_NEWREGISTERED_USERS:
      return {
        ...state,
        registeredusers: action.payload,
      };
    case STORE_USER_SEARCH_DATA:
      return {
        ...state,
        userSearchData: action.payload,
      };
    case CLEAR_USER_SEARCH_DATA:
      return {
        ...state,
        userSearchData: {},
      };
    case CLEAR_TODAYS_FEEDBACK:
      return {
        ...state,
        allFeedbacks: {},
      };
    case LOGOUT:
      return {
        ...INITIAL_STATE
      };
    case FORGOTPASSWORD:
      return {
        ...state,
        userName: payload.userName,
      };

    default:
      return state;
  }
};
