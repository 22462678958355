import {
	collection,
	where,
	query,
	setDoc,
	doc,
	onSnapshot,
} from "firebase/firestore";
import { db } from "../App";
import store from "../stores";
import { map, isEmpty } from "lodash";

export const fetchdailyPlans = (locId, machineId) => {
	let dailyPlans = [];

	const q = query(
		collection(db, `/acvms/${locId}/machines/${machineId}/plans`)
	);
	onSnapshot(q, (querySnapshot) => {
		let snapData = querySnapshot.docs;
		dailyPlans = map(snapData, (docx) => {
			return { ...docx.data(), docId: docx.id };
		});
		if (!isEmpty(dailyPlans)) {
			store.dispatch({
				type: "STORE_DAILY_PLANS",
				payload: {
					locId: locId,
					machineId: machineId,
					res: dailyPlans,
				},
			});
		}
	});
};

export const fetchArchivedPlans = (locId, machineId) => {
	let archivedPlans = [];

	const q = query(
		collection(db, `/acvms/${locId}/machines/${machineId}/history`)
	);
	onSnapshot(q, (querySnapshot) => {
		let snapData = querySnapshot.docs;
		archivedPlans = map(snapData, (docx) => {
			return { ...docx.data(), docId: docx.id, archivedPlan: true };
		});
		if (!isEmpty(archivedPlans)) {
			store.dispatch({
				type: "STORE_ARCHIVED_PLANS",
				payload: {
					locId: locId,
					machineId: machineId,
					res: archivedPlans,
				},
			});
		}
	});
};

export const AddPlan = async (locId, botId, docId, planData) => {
	try {
		// console.log("formData", locId, botId, docId, planData);
		await setDoc(
			doc(db, `/acvms/${locId}/machines/${botId}/plans/${docId}`),
			planData,
			{
				merge: true,
			}
		);

		console.log("Document successfully added/updated");
		return "success";
	} catch (error) {
		console.log("something went wrong", error);
		return error;
	}
};

export const getOperations = () => {
	let operations = [];

	const q = query(
		collection(db, `/acvms/common/operations`)
	);
	onSnapshot(q, (querySnapshot) => {
		let snapData = querySnapshot.docs;
		snapData.forEach((docx) => {
			operations = {
				...operations,
				[`${docx.id}`]: docx.data(),
			};
		});
		store.dispatch({ type: "STORE_OPERATIONS", payload: operations });
	});
};


export const AddOperation = async (locId, botId, docId, instructions) => {
	try {
		// console.log("formData", locId, botId, docId, instructions);
		await setDoc(
			doc(db, `/acvms/${locId}/machines/${botId}/operations/${docId}`),
			instructions,
			{
				merge: true,
			}
		);

		console.log("Operation successfully added/updated");
		return "success";
	} catch (error) {
		console.log("something went wrong", error);
		return error;
	}
};