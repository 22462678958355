import React, { useEffect, useState, useRef } from "react";
import {
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  Hidden,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import FHButton from "../../../components/FHButton";
import colors from "../../../themes/colors";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, map, filter, get } from "lodash";
import commonClasses from "../../../themes/commonClasses";
import { makeStyles } from "@mui/styles";
import InputBox from "../../../components/InputBox";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { updateMenuItemDetails } from "../../../services/menuServices";
import SampleImg from "../../../assets/images/sample.png";

const customStyles = makeStyles(commonClasses);
const MenuInfo = (props) => {
	const dispatch = useDispatch();
	let itemsWithStatus = props.menuStatus;
	// let rr = filter(itemsWithStatus, (item) => item.itemId === 'RZaV9oac1D3XNoLfgzVZ')
	// console.log("menuIntro", rr);
	const common = customStyles(commonClasses);
	const mobileView = useMediaQuery("(max-width:920px)");
	// const dispatch = useDispatch();
	const OpenFileInput = useRef(null);
	const storage = getStorage();

	const selectedMenuDetails = useSelector(
		(state) => state.menuReducer.selectedMenuDetails
	);
	const machinesInfo = useSelector((state) => state.botReducer.machinesInfo);
	const userRole = useSelector((state) => state.userReducer.userRole);
	const currentLocMenu = useSelector(
		(state) => state.menuReducer.currentLocationMenu
	);

  let checkItemQuanity =
    !isEmpty(itemsWithStatus) &&
    filter(
      itemsWithStatus,
      (sdata) => sdata.itemId === selectedMenuDetails?.itemId
    );
  let quantities =
    !isEmpty(checkItemQuanity) &&
    map(checkItemQuanity, (item) => item.availableQuantity);
  let currentItemQuantity = !isEmpty(quantities)
    ? quantities.reduce((a, b) => a + b, 0)
    : 0;

	// console.log("allInfo", checkItemQuanity, quantities, currentItemQuantity);

  //get machine Names and subSystem names from botInfo....//
  let botInfoKeys = Object.keys(selectedMenuDetails?.botInfo);
  let botIds = [];
  map(botInfoKeys, (item) => {
    if (item) {
      let temp = item.split("_");
      botIds.push(temp[0]);
    }
    return botIds;
  });
  let botInfo = [...new Set(botIds)];
  //............................................................................//

  const getMachineName = (machineId) => {
    let mId;
    if (machineId) {
      mId = filter(machinesInfo, (item) => item.id === machineId);
    }

    return mId && mId[0]?.name;
  };

  const [statusValue, setStatusValue] = useState("");
  const [editPrice, setEditPrice] = useState(false);
  const [itemPrice, setItemPrice] = useState(null);
  const [itemStatus, setItemStatus] = useState(null);
  const [hideItem, setHideItem] = useState(null);
  const [disableSave, setDisableSave] = useState(true);
  const [errorMessage, showErrorMessage] = useState(false);

	useEffect(() => {
		if (!isEmpty(selectedMenuDetails)) {
			setStatusValue(
				selectedMenuDetails?.hide === true
					? "Hide"
					: selectedMenuDetails?.status
			);
			setEditPrice(false);
			setItemPrice(null)
			setDisableSave(true);
			setImageFile(null);
			setImageUrl(null);
		}
	}, [selectedMenuDetails]);

	useEffect(() => {
		let updatedMenu = filter(currentLocMenu, item => item.id === selectedMenuDetails?.id)
		if (!isEmpty(updatedMenu)) {
			dispatch({ type: "STORE_SELECTED_MENU_DETAILS", payload: updatedMenu[0] });
		}
	}, [currentLocMenu])

	const [imageFile, setImageFile] = useState(null);
	const [imageUrl, setImageUrl] = useState(null);
	// console.log("imgFile and imgUrl", imageFile, imageUrl);
	const handleEditPrice = () => {
		if (editPrice) {
			setEditPrice(false);
			setItemPrice(null);
		} else {
			setEditPrice(true);
			setDisableSave(false);
		}
	};

	const handleImageEdit = () => {
		setDisableSave(false);
		OpenFileInput.current.click();
	};
	const handleImageUpload = async (event) => {
		const file = OpenFileInput.current.files[0];
		setImageFile(file);
		setImageUrl(URL.createObjectURL(file));
	};

  const handleStatusChange = (e) => {
    setStatusValue(e.target.value);
    if (e.target.value === "Hide") {
      setHideItem(true);
      setItemStatus(selectedMenuDetails?.status);
    } else if (e.target.value === "Active") {
      setHideItem(false);
      setItemStatus(e.target.value);
    } else {
      setHideItem(selectedMenuDetails?.hide);
      setItemStatus(e.target.value);
    }
    setDisableSave(false);
  };

  const handleRemoveImg = () => {
    setImageFile(null);
    setImageUrl(null);
  };

  const handleSave = async () => {
    if (
      itemStatus === "Active" &&
      selectedMenuDetails?.category !== "Idli" &&
      currentItemQuantity === 0
    ) {
      showErrorMessage(true);
    } else {
      showErrorMessage(false);
      let docId = selectedMenuDetails?.id;
      let info = docId.split("@");
      let locId = info[1];
      let price =
        itemPrice === null || undefined
          ? get(selectedMenuDetails, "price")
          : itemPrice;
      let status =
        itemStatus === null || undefined
          ? get(selectedMenuDetails, "status")
          : itemStatus;
      let hide =
        hideItem === null || undefined
          ? get(selectedMenuDetails, "hide")
          : hideItem;
      let imgurl = get(selectedMenuDetails, "imgs[0].uri");
      // if (isEmpty(errorCheck)) {
      if (imageFile !== null) {
        console.log("update with new image");
        const storageRef = ref(storage, `menuItemPictures/${imageFile?.name}`);
        const fileRef = uploadBytesResumable(storageRef, imageFile, {
          cacheControl: "public,max-age=31536000",
        });
        await fileRef
          .then(function (snapshot) {
            console.log("image uploaded to the storage", snapshot);
            if (snapshot.state === "success") {
              getDownloadURL(snapshot.ref).then(function (url) {
                // console.log("image url from storage", url);
                let newUrl = "";
                if (url) {
                  let tempUrl = url.split("/o/");
                  let baseUrl = tempUrl[0];
                  let additionalUrl = tempUrl[1];
                  let newbaseUrl = "https://images.freshot.in/";
                  newUrl = newbaseUrl + additionalUrl;
                  console.log("oldUrl", url);
                  // console.log("newUrl", newUrl);
                }
                if (!isEmpty(newUrl)) {
                  let finalData = {
                    ...selectedMenuDetails,
                    imgs: [{ uri: url }],
                  };
                  console.log("finalData with new image", finalData);
                  updateMenuItemDetails(locId, docId, price, url, status, hide);
                  setDisableSave(true);
                }
              });
            }
          })
          .catch((e) => console.log("uploading image error => ", e));
      } else {
        console.log("without image update");
        updateMenuItemDetails(locId, docId, price, imgurl, status, hide);
        setDisableSave(true);
      }
      setEditPrice(false);
      setImageFile(null);
      setImageUrl(null);
    }
  };

  const MenuImage = () => {
    return (
      <>
        <Box display={"flex"} justifyContent={"flex-end"} mr={1.5}>
          {userRole === "SuperAdmin" && (
            <IconButton
              className={common.mediaIcon}
              onClick={imageFile !== null ? handleRemoveImg : handleImageEdit}
              style={{ marginRight: mobileView ? 2 : 0 }}
            >
              {imageFile !== null ? (
                <HighlightOffIcon
                  sx={{ color: colors.success }}
                  fontSize="small"
                />
              ) : (
                <DriveFileRenameOutlineRoundedIcon
                  sx={{ color: colors.success }}
                  fontSize="small"
                />
              )}
            </IconButton>
          )}
          <input
            type="file"
            ref={OpenFileInput}
            onChange={handleImageUpload}
            style={{ display: "none" }}
          />
        </Box>
        <Box className={common.image}>
          <img
            src={
              imageUrl === null
                ? get(selectedMenuDetails, "imgs[0].uri") || SampleImg
                : imageUrl
            }
            style={{
              height:
                !isEmpty(get(selectedMenuDetails, "imgs[0].uri")) || imageUrl
                  ? "100%"
                  : 50,
              width:
                !isEmpty(get(selectedMenuDetails, "imgs[0].uri")) || imageUrl
                  ? "100%"
                  : 50,
              display: "flex",
              justifyContent: "center",
              background: "cover",
              margin: "auto",
              borderRadius: 5,
            }}
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <Grid item md={9} lg={9} xs={12} sm={9}>
        <Grid>
          <Typography fontSize={17} fontWeight={600} lineHeight={4}>
            {selectedMenuDetails?.name}
          </Typography>
        </Grid>
        <Hidden smUp>
          <MenuImage />
        </Hidden>
        <Grid>
          <Typography fontSize={16} lineHeight="20px" mt={mobileView ? 2 : 0}>
            {selectedMenuDetails?.description}
          </Typography>
        </Grid>

        <Grid display="flex" gap="20%" mt={2}>
          <Grid>
            <Typography
              color={colors.linergrey}
              fontSize={15}
              fontWeight={600}
              display="inline"
              mr={1}
            >
              Category:
            </Typography>
            <Typography display="inline" fontWeight={600} fontSize={15}>
              {selectedMenuDetails?.category}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              color={colors.linergrey}
              display="inline"
              fontWeight={600}
              fontSize={15}
              mr={1}
            >
              Bot:
            </Typography>
            {map(botInfo, (bItem) => (
              <Typography display="inline" fontWeight={600} fontSize={15}>
                {getMachineName(bItem)}{" "}
              </Typography>
            ))}
          </Grid>
        </Grid>

        <Grid gap="15%" mt={2}>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            value={statusValue}
            onChange={handleStatusChange}
          >
            <FormControlLabel
              value="Active"
              control={
                <Radio
                  sx={{
                    color: colors.lightGrey,
                    "&.Mui-checked": {
                      color: colors.success,
                    },
                  }}
                  disabled={userRole === "SuperAdmin" ? false : true}
                />
              }
              label="Active"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontWeight: 600,
                  fontSize: 15,
                },
              }}
            />
            <FormControlLabel
              value="inActive"
              control={
                <Radio
                  sx={{
                    color: colors.lightGrey,
                    "&.Mui-checked": {
                      color: colors.success,
                    },
                  }}
                  disabled={userRole === "SuperAdmin" ? false : true}
                />
              }
              label="InActive"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontWeight: 600,
                  fontSize: 15,
                },
              }}
            />
            <FormControlLabel
              value="Hide"
              control={
                <Radio
                  sx={{
                    color: colors.lightGrey,
                    "&.Mui-checked": {
                      color: colors.success,
                    },
                  }}
                  disabled={userRole === "SuperAdmin" ? false : true}
                />
              }
              label="Hide"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontWeight: 600,
                  fontSize: 15,
                },
              }}
            />
          </RadioGroup>
        </Grid>

        <Grid display="flex" justifyContent="space-between" gap="5px">
          <Grid mt={mobileView ? 0.5 : 0}>
            <Grid display="flex" justifyContent="space-between">
              <Typography fontSize={16} fontWeight={600} lineHeight={2}>
                Quantity
              </Typography>
            </Grid>
            <InputBox
              name="itemQuantity"
              value={selectedMenuDetails?.itemQuantity || ""}
              disabled={true}
              style={{ backgroundColor: colors.bright }}
            />
          </Grid>

          <Grid>
            <Grid display="flex" justifyContent="space-between">
              <Typography fontSize={16} fontWeight={600} lineHeight={2}>
                Price
              </Typography>
              {userRole === "SuperAdmin" && (
                <IconButton onClick={handleEditPrice}>
                  {editPrice ? (
                    <HighlightOffIcon
                      sx={{ color: colors.success }}
                      fontSize="small"
                    />
                  ) : (
                    <DriveFileRenameOutlineRoundedIcon
                      sx={{ color: colors.success }}
                      fontSize="small"
                    />
                  )}
                </IconButton>
              )}
            </Grid>
            <InputBox
              name="price"
              disabled={editPrice ? false : true}
              value={
                itemPrice === null
                  ? selectedMenuDetails?.price
                  : itemPrice || ""
              }
              placeholder={selectedMenuDetails?.price || ""}
              style={{ backgroundColor: colors.bright, color: "red" }}
              onChange={(e) => setItemPrice(Number(e.target.value))}
            />
            {/* {get(errorObj, "price") ? (
                      <ErrorLabel label={get(errorObj, "price")} />
                    ) : null} */}
          </Grid>
        </Grid>
      </Grid>

      <Hidden only={"xs"}>
        <Grid item md={3} lg={3} sm={3} xs={3}>
          <MenuImage />
        </Grid>
      </Hidden>
      {!disableSave && userRole === "SuperAdmin" && (
        <Grid item xs={12}>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <FHButton
              name={"Save Changes"}
              variant="outlined"
              bgSuccess
              smallButton
              textWhite
              onClick={handleSave}
            />
          </Box>
        </Grid>
      )}
      {errorMessage && (
        <Grid item xs={12}>
          <Typography color={colors.cancelled}>
            The item quantity cannot be zero.
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default MenuInfo;
