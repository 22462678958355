import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  itemList: Yup.array()
    .min(1, "At least one item is required")
    .max(4, "Maximum of four items can be selected")
    .required("At least one item must be added")
    .test("totalQuantity", "Total quantity cannot exceed 36", function (value) {
      const itemList = this.parent.itemList || [];
      const totalQuantity = itemList.reduce(
        (total, item) => total + item.quantity,
        0
      );
      return totalQuantity <= 36;
    })
    .test("noSelectItem", "Select a menu item", function (value) {
      const itemList = this.parent.itemList || [];
      const hasInvalidName = itemList.some(
        (item) => item.name === "Select Item"
      );
      return !hasInvalidName;
    }),
  when: Yup.date()
    .test(
      "is-greater-than-current",
      "Date and time must be in the future",
      (value) => {
        const currentTimeMinus10 = new Date();
        currentTimeMinus10.setMinutes(currentTimeMinus10.getMinutes() - 10);
        return new Date(value) > currentTimeMinus10;
      }
    )
    .required("Date and time is required"),
  // .test(
  //   "individualQuantity",
  //   "Individual quantity cannot exceed 16",
  //   function (value) {
  //     const itemList = this.parent.itemList || [];
  //     if (itemList.length === 2) {
  //       const valid = itemList.every((item) => item.quantity <= 16);
  //       return valid;
  //     }
  //     return true;
  //   }
  // )
  // .test(
  //   "individualQuantity",
  //   "Individual quantity cannot exceed 10.7",
  //   function (value) {
  //     const itemList = this.parent.itemList || [];
  //     if (itemList.length === 3) {
  //       const valid = itemList.every((item) => item.quantity <= 10.7);
  //       return valid;
  //     }
  //     return true;
  //   }
  // )
  // .test(
  //   "individualQuantity",
  //   "Only one of the item can have quantity 16 boxes or all 3 items can have max quantity 8 boxes each",
  //   function (value) {
  //     const itemList = this.parent.itemList || [];
  //     if (itemList.length === 3) {
  //       const hasQuantity16 = itemList.some((item) => item.quantity === 16);

  //       if (hasQuantity16) {
  //         const valid = itemList.every(
  //           (item) => item.quantity <= 8 || item.quantity === 16
  //         );

  //         return valid;
  //       } else {
  //         const valid = itemList.every((item) => item.quantity <= 8);
  //         return valid;
  //       }
  //     }

  //     return true;
  //   }
  // )
  // .test(
  //   "individualQuantity",
  //   "Individual quantity cannot exceed 8",
  //   function (value) {
  //     const itemList = this.parent.itemList || [];
  //     if (itemList.length === 4) {
  //       const valid = itemList.every((item) => item.quantity <= 8);
  //       return valid;
  //     }
  //     return true;
  //   }
  // )
  // cookingTime: Yup.number()
  //   .min(1, "Min cooking time has to be 1 min")
  //   .max(15, "Maximum cooking time can be 15 min"),
  // coolingPeriodAfterCooking: Yup.number()
  //   .min(1, "Min cooling period has to be 1 min")
  //   .max(10, "Maximum cooling period can be 10 min"),
});
