import React, { useEffect } from "react";
import { Typography, IconButton, useMediaQuery } from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { dailyPlansBackground } from "../../data/dailyPlans";
import { map, filter, isEmpty } from "lodash";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
	getDailyPlanStartEndTime,
	convertTimestamp,
} from "../../services/dateTimeConversion";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchdailyPlans,
	fetchArchivedPlans,
} from "../../services/dailyPlanServices";
import "./dailyPlan.css";
import {
	dailyPlans,
	dailyPlansForTbot,
	dailyPlansForChunteyBot,
} from "../../data/dailyPlans";
import { getSubArray } from "../../services/utils";

const localizer = momentLocalizer(moment);

export const DailyPlanCalender = () => {
	const dispatch = useDispatch();
	let cDate = new Date();
	const dayStart = cDate.setHours(0, 0, 0, 0);
	const dayEnd = cDate.setHours(23, 59, 0, 0);
	const mobileView = useMediaQuery("(max-width:920px)");

	const selectedLocation = useSelector(
		(state) => state.locationReducer.selectedLocation
	);
	const selectedMachine = useSelector(
		(state) => state.dailyPlanReducer.selectedMachine
	);

	const machinesInfo = useSelector((state) => state.botReducer.machinesInfo);
	const dailyPlansData = useSelector(
		(state) => state.dailyPlanReducer.dailyPlans
	);
	const archivedPlans = useSelector(
		(state) => state.dailyPlanReducer.archivedPlans
	);
	const userRole = useSelector((state) => state.userReducer.userRole);

	let plans = map(dailyPlansData, (item) => item);
	let archivedPlan = map(archivedPlans, (item) => item);
	let allDailyPlans = getSubArray(plans);
	let aPlans = getSubArray(archivedPlan);
	let plansInArchive = map(aPlans, (item) => item.docId);
	let allPlansCombined = [
		...map(allDailyPlans, (item) => {
			if (!plansInArchive.includes(item.docId)) return item;
		}),
		...(aPlans || []),
	];
	// console.log("allPlansCombined", allPlansCombined)
	let filteredPlans = filter(
		allPlansCombined,
		(item) =>
			item?.locId === selectedLocation?.d?.locId &&
			item?.botId === selectedMachine?.id
	);
	let currentWeekPlans = [];
	!isEmpty(filteredPlans) &&
		map(filteredPlans, (item) =>
			currentWeekPlans.push({
				...item,
				start: new Date(getDailyPlanStartEndTime(item?.start?.seconds)),
				end: new Date(getDailyPlanStartEndTime(item?.end?.seconds)),
				when: new Date(getDailyPlanStartEndTime(item?.when?.seconds)),
				title: item.operation,
			})
		);

	useEffect(() => {
		if (!isEmpty(currentWeekPlans)) {
			dispatch({ type: "DAILY_PLANS_BY_FILTER", payload: currentWeekPlans });
		} else {
			dispatch({ type: "DAILY_PLANS_BY_FILTER", payload: [] });
		}
	}, [dailyPlansData, selectedLocation, selectedMachine]);

	const getPlanBackground = (event) => {
		let data = filter(
			dailyPlansBackground,
			(item) => item.operation === event?.operation
		);

		return data[0]?.color;
	};

	useEffect(() => {
		map(machinesInfo, (item) => {
			let locId = item?.locId;
			let machineId = item?.id;
			fetchdailyPlans(locId, machineId);
			fetchArchivedPlans(locId, machineId);
		});
	}, []);

	const CustomToolbar = ({ label, date, onNavigate }) => {
		const startOfWeek = new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate() - date.getDay()
		);
		const endOfWeek = new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate() + (6 - date.getDay())
		);
		// console.log("startOfWeek endOfWeek", startOfWeek, endOfWeek)
		const handlePrev = () => {
			onNavigate("PREV");
		};

		const handleNext = () => {
			onNavigate("NEXT");
		};

		return (
			<div className="rbc-toolbar">
				<div>
					<IconButton
						onClick={handlePrev}
						style={{
							background: "#FFFFFF",
							boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
							color: "#44444",
							borderRadius: 5,
							width: mobileView ? 10 : 30,
						}}
					>
						<ArrowBackIosIcon style={{ marginLeft: -6 }} />
					</IconButton>
				</div>

				<div className="rbc-toolbar-label">
					<Typography
						style={{ textDecoration: "underline" }}
						fontSize={18}
						fontWeight={600}
					>
						Daily Plan ({label})
					</Typography>
				</div>

				<div>
					<IconButton
						onClick={handleNext}
						style={{
							background: "#FFFFFF",
							boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
							color: "#44444",
							borderRadius: 5,
							width: mobileView ? 10 : 30,
						}}
					>
						<ArrowForwardIosIcon style={{ marginLeft: -10 }} />
					</IconButton>
				</div>
			</div>
		);
	};

	const handleEventClick = (event) => {
		if (userRole === "SuperAdmin") {
			console.log("Clicked event:", event);
			let allPlans = [
				...dailyPlans,
				...dailyPlansForTbot,
				...dailyPlansForChunteyBot,
			];
			let getSelectedPlan = filter(
				allPlans,
				(item) => item.name === event?.operation
			);
			console.log("filteredPlan", getSelectedPlan);
			if (!isEmpty(getSelectedPlan)) {
				dispatch({ type: "SELECTED_ADD_PLAN", payload: getSelectedPlan[0] });
				dispatch({ type: "SELECTED_EDIT_PLAN", payload: event });
				dispatch({ type: "DAILY_PLAN", payload: true });
				dispatch({ type: "EDIT_PLAN", payload: true });
			}
		}
		// Handle the click event for the event here
		// You can open a modal, navigate to a different page, etc.
	};

	const Event = ({ event }) => {
		let start = event?.start;
		let end = event?.end;
		const timeDifferenceInMilliseconds = end.getTime() - start.getTime();
		let timePeriod;

		if (event?.operation === "Wash") {
			timePeriod = Math.floor(timeDifferenceInMilliseconds / 1000);
		} else {
			timePeriod = Math.floor(timeDifferenceInMilliseconds / 1000 / 60);
		}
		return (
			<div style={{ border: "none" }} onClick={() => handleEventClick(event)}>
				{event?.operation === "Wash" ? (
					<strong style={{ fontSize: 12, color: "#FFFFFF" }}>
						{event.operation}[{`${timePeriod}secs`}]
					</strong>
				) : (
					<strong style={{ fontSize: 12, color: "#FFFFFF" }}>
						{event.operation}[{`${timePeriod}min`}]
					</strong>
				)}
			</div>
		);
	};

	const EventWrapper = ({ event, children }) => {
		return (
			<div>
				<div>{children}</div>
			</div>
		);
	};

	const components = {
		event: Event,
		eventWrapper: EventWrapper,
		toolbar: CustomToolbar,
	};

	const eventPropGetter = (event) => {
		const backgroundColor = getPlanBackground(event); // Default background color

		return {
			style: {
				backgroundColor,
			},
		};
	};

	return (
		<div style={{ alignItems: 'center' }}>
			<Calendar
				localizer={localizer}
				events={currentWeekPlans}
				defaultView="week"
				components={components}
				eventPropGetter={eventPropGetter}
				onSelectEvent={handleEventClick}
				min={new Date(dayStart)}
				max={new Date(dayEnd)}
				timeslots={1}
				step={120}
				style={{ height: 600, width: window.innerWidth < 600 ? 360 : '100%' }}
			/>
		</div>
	);
};
