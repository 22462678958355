import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/material/Tabs";
import { map, isEmpty, filter } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
	useMediaQuery,
} from "@mui/material";

export const FHTabs = (props) => {
	const [value, setValue] = useState("1");
	const dispatch = useDispatch();
	const mobileView = useMediaQuery("(max-width:920px)");


	const selectedLocation = useSelector(
		(state) => state.locationReducer.selectedLocation
	);

	const handleChange = (e, newValue, data) => {
		const previousValue = value;
		setValue(newValue);
		let previousTab = filter(data, (item) => item.index === previousValue);
		let selectedTab = filter(data, (item) => item.index === newValue);
		dispatch({ type: previousTab[0]?.redux, payload: false });
		dispatch({ type: selectedTab[0]?.redux, payload: true });
		dispatch({ type: "CURRENT_TAB", payload: { ...selectedTab[0] } });
	};

	useEffect(() => {
		setValue("1");
		let current = props.array;
		dispatch({ type: "CURRENT_TAB", payload: { ...current[0] } });
	}, [selectedLocation]);

	return (
		<Box sx={{ maxWidth: { xs: 360, sm: '100%' } }}>
			<TabContext value={value}>
				<Box mb={1.5}>
					<Tabs
						onChange={(e, newValue) => handleChange(e, newValue, props.array)}
						variant="scrollable"
						scrollButtons={false}
						value={value}
					>
						{!isEmpty(props.array) &&
							map(props.array, (item, i) => (
								<Tab
									indicatorColor="primary"
									textColor="secondary"
									sx={{
										marginLeft: 0.4,
										marginRight: 0.4,
										// boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
										borderRadius: 2,
										backgroundColor: value === item.index ? "#4DA731" : "white",
										"&.MuiTab-root": {
											textTransform: "none",
											fontSize: 17,
											height: 15,
											fontWeight: 550,
										},
									}}
									key={i}
									label={item.tabLabel}
									value={item.index}
								/>
							))}
					</Tabs>
				</Box>
				{/* {map(props.array, (item, i) => (
          <TabPanel
            sx={{
              "&.MuiTabPanel-root": {
                padding: 0,
              },
            }}
            key={i}
            value={item.index}
          >
            <FHCard variant="default" padding={2} cards body={item.component} />
          </TabPanel>
        ))} */}
			</TabContext>
		</Box>
	);
};
