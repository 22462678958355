export const uischema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/Name",
      label: "Item Name",
    },
    {
      type: "Control",
      scope: "#/properties/actions",
    },
    {
      type: "Control",
      scope: "#/properties/buzStatus",
    },
    {
      type: "Control",
      scope: "#/properties/d/properties/locId",
    },
    {
      type: "Control",
      scope: "#/properties/d/properties/locationDetails/properties/actions",
    },
    {
      type: "Control",
      scope:
        "#/properties/d/properties/locationDetails/properties/addressFiled1",
    },
    {
      type: "Control",
      scope:
        "#/properties/d/properties/locationDetails/properties/addressFiled2",
    },
    {
      type: "Control",
      scope:
        "#/properties/d/properties/locationDetails/properties/contactNumber",
    },
    {
      type: "Control",
      scope: "#/properties/d/properties/locationDetails/properties/description",
    },
    {
      type: "Control",
      scope: "#/properties/d/properties/locationDetails/properties/email",
    },
    {
      type: "Control",
      scope:
        "#/properties/d/properties/locationDetails/properties/formattedAddress",
    },
    {
      type: "Control",
      scope:
        "#/properties/d/properties/locationDetails/properties/franchiseContactNum",
    },
    {
      type: "Control",
      scope:
        "#/properties/d/properties/locationDetails/properties/franchiseDesc",
    },
    {
      type: "Control",
      scope:
        "#/properties/d/properties/locationDetails/properties/franchiseEmail",
    },
    {
      type: "Control",
      scope:
        "#/properties/d/properties/locationDetails/properties/franchiseName",
    },
    {
      type: "Control",
      scope:
        "#/properties/d/properties/locationDetails/properties/immediateActionNeeded",
    },
    {
      type: "Control",
      scope: "#/properties/d/properties/locationDetails/properties/issues",
    },
    {
      type: "Control",
      scope: "#/properties/d/properties/locationDetails/properties/latitude",
    },
    {
      type: "Control",
      scope:
        "#/properties/d/properties/locationDetails/properties/locationType",
    },
    {
      type: "Control",
      scope: "#/properties/d/properties/locationDetails/properties/longitude",
    },
    {
      type: "Control",
      scope:
        "#/properties/d/properties/locationDetails/properties/outletCategory",
    },
    {
      type: "Control",
      scope: "#/properties/d/properties/locationDetails/properties/outletName",
    },
    {
      type: "Control",
      scope: "#/properties/d/properties/locationDetails/properties/place_id",
    },
    {
      type: "Control",
      scope: "#/properties/d/properties/locationDetails/properties/qrCode",
    },
    {
      type: "Control",
      scope: "#/properties/d/properties/locationDetails/properties/state",
    },
    {
      type: "Control",
      scope:
        "#/properties/d/properties/locationDetails/properties/toEarnLoyalityPoint",
    },
    {
      type: "Control",
      scope: "#/properties/d/properties/locationDetails/properties/websiteURL",
    },
    {
      type: "Control",
      scope: "#/properties/d/properties/locationDetails/properties/zip",
    },
    {
      type: "Control",
      scope: "#/properties/d/properties/systemUserEmail",
    },
    {
      type: "Control",
      scope: "#/properties/g",
    },
    {
      type: "Control",
      scope: "#/properties/geoHash",
    },
    {
      type: "Control",
      scope: "#/properties/immediateActionNeeded",
    },
    {
      type: "Control",
      scope: "#/properties/issues",
    },

    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/1/properties/_latitude",
        },
        {
          type: "Control",
          scope: "#/properties/1/properties/_longitude",
        },
      ],
    },

    {
      type: "Control",
      scope: "#/properties/locationCloseReason",
    },

    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/paymentMethods",
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/toEarnLoyalityPoint",
    },
    {
      type: "Control",
      scope: "#/properties/nearby",
    },
    {
      type: "Control",
      scope: "#/properties/nearby",
    },
    {
      type: "Control",
      scope: "#/properties/outletStatus",
    },

    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/workingHours",
        },
      ],
    },
  ],
};

export const schema = {
  type: "object",
  properties: {
    Name: {
      type: "string",
    },
    actions: {
      type: "string",
    },
    buzStatus: {
      type: "string",
    },
    d: {
      type: "object",
      properties: {
        locId: {
          type: "string",
        },
        locationDetails: {
          type: "object",
          properties: {
            actions: {
              type: "string",
            },
            addressFiled1: {
              type: "string",
            },
            addressFiled2: {
              type: "string",
            },
            city: {
              type: "string",
            },
            contactNumber: {
              type: "string",
            },
            description: {
              type: "string",
            },
            email: {
              type: "string",
            },
            formattedAddress: {
              type: "string",
            },
            franchiseContactNum: {
              type: "string",
            },
            franchiseDesc: {
              type: "string",
            },
            franchiseEmail: {
              type: "string",
            },
            franchiseName: {
              type: "string",
            },
            immediateActionNeeded: {
              type: "string",
            },
            issues: {
              type: "string",
            },
            latitude: {
              type: "string",
            },
            locationType: {
              type: "string",
            },
            longitude: {
              type: "string",
            },
            outletCategory: {
              type: "string",
            },
            outletName: {
              type: "string",
            },
            place_id: {
              type: "string",
            },
            qrCode: {
              type: "string",
            },
            state: {
              type: "string",
            },
            toEarnLoyalityPoint: {
              type: "string",
            },
            websiteURL: {
              type: "string",
            },
            zip: {
              type: "string",
            },
          },
        },
        systemUserEmail: {
          type: "string",
        },
      },
    },
    geoHash: {
      type: "string",
    },
    immediateActionNeeded: {
      type: "string",
    },
    issues: {
      type: "string",
    },
    1: {
      type: "object",
      properties: {
        _latitude: {
          type: "number",
        },
        _longitude: {
          type: "number",
        },
      },
    },
    locationCloseReason: {
      type: "string",
    },
    nearby: {
      type: "string",
    },
    outletStatus: {
      type: "string",
    },
    g: {
      type: "string",
    },
    paymentMethods: {
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
          },
          status: {
            type: "boolean",
          },
        },
      },
    },
    toEarnLoyalityPoint: {
      type: "number",
    },
    workingHours: {
      type: "array",
      items: {
        type: "object",
        properties: {
          closes: {
            type: "string",
          },
          day: {
            type: "string",
          },
          opens: {
            type: "string",
          },
        },
      },
    },
  },
};
