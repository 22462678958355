import React from "react";
import { Box } from "@mui/material";
import { intersection, keys, get, map } from "lodash";
import style from "../themes/commonClasses";

const FHBox = (props) => {
  let classNames = [];

  for (const property in props) {
    if (props[property] === true) {
      classNames.push(property);
    }
  }
  const matchingStyles = intersection(classNames, keys(style)).map((s) =>
    get(style, s)
  );
  let finalStyles = {};
  map(matchingStyles, (ms) => {
    finalStyles = { ...finalStyles, ...ms };
  });

  // let properties = { ...props };

  // console.log("Box properties",finalStyles, properties);

  return (<Box p={4} properties style={finalStyles}>{props.boxBody}</Box>)
	
};

export default FHBox;
