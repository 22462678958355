import _, { uniqBy } from "lodash";

const INITIAL_STATE = {
  activities: [],
  showActivities: false,
};

//Types
export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const LOG_ACTIVITY = "LOG_ACTIVITY";

//actions
export const STORE_ACTIVITIES = "STORE_ACTIVITIES";
export const SHOW_ACTIVITIES = "SHOW_ACTIVITIES";
export const LOGOUT = "LOGOUT";

export const reciveActivity = (msg) => ({ type: LOG_ACTIVITY, msg });

export const activityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STORE_ACTIVITIES:
      return {
        ...state,
        activities: uniqBy(
          action.payload.sort(
            (d1, d2) =>
              new Date(d2.created.at.seconds) - new Date(d1.created.at.seconds)
          ),
          "id"
        ),
      };
    case SHOW_ACTIVITIES:
      return {
        ...state,
        showActivities: action.payload,
      };
    case LOGOUT:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
