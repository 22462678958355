import { isEmpty, filter, map, uniqBy } from "lodash";
import { covertdateformate } from "../../../services/dateTimeConversion";
import {
  createPromo,
  createPromoAtLocation,
  createPromoForUsers,
  createPromoForItems,
  deletePromoAtLocation,
  deletePromoForUsers,
  deletePromoForItems,
} from "../../../services/promoServices";

let locationsIncluded = [];
let locationsExcluded = [];
let itemsIncluded = [];
let itemsExcluded = [];
let userIncluded = [];
let userExcluded = [];

export const EditPromoInitialValues = (
  ePromoData,
  formik,
  incLocations,
  excLocations,
  incItems,
  excItems,
  discountTypes,
  userInc,
  userExc
) => {
  let sfv = formik.setFieldValue;
  // console.log("editPromo", ePromoData);
  console.log("userInc in editPromoFunctions", userInc);

  //locations
  if (ePromoData?.locations === "all" && ePromoData?.locationMaxUses !== null) {
    console.log("inc exc locations ", incLocations, excLocations);
    locationsIncluded = [
      { ...incLocations[0], locationMaxUses: ePromoData?.locationMaxUses },
    ];
    locationsExcluded = [excLocations[0]];
  } else if (
    (ePromoData?.locations === "specific" &&
      ePromoData?.locationMaxUses !== null) ||
    (ePromoData?.locations === "specific" &&
      ePromoData?.locationMaxUses === null)
  ) {
    let locsWithSpecPromoInc = filter(
      ePromoData?.locationPromos,
      (item) => item.promoCode === "included"
    );
    let locsWithSpecPromoExc = filter(
      ePromoData?.locationPromos,
      (item) => item.promoCode === "excluded"
    );
    let locIncDropdown = map(incLocations, (item) => {
      let isloc = filter(
        locsWithSpecPromoInc,
        (res) => res.locationId === item?.d?.locId
      );
      if (!isEmpty(isloc)) {
        return {
          ...item,
          locationMaxUses: isloc[0]?.locationMaxUses,
        };
      }
    });
    let locExcDropdown = map(excLocations, (item) => {
      let isloc = filter(
        locsWithSpecPromoExc,
        (res) => res.locationId === item?.d?.locId
      );
      if (!isEmpty(isloc)) {
        return item;
      }
    });
    locationsIncluded = filter(locIncDropdown, (item) => item !== undefined);
    locationsExcluded = filter(locExcDropdown, (item) => item !== undefined);
  }

  //items

  if (ePromoData?.items === "all" && ePromoData?.itemMaxUses !== null) {
    itemsIncluded = [{ ...incItems[0], itemMaxUses: ePromoData.itemMaxUses }];
    itemsExcluded = [excItems[0]];
  } else if (
    (ePromoData?.items === "specific" && ePromoData?.itemMaxUses !== null) ||
    (ePromoData?.items === "specific" && ePromoData?.itemMaxUses === null)
  ) {
    let itemsWithSpecPromoInc = filter(
      ePromoData?.itemPromos,
      (item) => item.promoCode === "included"
    );
    let itemsWithspecWithExc = filter(
      ePromoData?.itemPromos,
      (item) => item.promoCode === "excluded"
    );
    let itemIncDropdown = map(incItems, (item) => {
      let menudoc = filter(
        itemsWithSpecPromoInc,
        (res) => res.menuItemId === item?.id
      );
      if (!isEmpty(menudoc)) {
        return {
          ...item,
          itemMaxUses: menudoc[0]?.itemMaxUses,
        };
      }
    });
    let itemExcDropdown = map(excItems, (item) => {
      let menuDoc = filter(
        itemsWithspecWithExc,
        (res) => res.menuItemId === item?.id
      );
      if (!isEmpty(menuDoc)) {
        return item;
      }
    });
    itemsIncluded = filter(itemIncDropdown, (item) => item !== undefined);
    itemsExcluded = filter(itemExcDropdown, (item) => item !== undefined);
  }

  //users
  if (
    (ePromoData?.users === "specific" && ePromoData?.userMaxUses !== null) ||
    (ePromoData?.users === "specific" && ePromoData?.userMaxUses === null)
  ) {
    let usersWithSpecPromoInc = filter(
      ePromoData?.userPromos,
      (item) => item.promoCode === "included"
    );
    let userWithspecWithExc = filter(
      ePromoData?.userPromos,
      (item) => item.promoCode === "excluded"
    );
    let incIds = map(usersWithSpecPromoInc, (item) => item.userId);
    let filterIncDropwon = filter(userInc, (item) => incIds.includes(item.uid));

    let ui = [];
    map(filterIncDropwon, (item) => {
      map(usersWithSpecPromoInc, (sItem) => {
        if (item.uid === sItem.userId) {
          ui.push({
            ...item,
            userMaxUses: sItem?.userMaxUses,
          });
        }
      });
    });
    userIncluded = uniqBy(ui, (item) => item.uid);

    let excIds = map(userWithspecWithExc, (item) => item.userId);
    let filterExcDropdown = filter(userExc, (item) =>
      excIds.includes(item.uid)
    );
    let ux = [];
    map(filterExcDropdown, (item) => {
      map(userWithspecWithExc, (sItem) => {
        if (item.uid === sItem.userId) {
          ux.push(item);
        }
      });
    });

    userExcluded = uniqBy(ux, (item) => item.uid);
  }

  //discountType
  let discountDropdown = filter(
    discountTypes,
    (item) => item.name === ePromoData?.discountType
  );

  //dates
  let validFrom = covertdateformate(ePromoData?.startDate?.seconds)?.dateTime;
  let validTill = covertdateformate(ePromoData?.endDate?.seconds)?.dateTime;

  // console.log("edit locInc locExc", validFrom, validTill);
  sfv("code", ePromoData.code);
  sfv("codeTerms", ePromoData.codeTerms);
  sfv("codeText", ePromoData.codeText);
  sfv(
    "locMaxUses",
    ePromoData.locationMaxUses === null ? 100 : ePromoData.locationMaxUses
  );
  sfv("locationsIncluded", locationsIncluded);
  sfv("locationsExcluded", locationsExcluded);
  sfv(
    "itemMaxUses",
    ePromoData.itemMaxUses === null ? 100 : ePromoData.itemMaxUses
  );
  sfv("itemsIncluded", itemsIncluded);
  sfv("itemsExcluded", itemsExcluded);
  sfv("discountType", discountDropdown[0]);
  sfv("discount", ePromoData.discount);
  sfv("minDiscount", ePromoData.minDiscount);
  sfv("maxDiscount", ePromoData.maxDiscount);
  sfv("minOrderTotal", ePromoData.minOrderTotal);
  sfv("status", ePromoData.status);
  sfv("validFrom", new Date(validFrom));
  sfv("validTill", new Date(validTill));
  sfv("redeemsPerDay", ePromoData.redeemsPerDay ? ePromoData.redeemsPerDay : 1);
  sfv("users", ePromoData.users);
  sfv("usersIncluded", userIncluded);
  sfv("usersExcluded", userExcluded);
  sfv(
    "allUsersMaxUses",
    ePromoData.userMaxUses === null ? 0 : ePromoData.userMaxUses
  );
  sfv(
    "userMaxUses",
    ePromoData.userMaxUses === null ? 100 : ePromoData.userMaxUses
  );
};

export const handleEditPromo = (
  formData,
  userInfo,
  formik,
  documentId,
  userIssuePromo
) => {
  console.log("formik", formData, userInfo, formik, documentId);
  let locations = map(formData?.locationsIncluded, (item) => item.Name);
  let locMaxUses = filter(
    formData?.locationsIncluded,
    (item) => item.Name === "all"
  );
  let itemsList = map(formData?.itemsIncluded, (item) => item.name);
  let itemMaxUses = filter(
    formData?.itemsIncluded,
    (item) => item.name === "all"
  );
  //locationDetails
  let locDetails = [];
  !isEmpty(formData.locationsIncluded) &&
    map(formData.locationsIncluded, (item) => {
      item?.d?.locId && locDetails.push({ [item?.d?.locId]: "included" });
    });
  !isEmpty(formData.locationsExcluded) &&
    map(formData.locationsExcluded, (item) => {
      item?.d?.locId && locDetails.push({ [item?.d?.locId]: "excluded" });
    });
  let locationDetails = Object.assign({}, ...locDetails);

  //itemDetails
  let itmDetail = [];
  !isEmpty(formData.itemsIncluded) &&
    map(formData.itemsIncluded, (item) => {
      item?.id && itmDetail.push({ [item?.id]: "included" });
    });
  !isEmpty(formData.itemsExcluded) &&
    map(formData.itemsExcluded, (item) => {
      item?.id && itmDetail.push({ [item?.id]: "excluded" });
    });
  let itemDetails = Object.assign({}, ...itmDetail);

  //userDetails
  let userDetail = [];
  !isEmpty(formData.usersIncluded) &&
    map(formData.usersIncluded, (item) => {
      item?.uid && userDetail.push({ [item?.uid]: "included" });
    });
  !isEmpty(formData.usersExcluded) &&
    map(formData.usersExcluded, (item) => {
      item?.uid && userDetail.push({ [item?.uid]: "excluded" });
    });

  let userDetails = Object.assign({}, ...userDetail);

  let promoData = {
    code: formData?.code,
    codeTerms: formData?.codeTerms,
    codeText: formData?.codeText,
    createdBy: userInfo,
    discount: formData?.discount,
    hide: formData?.hide === ("true" || "false"),
    discountType: formData?.discountType?.name,
    endDate: new Date(formData?.validTill),
    locations: isEmpty(locationDetails) ? "all" : "specific",
    locationMaxUses: !isEmpty(locMaxUses)
      ? locMaxUses[0]?.locationMaxUses
      : 100,
    locationDetails: isEmpty(locationDetails) ? {} : { ...locationDetails },
    minDiscount: formData?.minDiscount,
    maxDiscount: formData?.maxDiscount,
    minOrderTotal: formData?.minOrderTotal,
    redeemsPerDay: formData?.redeemsPerDay,
    startDate: new Date(formData?.validFrom),
    status: formData?.status,
    users: formData?.users,
    userMaxUses: formData?.users === "all" ? formData?.allUsersMaxUses : null,
    userDetails: formData?.users === "all" ? {} : { ...userDetails },
    items: isEmpty(itemDetails) ? "all" : "specific",
    itemMaxUses: !isEmpty(itemMaxUses) ? itemMaxUses[0]?.itemMaxUses : 100,
    itemDetails: isEmpty(itemDetails) ? {} : { ...itemDetails },
  };
  if (userIssuePromo) {
    promoData.userIssuePromo = userIssuePromo;
  }
  //updating main doc
  createPromo(documentId, promoData);

  let currentIncLocns = map(formData?.locationsIncluded, (item) => item.Name);
  let currentExcLocns = map(formData?.locationsExcluded, (item) => item.Name);
  let currentIncItems = map(formData?.itemsIncluded, (item) => item.name);
  let currentExcItems = map(formData?.itemsExcluded, (item) => item.name);
  let currentIncUsers = map(formData?.usersIncluded, (item) => item.name);
  let currentExcUsers = map(formData?.usersExcluded, (item) => item.name);
  let locnsIncToDelete = filter(
    locationsIncluded,
    (item) => !currentIncLocns.includes(item.Name)
  );
  let locnsExcToDelete = filter(
    locationsExcluded,
    (item) => !currentExcLocns.includes(item.Name)
  );
  let itemsIncToDelete = filter(
    itemsIncluded,
    (item) => !currentIncItems.includes(item.name)
  );
  let itemsExcToDelete = filter(
    itemsExcluded,
    (item) => !currentExcItems.includes(item.name)
  );
  let usersIncToDelete = filter(
    userIncluded,
    (item) => !currentIncUsers.includes(item.name)
  );
  let usersExcToDelete = filter(
    userExcluded,
    (item) => !currentExcUsers.includes(item.name)
  );
  //updating specific locations
  if (promoData?.locations === "specific") {
    !isEmpty(formData?.locationsIncluded) &&
      map(formData?.locationsIncluded, (item) => {
        promoData = {
          ...promoData,
          locationMaxUses: item.locationMaxUses,
          userDetails: formData?.users === "all" ? {} : { ...userDetails },
          itemDetails: itemsList.includes("all") ? {} : { ...itemDetails },
          locationDetails: {
            promoCode: "included",
          },
        };
        let locId = item?.d?.locId;
        createPromoAtLocation(locId, documentId, promoData);
      });
    let excludedLocations = map(
      formData?.locationsExcluded,
      (item) => item.Name
    );
    !isEmpty(excludedLocations) &&
      !excludedLocations.includes("none") &&
      map(formData?.locationsExcluded, (item) => {
        promoData = {
          ...promoData,
          locationMaxUses: null,
          userDetails: formData?.users === "all" ? {} : { ...userDetails },
          itemDetails: itemsList.includes("all") ? {} : { ...itemDetails },
          locationDetails: {
            promoCode: "excluded",
          },
        };
        let locId = item?.d?.locId;
        createPromoAtLocation(locId, documentId, promoData);
      });
  }

  //updating specific users
  if (formData?.users === "specific") {
    !isEmpty(formData?.usersIncluded) &&
      map(formData?.usersIncluded, (item) => {
        promoData = {
          ...promoData,
          userMaxUses: item.userMaxUses,
          itemDetails: itemsList.includes("all") ? {} : { ...itemDetails },
          locationDetails: locations.includes("all")
            ? {}
            : { ...locationDetails },
          userDetails: {
            promoCode: "included",
          },
        };
        let userId = item.uid;
        createPromoForUsers(userId, documentId, promoData);
      });

    !isEmpty(formData?.usersExcluded) &&
      map(formData?.usersExcluded, (item) => {
        promoData = {
          ...promoData,
          userMaxUses: null,
          itemDetails: itemsList.includes("all") ? {} : { ...itemDetails },
          locationDetails: locations.includes("all")
            ? {}
            : { ...locationDetails },
          userDetails: {
            promoCode: "excluded",
          },
        };
        let userId = item?.uid;
        createPromoForUsers(userId, documentId, promoData);
      });
  }

  //updating specific items
  if (promoData?.items === "specific") {
    !isEmpty(formData?.itemsIncluded) &&
      map(formData?.itemsIncluded, (item) => {
        promoData = {
          ...promoData,
          itemMaxUses: item.itemMaxUses,
          locationDetails: locations.includes("all")
            ? {}
            : { ...locationDetails },
          userDetails: formData?.users === "all" ? {} : { ...userDetails },
          itemDetails: {
            promoCode: "included",
          },
        };
        let itemId = item.id;
        createPromoForItems(itemId, documentId, promoData);
      });
    let excludedItems = map(formData?.itemsExcluded, (item) => item.name);
    !isEmpty(formData?.itemsExcluded) &&
      !excludedItems.includes("none") &&
      map(formData?.itemsExcluded, (item) => {
        promoData = {
          ...promoData,
          itemMaxUses: null,
          locationDetails: locations.includes("all")
            ? {}
            : { ...locationDetails },
          userDetails: formData?.users === "all" ? {} : { ...userDetails },
          itemDetails: {
            promoCode: "excluded",
          },
        };
        let itemId = item?.id;
        createPromoForItems(itemId, documentId, promoData);
      });
  }

  //delete location docs
  !isEmpty(locnsIncToDelete) &&
    map(locnsIncToDelete, (item) => {
      let locId = item?.d?.locId;
      deletePromoAtLocation(locId, documentId);
    });
  !isEmpty(locnsExcToDelete) &&
    map(locnsExcToDelete, (item) => {
      let locId = item?.d?.locId;
      deletePromoAtLocation(locId, documentId);
    });

  //delete users docs
  !isEmpty(usersIncToDelete) &&
    map(usersIncToDelete, (item) => {
      let userId = item.uid;
      deletePromoForUsers(userId, documentId);
    });
  !isEmpty(usersExcToDelete) &&
    map(usersExcToDelete, (item) => {
      let userId = item.uid;
      deletePromoForUsers(userId, documentId);
    });

  //delete item docs
  !isEmpty(itemsIncToDelete) &&
    map(itemsIncToDelete, (item) => {
      let itemId = item.id;
      deletePromoForItems(itemId, documentId);
    });
  !isEmpty(itemsExcToDelete) &&
    map(itemsExcToDelete, (item) => {
      let itemId = item.id;
      deletePromoForItems(itemId, documentId);
    });

  formik.resetForm();
};
