import React from "react";
import NavigationContainer from "./NavigationContainer";
import { Login } from "../containers/Login";
import LinearProgress from "@mui/material/LinearProgress";
import { useSelector } from "react-redux";

const Routes = () => {
  const inProgress = useSelector((state) => state.userReducer.inProgress);
  const userLoggedIn = useSelector((state) => state.userReducer.signedIn);


	// useEffect(() => {
	// 	dispatch({type:'LOGOUT'})
	// },[])
  return userLoggedIn ? (
    <NavigationContainer />
  ) : (
    <>
      {inProgress && <LinearProgress color="success" />}
      <Login />
    </>
  );
};

export default Routes;
