import React, { useEffect, useState } from "react";
import { Grid, List, ListItem, Typography, Popover } from "@mui/material";
import { Box } from "@mui/system";
import colors from "../../themes/colors";
import { filter, map, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";
import { EditMachine } from "../../containers/Settings/EditMachine";
import { FHDrawer } from "../../components/FHDrawer";
import EditOrDuplicate from "./EditOrDuplicate";
import {
  addItemToLocation,
  addDuplicateCommonItem,
} from "../../services/menuServices";
import FHButton from "../../components/FHButton";
import { duplicateMachine } from "../../services/botServices";
import { notify } from "../../services/utils";

const customStyles = makeStyles(commonClasses);

export const AllBotsList = (props) => {
  const common = customStyles(commonClasses);
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);
  const [dMachine, setAddDMachine] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const menuLocation = useSelector((state) => state.menuReducer.menuLocation);
  const user = useSelector((state) => state.userReducer.user);
  const masterMachines = useSelector(
    (state) => state.botReducer.masterMachines
  );

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setAddDMachine(item);
  };

  const handleClose = (name) => {
    if (name === "Edit") {
      setDrawer(true);
    }
    if (name === "Duplicate") {
      duplicateMachine(dMachine);
      notify("Created Duplicated machine");
      let payload = {
        msg: `Created Duplicated machine`,
        botId: "",
        activityType: "updates",
        created: {
          at: new Date(),
          name: user?.name || user.firstName,
          uid: user?.uid,
        },
        initiator: "superAdmin",
        logLevel: "info",
        metaData: {
          locId: "common",
        },
      };
      dispatch({
        type: "LOG_ACTIVITY",
        payload: payload,
      });
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  const currentLocMenu = useSelector(
    (state) => state.menuReducer.currentLocationMenu
  );

  let menus = [];
  map(currentLocMenu, (item) => {
    if (item?.locationId === menuLocation?.d?.locId) {
      menus.push({ ...item });
    }
  });

  return (
    <Box>
      <Grid container mb={1}>
        <Grid pt={1} item xs={8} sm={6}>
          <Typography fontSize={18} fontWeight={700}>
            Bots
          </Typography>
        </Grid>
      </Grid>
      <Box justifyContent={"centeter"}>
        <List className={common.list50align}>
          {map(masterMachines, (item, i) => (
            <ListItem
              key={i}
              mt={1}
              className={[common.menulistItemalign, common.bgMain].join(" ")}
              onClick={(e) => handleClick(e, item)}
            >
              <Grid container>
                <Grid
                  item
                  md={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Typography
                    fontSize={15}
                    color={colors.darkGrey}
                    style={{
                      width: "170px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item?.botInfo.name || "No Name"}
                  </Typography>
                  <Typography
                    fontSize="12px"
                    textAlign={"right"}
                    style={{
                      width: "230px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    color={colors.darkGrey}
                  >
                    Version: {item?.botInfo?.version}
                  </Typography>
                </Grid>

                <Grid item md={9}>
                  <Typography
                    style={{
                      width: "380px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    fontSize={12}
                    color={colors.LighterGrey}
                  >
                    {item?.email}
                  </Typography>
                </Grid>
                <Grid item md={3} textAlign={"right"}>
                  <Typography fontSize={12} color={colors.LighterGrey}>
                    {item?.phoneNumber}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <EditOrDuplicate
          editAndDuplicate={true}
          anchorEl={anchorEl}
          onPopClose={handleClose}
        />
      </Popover>
      <FHDrawer
        open={drawer}
        onClose={() => {}}
        onOpen={() => {}}
        drawerBorder
        data={
          <EditMachine dMachine={dMachine} onClose={() => setDrawer(false)} />
        }
      />
    </Box>
  );
};
