import React from "react";
import { intersection, keys, get, map } from "lodash";
import Button from "@mui/material/Button";
import style from "../themes/commonClasses";
import CircularProgress from "@mui/material/CircularProgress";

const FHButton = (props) => {
  const { onClick, endIcon, disabled } = props;
  let classNames = [];

  for (const property in props) {
    if (props[property] === true) {
      classNames.push(property);
    }
  }
  const matchingStyles = intersection(classNames, keys(style)).map((s) =>
    get(style, s)
  );
  let finalStyles = {};
  map(matchingStyles, (ms) => {
    finalStyles = { ...finalStyles, ...ms };
  });

  // let properties = { ...props };

  // console.log("matchingStyles and finalStyles", properties);

  return (
    <Button
      // properties
      disabled={disabled}
      style={finalStyles}
      onClick={onClick}
      endIcon={endIcon}
      sx={{
        backgroundColor: props.color+" !important",
      }}
    >
      {props.loading ? <CircularProgress size={20} /> : props.name}
    </Button>
  );
};

export default FHButton;
