import React, { useState, useEffect, useRef } from "react";
import {
	Grid,
	Box,
	Typography,
	useMediaQuery,
	Hidden,
	IconButton,
} from "@mui/material";
import hamburger from "../../assets/icons/hamburger.png";
import Colors from "../../themes/colors";
import { getStatusBar, getOrdersByDateFilter, getOrderProcessKey } from "../../services/utils";
import FHSwitch from "../../components/FHSwitch";
import FHButton from "../../components/FHButton";
import salesLogo from "../../assets/icons/sales.svg";
import FHCard from "../../components/FHCard";
import FhActivityBar from "../../components/FHActivityBar";
import FHSlide from "../../components/FHSlide";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, map, groupBy, filter, uniqBy } from "lodash";
import { OrdersCard } from "./OrdersCard";
import OrderDetails from "./OrderDetails";
import UserDetails from "./UserDetails";
import { MenuCard } from "./MenuCard";
import OutletSwitchDialog from "./OutletSwitch";
import { planned, unplanned } from "../../data/outletClosingData";
import { updateOutletStatus } from "../../services/locationServices";
import { PromoCode } from "./PromoCode";
import { UserCard } from "./UserCard";
import MenuDetails from "./MenuDetails";
import { FeedbackCard } from "./FeedbackCard";
import { DashboardLocSelect } from "./DashboardLocSelect";
import { BiStats } from "react-icons/bi";
// import { makeStyles } from "@mui/styles";
// import commonClasses from "../../themes/commonClasses";
import { VideoCallButton } from "../VideoCallButton";
import { FHDrawer } from "../../components/FHDrawer";
import { PromoCodes } from "../Settings/Promocodes/AddOrEditPromos";
import { FHTabs } from "../../components/FHTabs";
import { dashboardTabs } from "../../data/mobileViewTabs";
import { DateFilter } from "./DateFilter";
import SalesSummary from './SalesSummary'
import { botManagementStatus } from '../../services/botServices'


// const customStyles = makeStyles(commonClasses);
export const Dashboard = () => {
	// const common = customStyles(commonClasses);
	const notMobileView = useMediaQuery("(max-width:921px)");
	const mobileView = useMediaQuery("(max-width:920px)");

	const containerRef = useRef(null);
	const dispatch = useDispatch();

	const user = useSelector((state) => state.userReducer.user);
	const userRole = useSelector((state) => state.userReducer.userRole);
	const locations = useSelector((state) => state.locationReducer.locations);
	const historyData = useSelector((state) => state.orderReducer.historyData);
	const Incartdata = useSelector((state) => state.orderReducer.incartData);
	const cartData = useSelector((state) => state.orderReducer.cartData);
	const selectedLocation = useSelector(
		(state) => state.locationReducer.selectedLocation
	);
	const menuCard = useSelector((state) => state.dashboard.menuCard);
	const promoCard = useSelector((state) => state.dashboard.promoCard);
	const ordersCard = useSelector((state) => state.dashboard.ordersCard);
	const usersCard = useSelector((state) => state.dashboard.usersCard);
	const feedbackCard = useSelector((state) => state.dashboard.feedbackCard);
	const ordersDetailCard = useSelector(
		(state) => state.dashboard.orderDetailsCard
	);
	const userDetailsCard = useSelector(
		(state) => state.dashboard.userDetailsCard
	);
	const userOrderDetailsCard = useSelector(
		(state) => state.dashboard.userOrderDetailsCard
	);
	const menuDetailsCard = useSelector(
		(state) => state.dashboard.menuDetailsCard
	);
	const selectedMenuLocation = useSelector(
		(state) => state.menuReducer.menuLocation
	);
	const showPromoDrawer = useSelector(
		(state) => state.promocodeReducer.showPromoDrawer
	);
	const mobileMenu = useSelector((state) => state.dashboard.mobileMenu);
	// const ordersByDate = useSelector((state) => state.orderReducer.ordersByDate);
	const dateRange = useSelector((state) => state.orderReducer.dateRange);
	const showActivities = useSelector(
		(state) => state.activityReducer.showActivities
	);
	const showSales = useSelector(
		(state) => state.salesReducer.showSales
	);
	const machinesList = useSelector((state) => state.botReducer.machinesInfo);


	const [activities, setActivities] = useState(false);
	const [resonForClosing, setResonForClosing] = useState("");
	const [showmsg, setShowmsg] = useState(false);

	let ordersList = [];
	ordersList = [
		...(map(historyData, (item) => item) || []),
		...(map(Incartdata, (item) => item) || []),
		...(map(cartData, (item) => item) || []),
	];
	let finalOrders = uniqBy(ordersList, (item) => item.cartId);

	let ordersByDate = [];
	if (!isEmpty(finalOrders) && !isEmpty(dateRange)) {
		ordersByDate = getOrdersByDateFilter(finalOrders, dateRange);
	}
	let ordersByLocation =
		selectedLocation?.Name === "All Locations"
			? map(ordersByDate, (item) => item)
			: filter(
				ordersByDate,
				(item) => item.locationId === selectedLocation?.d?.locId
			);

	const currentStatus = groupBy(ordersByLocation, (u) => u.currentStatus);
	const ordersDispensing = filter(ordersByLocation, (u) => {
		let key = getOrderProcessKey(u)
		if (u.currentStatus === 'Payment Success' && u[key]?.status === true || u.currentStatus === 'COD' && u[key]?.status === true) {
			return u
		}
	}
	)
	const orderPaymentDone = filter(ordersByLocation, (u) => {
		let key = getOrderProcessKey(u)
		if ((u.currentStatus === 'Payment Success' && u[key]?.status === false) || (u.currentStatus === 'COD' && u[key]?.status === false) || (u.currentStatus === 'Payment Success' && getOrderProcessKey(u) === undefined) || (u.currentStatus === 'COD' && getOrderProcessKey(u) === undefined  )) {
			return u
		}
	}
	)
	// console.log("ordersDispensing", orderPaymentDone, ordersDispensing);

	let outlet = filter(
		locations,
		(item) => item.d?.locId === selectedLocation?.d?.locId
	);

	useEffect(() => {
		if (!isEmpty(user)) {
			let locIds = user?.locIds;
			dispatch({ type: "GET_LOCATIONS", payload: locIds });
		}
	}, [user]);

	const handleActivities = () => {
		setActivities(!activities);
		dispatch({ type: "SHOW_ACTIVITIES", payload: !showActivities });
	};
	//dialog
	const [dialogOpen, setDialogOpen] = useState(false);
	const handleDialog = (actn) => {
		if (actn === "close") {
			setDialogOpen(false);
		} else {
			setDialogOpen(true);
		}
	};
	const handleSwitch = () => {
		handleDialog("open");
	};
	const handleOutletOpen = () => {
		console.log("outlet opening");
		dispatch({ type: "UPDATING_OUTLET_STATUS", payload: true });
		let locId = selectedLocation?.d?.locId;
		let outletStatus = "open";
		let locationStatus = {
			status: "open",
			updatedAt: new Date(),
			reason: "",
			updatedBy: {
				name: user?.name,
				uid: user?.uid,
			},
		};
		updateOutletStatus(locId, outletStatus, locationStatus).then((res) => {
			let payload = {
				msg: `${selectedLocation?.Name} is opened`,
				botId: "",
				activityType: "updates",
				created: {
					at: new Date(),
					name: locationStatus?.updatedBy?.name,
					uid: locationStatus?.updatedBy?.uid,
				},
				initiator: "superAdmin",
				logLevel: "info",
				metaData: {
					// locName: selectedLocation?.Name,
				},
			};
			dispatch({
				type: "LOG_ACTIVITY",
				payload: payload,
			});
		});
		setDialogOpen(false);
		setShowmsg(false);
		setResonForClosing("");
	};

	const handleOuletClose = () => {
		if (!isEmpty(resonForClosing)) {
			dispatch({ type: "UPDATING_OUTLET_STATUS", payload: true });
			let locId = selectedLocation?.d?.locId;
			let outletStatus = "closed";
			let locationStatus = {
				status: "closed",
				updatedAt: new Date(),
				reason: resonForClosing?.reason,
				updatedBy: {
					name: user?.name,
					uid: user?.uid,
				},
			};
			updateOutletStatus(locId, outletStatus, locationStatus).then((res) => {
				let payload = {
					msg: `${selectedLocation?.Name} is closed`,
					botId: "",
					activityType: "updates",
					created: {
						at: new Date(),
						name: locationStatus?.updatedBy?.name,
						uid: locationStatus?.updatedBy?.uid,
					},
					initiator: "superAdmin",
					logLevel: "info",
					metaData: {
						reason: resonForClosing?.reason,
						locName: selectedLocation?.Name,
					},
				};
				dispatch({
					type: "LOG_ACTIVITY",
					payload: payload,
				});
			});
			setDialogOpen(false);
			setShowmsg(false);
			setResonForClosing("");
		} else {
			setShowmsg(true);
		}
	};

	const handleMobileMenu = () => {
		dispatch({ type: "MOBILEMENU", payload: !mobileMenu });
	};

	useEffect(() => {
		if (notMobileView) {
			dispatch({ type: "MOBILEMENU", payload: false });
		}
	}, [notMobileView]);

	useEffect(() => {
		if (mobileView) {
			dispatch({ type: "MENUCARD", payload: true });
			dispatch({ type: "PROMOCARD", payload: false });
			dispatch({ type: "ORDERSCARD", payload: false });
			dispatch({ type: "USERSCARD", payload: false });
			dispatch({ type: "FEEDBACKCARD", payload: false });
		} else {
			dispatch({ type: "MENUCARD", payload: true });
			dispatch({ type: "PROMOCARD", payload: true });
			dispatch({ type: "ORDERSCARD", payload: true });
			dispatch({ type: "USERSCARD", payload: true });
			dispatch({ type: "FEEDBACKCARD", payload: true });
		}
		dispatch({ type: "ORDERDETAILS", payload: false });
		dispatch({ type: "USERDETAILS", payload: false });
		dispatch({ type: "USERLASTORDERDETAILS", payload: false });
		dispatch({ type: "MENUDETAILS", payload: false });
		dispatch({ type: "CLEAR_DETAILS_CARD" });
		dispatch({ type: "CLEAR_OLD_ORDERS" });
		dispatch({ type: "CLEAR_SELECTED_MENU_DETAILS" });
		dispatch({ type: "CLEAR_USER_OLD_ORDERS" });
		dispatch({ type: "CLEAR_USER_ORDER_DETAILS" });
		dispatch({ type: "CLEAR_SELECTED_ICON_DATA" });
		dispatch({ type: "CLEAR_SELECTED_USERDATA" });
	}, [selectedLocation, selectedMenuLocation]);

	useEffect(() => {
		dispatch({ type: "FEEDBACKVIEW", payload: false });
		dispatch({ type: "ADD_PROMO_CODE", payload: false });
		dispatch({ type: "EDIT_PROMO_CODE", payload: false });
		dispatch({ type: "SHOW_PROMO_DRAWER", payload: false });
		dispatch({ type: "SHOW_SALES", payload: false });
	}, []);

	useEffect(() => {
		if (!isEmpty(locations)) {
			let locIds = map(locations, (item) => item.d?.locId);
			dispatch({ type: "GET_MACHINES_INFO", payload: locIds });
			dispatch({ type: "GET_PROMO_CODES", payload: locIds });
			dispatch({ type: "GET_LOCATIONS_MENU", payload: locIds });
			dispatch({ type: "GET_CURRENT_AVAILABLE_MENU", payload: locIds });
			dispatch({ type: "GET_ORDER_DATA", payload: { locId: locIds } });
			dispatch({ type: "GET_INCART_DATA", payload: { locId: locIds } });
			dispatch({ type: "GET_CART_DATA", payload: { locId: locIds } });

			// dispatch({ type: "GET_LOCATION_BASED_PROMOS", payload: locIds });
		}
	}, [locations]);

	useEffect(() => {
		if (user?.role === "SuperAdmin") {
			dispatch({ type: "GET_ALL_LOCATIONS" });
			dispatch({ type: "GET_ALL_ADMIN_USERS" });
		}
		dispatch({ type: "GET_NEWREGISTERED_USERS" });
		dispatch({ type: "GET_COMMON_MENU" });
		dispatch({
			type: "GET_ACTIVITIES",
			payload: {
				locIds:
					selectedLocation?.Name === "All Locations"
						? selectedLocation?.locIds
						: [selectedLocation?.d?.locId],
			},
		});
	}, []);

	const handleShowSales = () => {
		dispatch({ type: 'SHOW_SALES', payload: true })
	}

	return (
		<Box>
			<Hidden mdDown>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={2.5}>
						<DashboardLocSelect />
					</Grid>
					<Grid item md={1}>
						<Box>
							<DateFilter />
						</Box>
					</Grid>

					<Grid item xs={12} sm={6} md={2} justifyContent={"flex-start"}>
						{userRole === "SuperAdmin" && (
							<>
								{selectedLocation &&
									selectedLocation?.Name === "All Locations" ? (
									<></>
								) : (
									<FHSwitch
										handleSwitch={(e) => {
											handleSwitch(e);
										}}
										switchstate={
											!isEmpty(outlet) && outlet[0].outletStatus === "open"
												? true
												: false
										}
									/>
								)}
							</>
						)}
					</Grid>

					<Grid
						item
						xs={12}
						sm={12}
						md={4.2}
						display="flex"
						justifyContent="space-around"
						flexDirection="row"
						alignItems="center"
						backgroundColor="white"
					>
						{map(getStatusBar(currentStatus, orderPaymentDone, ordersDispensing), (u, i) => (
							<Grid item key={i} style={{ cursor: "pointer" }}>
								<u.icon
									style={{ color: u.color }}
									onClick={() => {
										dispatch({
											type: "SHOW_SELECTED_ICON_DATA",
											payload: u.status,
										});
									}}
								/>
								<Typography textAlign="center" fontSize={15}>
									{u.lengths}
								</Typography>
							</Grid>
						))}
					</Grid>
					<Grid item xs={12} sm={12} md={2.3}>
						<Box display={"flex"} justifyContent="flex-end">
							<Box display="flex" justifyContent="space-evenly" gap={1}>
								<FHButton
									name={<img src={salesLogo} style={{ height: "30", width: "40" }} />}
									variant="outlined"
									// disabled={true}
									smallButton
									w_50
									viewActivityBtnsmall
									textWhite
									onClick={() => handleShowSales()}
								/>
								<FHButton
									name={<BiStats style={{ height: "30", width: "40" }} />}
									variant="outlined"
									// disabled={true}
									smallButton
									w_50
									viewActivityBtnsmall
									textWhite
									onClick={() => handleActivities()}
								/>
								{userRole === "SuperAdmin" || userRole === "CustomerSupport" ? (
									<>
										{selectedLocation?.Name !== "All Locations" && (
											<VideoCallButton />
										)}
									</>
								) : (
									<></>
								)}
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Hidden>
			<Hidden mdUp>
				<Grid container>
					<Grid item xs={12} mt={1}>
						<DashboardLocSelect />
					</Grid>
					<Grid item xs={6} mt={1}>
						<DateFilter />
					</Grid>
					<Grid item xs={6} mt={1} justifyContent={"center"}>
						{selectedLocation && selectedLocation?.Name === "All Locations" ? (
							<></>
						) : (
							<FHSwitch
								handleSwitch={(e) => {
									handleSwitch(e);
								}}
								switchstate={
									!isEmpty(outlet) && outlet[0].outletStatus === "open"
										? true
										: false
								}
							/>
						)}
					</Grid>
					<Grid
						item
						xs={12}
						display="flex"
						justifyContent="space-around"
						flexDirection="row"
						alignItems="center"
						backgroundColor="white"
						mt={1.5}
						p={1}
					>
						{map(getStatusBar(currentStatus, orderPaymentDone, ordersDispensing), (u, i) => (
							<Grid item key={i} style={{ cursor: "pointer" }}>
								<u.icon
									style={{ color: u.color }}
									onClick={() => {
										dispatch({
											type: "SHOW_SELECTED_ICON_DATA",
											payload: u.status,
										});
									}}
								/>
								<Typography textAlign="center" fontSize={15}>
									{u.lengths}
								</Typography>
							</Grid>
						))}
					</Grid>
				</Grid>
				<Box mt={1.5} p={1}>
					<FHTabs array={dashboardTabs} />
				</Box>
			</Hidden>
			{/* <Hidden only={"xs"}> */}
			<Box mt={2}>
				<Grid container spacing={3}>
					{menuCard || promoCard ? (
						<Grid item xs={12} sm={12} md={4}>
							{menuCard || (menuCard && menuDetailsCard) ? (
								<FHSlide
									slideIn={menuCard || (menuCard && menuDetailsCard)}
									container={containerRef.current}
									component={
										<FHCard
											miniCard={menuCard && menuDetailsCard ? false : true}
											fullLengthCard={
												(menuCard && menuDetailsCard) || mobileView
													? true
													: false
											}
											body={<MenuCard />}
										/>
									}
								/>
							) : (
								<></>
							)}
							{promoCard && (
								<FHSlide
									slideIn={promoCard}
									container={containerRef.current}
									component={
										<Box mt={2}>
											<FHCard
												miniCard={!mobileView ? true : false}
												fullLengthCard={mobileView ? true : false}
												body={<PromoCode />}
											/>
										</Box>
									}
								/>
							)}
						</Grid>
					) : (
						<></>
					)}
					{(menuCard && menuDetailsCard) || menuDetailsCard ? (
						<Grid item xs={12} sm={12} md={8}>
							<FHSlide
								slideIn={(menuCard && menuDetailsCard) || menuDetailsCard}
								container={containerRef.current}
								component={
									<FHCard
										fullLengthCard
										body={
											<>
												<MenuDetails />
											</>
										}
									/>
								}
							/>
						</Grid>
					) : (
						<></>
					)}
					{ordersCard ||
						(ordersCard && ordersDetailCard) ||
						(ordersCard && usersCard && userDetailsCard) ? (
						<Grid item xs={12} sm={12} md={4}>
							<FHSlide
								slideIn={
									ordersCard ||
									(ordersCard && ordersDetailCard) ||
									(ordersCard && usersCard && userDetailsCard)
								}
								container={containerRef.current}
								component={<OrdersCard />}
							/>
						</Grid>
					) : (
						<></>
					)}{" "}
					{usersCard || feedbackCard ? (
						<Grid item xs={12} sm={12} md={4}>
							{usersCard || (ordersCard && usersCard && userDetailsCard) ? (
								<FHSlide
									slideIn={
										usersCard || (ordersCard && usersCard && userDetailsCard)
									}
									container={containerRef.current}
									component={
										<FHCard
											miniCard={
												(ordersCard && usersCard && userDetailsCard) ||
													mobileView
													? false
													: true
											}
											fullLengthCard={
												(ordersCard && usersCard && userDetailsCard) ||
													mobileView
													? true
													: false
											}
											body={<UserCard />}
										/>
									}
								/>
							) : (
								<></>
							)}
							{feedbackCard || (feedbackCard && ordersDetailCard) ? (
								<FHSlide
									slideIn={feedbackCard || (feedbackCard && ordersDetailCard)}
									container={containerRef.current}
									component={
										<Box mt={feedbackCard && ordersDetailCard ? 0 : 2}>
											<FHCard
												miniCard={
													(feedbackCard && ordersDetailCard) || mobileView
														? false
														: true
												}
												fullLengthCard={
													(feedbackCard && ordersDetailCard) || mobileView
														? true
														: false
												}
												body={<FeedbackCard />}
											/>
										</Box>
									}
								/>
							) : (
								<></>
							)}
						</Grid>
					) : (
						<></>
					)}
					{(ordersCard && ordersDetailCard) ||
						(ordersDetailCard && userDetailsCard) ||
						(feedbackCard && ordersDetailCard) ||
						(mobileView && ordersDetailCard) ? (
						<Grid item xs={12} sm={12} md={8}>
							<FHSlide
								slideIn={
									(ordersCard && ordersDetailCard) ||
									(ordersDetailCard && userDetailsCard) ||
									(feedbackCard && ordersDetailCard) ||
									(mobileView && ordersDetailCard)
								}
								container={containerRef.current}
								component={
									<FHCard
										variant={mobileView ? "default" : "primary"}
										fullLengthCard
										body={
											<>
												<OrderDetails />
											</>
										}
									/>
								}
							/>
						</Grid>
					) : (
						<></>
					)}
					{(ordersDetailCard && userDetailsCard) ||
						(userDetailsCard && userOrderDetailsCard) ||
						(ordersCard && usersCard && userDetailsCard) ||
						(mobileView && userDetailsCard) ? (
						<Grid item xs={12} sm={12} md={4}>
							<FHSlide
								slideIn={
									(ordersDetailCard && userDetailsCard) ||
									(userDetailsCard && userOrderDetailsCard) ||
									(ordersCard && usersCard && userDetailsCard) ||
									(mobileView && userDetailsCard)
								}
								container={containerRef.current}
								component={
									<FHCard
										fullLengthCard
										body={
											<>
												<UserDetails />
											</>
										}
									/>
								}
							/>
						</Grid>
					) : (
						<></>
					)}
					{(userDetailsCard && userOrderDetailsCard) ||
						(mobileView && userOrderDetailsCard) ? (
						<Grid item xs={12} sm={12} md={8}>
							<FHSlide
								slideIn={
									(userDetailsCard && userOrderDetailsCard) ||
									(mobileView && userOrderDetailsCard)
								}
								container={containerRef.current}
								component={
									<FHCard
										variant={mobileView ? "default" : "primary"}
										fullLengthCard
										body={
											<>
												<OrderDetails />
											</>
										}
									/>
								}
							/>
						</Grid>
					) : (
						<></>
					)}
				</Grid>
			</Box>
			{/* </Hidden> */}
			{showActivities && (
				<FhActivityBar
					handleActivities={handleActivities}
					activities={showActivities}
				/>
			)}
			<FHDrawer
				open={showPromoDrawer}
				onOpen={() => dispatch({ type: "SHOW_PROMO_DRAWER", payload: true })}
				onClose={() => {
					dispatch({ type: "SHOW_PROMO_DRAWER", payload: false });
					dispatch({ type: "ADD_PROMO_CODE", payload: false });
					dispatch({ type: "EDIT_PROMO_CODE", payload: false });
					dispatch({ type: "RESET_SELETED_EDITPROMO" });
				}}
				drawerBorder
				data={<PromoCodes />}
			/>
			<FHDrawer
				open={showSales}
				onOpen={() => dispatch({ type: "SHOW_SALES", payload: true })}
				onClose={() => {
					dispatch({ type: "SHOW_SALES", payload: false });
				}}
				data={<SalesSummary />}
			/>
			<OutletSwitchDialog
				button1={"Cancel"}
				button2={
					!isEmpty(outlet) && outlet[0].outletStatus !== "open"
						? "Open"
						: "Close"
				}
				outletDialogOpen={dialogOpen}
				onClose={() => handleDialog("close")}
				cancelClick={() => {
					handleDialog("close");
					setResonForClosing("");
					setShowmsg(false);
				}}
				handleOpenOrClose={() =>
					!isEmpty(outlet) && outlet[0].outletStatus !== "open"
						? handleOutletOpen()
						: handleOuletClose()
				}
				outletClosingPopup={
					<Box p={1.5} style={{ height: 300, width: "auto" }}>
						<Box mb={3}>
							<Typography fontSize={23} fontWeight={700}>
								Reason for Closing
							</Typography>
						</Box>
						<Box mb={1}>
							<Typography fontSize={18} fontWeight={550}>
								Planned
							</Typography>
						</Box>
						<Grid container mb={2} spacing={1}>
							{map(planned, (b, i) => (
								<Grid item xs={12} sm={4} key={i}>
									<FHButton
										smallButton
										w_100
										textCapitalize
										menuTabButton={resonForClosing?.id === b.id ? false : true}
										activeTabButton={
											resonForClosing?.id === b.id ? true : false
										}
										name={b.reason}
										onClick={() => setResonForClosing(b)}
									/>
								</Grid>
							))}
						</Grid>
						<Box mb={1}>
							<Typography fontSize={18} fontWeight={550}>
								Unplanned
							</Typography>
						</Box>
						<Grid container spacing={1}>
							{map(unplanned, (b, i) => (
								<Grid item xs={12} sm={4} key={i}>
									<FHButton
										smallButton
										w_100
										textCapitalize
										menuTabButton={resonForClosing?.id === b.id ? false : true}
										activeTabButton={
											resonForClosing?.id === b.id ? true : false
										}
										name={b.reason}
										onClick={() => setResonForClosing(b)}
									/>
								</Grid>
							))}
						</Grid>
						{showmsg && (
							<Box mt={1}>
								<Typography
									fontSize={13}
									fontWeight={550}
									color={Colors.cancelled}
								>
									Select reson for closing
								</Typography>
							</Box>
						)}
					</Box>
				}
			/>
		</Box>
	);
};
