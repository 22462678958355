import { get } from "lodash";

const INITIAL_STATE = {
  machinesInfo: {},
  botStatus: [],
  acvmUpdates: [],
  masterMachines: [],
  botReports: [],
};

//Types
export const GET_MACHINES_INFO = "GET_MACHINES_INFO";
export const GET_BOT_STATUS = "GET_BOT_STATUS";
export const GET_MASTER_MACHINES = "GET_MASTER_MACHINES";

//actions
export const STORE_MASTER_MACHINES = "STORE_MASTER_MACHINES";
export const STORE_MACHINES_INFO = "STORE_MACHINES_INFO";
export const STORE_BOT_STATUS = "STORE_BOT_STATUS";
export const STORE_BOT_REPORTS = "STORE_BOT_REPORTS";
export const STORE_BOT_MANAGEMENT_STATUS = "STORE_BOT_MANAGEMENT_STATUS";
export const STORE_ACVM_UPDATES = "STORE_ACVM_UPDATES";
export const CLEAR_UPADTES = "CLEAR_UPADTES";
export const LOGOUT = "LOGOUT";

export const botReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STORE_MACHINES_INFO:
      return {
        ...state,
        machinesInfo: action.payload,
      };
    case STORE_MASTER_MACHINES:
      return {
        ...state,
        masterMachines: action.payload,
      };
    case STORE_BOT_STATUS:
      let statusRes = action.payload;
      let bStatus = [];
      statusRes.forEach((docx) => {
        bStatus = {
          ...state.botStatus,
          ...bStatus,
          [`${docx.locationId}_${docx.machineName}_${docx.subSystem}`]: {
            ...docx,
          },
        };
      });
      return {
        ...state,
        botStatus: bStatus,
      };
    case STORE_BOT_REPORTS:
      return {
        ...state,
        botReports: action.payload,
      };
    case STORE_ACVM_UPDATES:
      return {
        ...state,
        acvmUpdates: {
          ...state.acvmUpdates,
          [action.payload.index]: {
            ...(get(`state.acvmUpdates[${action.payload.index}]`) || {}),
            ...action.payload.data,
          },
        },
      };

    case CLEAR_UPADTES:
      return {
        ...state,
        acvmUpdates: [],
      };
    case LOGOUT:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
