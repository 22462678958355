import {  call } from "redux-saga/effects";
import { fetchdailyPlans, getOperations } from "../services/dailyPlanServices";

export function* getDailyPlans(action) {
  try {
    yield call(fetchdailyPlans, action);
		
  } catch (error) {
    console.log('dailyPlans error', error)
  }
}

export function* fetchOperations(action) {
  try {
    yield call(getOperations, action);
		
  } catch (error) {
    console.log('operations error', error)
  }
}
