export const resolveMethodsOptions = [
  "Manual Reset",
  "Maintenance Service",
  "Software Update",
  "Replacement",
  "Other",
];

export const reportTypesOptions = [
  "Human Error",
  "Mechanical Failure",
  "Hardware Failure",
  "Other",
];
