import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import {
  Grid,
  Box,
  IconButton,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputAdornment,
  Chip,
  Hidden,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import colors from "../../themes/colors";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { map, filter, get, isEmpty, find } from "lodash";
import FHButton from "../../components/FHButton";
import { FHLabel } from "../../components/FormInputs/FHLabel";
import { FHTextfield } from "../../components/FormInputs/FHTextfield";
import InputBox from "../../components/InputBox";
import * as yup from "yup";
import SampleImg from "../../assets/images/sample.png";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import commonClasses from "../../themes/commonClasses";
import { makeStyles } from "@mui/styles";
import closeIcon from "../../assets/icons/Close.png";
import { roles } from "../../Constants";
import { FHAutocomplete } from "../../components/FormInputs/FHAutocomplete";
import { getAuth } from "firebase/auth";
import { updateAdmin, createAdmin } from "../../services/userServices";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { notify } from "../../services/utils";

const customStyles = makeStyles(commonClasses);

export const AddOrEditUser = (props) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const common = customStyles(commonClasses);
  const [imageFile, setImageFile] = useState(null);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [botConfig, setBotConfig] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const storage = getStorage();
  const OpenFileInput = useRef(null);

  const selectedAdminDetails = useSelector(
    (state) => state.userReducer.selectedAdminDetails
  );
  const allLocations = useSelector(
    (state) => state.locationReducer.allLocations
  );

  const user = useSelector((state) => state.userReducer.user);
  const menuLocation = useSelector((state) => state.menuReducer.menuLocation);

  const validationSchema = yup.object({
    role: yup.object().required("user is required"),
    // firstName: yup.string().required("firstName is required"),
    // lastName: yup.string().required("lastName is required"),
    phoneNumber: yup
      .string()
      .matches(/^\d{10}$/, "Phone number must be 10 digits"),
    // .required("phoneNumber is required"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("email is required"),
    // address: yup.string().required("address is required"),
    // locIds: yup.string().required("locIds is required"),
    locIds: yup
      .array()
      .required("Required")
      .min(1, "Selecte atleast one location"),
  });

  const formFields = {
    role: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    locIds: [],
    locId: {},
    status: "Active",
    uid: "",
    img: "",
  };
  const formik = useFormik({
    // enableReinitialize: editPromo,
    initialValues: formFields,
    validationSchema: validationSchema,
    onSubmit: async (e) => {
      setLoading(true);
      console.log("formik values on submit", formik.values);
      let locIds = [];
      if (!isEmpty(formik.values?.locIds)) {
        locIds = map(formik.values?.locIds, (itm) => itm.id);
      }
      let formData = {
        role: formik.values.role?.id,
        phoneNumber: formik.values.phoneNumber,
        email: formik.values.email,
        address: formik.values.address,
        status: formik.values.status,
        uid: formik.values.uid,
        img: formik.values.img,
        name: formik.values?.firstName || "",
      };
      if (
        formik.values.role?.id === "BotAdmin" ||
        formik.values.role?.id === "TvAdmin"
      ) {
        formData.appConfig = formik.values.appConfig;
        formData.locId = formik.values.locId?.d?.locId;
        formData.locIds = [formik.values.locId?.d?.locId];
      } else {
        formData.locIds = locIds;
        formData.firstName = formik.values.firstName;
        formData.lastName = formik.values.lastName;
      }
      if (formik.values.email && showPasswordField && password) {
        const auth = getAuth();
        // createUserWithEmailAndPassword(auth, formik.values.email, password)
        await axios
          .post(
            "https://us-central1-freshot-prod.cloudfunctions.net/createUser",
            { email: formik.values.email, password: password }
          )
          .then((userCredential) => {
            console.log(userCredential, "dsjjdkj");
            // User created successfully
            let uid = userCredential?.data?.userId;
            if (uid) {
              createAdmin({
                ...formData,
                uid: uid,
              })
                .then((res) => {
                  console.log(res, "success");
                  notify("User created Successfully");
                  let payload = {
                    msg: `New ${formik.values.role?.name} is Added`,
                    botId: "",
                    activityType: "updates",
                    created: {
                      at: new Date(),
                      name: user?.name || user.firstName,
                      uid: user?.uid,
                    },
                    initiator: "superAdmin",
                    logLevel: "info",
                    metaData: {
                      userName: formik.values.firstName,
                      userId: formik.values.uid,
                      locId: menuLocation?.d?.locId || "common",
                      locName: menuLocation?.Name || "",
                    },
                  };
                  dispatch({
                    type: "LOG_ACTIVITY",
                    payload: payload,
                  });
                  handleOnClose();
                })
                .catch((err) => {
                  console.log(err, "error");
                });
            }
          })
          .catch((error) => {
            // Handle error
            console.error("Error creating user:", error);
          });
      }

      if (
        formik.values.email &&
        showPasswordField === false &&
        formik.values.uid
      ) {
        updateAdmin({
          ...formData,
        });

        notify("Updated Successfully");
        let payload = {
          msg: `Updated Admin user`,
          botId: "",
          activityType: "updates",
          created: {
            at: new Date(),
            name: user?.name || user.firstName,
            uid: user?.uid,
          },
          initiator: "superAdmin",
          logLevel: "info",
          metaData: {
            userName: formik.values.firstName,
            userId: formik.values.uid,
            locId: menuLocation?.d?.locId || "common",
            locName: menuLocation?.Name || "",
          },
        };
        dispatch({
          type: "LOG_ACTIVITY",
          payload: payload,
        });
        handleOnClose();
      }

      if (formik.values.email && !formik.values.uid && password <= 5) {
        axios
          .post(
            "https://us-central1-freshot-prod.cloudfunctions.net/checkEmailExists",
            {
              email: formik.values.email,
            }
          )
          .then((res) => {
            if (res.data?.uid) {
              console.log(`Existing user`);
              formik.setFieldValue("uid", res.data?.uid);
            } else {
              console.log(`New user`);
              setShowPasswordField(true);
              setLoading(false);
            }
          });
      }
      // dispatch({ type: "GET_ALL_ADMIN_USERS" });
    },
  });

  let newlyAdded = formik.values?.locIds?.map((itm) => itm?.id);

  let filteredDropDown = filter(
    allLocations,
    (i) => !newlyAdded?.includes(i.id)
  );

  useEffect(() => {
    handleRemoveImg();
    setShowPasswordField(false);
    setPassword("");
    if (
      selectedAdminDetails?.role === "BotAdmin" ||
      selectedAdminDetails?.role === "TvAdmin"
    ) {
      setBotConfig(true);
    }
    formik.setFieldValue("firstName", selectedAdminDetails?.firstName || "");
    formik.setFieldValue("lastName", selectedAdminDetails?.lastName || "");
    formik.setFieldValue(
      "phoneNumber",
      selectedAdminDetails?.phoneNumber || ""
    );
    formik.setFieldValue("email", selectedAdminDetails?.email || "");
    formik.setFieldValue("address", selectedAdminDetails?.address || "");
    formik.setFieldValue("img", selectedAdminDetails?.img || "");
    formik.setFieldValue(
      "role",
      find(roles, (itm) => itm.id === selectedAdminDetails?.role) || ""
    );

    formik.setFieldValue("status", selectedAdminDetails?.status || "Active");
    formik.setFieldValue("appConfig", selectedAdminDetails?.appConfig || "dev");
    formik.setFieldValue("uid", selectedAdminDetails?.uid || "");
    formik.setFieldValue("locId", selectedAdminDetails?.locId || "");
    if (!isEmpty(selectedAdminDetails?.locId)) {
      formik.setFieldValue(
        "locId",
        find(allLocations, (i) => selectedAdminDetails?.locId === i.id) || ""
      );
    }
    if (!isEmpty(selectedAdminDetails?.locIds)) {
      formik.setFieldValue(
        "locIds",
        filter(allLocations, (i) =>
          selectedAdminDetails?.locIds?.includes(i.id)
        ) || []
      );
    }
  }, [selectedAdminDetails]);

  const onDeleteLoc = (title) => {
    formik.setFieldValue(
      "locIds",
      filter(formik.values.locIds, (v) => v.Name !== title)
    );
  };
  const handleOnClose = () => {
    formik.resetForm();
    setShowPasswordField(false);
    onClose();
    dispatch({ type: "STORE_SELECTED_ADMIN_DETAILS", payload: {} });
    setLoading(false);
  };

  let imgurl = get(selectedAdminDetails, "img");

  const handleRemoveImg = () => {
    setImageFile(null);
    setImageUrl(null);
    formik.setFieldValue("img", imgurl);
  };
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    setImageUrl(URL.createObjectURL(file));
    if (file !== null) {
      console.log("update with new image");
      const storageRef = ref(storage, `adminUserPictures/${file?.name}`);
      const fileRef = uploadBytesResumable(storageRef, file, {
        cacheControl: "public,max-age=31536000",
      });
      await fileRef
        .then(function (snapshot) {
          console.log("image uploaded to the storage", snapshot);
          if (snapshot.state == "success") {
            getDownloadURL(snapshot.ref).then(function (url) {
              // console.log("image url from storage", url);
              let newUrl = "";
              if (url) {
                let tempUrl = url.split("/o/");
                let baseUrl = tempUrl[0];
                let additionalUrl = tempUrl[1];
                let newbaseUrl = "https://images.freshot.in/";
                newUrl = newbaseUrl + additionalUrl;
                console.log("oldUrl", url);
                // console.log("newUrl", newUrl);
              }
              if (!isEmpty(newUrl)) {
                let finalData = {
                  ...selectedAdminDetails,
                  img: url,
                };
                console.log("finalData with new image", finalData);
                formik.setFieldValue("img", url);
              }
            });
          }
        })
        .catch((e) => console.log("uploading image error => ", e));
    } else {
      formik.setFieldValue("img", imgurl);
      console.log("without image update");
    }
  };

  const handleImageEdit = () => {
    OpenFileInput.current.click();
  };
  const onchangeRole = (newValue) => {
    if (newValue?.id === "BotAdmin" || newValue?.id === "TvAdmin") {
      setBotConfig(true);
      formik.setFieldValue("locIds", []);
    } else {
      setBotConfig(false);
    }
    formik.setFieldValue("role", newValue);
  };

  const MenuImage = () => {
    return (
      <>
        <Box display={"flex"} justifyContent={"flex-end"} mr={1.5}>
          <IconButton
            className={common.mediaIcon}
            onClick={imageFile !== null ? handleRemoveImg : handleImageEdit}
          >
            {imageFile !== null ? (
              <HighlightOffIcon
                sx={{ color: colors.success }}
                fontSize="small"
              />
            ) : (
              <DriveFileRenameOutlineRoundedIcon
                sx={{ color: colors.success }}
                fontSize="small"
              />
            )}
          </IconButton>
          <input
            type="file"
            ref={OpenFileInput}
            onChange={handleImageUpload}
            style={{ display: "none" }}
          />
        </Box>
        <Box className={common.image}>
          <img
            src={
              imageUrl === null
                ? get(selectedAdminDetails, "img") || SampleImg
                : imageUrl
            }
            style={{
              height:
                !isEmpty(get(selectedAdminDetails, "img")) || imageUrl
                  ? "100%"
                  : 50,
              width:
                !isEmpty(get(selectedAdminDetails, "img")) || imageUrl
                  ? "100%"
                  : 50,
              display: "flex",
              justifyContent: "center",
              background: "cover",
              margin: "auto",
              borderRadius: 5,
            }}
          />
        </Box>
      </>
    );
  };
  console.log(formik, "++++++ffffff");
  return (
    <Box p={2.5}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Box>
              <Typography fontSize={23} fontWeight={700}>
                {selectedAdminDetails?.uid ? "Edit User" : "Add User"}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={() => handleOnClose()}>
                <img src={closeIcon} />
              </IconButton>
            </Box>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Hidden smUp>
                <Grid item xs={12} justifyContent={"center"}>
                  <MenuImage />
                </Grid>
              </Hidden>
              <Grid item md={7} sm={7} xs={12}>
                <Grid container>
                  <Grid item md={12} sm={12} xs={12}>
                    <FHLabel label={"User Role"} />
                    <FHAutocomplete
                      options={roles}
                      // disabled={readOnly}
                      // defaultValue={formik.values.item}
                      value={formik.values.role}
                      singleselect={"true"}
                      id="item"
                      name="item"
                      placeholder="Select User role"
                      multiple={false}
                      onChange={(e, newValue) => onchangeRole(newValue)}
                      // error={formik.touched.role && Boolean(formik.errors.role)}
                      // helperText={formik.touched.role && formik.errors.role}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <FHLabel label={"Phone Number"} />
                    <FHTextfield
                      fullWidth
                      id={"phoneNumber"}
                      type={"text"}
                      name={"Phone Number"}
                      width={"100%"}
                      // disabled={formik.values === "locId"}
                      placeholder="Enter Phone number"
                      value={formik.values.phoneNumber}
                      onChange={(e) =>
                        formik.setFieldValue("phoneNumber", e.target.value)
                      }
                      error={
                        formik.touched.phoneNumber &&
                        Boolean(formik.errors.phoneNumber)
                      }
                      helperText={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                      }
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <FHLabel label={"Email"} />
                    <FHTextfield
                      fullWidth
                      id={"email"}
                      type={"text"}
                      name={"Email"}
                      width={"100%"}
                      disabled={formik.values.uid === selectedAdminDetails?.uid}
                      placeholder="Enter Email"
                      value={formik.values.email}
                      onChange={(e) =>
                        formik.setFieldValue("email", e.target.value)
                      }
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Hidden only={"xs"}>
                <Grid item md={5} mt={4} sm={5} xs={5}>
                  <MenuImage />
                </Grid>
              </Hidden>
              {showPasswordField && formik.values.email && (
                <Grid item md={6} sm={12} xs={12}>
                  <FHLabel label={"Password"} />
                  <InputBox
                    style={{ width: "100%" }}
                    type={showPassword ? "text" : "password"}
                    value={password}
                    endadornment={
                      <InputAdornment
                        position="end"
                        className="pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityIcon style={{ cursor: "pointer" }} />
                        ) : (
                          <VisibilityOffIcon style={{ cursor: "pointer" }} />
                        )}
                      </InputAdornment>
                    }
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <Typography
                    style={{ whiteSpace: "normal", fontSize: 12, color: "red" }}
                  >
                    {password.length < 6 ? "Atleast 6 latters" : ""}
                  </Typography>
                </Grid>
              )}
              {!botConfig && (
                <>
                  <Grid item md={6} sm={12} xs={12}>
                    <FHLabel label={"First Name"} />
                    <FHTextfield
                      fullWidth
                      id={"firstName"}
                      type={"text"}
                      name={"First Name"}
                      width={"100%"}
                      placeholder="Enter first name"
                      value={formik.values.firstName}
                      onChange={(e) =>
                        formik.setFieldValue("firstName", e.target.value)
                      }
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <FHLabel label={"Last Name"} />
                    <FHTextfield
                      fullWidth
                      id={"lastName"}
                      type={"text"}
                      name={"Last Name"}
                      width={"100%"}
                      // disabled={formik.values === "locId"}
                      placeholder="Enter Last name"
                      value={formik.values.lastName}
                      // onChange={formik.handleChange}
                      onChange={(e) =>
                        formik.setFieldValue("lastName", e.target.value)
                      }
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </Grid>
                </>
              )}
              <Grid item md={6} sm={12} xs={12}>
                <FHLabel label={"Status"} />
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={formik.values.status}
                  onChange={(e) =>
                    formik.setFieldValue("status", e.target.value)
                  }
                >
                  <FormControlLabel
                    value={"Active"}
                    control={
                      <Radio
                        sx={{
                          color: colors.lightGrey,
                          "&.Mui-checked": {
                            color: colors.success,
                          },
                        }}
                      />
                    }
                    label="ACTIVE"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontWeight: 600,
                        fontSize: 14,
                      },
                    }}
                  />
                  <FormControlLabel
                    value={"inActive"}
                    control={
                      <Radio
                        sx={{
                          color: colors.lightGrey,
                          "&.Mui-checked": {
                            color: colors.success,
                          },
                        }}
                      />
                    }
                    label="INACTIVE"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontWeight: 600,
                        fontSize: 14,
                      },
                    }}
                  />
                </RadioGroup>
              </Grid>
              {botConfig && (
                <Grid item md={6} sm={12} xs={12}>
                  <FHLabel label={"App config"} />
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={formik.values.appConfig}
                    onChange={(e) =>
                      formik.setFieldValue("appConfig", e.target.value)
                    }
                  >
                    <FormControlLabel
                      value={"dev"}
                      control={
                        <Radio
                          sx={{
                            color: colors.lightGrey,
                            "&.Mui-checked": {
                              color: colors.success,
                            },
                          }}
                        />
                      }
                      label="Dev"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontWeight: 600,
                          fontSize: 14,
                        },
                      }}
                    />
                    <FormControlLabel
                      value={"prod"}
                      control={
                        <Radio
                          sx={{
                            color: colors.lightGrey,
                            "&.Mui-checked": {
                              color: colors.success,
                            },
                          }}
                        />
                      }
                      label="Prod"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontWeight: 600,
                          fontSize: 14,
                        },
                      }}
                    />
                  </RadioGroup>
                </Grid>
              )}

              <Grid item md={12} sm={12} xs={12}>
                <FHLabel label={"Address"} />
                <FHTextfield
                  fullWidth
                  multiline
                  rows={4}
                  id={"address"}
                  type={"text"}
                  name={"Address"}
                  width={"100%"}
                  // disabled={formik.values === "locId"}
                  placeholder="Enter Address"
                  value={formik.values.address}
                  // onChange={formik.handleChange}
                  onChange={(e) =>
                    formik.setFieldValue("address", e.target.value)
                  }
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              {botConfig ? (
                <Grid item md={6} sm={12} xs={12}>
                  <FHLabel label={"User Store"} />
                  <FHAutocomplete
                    multiple={false}
                    options={filteredDropDown}
                    // defaultValue={[]}
                    // disabled={}
                    value={formik.values.locId}
                    singleselect={"true"}
                    id="locId"
                    name="locId"
                    placeholder="Select locations"
                    onChange={(e, newValue) => {
                      formik.setFieldValue("locId", newValue);
                      formik.setFieldValue("locIds", [newValue]);
                    }}
                    // chip={map(formik.values.locIds, (v, i) => (
                    //   <Box mb={1} key={i}>
                    //     <Chip
                    //       label={
                    //         <Typography
                    //           style={{ whiteSpace: "normal", fontSize: 14 }}
                    //         >
                    //           {v?.Name}
                    //         </Typography>
                    //       }
                    //       variant="filled"
                    //       onDelete={() => {
                    //         onDeleteLoc(v.Name);
                    //       }}
                    //       style={{ background: "#F0F4FF", color: "black" }}
                    //     />
                    //   </Box>
                    // ))}
                    error={formik.touched.locId && Boolean(formik.errors.locId)}
                    helperText={formik.touched.locId && formik.errors.locId}
                  />
                </Grid>
              ) : (
                <Grid item md={6} sm={12} xs={12}>
                  <FHLabel label={"User`s Store"} />
                  <FHAutocomplete
                    multiple
                    options={filteredDropDown}
                    defaultValue={[]}
                    // disabled={}
                    value={formik.values.locIds || ""}
                    id="locIds"
                    name="locIds"
                    placeholder="Select locations"
                    onChange={(e, newValue) =>
                      formik.setFieldValue("locIds", newValue)
                    }
                    chip={map(formik.values.locIds, (v, i) => (
                      <Box mb={1} key={i}>
                        <Chip
                          label={
                            <Typography
                              style={{ whiteSpace: "normal", fontSize: 14 }}
                            >
                              {v?.Name}
                            </Typography>
                          }
                          variant="filled"
                          onDelete={() => {
                            onDeleteLoc(v.Name);
                          }}
                          style={{ background: "#F0F4FF", color: "black" }}
                        />
                      </Box>
                    ))}
                    error={
                      formik.touched.locIds && Boolean(formik.errors.locIds)
                    }
                    helperText={formik.touched.locIds && formik.errors.locIds}
                  />
                </Grid>
              )}
              <Grid item md={12} sm={12} xs={12} textAlign={"right"}>
                <FHButton
                  loading={loading}
                  name={"Save"}
                  variant="outlined"
                  color={colors.success}
                  small
                  w_40
                  // disabled={
                  //   showItemMaxUses || showLocMaxUses || showUserMaxUses
                  // }
                  commonButtonStyle
                  onClick={formik.handleSubmit}
                  // errors={validated ? validate.errors : undefined}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};
