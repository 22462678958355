import { MenuCard } from "../containers/Dashboard/MenuCard";
import { PromoCode } from "../containers/Dashboard/PromoCode";
import { OrdersCard } from "../containers/Dashboard/OrdersCard";
import { UserCard } from "../containers/Dashboard/UserCard";
import { FeedbackCard } from "../containers/Dashboard/FeedbackCard";

export const dashboardTabs = [
  { index: '1', tabLabel: "Menu", component: <MenuCard />, redux: "MENUCARD" },
  {
    index: '2',
    tabLabel: "Promocodes",
    component: <PromoCode />,
    redux: "PROMOCARD",
  },
  {
    index: '3',
    tabLabel: "Orders",
    component: <OrdersCard />,
    redux: "ORDERSCARD",
  },
  { index: '4', tabLabel: "Users", component: <UserCard />, redux: "USERSCARD" },
  {
    index: '5',
    tabLabel: "Feedbacks",
    component: <FeedbackCard />,
    redux: "FEEDBACKCARD",
  },
];
