/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  locations: [],
  locationDropdown: [],
  selectedLocation: "",
  updatingOutlet: false,
  allLocations: [],
};

// Types
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_ALL_LOCATIONS = "GET_ALL_LOCATIONS";

//Actions
export const STORE_LOCATIONS = "STORE_LOCATIONS";
export const STORE_ALL_LOCATIONS = "STORE_ALL_LOCATIONS";
export const LOCATION_DROPDOWN = "LOCATION_DROPDOWN";
export const STORE_SELECTED_LOCATION = "STORE_SELECTED_LOCATION";
export const UPDATING_OUTLET_STATUS = "UPDATING_OUTLET_STATUS";
export const LOGOUT = "LOGOUT";
// Reducers
export const locationReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case STORE_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    case STORE_ALL_LOCATIONS:
      return {
        ...state,
        allLocations: action.payload,
      };
    case LOCATION_DROPDOWN:
      return {
        ...state,
        locationDropdown: action.payload,
      };
    case STORE_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload,
      };
    case UPDATING_OUTLET_STATUS:
      return {
        ...state,
        updatingOutlet: action.payload,
      };
    case LOGOUT:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};
