import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  divider: {
    opacity: "1",
    height: "3px",
    backgroundImage: "linear-gradient(45deg, transparent, white,transparent)",
  },
  drawerPaper: {
    background: "linear-gradient(90deg, #ED3024 0%, #AD1209 100%)",
    color: `#fff`,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
  },
  menuText: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 33,
    color: "rgba(255, 255, 255, 0.5);",
  },
  iconButton: {
    // marginLeft
    color: "blue",
    backgroundColor: "#fffff",
    height: 15,
    width: 15,
    marginRight: -40,
  },
  toolbarLogo: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
  },
  selectedMenu: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 33,
    color: "#ffffff",
		textDecoration:'underline'
  },
}));
