import React from 'react'
import { Grid, Typography } from '@mui/material'
import { get } from 'lodash'

const errorTitle = 'Display error'
const errorSubTitle = 'Something went wrong. Error Details: '

export default class ErrorBoundries extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
            error: '',
            errorInfo: ''
        }
    }

    componentDidCatch(error, errorInfo) {
        console.log('Error!', error)
        console.log('ErrorInfo!', errorInfo)
        this.setState({ error, errorInfo })
    }


    static getDerivedStateFromError(error) {
        console.log('Error')
        return { hasError: true }
    }

    render() {
        if (this.state.error) {
            return <Grid container>
                <Grid item xs={12}>
                    <Typography variant='h2'>{errorTitle}</Typography>
                    <Typography variant='h3'>{errorSubTitle}</Typography>
                    <Typography variant='h4'>{this.state.error && this.state.error.toString()}</Typography><br/>
                    <Typography variant='h4'>{get(this.state, 'errorInfo.componentStack')}</Typography>
                </Grid>
            </Grid>
        } else return this.props.children

    }
}