import {
  collection,
  where,
  query,
  onSnapshot,
  limit,
  orderBy,
} from "firebase/firestore";
import { db } from "../App";
import store from "../stores";

function filterItems(collectionRef, filters) {
  const startDate = filters.payload?.startDate || "";
  const endDate = filters.payload?.endDate || "";
  let q = collectionRef;
  if (startDate && endDate) {
    q = query(q, where("created.at", ">=", startDate));
    q = query(q, where("created.at", "<=", endDate));
    q = query(q, orderBy("created.at", "desc"));
  } else if (startDate) {
    q = query(q, where("created.at", ">=", startDate));
    q = query(q, orderBy("created.at", "desc"));
  } else {
    q = query(q, orderBy("created.at", "desc"), limit(100));
  }
  return q;
}

export const activities = (data) => {
  let locIds = data.payload?.locIds;
  const startDate = data.payload?.startDate || "";
  const endDate = data.payload?.endDate || "";
  let activityData = [];
  for (let locId of locIds) {
    const collectionRef = collection(db, "activities", locId, "_");
    const q = filterItems(collectionRef, data);
    onSnapshot(q, (querySnapshot) => {
      const activitiesData = querySnapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      if (startDate && endDate) {
        const archiveRef = collection(db, "activities", locId, "archive");
        const qs = filterItems(archiveRef, data);
        onSnapshot(qs, (querySnapshot1) => {
          const archiveData = querySnapshot1?.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          });
          activityData = [...activityData, ...activitiesData, ...archiveData];
          store.dispatch({
            type: "STORE_ACTIVITIES",
            payload: activityData,
          });
        });
      } else {
        activityData = [...activityData, ...activitiesData];
        store.dispatch({
          type: "STORE_ACTIVITIES",
          payload: activityData,
        });
      }
    });
  }
};
