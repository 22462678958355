import React, { useEffect, useState, useRef } from "react";
import {
	Typography,
	useMediaQuery,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material";
import {
	getOrdersByDateFilter,
	getSubArray
} from "../../../services/utils";
import { makeStyles, withStyles } from '@mui/styles';
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, map, filter, get } from "lodash";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { graphOptions } from './commonSalesFunctions'


ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);


const useStyles = makeStyles({
	table: {
		width: '100%',
		"& .MuiTableCell-root": {
			border: '1.5px dashed rgba(71, 71, 71, 0.12)',
			height: '100%'
		},
	},
	amountBackground: {
		padding: '2px 10px',
		justifyContent: 'center',
		alignItems: 'center',
		gap: 10,
		borderRadius: 3,
		background: 'rgba(98, 187, 70, 0.12)'
	}
});

const StickyTableCell = withStyles((theme) => ({
	head: {
		// backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		left: 0,
		position: "sticky",
		zIndex: theme.zIndex.appBar + 10
	},
	body: {
		// backgroundColor: "#ddd",
		minWidth: "50px",
		left: 0,
		position: "sticky",
		zIndex: theme.zIndex.appBar + 1
	}
}))(TableCell);


export const DailySalesTable = () => {
	const classes = useStyles();
	const mobileView = useMediaQuery("(max-width:920px)");

	const selectedSalesLocation = useSelector(
		(state) => state.salesReducer.salesLocation
	);
	const salesDateRange = useSelector((state) => state.salesReducer.salesDateRange);
	const currentLocMenu = useSelector(
		(state) => state.menuReducer.currentLocationMenu
	);
	const allAdminUsers = useSelector((state) => state.userReducer.allAdminUsers);
	const historyData = useSelector((state) => state.orderReducer.historyData);
	let ordersByLocation = map(
		filter(historyData, (data) =>
			data.locationId === selectedSalesLocation?.d?.locId && data.hasOwnProperty("deliveredAt")
		), item => item)
	let filteredOrders = []
	if (!isEmpty(ordersByLocation)) {
		filteredOrders = getOrdersByDateFilter(ordersByLocation, salesDateRange);
	}
	const isOrderNotDoneByAdmin = (data) => {
		let getAdmin = filter(allAdminUsers, item => item.uid === data.uid)
		if (isEmpty(getAdmin) || !isEmpty(getAdmin) && getAdmin[0]?.role !== "SuperAdmin" || !isEmpty(getAdmin) && getAdmin[0]?.role !== "Admin") {
			return true
		}
		return false
	}
	let nonAdminOrders = filter(filteredOrders, item => item.txnId !== '' || item.txnId === '' && isOrderNotDoneByAdmin(item))
	// console.log("nonAdminOrders", nonAdminOrders)

	let orderedItems = getSubArray(map(nonAdminOrders, item => map(item.cartInfo, sItem => { return { ...sItem, deliveredAt: item.deliveredAt, createdAt: item.createdAt } })))
	let menuAtLocation =
		map(
			filter(currentLocMenu, (data) =>
				data.locationId === selectedSalesLocation?.d?.locId
			), item => item)

	const getOrderByTimeSlot = (time, start, end) => {
		let t1 = new Date(time * 1000);
		let t2 = t1.getHours() * 60 + t1.getMinutes();
		if (t2 >= start * 60 && t2 <= end * 60) {
			return true;
		} return false;
	};

	const [tableData, setTableData] = useState([])
	// console.log("tableData", tableData)

	const getTableData = (menuAtLocation) => {
		let finalData = map(menuAtLocation, (data) => {
			return {
				menuName: data.name,
				itemPrice: data.price,
				price: map(filter(orderedItems, item => item.id === data.id), sItem => sItem.price * sItem.quantity),
				totalQuantity: map(filter(orderedItems, item => item.id === data.id), sItem => sItem.quantity),
				dailySales: [
					{
						timeSlot: '4-8 am',
						quantity: map(filter(orderedItems, item => item.id === data.id && getOrderByTimeSlot(item?.deliveredAt?.seconds, 4, 7.59)), sItem => sItem.quantity)
					},
					{
						timeSlot: '8-12 am',
						quantity: map(filter(orderedItems, item => item.id === data.id && getOrderByTimeSlot(item?.deliveredAt?.seconds, 8, 11.59)), sItem => sItem.quantity),
					},
					{
						timeSlot: '12-4 pm',
						quantity: map(filter(orderedItems, item => item.id === data.id && getOrderByTimeSlot(item?.deliveredAt?.seconds, 12, 15.59)), sItem => sItem?.quantity),
					},
					{
						timeSlot: '4-8 pm',
						quantity: map(filter(orderedItems, item => item.id === data.id && getOrderByTimeSlot(item?.deliveredAt?.seconds, 16, 19.59)), sItem => sItem.quantity),
					},
					{
						timeSlot: '8-11.59 pm',
						quantity: map(filter(orderedItems, item => item.id === data.id && getOrderByTimeSlot(item?.deliveredAt?.seconds, 20, 23.59)), sItem => sItem.quantity),
					},
					{
						timeSlot: '12-4 am',
						quantity: map(filter(orderedItems, item => item.id === data.id && getOrderByTimeSlot(item?.deliveredAt?.seconds, 0, 4)), sItem => sItem.quantity),
					}
				]
			}
		})
		return finalData
	}

	const sortedArray = [...tableData].sort((item1, item2) => {
		const sum1 = item1?.totalQuantity.reduce((acc, val) => acc + val, 0);
		const sum2 = item2?.totalQuantity.reduce((acc, val) => acc + val, 0);
		return sum2 - sum1; // Sort in descending order (largest sum first)
	});

	let tqy = map(tableData, item => item?.totalQuantity.reduce((a, b) => a + b, 0))
	let tpc = map(tableData, item => item?.price.reduce((a, b) => a + b, 0))
	// console.log("tqy", tqy.reduce((a, b) => a + b, 0), tpc.reduce((a, b) => a + b, 0))

	const getTableHead = (time) => {
		const timestamp = time * 1000; // Convert Unix timestamp to milliseconds
		const date = new Date(timestamp);

		const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
		const formattedDate = date.toLocaleDateString('en-US', options);
		return formattedDate
	}

	useEffect(() => {
		let res = getTableData(menuAtLocation)
		setTableData(res)
	}, [selectedSalesLocation, salesDateRange, currentLocMenu])



	//*************************************graph data***************************************************//



	let graphDataByTimeSlot = [
		{
			totalPrice: map(filter(nonAdminOrders, item => getOrderByTimeSlot(item?.deliveredAt?.seconds, 4, 7.59)), sItem => sItem.orderTotal
			),
		},
		{
			totalPrice: map(filter(nonAdminOrders, item => getOrderByTimeSlot(item?.deliveredAt?.seconds, 8, 11.59)), sItem => sItem.orderTotal),
		},
		{
			totalPrice: map(filter(nonAdminOrders, item => getOrderByTimeSlot(item?.deliveredAt?.seconds, 12, 15.59)), sItem => sItem?.orderTotal),
		},
		{
			totalPrice: map(filter(nonAdminOrders, item => getOrderByTimeSlot(item?.deliveredAt?.seconds, 16, 19.59)), sItem => sItem.orderTotal),
		},
		{
			totalPrice: map(filter(nonAdminOrders, item => getOrderByTimeSlot(item?.deliveredAt?.seconds, 20, 23.59)), sItem => sItem.orderTotal),
		},
		{
			totalPrice: map(filter(nonAdminOrders, item => getOrderByTimeSlot(item?.deliveredAt?.seconds, 0, 3.59)), sItem => sItem.orderTotal),
		}
	]


	const data = {
		labels: ["4-8 am", "8-12 pm", "12-4 pm", "4-8 pm", "8-12 am", "12-4 am"],
		datasets: [{
			label: 'Today',
			data: map(graphDataByTimeSlot, item => item?.totalPrice.reduce((a, b) => a + b, 0)),
			fill: false,
			backgroundColor: '#62BB46',
			borderColor: '#62BB46',
			borderWidth: 1
		}]



	};

	//***************************************************************************************//

	return (
		<Box>
			<Box p={1} style={{ position: 'sticky' }}>
				<Typography fontSize={22} fontWeight={600} style={{ opacity: 0.7 }}>{getTableHead(salesDateRange?.startDateInSeconds)}</Typography>
				<Typography fontSize={17} fontWeight={600} style={{ opacity: 0.7 }}>Total Sales: {tqy?.reduce((a, b) => a + b, 0)}</Typography>
			</Box>
			<Box mt={2} mb={3} p={1} sx={{ boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.12)', borderRadius: 1, border: '1px solid rgba(0, 0, 0, 0.12)' }}>
				<Line data={data} options={graphOptions} />
			</Box>
			<TableContainer component={Paper} sx={{ height: mobileView ? 600 : 'auto' }}>

				<Table
					className={classes.table}
					sx={{
						width: '100%',
						boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.12)'
					}} stickyHeader aria-label="sticky table">
					<TableHead>
					</TableHead>
					<TableHead>
						<TableRow>
							<TableCell align="center" style={{ fontSize: 13, fontWeight: 600 }}>4-8 am</TableCell>
							<TableCell align="center" style={{ fontSize: 13, fontWeight: 600 }}>8-12 pm</TableCell>
							<TableCell align="center" style={{ fontSize: 13, fontWeight: 600 }}>12-4 pm</TableCell>
							<TableCell align="center" style={{ fontSize: 13, fontWeight: 600 }}>4-8 pm</TableCell>
							<TableCell align="center" style={{ fontSize: 13, fontWeight: 600 }}>8-12 am</TableCell>
							<TableCell align="center" style={{ fontSize: 13, fontWeight: 600 }}>12-4 am</TableCell>
							<TableCell align="center" style={{ fontSize: 13, fontWeight: 600 }}>Total Qty</TableCell>
							<TableCell align="center" style={{ fontSize: 13, fontWeight: 600 }}>Total Price</TableCell>

						</TableRow>
					</TableHead>
					{/* </Table> */}
					{/* <div style={{ height: "400px", overflowY: "scroll", width:'100%' }}> */}
					{/* <Table > */}
					<TableBody >
						{map(sortedArray, (row) => (
							<>
								<TableRow>
									<TableCell colSpan={8} scope="row" style={{ width: 80, padding: 0, background: '#EFEFEF', height:30 }}>
										<Typography fontSize={13} fontWeight={600} ml={1}>{row.menuName} (&#8377;{row.itemPrice})</Typography>
									</TableCell>
								</TableRow>
								<TableRow>
									{map(row.dailySales, rItem =>
										<TableCell component="th" scope="row" align="center" style={{ fontSize: 13, padding: 0 }}>{`${rItem?.quantity.reduce(
											(a, b) => a + b,
											0,
										)}`}</TableCell>
									)}

									<TableCell component="th" scope="row" align="center" style={{ padding: 0 }}>
										<Typography fontSize={13.5}>{`${row?.totalQuantity.reduce(
											(a, b) => a + b,
											0,
										)}`}</Typography>
									</TableCell>
									<TableCell component="th" scope="row" align="center" style={{ padding: 0 }}>

										<Typography fontSize={12}>&#8377;{`${row?.price.reduce(
											(a, b) => a + b,
											0,
										)}`}</Typography>
									</TableCell>

								</TableRow>
							</>
						))}
					</TableBody>
				</Table>
				{/* </div> */}

			</TableContainer>
		</Box >
	)
}
