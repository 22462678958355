import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  useMediaQuery,
  Typography,
  Divider,
  Card,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FHCard from "../../components/FHCard";
import { map, filter, isEmpty } from "lodash";
import colors from "../../themes/colors";

export const BotStatus = (props) => {
  const extractedData = props.filteredBotData;
  const mobileView = useMediaQuery("(max-width:920px)");

  const dispatch = useDispatch();
  const [allEmpty, setAllEmpty] = useState(false);
  const [botInfo, setBotInfo] = useState({});
  const [nonEmptyKeys, setNonEmptyKeys] = useState([]);

  console.log("botInfo", extractedData, botInfo);

  // function transformData(data) {
  // 	const transformedData = {};
  // 	data.forEach((item) => {
  // 		const { subSystem, dcMotors, lss, motors, relays } = item;
  // 		const subsystemKey = `${subSystem}-`;
  // 		transformedData[subsystemKey + "dcMotors"] = dcMotors;
  // 		transformedData[subsystemKey + "lss"] = lss;
  // 		transformedData[subsystemKey + "motors"] = motors;
  // 		transformedData[subsystemKey + "relays"] = relays;
  // 	});
  // 	const result = [{ status: transformedData }];
  // 	return result;
  // }

  function transformData(data) {
    const transformedData = {};
    data.forEach((item) => {
      const { subSystem, status } = item;
      if (status && typeof status === "object") {
        Object.keys(status).forEach((key) => {
          const dynamicKey = `${subSystem}-${key}`;
          transformedData[dynamicKey] = status[key];
          // console.log("keys",subSystem, key, transformedData[dynamicKey], transformedData)
        });
      }
    });
    const result = [{ status: transformedData }];
    return result;
  }

  useEffect(() => {
    let statusData = transformData(extractedData);
    setBotInfo(statusData);
    const isAllEmpty =
      statusData[0].status &&
      Object.keys(statusData[0].status).every((key) => {
        const obj = statusData[0].status[key];
        return typeof obj === "object" && Object.keys(obj).length === 0;
      });
    if (isAllEmpty === true) {
      setAllEmpty(isAllEmpty);
    } else {
      setAllEmpty(false);
    }

    const keys = Object.keys(statusData[0].status);
    const nonEmptyKeys = keys.filter((key) => {
      const obj = statusData[0].status[key];
      return typeof obj === "object" && Object.keys(obj).length > 0;
    });
    setNonEmptyKeys(nonEmptyKeys);
  }, [extractedData]);

  return (
    <Card
      elevation={0}
      style={{
        borderRadius: 6,
        padding: "10px 20px",
        background: colors.white,
        height: "auto",
        maxHeight: mobileView ? "auto" : 600,
        overflow: "hidden",
        columnCount: nonEmptyKeys.length <= 5 || mobileView ? 1 : 7,
        // columnWidth: 200,
        // columnGap: 5,
      }}
    >
      <Grid container p={0.5}>
        {allEmpty ? (
          <Box p={3}>
            <Typography fontSize={20}>No status available</Typography>
          </Box>
        ) : (
          <>
            {map(botInfo, (data, i) => (
              <Box display={"flex"} flexDirection={"column"} p={0.2}>
                {map(Object.keys(data?.status), (dItem, index) => (
                  <div style={{ marginBottom: 3 }}>
                    {!isEmpty(data?.status[dItem]) ||
                    (typeof data?.status[dItem] === "number" &&
                      data?.status[dItem] !== 0) ||
                    typeof data?.status[dItem] === "boolean" ? (
                      <>
                        <Typography
                          fontSize={11.5}
                          fontWeight={600}
                          color={"#474747"}
                        >
                          {dItem}
                        </Typography>
                        <>
                          {typeof data?.status[dItem] === "object" ? (
                            <>
                              {map(
                                Object.keys(data?.status[dItem]),
                                (bData, index) => (
                                  <>
                                    {dItem.includes("system-") ? (
                                      <>
                                        <Typography
                                          fontSize={11}
                                          fontWeight={500}
                                          color={"black"}
                                        >
                                          {bData}-
                                          {Object.values(
                                            data?.status[dItem][bData]
                                          ).join(", ")}
                                        </Typography>
                                      </>
                                    ) : (
                                      <Typography
                                        fontSize={11}
                                        fontWeight={500}
                                        color={
                                          data?.status[dItem][bData] ===
                                            "BUSY" ||
                                          data?.status[dItem][bData] === "ON" ||
                                          data?.status[dItem][bData] === true
                                            ? "#62BB46"
                                            : "black"
                                        }
                                      >
                                        {bData}
                                      </Typography>
                                    )}
                                  </>
                                )
                              )}
                            </>
                          ) : typeof data?.status[dItem] === "boolean" ? (
                            <>
                              {data?.status[dItem] === true ? (
                                <Typography fontSize={11} fontWeight={500}>
                                  true
                                </Typography>
                              ) : (
                                <Typography fontSize={11} fontWeight={500}>
                                  false
                                </Typography>
                              )}
                            </>
                          ) : (
                            <>
                              <Typography
                                fontSize={11}
                                fontWeight={500}
                                color={"black"}
                                style={{
                                  maxWidth: 170,
                                  wordWrap: "break-word",
                                }}
                              >
                                {data?.status[dItem]}
                              </Typography>
                            </>
                          )}
                        </>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </Box>
            ))}
          </>
        )}
      </Grid>
    </Card>
  );
};
