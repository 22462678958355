import React, { useEffect, useState } from "react";
import {
  Grid,
  List,
  ListItem,
  Typography,
  Popover,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import colors from "../../themes/colors";
import { filter, map, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";
import { AddOrEditMenu } from "../../containers/Settings/AddOrEditMenu";
import { FHDrawer } from "../../components/FHDrawer";
import EditOrDuplicate from "./EditOrDuplicate";
import {
  addItemToLocation,
  addDuplicateCommonItem,
  categories,
} from "../../services/menuServices";
import FHButton from "../../components/FHButton";
import { notify } from "../../services/utils";
import { FHAutocomplete } from "../../components/FormInputs/FHAutocomplete";
import { FHLabel } from "../../components/FormInputs/FHLabel";
import { FHTextfield } from "../../components/FormInputs/FHTextfield";
import FHDialogBox from "../../components/FHDialogBox";

const customStyles = makeStyles(commonClasses);

export const MenuItemList = (props) => {
  const common = customStyles(commonClasses);
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);
  const [menuData, setMenuData] = useState();
  const [AddComItem, setAddComItem] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [addItemPop, setAddItemPop] = useState(false);
  const [category, setCategory] = useState();

  const selectedMenuDetails = useSelector(
    (state) => state.menuReducer.selectedMenuDetails
  );

  const menuLocation = useSelector((state) => state.menuReducer.menuLocation);
  const user = useSelector((state) => state.userReducer.user);
  const commonMenu = useSelector((state) => state.menuReducer.commonMenu);
  const categories = useSelector((state) => state.menuReducer.categories);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    dispatch({ type: "STORE_SELECTED_MENU_DETAILS", payload: item });
  };
  const handleAddCommonItem = (event) => {
    setAddItemPop(!addItemPop);
  };
  const handleAddItem = (item) => {
    setAddItemPop(!addItemPop);
    setAnchorEl(null);
    let commonItem = {
      ...item,
      id: item.id.concat("@", menuLocation.d.locId),
    };
    addItemToLocation(commonItem)
      .then((res) => {
        notify("Menu Item Added successfully");
        let payload = {
          msg: `Menu Item Added successfully`,
          botId: "",
          activityType: "updates",
          created: {
            at: new Date(),
            name: user?.name || user.firstName,
            uid: user?.uid,
          },
          initiator: "superAdmin",
          logLevel: "info",
          metaData: {
            itemName: item?.name,
            itemId: item?.itemId,
            locId: menuLocation?.d?.locId || "common",
          },
        };
        dispatch({
          type: "LOG_ACTIVITY",
          payload: payload,
        });
      })
      .catch((err) => {
        notify("Error while Adding");
      });
  };

  const handleClose = (name) => {
    if (name === "Edit") {
      setDrawer(true);
    }
    if (name === "Duplicate") {
      addDuplicateCommonItem(selectedMenuDetails);
      notify("Duplicate Menu Added successfully");
      let payload = {
        msg: `Duplicate Menu Added successfully`,
        botId: "",
        activityType: "updates",
        created: {
          at: new Date(),
          name: user?.name || user.firstName,
          uid: user?.uid,
        },
        initiator: "superAdmin",
        logLevel: "info",
        metaData: {
          itemName: selectedMenuDetails?.name,
          itemId: selectedMenuDetails?.itemId,
          locId: menuLocation?.d?.locId || "common",
        },
      };
      dispatch({
        type: "LOG_ACTIVITY",
        payload: payload,
      });
    }
    setAddComItem(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  const currentLocMenu = useSelector(
    (state) => state.menuReducer.currentLocationMenu
  );

  let menus = [];
  let menuIds = [];
  map(currentLocMenu, (item) => {
    if (item?.locationId === menuLocation?.d?.locId) {
      menus.push({ ...item });
      menuIds.push(item.itemId);
    }
  });

  useEffect(() => {
    setMenuData(menus);
  }, [menuLocation, currentLocMenu]);

  return (
    <Box>
      <Grid container mb={1}>
        <Grid item xs={8} sm={6}>
          <Typography fontSize={18} fontWeight={700}>
            Menu
          </Typography>
        </Grid>
        <Grid item xs={4} sm={6} textAlign={"right"}>
          {!isEmpty(menuLocation) && (
            <FHButton
              name={"Add +"}
              variant="outlined"
              smallAddButton
              commonButtonStyle
              onClick={(e) => handleAddCommonItem(e)}
            />
          )}
        </Grid>
      </Grid>
      {!isEmpty(menuLocation) ? (
        <List className={common.listalign}>
          {!isEmpty(menuData) ? (
            <>
              {map(
                filter(
                  menuData,
                  (data) => data.locationId === menuLocation?.d?.locId
                ),
                (item, i) => (
                  <ListItem
                    key={i}
                    className={[common.menulistItemalign, common.bgMain].join(
                      " "
                    )}
                    onClick={(e) => handleClick(e, item)}
                  >
                    <Grid container>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <Typography
                          fontSize={15}
                          color={colors.darkGrey}
                          style={{
                            // margin: 0,
                            width: "320px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item.name}
                        </Typography>

                        <Typography
                          textAlign={"right"}
                          fontSize="16px"
                          color={colors.darkGrey}
                        >
                          Rs.{item.price}
                        </Typography>
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Typography
                          style={{
                            // margin: 0,
                            width: "300px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          // mb
                          fontSize={12}
                          color={colors.LighterGrey}
                        >
                          {item?.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                )
              )}
            </>
          ) : (
            <Box mt={2} justifyContent={"centeter"}>
              <Typography>No Menu Items.</Typography>
            </Box>
          )}
        </List>
      ) : (
        <Box justifyContent={"center"}>
          <List className={common.listalign}>
            {map(commonMenu, (item, i) => (
              <ListItem
                key={i}
                mt={1}
                className={[common.menulistItemalign, common.bgMain].join(" ")}
                onClick={(e) => handleClick(e, item)}
              >
                <Grid container>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <Typography
                      fontSize={15}
                      color={colors.darkGrey}
                      style={{
                        // margin: 0,
                        width: "320px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.name}
                    </Typography>

                    <Typography
                      textAlign={"right"}
                      fontSize="16px"
                      color={colors.darkGrey}
                    >
                      Rs.{item.price}
                    </Typography>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Typography
                      style={{
                        // margin: 0,
                        maxWidth: "300px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      // mb
                      fontSize={12}
                      color={colors.LighterGrey}
                    >
                      {item?.description}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      <FHDialogBox
        open={addItemPop}
        handleClose={() => setAddItemPop(!addItemPop)}
        body={
          <Box style={{ width: "420px" }}>
            <FHLabel label={"Category"} />
            <FHTextfield
              select
              fullWidth
              id={"category"}
              type={"text"}
              name={"category"}
              width={"100%"}
              // disabled={formik.values === "locId"}
              placeholder="Enter category"
              value={category||""}
              onChange={(e) => setCategory(e.target.value)}
            >
              {categories?.map((option) => (
                <MenuItem key={option.id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </FHTextfield>

            <Grid container spacing={1} mt={1}>
              <Grid item md={12} sm={12} xs={12} mb={0.5}>
                <Typography>Results</Typography>
              </Grid>

              {map(
                filter(
                  commonMenu,
                  (data) =>
                    !menuIds.includes(data.itemId) && data?.category == category
                ),
                (u, i) => (
                  <Grid
                    item
                    key={i}
                    md={6}
                    sm={6}
                    xs={6}
                    onClick={(e) => handleAddItem(u)}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      gap="12px"
                      key={i}
                      p={1}
                      style={{
                        border: "1px solid #ACACAC",
                        borderRadius: "5px",
                      }}
                      alignItems="center"
                      textAlign={"center"}
                      className={common.mousecursor}
                    >
                      <Typography
                        sx={{
                          color: "black",
                        }}
                      >
                        {u.name}
                      </Typography>
                    </Box>
                  </Grid>
                )
              )}
            </Grid>
          </Box>
        }
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <EditOrDuplicate
          editAndDuplicate={true}
          anchorEl={anchorEl}
          onPopClose={handleClose}
        />
      </Popover>
      <FHDrawer
        open={drawer}
        onClose={() => {}}
        onOpen={() => {}}
        drawerBorder
        data={<AddOrEditMenu onClose={() => setDrawer(false)} />}
      />
    </Box>
  );
};
