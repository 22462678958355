import React, { useState } from "react";
import { Button, Popover, Typography } from "@mui/material";
import Filter from "../../assets/icons/ordersMenu/Filter.png";
import { Box } from "@mui/system";
import colors from "../../themes/colors";
import { isEmpty, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { filterDropdown } from "../../services/utils";
import { makeStyles } from "@mui/styles";
import commonClasses from "../../themes/commonClasses";

const customStyles = makeStyles(commonClasses);

const commonStyles = {
  m: 1,
  color: colors.linegrey,
  width: "10rem",
  height: "1rem",
};

export const StatusFilter = () => {
  const common = customStyles(commonClasses);
  const dispatch = useDispatch();

  const selectedFilter = useSelector(
    (state) => state.orderReducer.selectedIcon
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        sx={{
					width:20,
					height:35.5,
          display: "flex",
          justifyContent: "center",
          backgroundColor: !isEmpty(selectedFilter)
            ? `${colors.success}`
            : `${colors.main}`,
        }}
      >
        {/* <Typography fontSize={15}>Filter</Typography> */}
        <img src={Filter} alt="Filter" />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ sx: { border: `2px solid ${colors.success}` } }}
      >
        {map(filterDropdown, (u, i) => (
          <Box key={i}>
            <Box
              display="flex"
              justifyContent="space-end"
              gap="20px"
              key={i}
              p={1}
              sx={{
                ...commonStyles,
                borderBottom: 1,
                backgroundColor:
                  u.status === selectedFilter ? colors.success : "",
              }}
              alignItems="center"
              className={common.mousecursor}
              onClick={() => {
                dispatch({
                  type: "SHOW_SELECTED_ICON_DATA",
                  payload: u.status,
                });
                setAnchorEl(null);
              }}
            >
              <u.icon
                style={{
                  color: u.status === selectedFilter ? colors.bright : u.color,
                }}
              />
              <Typography
                textAlign="end"
                sx={{
                  color: u.status === selectedFilter ? "white" : "black",
                }}
              >
                {u.name}
              </Typography>
            </Box>
          </Box>
        ))}
        <Box
          p={0.5}
          display={"flex"}
          justifyContent={"flex-end"}
          className={common.mousecursor}
          onClick={() => {
            dispatch({ type: "SHOW_SELECTED_ICON_DATA", payload: "" });
            setAnchorEl(null);
          }}
        >
          <Typography>...Clear Filter</Typography>
        </Box>
      </Popover>
    </Box>
  );
};
