import React, { useEffect, useState } from "react";
import { Box, Grid, useMediaQuery, Typography } from "@mui/material";
import { LocationSelect } from "../LocationSelect";
import FHSelect from "../../components/FHSelect";
import FHButton from "../../components/FHButton";
import { useSelector, useDispatch } from "react-redux";
import { filter, isEmpty, map } from "lodash";
import { BiStats } from "react-icons/bi";
import { BotStatus } from "./BotStatus";
import SaveIcon from "@mui/icons-material/Save";
import { saveAs } from "file-saver";
import RefreshIcon from "@mui/icons-material/Refresh";
import FhActivityBar from "../../components/FHActivityBar";
import { FHDrawer } from "../../components/FHDrawer";
import { appInfoUpdate, botManagementStatus } from "../../services/botServices";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { convertTimestamp } from "../../services/dateTimeConversion";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const BotManagement = () => {
  const dispatch = useDispatch();
  const [selectedMachine, setSelectedMachine] = useState("");
  const [activities, setActivities] = useState(false);
  const mobileView = useMediaQuery("(max-width:920px)");

  const selectedLocation = useSelector(
    (state) => state.locationReducer.selectedLocation
  );
  const machinesList = useSelector((state) => state.botReducer.machinesInfo);
  let machinesDropdown = filter(
    machinesList,
    (item) => item?.locId === selectedLocation?.d?.locId
  );
  const userRole = useSelector((state) => state.userReducer.userRole);
  const machineSelected = useSelector(
    (state) => state.botManagementReducer.machineSelected
  );
  const botManagement = useSelector(
    (state) => state.botManagementReducer.botManagementStatus
  );
  const statusLastUpdated = useSelector(
    (state) => state.botManagementReducer.statusLastUpdated
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [dataRefreshed, setDataRefreshed] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let defaultMachine;

  let getIdliBot = filter(
    machinesDropdown,
    (item) => item?.botInfo?.botType === "ICVB"
  );
  // console.log("machinesList", machinesList, getIdliBot);

  if (!isEmpty(machinesDropdown)) {
    if (!isEmpty(getIdliBot)) {
      defaultMachine = getIdliBot[0];
    } else {
      defaultMachine = machinesDropdown[0];
    }
  }

  useEffect(() => {
    map(machinesList, (docData) => {
      let payload = {
        machineId: docData?.id || docData?.botInfo?.id,
        locId: docData?.locId,
        machineName: docData?.name || docData?.botInfo?.name,
      };
      botManagementStatus(payload);
    });
    let t = new Date();
    dispatch({
      type: "STATUS_LAST_UPDATED",
      payload: Math.floor(t.getTime() / 1000),
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(defaultMachine)) {
      setSelectedMachine(defaultMachine);
      dispatch({ type: "STORE_MACHINE_SELECTED", payload: defaultMachine });
    }
  }, [selectedLocation]);

  useEffect(() => {
    let t = new Date();
    dispatch({
      type: "STATUS_LAST_UPDATED",
      payload: Math.floor(t.getTime() / 1000),
    });
    setDataRefreshed(true);
  }, [botManagement]);

  // useEffect(() => {
  // 	let sMachine = filter(machinesList, item => item?.id === machineSelected?.id || item?.id === machineSelected?.botInfo?.id)
  // 	dispatch({ type: "STORE_MACHINE_SELECTED", payload: sMachine[0] })
  // }, [machinesList])

  const handleMachine = (e, v) => {
    dispatch({ type: "STORE_MACHINE_SELECTED", payload: v });
    setSelectedMachine(v);
  };

  const machineStatus = filter(
    botManagement,
    (item) =>
      item?.locationId === selectedLocation?.d?.locId &&
      item?.machineId === machineSelected?.id
  );

  const extractedData = map(machineStatus, (machine) => {
    if (machine.status && typeof machine.status === "object") {
      const updatedStatusObj = { ...machine.status };
      delete updatedStatusObj.relays;
      delete updatedStatusObj.motors;
      delete updatedStatusObj.updatedAt;
      delete updatedStatusObj.dcMotors;

      const sortedKeys = Object.keys(updatedStatusObj).sort();

      const sortedStatusObj = {};
      sortedKeys.forEach((key) => {
        sortedStatusObj[key] = updatedStatusObj[key];
      });

      machine.status = sortedStatusObj;
    }
    return machine;
  });

  // console.log("extracetedData", JSON.stringify(extractedData))

  // const extractedData = map(machineStatus, botItem => {
  // 	const dcMotors = botItem.status?.dcMotors || {};
  // 	const lss = botItem.status?.lss || {};
  // 	const motors = botItem.status?.motors || {};
  // 	const relays = botItem.status?.relays || {};

  // 	return {
  // 		locationId: botItem.locationId,
  // 		subSystem: botItem.subSystem,
  // 		machineId: botItem.machineId,
  // 		machineName: botItem.machineName,
  // 		dcMotors,
  // 		lss,
  // 		motors,
  // 		relays
  // 	};
  // });

  // console.log('machineStatus', updatedStatus);

  let dataToExport = map(machineStatus, (machine) => {
    if (machine.status && typeof machine.status === "object") {
      const updatedStatusObj = { ...machine.status };
      delete updatedStatusObj.relays;
      delete updatedStatusObj.motors;
      delete updatedStatusObj.updatedAt;
      delete updatedStatusObj.dcMotors;

      const sortedKeys = Object.keys(updatedStatusObj).sort();

      const sortedStatusObj = {};
      sortedKeys.forEach((key) => {
        sortedStatusObj[key] = updatedStatusObj[key];
      });

      machine.status = sortedStatusObj;
    }
    return {
      subSystem: machine.subSystem,
      status: machine.status,
    };
  });

  let machineId = machineSelected?.id || machineSelected?.botInfo?.id;
  let locId = machineSelected?.locId;
  let machineName = machineSelected?.name || machineSelected?.botInfo?.name;
  let payload = {
    machineId: machineId,
    locId: locId,
    machineName: machineName,
  };

  const handleRefresh = () => {
    setDataRefreshed(false);
    appInfoUpdate(locId, machineId)
      .then(() => {
        // setTimeout(() => {
        if (machineSelected?.appInfo?.getSensorsInfo === false) {
          botManagementStatus(payload)
            .then(() => {
              setDataRefreshed(true);
              let t = new Date();
              dispatch({
                type: "STATUS_LAST_UPDATED",
                payload: Math.floor(t.getTime() / 1000),
              });
            })
            .catch((error) => {
              console.log("Function encountered an error:", error);
            });
        }
        // }, 5000)
      })
      .catch((error) => {
        console.log("Function encountered an error:", error);
      });
  };

  const handleDownload = () => {
    let locId = selectedLocation?.d?.locId;
    let machineName = selectedMachine?.name;
    let dataToSave = {
      locationId: locId,
      machineName: machineName,
      lastUpdate: convertTimestamp(statusLastUpdated)?.pattern1,
      subSystems: [...dataToExport],
    };
    const jsonData = JSON.stringify(dataToSave, null, 2);
    const blob = new Blob([jsonData], { type: "application/json" });
    saveAs(
      blob,
      `botStatus_${locId}_${machineName}_${
        convertTimestamp(statusLastUpdated)?.pattern1
      }.json`
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDataRefreshed(false);
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item md={3} sm={12} xs={12}>
          <Box display="flex" flexDirection={"column"}>
            <Typography fontSize={20} fontWeight={700} mr={4}>
              Bot Management
            </Typography>
            <Typography fontSize={16} fontWeight={700}>
              Last Updated: {convertTimestamp(statusLastUpdated)?.pattern1}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={6} sm={12} xs={12} mt={mobileView ? 2 : 0}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <LocationSelect />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FHSelect
                height={45}
                disableClearable={true}
                name="machines"
                placeholder="Select machine"
                options={machinesDropdown || []}
                value={(!isEmpty(selectedMachine) && selectedMachine) || {}}
                onChange={(e, v) => {
                  handleMachine(e, v);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={3}
          sm={12}
          xs={12}
          display="flex"
          justifyContent={mobileView ? "flex-start" : "flex-end"}
          mt={1}
        >
          <Box mr={2}>
            <FHButton
              name={
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <RefreshIcon style={{ color: "white" }} />
                  <Typography style={{ color: "white" }}>Refresh</Typography>
                </Box>
              }
              variant="outlined"
              smallRedButton
              onClick={handleRefresh}
            />
          </Box>
          {userRole === "SuperAdmin" && (
            <FHButton
              name={
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <SaveIcon style={{ color: "white" }} />
                  <Typography style={{ color: "white" }}>Save</Typography>
                </Box>
              }
              variant="outlined"
              smallRedButton
              onClick={handleDownload}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <BotStatus filteredBotData={extractedData} />
        </Grid>
      </Grid>
      <Snackbar
        duration={200}
        open={dataRefreshed}
        onClose={handleClose}
        sx={{ backroundColor: "#62BB46", color: "white" }}
        message={`Bot status Refreshed.`}
        key={"top" + "right"}
      />
    </Box>
  );
};
