import { all, takeLatest, fork } from "redux-saga/effects";
import { login } from "./loginSaga";
import { getLocations, getAllLocations } from "./locationSaga";
import { getActivities, watchActivities } from "./activitySaga";
import {
  orderData,
  orderIncart,
  itemsIncart,
  oldOrdersByFilter,
} from "./orderSaga";
import {
  getCurrentLocationMenu,
  getCategories,
  getCurrentLocAvailMenu,
  getAllMenu,
} from "./menuSaga";
import {
  getAllUsers,
  getAllAdminUsers,
  getRegisteredUsers,
  getSelectedUSer,
  getTodaysFeedback,
  getUserFeedback,
  getUserOldOrders,
  getUserSearchData,
} from "./userSaga";
import {
  getPromoCodeData,
  getPromoByLocation,
  getPromoByUserId,
} from "./promocodeSaga";
import {
  getMachinesInfo,
  getBotStatus,
  getMasterMachines,
  getBotReports,
} from "./botSaga";
import { getDailyPlans, fetchOperations } from "./dailyPlanSaga";

export default function* rootSaga() {
  yield all([
    takeLatest("LOGIN", login),
    takeLatest("GET_LOCATIONS", getLocations),
    takeLatest("GET_ACTIVITIES", getActivities),
    takeLatest("GET_ORDER_DATA", orderData),
    takeLatest("GET_INCART_DATA", orderIncart),
    takeLatest("GET_CART_DATA", itemsIncart),
    takeLatest("GET_PROMO_CODES", getPromoCodeData),
    takeLatest("GET_LOCATION_BASED_PROMOS", getPromoByLocation),
    takeLatest("GET_USER_PROMOS", getPromoByUserId),
    takeLatest("LOAD_OLD_ORDERS", oldOrdersByFilter),
    takeLatest("GET_ALL_USERS", getAllUsers),
    takeLatest("GET_ALL_LOCATIONS", getAllLocations),
    takeLatest("GET_ALL_ADMIN_USERS", getAllAdminUsers),
    takeLatest("GET_SELECTED_USER", getSelectedUSer),
    takeLatest("GET_USER_OLD_ORDERS", getUserOldOrders),
    takeLatest("GET_LOCATIONS_MENU", getCurrentLocationMenu),
    takeLatest("GET_CATEGORIES", getCategories),
    takeLatest("GET_USER_FEEDBACK", getUserFeedback),
    takeLatest("GET_CURRENT_AVAILABLE_MENU", getCurrentLocAvailMenu),
    takeLatest("GET_MACHINES_INFO", getMachinesInfo),
    takeLatest("GET_MASTER_MACHINES", getMasterMachines),
    takeLatest("GET_BOT_STATUS", getBotStatus),
    takeLatest("GET_NEWREGISTERED_USERS", getRegisteredUsers),
    takeLatest("GET_FEEDBACKS", getTodaysFeedback),
    takeLatest("GET_USER_SEARCH_DATA", getUserSearchData),
    takeLatest("GET_COMMON_MENU", getAllMenu),
    takeLatest("GET_DAILY_PLANS", getDailyPlans),
    takeLatest("GET_OPERATIONS", fetchOperations),
    takeLatest("GET_BOT_REPORTS", getBotReports),
    fork(watchActivities),
  ]);
}
