import React from 'react'

const ErrorFallback = ({ error, resetErrorBoundary }) => {
	return (
		<div role='alert'>
			<p>Something went wrong, please copy the below information to report:</p>
			<pre>{error.message}</pre>
			<pre>{error.stack}</pre>

			<button onClick={resetErrorBoundary}>Back to Home</button>
		</div>
	)
}

export default ErrorFallback
